import React, { useState } from "react";
import TaskComment from "./TaskComment";
import { PrimaryButton, SecondaryButton } from "./Button";
import { BsPersonCircle } from "react-icons/bs";
import { serverDELETE, serverPOST, serverPUT } from "../../HttpFunctions";
import { useMutation, useQueryClient } from "react-query";

export type Comment = {
  _id?: string;
  task?: string;
  comment: string;
  user?: { _id: string; first_name: string; last_name };
  createdAt?: Date;
  updatedAt?: Date;
};

type CommentList = {
  taskId: string;
  comments: Comment[];
  commentRefetch: any;
  isApproved?: boolean;
};

export default function CommentList({
  taskId,
  comments,
  commentRefetch,
  isApproved,
}: CommentList) {
  const [comment, setComment] = useState("");
  const queryClient = useQueryClient();

  const createCommentMutation = useMutation({
    mutationFn: (comment: Comment) => {
      return serverPOST("/comment/create", comment);
    },
    onError: (context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any) => {
      queryClient.setQueryData(["comments"], (oldData: any) => {
        return {
          comments: {
            ...oldData.comments,
            [data.comment.task]: [
              data.comment,
              ...oldData.comments[data.comment.task],
            ],
          },
        };
      });
    },
  });

  const updateCommentMutation = useMutation({
    mutationFn: (updatedComment: any) => {
      return serverPUT(`/comment/${updatedComment._id}`, updatedComment);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any) => {
      if (commentRefetch) {
        commentRefetch();
      }
    },
  });

  const deleteCommentMutation = useMutation({
    mutationFn: (commentId: string) => {
      return serverDELETE(`/comment/delete/${commentId}`);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any, variables, context) => {
      if (commentRefetch) {
        commentRefetch();
      }
    },
  });

  const createComment = (comment) => {
    createCommentMutation.mutate(comment);
  };

  const updateComment = (comment) => {
    updateCommentMutation.mutate(comment);
  };

  const deleteComment = (commentId) => {
    deleteCommentMutation.mutate(commentId);
  };

  return (
    <div>
      <p className="text-sm text-gray-500 font-semibold leading-6">
        {comments?.length === 0 && isApproved ? "" : "Comments"}
      </p>
      {isApproved ? (
        <></>
      ) : (
        <div className="flex">
          <BsPersonCircle className="text-customGrey-600 text-3xl mt-2" />
          <div className="ml-2 w-full">
            <div>
              <input
                className=" placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3
          shadow-sm focus:outline-none focus:border-sky-500
          focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-gray-300"
                placeholder={"Add comment here"}
                type={"text"}
                name={"name"}
                onChange={(event) => setComment(event.target.value)}
                value={comment}
              />
            </div>
            <div className="w-full mt-3.5">
              {comment === "" ? null : (
                <div className="">
                  <SecondaryButton text="Cancel" />
                  <PrimaryButton
                    text="Save"
                    className="ml-3"
                    onClick={() => {
                      createComment({
                        task: taskId,
                        comment: comment,
                      });
                      setComment("");
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
      {comments?.map((x) => {
        return (
          <TaskComment
            commentInfo={x}
            deleteComment={deleteComment}
            comments={comments}
            updateComment={updateComment}
            isApproved={isApproved}
          />
        );
      })}
    </div>
  );
}

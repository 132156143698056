import moment from "moment";
import React, { useState } from "react";
import { APPROVED, DISAPPROVED, PENDING } from "../../utils/Constants";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import { TimeOffRequest as TimeOffRequestModel } from "../User/RequestALeave";
import Chip from "../Global/Chip";
import Dialogue from "../Global/Dialogue";

type TimeOffRequestProps = {
  selectedTimeOffRequest: TimeOffRequestModel;
  closeClick: () => void;
  onApprove: () => void;
  onReject: () => void;
};

export default function TimeOffRequest({
  selectedTimeOffRequest,
  closeClick,
  onApprove,
  onReject,
}: TimeOffRequestProps) {
  return (
    <Dialogue
      title="Leave Request"
      description="All the details to the requested leave are listed below."
      handleClose={closeClick}
    >
      <div className="relative p-6 flex-auto">
        <div className="md:grid md:grid-cols-2 gap-4 w-full">
          <div className="min-h-100 bg-transparent w-full border border-gray-200 border-solid p-2 rounded-sm ">
            <div className="flex">
              <div className="w-96">
                <h2 className="text-sm">Employee Name</h2>
                <p className="text-sm text-gray-500">
                  {selectedTimeOffRequest?.user?.first_name +
                    " " +
                    selectedTimeOffRequest?.user?.last_name}
                </p>
              </div>

              {/* <div className="w-96">
                      <h2 className="text-sm">Employee Id</h2>
                      <p className="text-sm text-gray-500">
                        {selectedTimeOffRequest?.user?._id}
                      </p>
                    </div> */}
              <div className="w-96">
                <h2 className="text-sm">Leave Type</h2>
                <p className="text-sm text-gray-500">
                  {selectedTimeOffRequest?.leave_type}
                </p>
              </div>
            </div>

            <div className="flex">
              <div className="w-96">
                <h2 className="text-sm">Number of Days</h2>
                <p className="text-sm text-gray-500">
                  {selectedTimeOffRequest?.no_of_days}
                </p>
              </div>
            </div>
          </div>

          <div className="min-h-100 bg-transparent w-full border border-gray-200 border-solid p-2 rounded-sm">
            <div className="w-96">
              <h2 className="text-sm">Period</h2>
              <p className="text-sm text-gray-500">
                {moment(selectedTimeOffRequest?.start_time).format(
                  "DD-MM-YYYY"
                )}
              </p>
            </div>

            <div className="w-96 grid grid-cols-2">
              <div>
                <h2 className="text-sm">Duration</h2>
                <p className="text-sm text-gray-500">
                  {Math.floor(selectedTimeOffRequest?.duration_minutes / 60) +
                    " hr " +
                    (selectedTimeOffRequest?.duration_minutes % 60) +
                    " min "}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="min-h-100 bg-transparent w-full border border-gray-200 border-solid p-2 rounded-sm mt-4 mb-4">
          <div>
            <h2 className="text-sm">Description</h2>
            <p className="text-sm text-gray-500">
              {selectedTimeOffRequest?.description}
            </p>
          </div>
        </div>
        {/* 
               <div className="min-h-100 bg-transparent w-full border-solid p-2 rounded-sm"> 
                <div className="w-96 flex items-center">
                  <h2 className="text-sm pr-2">Status</h2>
                  <p className="text-sm text-gray-500 bg-customGrey-100 rounded-full px-4 py-2"> 
                    {selectedTimeOffRequest?.status}
                  </p>
                </div>
              </div> */}
        {/* 
              <div className="min-h-100 bg-transparent w-full border-solid p-2 rounded-sm">
                {selectedTimeOffRequest?.status === PENDING ? (
                  <PendingChip />
                ) : selectedTimeOffRequest?.status === APPROVED ? (
                  <ApprovedChip />
                ) : (
                  <DisapprovedChip />
                )}
              </div> */}
        <Chip
          color={
            selectedTimeOffRequest?.status === PENDING
              ? "bg-customGrey-400 text-customeGrey-500"
              : selectedTimeOffRequest?.status === APPROVED
              ? "bg-paleGreen-100 text-paleGreen-200"
              : "bg-customRed-100 text-customRed-200"
          }
          title={
            selectedTimeOffRequest?.status === DISAPPROVED
              ? "Denied"
              : selectedTimeOffRequest?.status
          }
        />
      </div>

      {/* {selectedTimeOffRequest?.status === PENDING && ( */}
      <div className="flex items-center justify-center p-6 bg-customGrey-100">
        <div className="pr-4">
          <SecondaryButton
            // type="button"
            onClick={() => {
              onReject();
              closeClick();
            }}
            text="Deny"
          />
        </div>
        <PrimaryButton
          // type="button"
          text={"Approve"}
          onClick={() => {
            onApprove();
            closeClick();
          }}
        />
      </div>
    </Dialogue>
  );
}

export const INSPECTION = { name: "Inspection & Reports", is_billable: true };
export const LAB_TESTING = { name: "Lab Testing", is_billable: true };
export const DESIGN_ANALYSIS = {
  name: "Design & Analysis",
  is_billable: true,
};
export const FIELD_COLLECTION = {
  name: "Field Data Collection",
  is_billable: true,
};

export const AUDITS = { name: "Audits", is_billable: false };
export const PROPOSALS = { name: "Proposal", is_billable: false };
export const MEETING = { name: "Meeting", is_billable: false };
export const BUSINESS = { name: "Business Development", is_billable: false };

export const GENERAL_TASKS = { name: "General Tasks", is_billable: false };
export const ACCOUNTS = { name: "Accounts", is_billable: false };
export const INVOICING = { name: "Invoicing", is_billable: false };
export const TRAVEL = { name: "Travel Time", is_billable: false };
export const SPECIAL_TASKS = { name: "Special Projects", is_billable: true };

export const OFFICE = "Office";
export const REMOTE = "Remote";
export const FIELD = "Field";

export const PENDING = "Pending";

export const APPROVED = "Approved";
export const DISAPPROVED = "Disapproved";
export const REVISED = "Revised";

export const PTO = "PTO";
export const HOLIDAY = "Holiday";
export const VACATION = "Vacation";

export const PAGE_COUNT = 50;

export const ACTIVE = "Active";
export const ARCHIVED = "Inactive";

export const PM = "pm";
export const USER = "user";
export const ADMIN = "admin";

//to Store
export const HOURLY_RATE_TYPE = "hourlyRate";
export const UNIT_PRICE_TYPE = "unitPrice";
export const COST_FEE_TYPE = "costFee";

//to render
export const HOURLY_RATE = "Hourly Rate";
export const UNIT_PRICE = "Unit Price";
export const COST_FEE = "Cost Fee";

export const MULTIPLIER = "Multiplier";

export const TableHeaders = {
  hourlyRate: "Hourly Services",
  costFee: "Cost plus fee Services",
  unitPrice: "Unit price Services",
  nonBillable: "Non-billable Services",
};

export const SECONDS_IN_A_DAY = 86400000;

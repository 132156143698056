import React from "react";
import { default as ReactSelect } from "react-select";
import { components } from "react-select";
import { FiltersModel } from "./FilteredChecks";

export const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

export type SelectProps = {
  setOptionsSelected?: React.Dispatch<React.SetStateAction<any[] | any>>;
  optionsSelected?: FiltersModel;
  options: { value: string; label: string }[];
  label: string;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
};

//uppercase first letter in placeholder
const placeHolderLetter = (word) => word.charAt(0).toUpperCase() + word.slice(1)

export function MultiSelect({
  options,
  setOptionsSelected,
  optionsSelected,
  label,
  setPage,
}: SelectProps) {
  const handleChange = (selected) => {
    setPage(0);
    if (Array.isArray(selected)) {
      setOptionsSelected({
        ...optionsSelected,
        [label]: selected.map((opt) => opt.value),
      });
    }
  };
  return (
    <span className="flex max-w-[240px]">
      <ReactSelect
        options={options}
        styles={{
          control: (styles) => ({
            ...styles,
            width: "100%",
            minWidth: "9rem",
            fontSize: "14px",
          }),
          multiValue: (styles, { data }) => ({
            ...styles,
            width: "100px",
            minWidth: "60px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            maxWidth: "140px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            flexWrap: "nowrap",
          }),
        }}
        defaultValue={[]}
        isMulti
        closeMenuOnSelect={false}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        placeholder={placeHolderLetter(label)}
        onChange={handleChange}
      />
    </span>
  );
}

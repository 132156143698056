import { RiHandbagLine } from "react-icons/ri";
import { IoMdTime } from "react-icons/io";
import { CiCircleCheck, CiDollar } from "react-icons/ci";
import moment from "moment";
import { convertMinutesToHoursAndMinutes } from "../../utils/CommonFunctions";

const CardDetails = (props) => {
  const { data, showCards } = props;
  const { hours: totalHours, minutes: totalMinutes } =
    convertMinutesToHoursAndMinutes(data?.totalMinutes);
  const { hours: totalBillablelHours, minutes: totalBillablelMinutes } =
    convertMinutesToHoursAndMinutes(data?.totalBillableMinutes);
  const { hours: totalPaidHours, minutes: totalPaidMinutes } =
    convertMinutesToHoursAndMinutes(data?.totalPaidMinutes);

  return (
    <div className=" grid grid-cols-1 md:grid-cols-4 gap-4 mb-3">
      {showCards?.[0] && (
        <div className="bg-[#F7F1FF] rounded-lg">
          <div className="flex justify-between items-center p-4">
            <div>
              <p className="text-lg font-semibold">{data?.count || 0}</p>
              <p className="color-[#6F727A] ">Total tasks</p>
            </div>
            <div className="bg-[#9B4DFF] p-2 rounded-full">
              <RiHandbagLine className="text-2xl text-white" />
            </div>
          </div>
        </div>
      )}
      {showCards?.[1] && (
        <div className="bg-[#E9F9F0] rounded-lg">
          <div className="flex justify-between items-center p-4">
            <div>
              {/* <p className="text-lg font-semibold">{`${moment
                .duration(data?.totalMinutes || 0, "minutes")
                .asHours()
                .toFixed(4)} H`}</p>
              <p className="color-[#6F727A] ">Total Hours</p> */}
              <p className="text-lg font-semibold">
                {totalMinutes > 0
                  ? `${totalHours} hrs - ${totalMinutes} mins`
                  : `${isNaN(totalHours) ? 0 : totalHours} hrs`}
              </p>
              <p className="color-[#6F727A] ">Total time</p>
            </div>
            <div className="bg-[#21C56D] p-2 rounded-full">
              <IoMdTime className="text-2xl text-white" />
            </div>
          </div>
        </div>
      )}
      {showCards?.[2] && (
        <div className="bg-[#EBF9FF] rounded-lg">
          <div className="flex justify-between items-center p-4">
            <div>
              {/* <p className="text-lg font-semibold">{`${moment
                .duration(data?.totalBillableMinutes || 0, "minutes")
                .asHours()
                .toFixed(4)} H`}</p>
              <p className="color-[#6F727A] ">Billable Hours</p> */}
              <p className="text-lg font-semibold">
                {totalBillablelMinutes > 0
                  ? `${totalBillablelHours} hrs - ${totalBillablelMinutes} mins `
                  : `${
                      isNaN(totalBillablelMinutes) ? 0 : totalBillablelMinutes
                    } hrs`}
              </p>
              <p className="color-[#6F727A] ">Total billable time</p>
            </div>

            <div className="bg-[#33BFFF] p-2 rounded-full">
              <CiCircleCheck className="text-2xl text-white" />
            </div>
          </div>
        </div>
      )}
      {showCards?.[3] && (
        <div className="bg-[#FFF6E5] rounded-lg">
          <div className="flex justify-between items-center p-4">
            <div>
              {/* <p className="text-lg font-semibold">{`${moment
                .duration(data?.totalPaidMinutes || 0, "minutes")
                .asHours()
                .toFixed(4)} H`}</p>
              <p className="color-[#6F727A] ">Total Paid Hours</p> */}
              <p className="text-lg font-semibold">
                {totalPaidMinutes > 0
                  ? `${totalPaidHours} hrs - ${totalPaidMinutes} mins `
                  : `${isNaN(totalPaidHours) ? 0 : totalPaidHours} hrs`}
              </p>
              <p className="color-[#6F727A] ">Total paid time</p>
            </div>

            <div className="bg-[#FF6633] p-2 rounded-full">
              <CiDollar className="text-2xl text-white" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CardDetails;

import React, { createElement, useState } from "react";
import ClientTable from "../../components/Admin/ClientTable";
import { serverGET, serverPOST } from "../../HttpFunctions";
import { useQuery } from "react-query";
import EditClient from "../../components/Admin/EditClient";
import Pagination from "../../components/Global/Pagination";
import { useNavigate } from "react-router";
import Alert from "../../components/Global/Toasts";
import ContentContainer from "../../components/Global/ContentContainer";
import Spinner from "src/components/Global/Spinner";

export default function ClientList() {
  const [editClient, setEditClient] = useState(null);
  const [page, setPage] = useState(0);
  const navigate = useNavigate();

  const { error, data, isLoading, isPreviousData, refetch, status } = useQuery({
    queryKey: ["clients"],
    queryFn: () => serverGET(`/project/clients?isAdmin=1`),
    // keepPreviousData: true,
    // staleTime: Infinity,
    // cacheTime: Infinity,
  });

  if (isLoading) {
    // return createElement("div", null, <Spinner />);
    return <Spinner />;
  }

  if (error) {
    return createElement("div", null, "Something went wrong");
  }

  // if (data?.constructor === String) {
  //   return <h1 className="text-lg text-center w-full mt-4">{data}</h1>;
  // }

  const handleClick = (client) => {
    setEditClient(client);
  };

  const onClientClick = (clientId) => {
    navigate(`/dashboard/client-projects/${clientId}`);
  };

  if(isLoading) 
    {
      return <Spinner/> 
    }
    
  return (
    <ContentContainer>
      <ClientTable data={data} handleClick={handleClick} />
      {/* <Pagination
        page={page}
        data={data}
        setPage={setPage}
        isPreviousData={isPreviousData}
      /> */}
      {!!editClient && (
        <EditClient
          editClient={editClient}
          setEditClient={setEditClient}
          // page={page}
          // index={index}
          // refetch={refetch}
        />
      )}
      <Alert />
    </ContentContainer>
  );
}

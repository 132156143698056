import moment from "moment";
import { useState } from "react";
import { APPROVED, DISAPPROVED, PENDING } from "./Constants";
import momentTimezone from "moment-timezone";

export const groupWeekView = (tasks, user) => {
  let taskToLoop = [];
  if (user) {
    taskToLoop = tasks;
  } else {
    taskToLoop = tasks[0];
  }
  const groups = taskToLoop.reduce((groups, task) => {
    let date = momentTimezone(task.start_time)
      .tz(task?.timezone || "America/New_York")
      .format("DD-MM-YYYY");
    if (!groups[date]) {
      groups[date] = [];
    }
    groups[date].push(task);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date,
      total_hrs: groups[date].reduce(
        (total, a) =>
          a.duration_minutes ? total + a.duration_minutes : total + 0,
        0
      ),

      bill_hrs: groups[date].reduce(
        (total, a) =>
          a?.billable_minutes ? total + a.billable_minutes : total + 0,
        0
      ),
      pending_hrs: groups[date].reduce(
        (total, a) =>
          a.status === PENDING ? total + a.duration_minutes : total + 0,
        0
      ),
      accepted_hrs: groups[date].reduce(
        (total, a) =>
          a.status === APPROVED ? total + a.duration_minutes : total + 0,
        0
      ),
      denied_hrs: groups[date].reduce(
        (total, a) =>
          a.status === DISAPPROVED ? total + a.duration_minutes : total + 0,
        0
      ),
      tasks: groups[date],
    };
  });

  return groupArrays;
};

export const groupWeekViewType = (tasks, user) => {
  let taskToLoop = [];
  if (user) {
    taskToLoop = tasks;
  } else {
    taskToLoop = tasks[0];
  }
  const groups = taskToLoop.reduce((groups, task) => {
    let identifier =
      task.type.name +
      "-" +
      task.project._id +
      "-" +
      task.project.client._id +
      "-" +
      task?.service?._id;

    if (!groups[identifier]) {
      groups[identifier] = [];
    }
    groups[identifier].push(task);
    return groups;
  }, {});

  const groupArrays = Object.keys(groups).map((date) => {
    return {
      date, //its not actually its key
      total_hrs: groups[date].reduce(
        (total, a) =>
          a.duration_minutes ? total + a.duration_minutes : total + 0,
        0
      ),

      bill_hrs: groups[date].reduce(
        (total, a) =>
          a?.billable_minutes ? total + a.billable_minutes : total + 0,
        0
      ),
      pending_hrs: groups[date].reduce(
        (total, a) =>
          a.status === PENDING ? total + a.duration_minutes : total + 0,
        0
      ),
      accepted_hrs: groups[date].reduce(
        (total, a) =>
          a.status === APPROVED ? total + a.duration_minutes : total + 0,
        0
      ),
      denied_hrs: groups[date].reduce(
        (total, a) =>
          a.status === DISAPPROVED ? total + a.duration_minutes : total + 0,
        0
      ),
      tasks: groups[date],
    };
  });

  return groupArrays;
};

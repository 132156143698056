import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { schema } from "../../components/Admin/AddClient";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Dialogue from "../Global/Dialogue";
import Input from "../Global/Input";
import { useMutation, useQueryClient } from "react-query";
import { serverPUT } from "../../HttpFunctions";
import { successNotfiy } from "../Global/Toasts";

type editClientProps = {
  editClient: any;
  setEditClient: any;
};
export default function EditClient({
  editClient,
  setEditClient,
}: editClientProps) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
    },
  });

  const queryClient = useQueryClient();
  useEffect(() => {
    reset(editClient);
  }, [editClient]);

  const handleClose = () => {
    setEditClient("");
  };

  const mutation = useMutation({
    mutationFn: (client: any) => {
      return serverPUT(`/project/client/${client._id}`, client);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(["clients"], (oldData: any) => {
        const clients = oldData.clients.map((client) => {
          return client._id === updatedData.client._id
            ? updatedData.client
            : client;
        });

        return {
          clients,
        };
      });

      successNotfiy("Client has been updated successfully");
    },
  });

  function onSubmit(data) {
    mutation.mutate(data);
    handleClose();
  }

  return (
    <Dialogue
      title="Edit Client"
      description="Input relevant data and click on Update to update client"
      handleClose={handleClose}
      central={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative p-6 flex-auto">
          <div className="grid md:grid-cols-2 gap-8 my-2">
            <div>
              <Input
                label={"Client Name"}
                placeholder="Enter client name"
                type={"text"}
                name="name"
                regProp={{ ...register("name") }}
                error={errors.name?.message}
              />
            </div>
            <div>
              <Input
                label={"Email"}
                placeholder="Enter email"
                type={"text"}
                name="email"
                regProp={{ ...register("email") }}
                error={errors.email?.message}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-8 my-2">
            <div>
              <Input
                label={"Phone Number"}
                placeholder="Enter phone number"
                type={"text"}
                name="phoneNo"
                regProp={{ ...register("phone_number") }}
                error={errors.phone_number?.message}
              />
            </div>
          </div>
        </div>

        {/*footer*/}
        <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <PrimaryButton text="Update" mutation={mutation} />
        </div>
      </form>
    </Dialogue>
  );
}

import React from "react";
import "./button.css";
import { ButtonSpinner } from "./Spinner";

type buttonProps = {
  text: string;
  onClick?: () => void;
  className?: string;
  mutation?: any;
  disabled?: boolean;
  isLoading?: boolean;
  icon?: any;
};

export function PrimaryButton({
  text,
  onClick,
  className,
  mutation,
  disabled,
  isLoading,
}: buttonProps) {
  return (
    <button
      type="submit"
      className={`p-custom1 rounded-md text-sm hover:bg-customPurple-900 text-white border-2 border-customPurple-900 bg-customPurple-800 ${className} disabled:bg-customPurple-400 capitalize`}
      onClick={onClick}
      disabled={mutation?.isLoading || disabled || isLoading}
    >
      {mutation?.isLoading || isLoading ? <ButtonSpinner /> : text}
    </button>
  );
}

export function SecondaryButton({ text, onClick, isLoading }: buttonProps) {
  return (
    <button
      type="button"
      className="secondary-button secondary-hover-effect capitalize"
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? <ButtonSpinner /> : text}
    </button>
  );
}

export function PrimaryIconButton() {
  return <button className="primary-icon-button">Cancel</button>;
}

export function SecondaryIconButton({
  text,
  icon,
  onClick,
  disabled,
}: buttonProps) {
  return (
    <button
      className=" bg-transparent text-customPurple-900 border border-customPurple-900 p-custom1 rounded-md text-sm flex font-medium disabled:border-customGrey-600 disabled:text-customGrey-600 capitalize"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
      {icon && <div className="pl-2 pt-1">{icon}</div>}
    </button>
  );
}

export function TertiaryButton() {
  return <button className="tertiary-button-button">Cancel</button>;
}

export function TertiaryIconButton() {
  return <button className="tertiary-icon-button">Cancel</button>;
}

export function DestructiveButton({ text, onClick, disabled = false }) {
  return (
    <button
      className="destructive-button disabled:bg-red-400 capitalize"
      onClick={onClick}
      disabled={disabled}
    >
      {text}
    </button>
  );
}

import React, { useState } from "react";
import Dialogue from "../Global/Dialogue";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { serverDELETE } from "../../HttpFunctions";
import { successNotfiy } from "../Global/Toasts";
import Input from "../Global/Input";
import {
  DestructiveButton,
  PrimaryButton,
  SecondaryButton,
} from "../Global/Button";
import { Employee } from "src/pages/User/EmployeeList";

type deleteUserProps = {
  delModal: Employee;
  setDelModal: React.Dispatch<React.SetStateAction<Employee>>;
};

export default function DeleteUser({ delModal, setDelModal }: deleteUserProps) {
  const [delText, setDelText] = useState("");
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: () => {
      return serverDELETE(`/user/${delModal._id}`);
    },
    onError: (context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData) => {
      if (updatedData && updatedData.constructor !== String) {
        queryClient.setQueryData(["employees"], (oldData: any) => {
          let temp: any = [];
          if (oldData?.employees) {
            temp = [...oldData.employees];
          } else {
            temp = [...oldData];
          }
          const user = temp.find((e) => e._id === delModal._id);
          if (user) {
            const index = temp.indexOf(user);
            temp.splice(index, 1);
          }
          return temp;
        });
        successNotfiy("Employee deleted successfully");
      }
      handleClose();
    },
  });

  const handleDelete = () => {
    if (delText.trim() === "DELETE") {
      mutation.mutate();
    }
  };
  const handleClose = () => {
    setDelModal({} as Employee);
  };

  return (
    <Dialogue
      title="Delete this team member?"
      handleClose={handleClose}
      central={true}
    >
      <div className="px-4">
        <h1 className="text-sm text-center">
          Are you sure you want to delete/undo the this team member? <br />
          Note: Member will be automatically logged out of their JPCL account{" "}
          <br /> and cannot access it unless added again by the admin as a team
          member.
        </h1>
        <p className="my-2 italic text-sm text-center text-red-600">
          Please Write DELETE in Capital letters
        </p>
        <input
          className="placeholder:text-slate-400 block bg-white w-full border border-customGrey-400 rounded-md py-2 pl-3 pr-3
         shadow-sm focus:outline-none focus:border-sky-500
          focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
          placeholder={"Write Delete in capital letters"}
          type={"text"}
          name={"delete"}
          onChange={(e) => setDelText(e.target.value)}
        />
        <div className="flex items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <DestructiveButton
            text={"Delete"}
            onClick={handleDelete}
            disabled={delText !== "DELETE"}
          />
        </div>
      </div>
    </Dialogue>
  );
}

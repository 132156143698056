import React from "react";
import { HOURLY_RATE, MULTIPLIER, UNIT_PRICE } from "src/utils/Constants";
import Input from "../Global/Input";
import Select from "../Global/Select";

export default function CostUnit({ register, watch, errors }) {
  console.log("dsdsdsdd", watch("overtime"));
  return (
    <>
      <div className="grid md:grid-cols-3 gap-4 mt-4">
        <div>
          <Select
            label={"Cost rate type"}
            id="cost_type"
            regProp={{ ...register("costType") }}
            options={[HOURLY_RATE, UNIT_PRICE]}
            error={errors.costType?.message}
          />
        </div>
        <div>
          <Input
            label={"Cost rate"}
            placeholder="$"
            type={"text"}
            name="costRate"
            regProp={{ ...register("costRate") }}
            error={errors.costRate?.message}
          />
        </div>
        <div>
          <Select
            label={"Overtime"}
            id="overtime"
            regProp={{ ...register("overtime") }}
            options={["1", "1.5", "2", "2.5", "3"]}
            error={errors.overtime?.message}
          />
        </div>
      </div>
      <div className="grid md:grid-cols-2 gap-8 mt-4">
        <div>
          <Select
            label={"Fee rate type"}
            id="feeType"
            regProp={{ ...register("feeType") }}
            options={[UNIT_PRICE, MULTIPLIER]}
            error={errors.feeType?.message}
          />
        </div>

        {watch("feeType") == UNIT_PRICE ? (
          <div>
            <Input
              label={"Fee Rate"}
              placeholder="$"
              type={"text"}
              name="feeRate"
              regProp={{ ...register("feeRate") }}
              error={errors.feeRate?.message}
            />
          </div>
        ) : (
          <div>
            <Select
              label={"Fee Rate"}
              id="feeRate"
              regProp={{ ...register("feeRate") }}
              options={[1, 1.5, 2, 2.5, 3]}
              error={errors.feeRate?.message}
            />
          </div>
        )}
      </div>
    </>
  );
}

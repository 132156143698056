import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect, useState } from "react";
import { HiOutlineMail, HiOutlinePhone } from "react-icons/hi";
import ContentContainer from "src/components/Global/ContentContainer";
import Input, { PasswordInput } from "src/components/Global/Input";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { PrimaryButton, SecondaryButton } from "src/components/Global/Button";
import { useAuth } from "src/context/Auth";
import { PM, USER } from "src/utils/Constants";
import { serverPUT } from "../../HttpFunctions";
import { useMutation } from "react-query";
import Alert, { successNotfiy } from "../../components/Global/Toasts";

export const infoSchema = yup
  .object({
    firstName: yup.string().required().max(16),
    lastName: yup.string().required().max(16),
    phone: yup.string().required().min(8),
  })
  .required();

export default function Settings() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
  } = useForm({
    resolver: yupResolver(infoSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      phone: "",
    },
  });
  const { user, updateUserInfo } = useAuth();
  const [disableButton, setDisableButton] = useState(true);
  const initials =
    user?.first_name?.charAt(0) + user?.last_name?.charAt(0).toUpperCase();

  useEffect(() => {
    setValue("firstName", user.first_name);
    setValue("lastName", user.last_name);
    setValue("phone", user.phone_number);
  }, []);

  useEffect(() => {
    if (
      watch("firstName") === user.first_name &&
      watch("lastName") === user.last_name &&
      watch("phone") === user.phone_number
    ) {
      setDisableButton(true);
    } else setDisableButton(false);
  }, [watch("firstName"), watch("lastName"), watch("phone")]);

  const mutation = useMutation({
    mutationFn: (user: any) => {
      return serverPUT("/user/updateInfo", user);
    },
    onError: (context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any) => {
      successNotfiy("Employee information successfully updated");
      updateUserInfo(data?.user);
    },
  });

  const onSubmit = (data) => {
    let updateData = {
      first_name: data.firstName,
      last_name: data.lastName,
      phone_number: data.phone,
    };

    mutation.mutate(updateData);
  };

  return (
    <ContentContainer>
      <p className="text-2xl font-bold">Settings</p>
      <div className="border-t border-gray-300 my-4"></div>
      <div className="block md:flex">
        <div className="w-full md:w-[30%]">
          <div className="flex flex-col items-center gap-2">
            <div
              className={`w-32 h-32 flex items-center justify-center rounded-full bg-customPurple-400`}
            >
              <span className="text-5xl font-semibold capitalize">
                {initials}
              </span>
            </div>
            <p className="text-xl font-bold capitalize">
              {user.first_name} {user.last_name}
            </p>
            <p className="text-base font-normal text-customGrey-300 capitalize">
              {user.type_of_user.map((type, ind) => {
                return (
                  <>
                    {type === "pm" ? "Project Manager" : type}
                    {ind !== user.type_of_user.length - 1 && ", "}
                  </>
                );
              })}
            </p>
          </div>
          <div className="border-t border-gray-300 my-4"></div>
          <div className="flex flex-col jus items-center">
            <div className="flex items-center pb-2">
              <HiOutlineMail />
              <p className="text-sm pl-2 font-medium text-customGrey-900">
                {user.email}
              </p>
            </div>
            <div className="flex items-center">
              <HiOutlinePhone />
              <p className="text-sm pl-2 font-medium text-customGrey-900">
                {user.phone_number}
              </p>
            </div>
            <div></div>
          </div>
        </div>

        <div className="h-full border-l border-gray-300"></div>
        <div className="w-full md:w-[50%] ml-0 md:ml-5">
          <form onSubmit={handleSubmit(onSubmit)}>
            <p className="text-2xl font-bold">Personal Info</p>
            <div className="grid md:grid-cols-2 gap-4 my-2">
              <div>
                <Input
                  label={"First Name"}
                  placeholder="First Name"
                  type={"text"}
                  name="firstName"
                  regProp={{ ...register("firstName") }}
                  error={errors.firstName?.message}
                />
              </div>
              <div>
                <Input
                  label={"Last Name"}
                  placeholder="Last Name"
                  type={"text"}
                  name="lastName"
                  regProp={{ ...register("lastName") }}
                  error={errors.lastName?.message}
                />
              </div>
              <Input
                label={"Phone Number"}
                placeholder="Phone Number"
                type={"text"}
                name="phone"
                regProp={{ ...register("phone") }}
                error={errors.phone?.message}
              />
            </div>
            <div className="mt-2">
              <PrimaryButton text="Save changes" disabled={disableButton} />
            </div>
          </form>
          <ChangePassword />
        </div>
      </div>
    </ContentContainer>
  );
}

export const passwordSchema = yup
  .object({
    oldPassword: yup.string().required().min(8),
    newPassword: yup.string().required().min(8),
    confirmPassword: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref("newPassword")], "Passwords do not match"),
  })
  .required();

function ChangePassword() {
  const [showChangePassword, setShowChangePassword] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
  });

  const mutation = useMutation({
    mutationFn: (user: any) => {
      return serverPUT("/user/updatePassword", user);
    },
    onError: (context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any) => {
      successNotfiy("Employee information successfully updated");
    },
  });

  const onSubmit = (data) => {
    let updateData = {
      old_password: data.oldPassword,
      new_password: data.newPassword,
    };

    mutation.mutate(updateData);
  };
  return (
    <div>
      <p className="text-2xl font-bold pt-5 mb-2">Password</p>

      {!showChangePassword ? (
        <SecondaryButton
          text="Change Password"
          onClick={() => setShowChangePassword(true)}
        />
      ) : (
        <div className="my-2 gap-2 flex flex-col">
          <form onSubmit={handleSubmit(onSubmit)}>
            <PasswordInput
              label={"Old Password"}
              placeholder="********"
              type={"password"}
              name="password"
              regProp={{ ...register("oldPassword") }}
              error={errors.oldPassword?.message}
            />
            <PasswordInput
              label={"New Password"}
              placeholder="********"
              type={"password"}
              name="password"
              regProp={{ ...register("newPassword") }}
              error={errors.newPassword?.message}
            />
            <PasswordInput
              label={"Comfirm Password"}
              placeholder="********"
              type={"password"}
              name="password"
              regProp={{ ...register("confirmPassword") }}
              error={errors.confirmPassword?.message}
            />

            <div className="mt-2">
              <PrimaryButton text="Save Password" />
            </div>
          </form>
        </div>
      )}
      <div className="relative">
        <Alert />
      </div>
    </div>
  );
}

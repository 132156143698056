import React from "react";
import ProjectList from "../Project/ProjectList";

export default function ClientProjectList() {
  return (
    <>
      <ProjectList isClientProjects={true} />
    </>
  );
}

import { serverGET } from "../HttpFunctions";

export const fetchData = async (url: string, options, isSheet?) => {
  // Build the URL with query parameters
  options.status.forEach((value) => {
    url = url + `&status=${value}`;
  });
  options.type.forEach((value) => {
    const query = encodeURIComponent(value);
    url = url + `&type=${query}`;
  });
  options.employee.forEach((value) => {
    url = url + `&employee=${value}`;
  });
  options.project.forEach((value) => {
    url = url + `&project=${value}`;
  });
  options.client.forEach((value) => {
    url = url + `&client=${value}`;
  });
  if (options.date) {
    url = url + `&date=${options.date.getTime()}`;
  }
  if (options.endDate) {
    url = url + `&endDate=${options.endDate.getTime()}`;
  }
  if (options.create_startDate) {
    url = url + `&create_startDate=${options.create_startDate.getTime()}`;
  }
  if (options.create_endDate) {
    url = url + `&create_endDate=${options.create_endDate.getTime()}`;
  }
  if (isSheet) return url;

  const data = await serverGET(url);
  return data;
};

import React, { createElement, useState } from "react";
import TaskTypeTable from "../../components/Admin/TaskTypeTable";
import { useQuery } from "react-query";
import { serverGET } from "../../HttpFunctions";
// import EditTaskType from "../../components/Admin/EditTaskType";
import { useParams } from "react-router";
import Alert from "../../components/Global/Toasts";
import { SecondaryIconButton } from "../../components/Global/Button";
import { FaPlus } from "react-icons/fa";
import AddTaskType from "../../components/Admin/AddTaskType";
import ContentContainer from "../../components/Global/ContentContainer";
import Spinner from "src/components/Global/Spinner";
import EditTaskType from "src/components/Admin/EditTaskType";

export default function TaskTypeList({}: {}) {
  const [editTaskType, setEditTaskType] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  let path = "/taskType/";
  const { isLoading, data, error } = useQuery({
    queryKey: ["taskTypes"],
    queryFn: () => serverGET(path),
  });

  if (isLoading) {
    return <Spinner />;
  }

  if (error) {
    return createElement("div", null, "Something went wrong");
  }

  const handleClick = (index) => {
    setEditTaskType(data?.taskTypes[index]);
  };

  return (
    <ContentContainer>
      <div className="flex">
        <h1 className="text-xl pb-5 font-semibold">Task types</h1>
        <div className="ml-auto">
          <SecondaryIconButton
            text="TaskType"
            icon={<FaPlus />}
            onClick={() => setOpenDialog(true)}
          />
        </div>
      </div>
      {data?.constructor === String || !data ? (
        <h1 className="text-lg text-center w-full mt-4">{data}</h1>
      ) : (
        <TaskTypeTable data={data} handleClick={handleClick} />
      )}
      {!!editTaskType && (
        <EditTaskType
          editTaskType={editTaskType}
          setEditTaskType={setEditTaskType}
        />
      )}
      {!openDialog ? null : (
        <AddTaskType handleClose={() => setOpenDialog(false)} />
      )}
      <Alert />
    </ContentContainer>
  );
}

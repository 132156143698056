import moment from "moment";
import { useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { serverGET, serverPUT } from "../../HttpFunctions";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Alert from "../../components/Global/Toasts";
import { groupWeekViewType } from "../../utils/GroupTable";
import ContentContainer from "../../components/Global/ContentContainer";
import { downloadExcel } from "src/utils/DownloadExcel";
import { useAuth } from "src/context/Auth";
import EditTimeLog from "./EditTimeLog";
import Dialogue from "src/components/Global/Dialogue";
import AddTimeLog from "./AddTimeLog";
import UserWeekView from "src/components/User/UserWeekView";
import { AiOutlineCalendar } from "react-icons/ai";
import { HiOutlineViewList } from "react-icons/hi";

export type HeaderInfo = {
  service: { id: string; value: string };
  client: { id: string; value: string };
  project: { id: string; value: string };
  taskType: { id: string; value: string; is_billable?: boolean };
  taskDate: Date;
};

export default function AddTimelogWeek({
  handleViewChange,
}: {
  handleViewChange: () => void;
}) {
  const { user } = useAuth();
  const [tasksData, setTasksData] = useState(null);
  const [selectedTasks, setSelectedTasks] = useState({
    tasks: [],
    startDate: null,
  });
  const [addTasks, setAddTasks] = useState(false);
  const [headerInfo, setHeaderInfo] = useState({} as HeaderInfo);
  const [currentWeekStart, setCurrentWeekStart] = useState(
    moment()
      .day(6 - 7)
      .startOf("day")
  );
  const [currentWeekEnd, setCurrentWeekEnd] = useState(() =>
    moment()
      .day(6 - 7)
      .add(6, "days")
      .startOf("day")
  );
  const { error, isLoading, data, refetch } = useQuery({
    queryKey: ["user-tasks-user", user?._id, currentWeekStart.valueOf()],
    queryFn: () =>
      serverGET(
        `/task?startTime=${currentWeekStart.valueOf()}&endTime=${currentWeekEnd.valueOf()}`
      ),
    // keepPreviousData: true,
    enabled: !!user, //isAdmin is false then fetch only
  });
  useEffect(() => {
    gatherData();
  }, [data]);
  const gatherData = async () => {
    if (data && data?.tasks?.length > 0) {
      let groupedData = [];
      let tasks = [...data?.tasks];
      groupedData = groupWeekViewType(tasks, true);
      setTasksData({ count: data?.count, tasks: groupedData });
    } else {
      // setTasksData({ count: 0, tasks: [] });
      handleAddTimesheetRow({ isEmpty: true });
    }
  };
  const getNextWeek = () => {
    const nextWeekStart = currentWeekStart.clone().add(1, "weeks");
    const nextWeekEnd = currentWeekEnd.clone().add(1, "weeks");
    setCurrentWeekStart(nextWeekStart);
    setCurrentWeekEnd(nextWeekEnd);
  };
  const getPrevWeek = () => {
    const prevWeekStart = currentWeekStart.clone().subtract(1, "weeks");
    const prevWeekEnd = currentWeekEnd.clone().subtract(1, "weeks");
    setCurrentWeekStart(prevWeekStart);
    setCurrentWeekEnd(prevWeekEnd);
  };
  const handleDownload = async () => {
    downloadExcel(
      `/task/downloadWeek?start_time=${currentWeekStart.valueOf()}`
    );
  };
  const handleAddTimesheetRow = ({ isEmpty }: { isEmpty: boolean }) => {
    const newObj = {
      accepted_hrs: 0,
      bill_hrs: 0,
      pending_hrs: 0,
      denied_hrs: 0,
      total_hrs: 0,
      date: "undefined-undefined-undefined-undefined",
      tasks: [],
    };
    if (isEmpty) {
      setTasksData((prev) => {
        return { count: 1, tasks: [newObj] };
      });
    } else {
      setTasksData((prev) => {
        return { count: prev.count + 1, tasks: [...prev.tasks, newObj] };
      });
    }
  };

  return (
    <div>
      <div
        className={`block md:flex items-center justify-between pb-5 sticky top-0 sm:only:bg-customGrey-200 z-10 sm:only:py-1`}
      >
        <div className="flex justify-center items-center text-xl font-semibold">
          <p>Timesheet</p>
          <span>
            <button
              className="flex items-center ml-4 text-sm gap-2 text-customPurple-400 cursor-pointer hover:text-customPurple-900"
              onClick={() => handleViewChange()}
            >
              Switch to list view <HiOutlineViewList />
            </button>
          </span>
        </div>
        <div className="flex py-2">
          <div className="flex justify-left items-center px-2">
            {currentWeekStart.format("MMM DD YYYY")} -{" "}
            {currentWeekEnd.format("MMM DD YYYY")}
            {/* <FaCalendarAlt className="ml-2" /> */}
          </div>
          {/* <div>
            <button
              className="px-3 py-1 border border-customPurple-400 text-customPurple-400 rounded-md enabled:secondary-hover-effect disabled:border-gray-400 disabled:text-gray-400"
              onClick={handleDownload}
            >
              Export
            </button>
          </div> */}
          <div className="flex ml-auto items-center">
            <FaArrowLeft
              onClick={getPrevWeek}
              className="mr-4 cursor-pointer"
            />
            <FaArrowRight onClick={getNextWeek} className="cursor-pointer" />
          </div>
        </div>
      </div>
      <UserWeekView
        currentWeekStart={currentWeekStart}
        data={tasksData}
        setTasksData={setTasksData}
        setSelectedTasks={setSelectedTasks}
        setAddTasks={setAddTasks}
        setHeaderInfo={setHeaderInfo}
        handleAddTimesheetRow={handleAddTimesheetRow}
        refetch={refetch}
        isAdmin={false}
        loading={isLoading}
      />
      {selectedTasks.tasks.length > 0 ? (
        <Dialogue
          handleClose={() => setSelectedTasks({ tasks: [], startDate: null })}
          title={`Task details of ${selectedTasks.startDate}`}
          width="max-w-xl"
          // description="Edit tasks here"
        >
          <EditTimeLog
            editTask={selectedTasks}
            isWeekView={true}
            handleClose={() => setSelectedTasks({ tasks: [], startDate: null })}
            weeklyRefetch={refetch}
          />
        </Dialogue>
      ) : null}
      {addTasks ? (
        <Dialogue
          handleClose={() => setAddTasks(false)}
          title={`Task details for ${headerInfo.taskDate}`}
          width="max-w-xl"
          // description="Add tasks here"
        >
          <AddTimeLog
            isWeekView={true}
            headerInfo={headerInfo}
            handleClose={() => setAddTasks(false)}
            weeklyRefetch={refetch}
          />
        </Dialogue>
      ) : null}
      <Alert />
    </div>
  );
}

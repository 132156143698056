import React from "react";
import ReactTable from "react-table-6";
import { FaEdit } from "react-icons/fa";
import Chip from "../Global/Chip";
import { APPROVED, DISAPPROVED } from "../../utils/Constants";

type TaskTypeTableProps = {
  data: any;
  handleClick?: (taskType) => void;
  isPm?: boolean;
};

export default function TaskTypeTable({
  data,
  handleClick,
  isPm,
}: TaskTypeTableProps) {
  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Billable",
      accessor: "isBillable",
    },
    {
      Header: "Status",
      accessor: "status",
      show: !isPm,

      Cell: (row) => (
        <div>
          <div className="py-2">
            <Chip
              color={
                row.original?.status
                  ? "bg-paleGreen-100 text-paleGreen-200"
                  : "bg-customRed-100 text-customRed-200"
              }
              title={row.original?.status ? APPROVED : DISAPPROVED}
            />
          </div>
        </div>
      ),
    },
    {
      Header: "Actions",
      maxWidth: 100,
      show: !isPm,

      Cell: ({ row }) => (
        <div>
          <FaEdit
            className={"text-black cursor-pointer"}
            onClick={() => handleClick(row._index)}
          />
        </div>
      ),
    },
  ];

  const tableData = data.taskTypes.map((taskType) => {
    return {
      name: taskType.name,
      isBillable: taskType.is_billable ? "Yes" : "No",
      status: taskType.is_active,
    };
  });

  return (
    <div>
      <ReactTable
        data={tableData}
        columns={columns}
        showPagination={false}
        pageSize={tableData.length}
        style={{ maxHeight: "calc(100vh - 160px)" }}
      />
    </div>
  );
}

import moment from "moment";
import React, { createElement, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { getEmployeesWithTasks } from "../../api";
import WeekTable from "../../components/Admin/WeekTable";
import { serverGET } from "../../HttpFunctions";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Alert from "../../components/Global/Toasts";
import { groupWeekView } from "../../utils/GroupTable";
import ContentContainer from "../../components/Global/ContentContainer";
import { downloadExcel } from "src/utils/DownloadExcel";
import Spinner from "src/components/Global/Spinner";
import ExportDate from "src/components/Admin/ExportDate";

export default function Home() {
  const [tasksData, setTasksData] = useState([]);
  const [exportModal, setExportModal] = useState(false);

  const [currentWeekStart, setCurrentWeekStart] = useState(
    moment()
      .day(6 - 7)
      .startOf("day")
  );
  const [currentWeekEnd, setCurrentWeekEnd] = useState(() =>
    moment()
      .day(6 - 7)
      .add(6, "days")
      .startOf("day")
  );
  const [exportedDates, setExportedDates] = useState(() => {
    return {
      start: currentWeekStart?.toDate(),
      end: currentWeekEnd?.toDate(),
    };
  });
  const { isLoading, isError,data, error, isPreviousData } = useQuery({
    queryKey: ["tasks", currentWeekStart.valueOf()],
    keepPreviousData: true,
    // staleTime: Infinity,
    // cacheTime: Infinity,
    queryFn: () =>
      serverGET(
        getEmployeesWithTasks +
          `?page=0&start_time=${currentWeekStart.valueOf()}`
      ),
  });

  // useEffect(() => {
  //   if (!isPreviousData) {
  //     queryClient.prefetchQuery({
  //       queryKey: ["tasks", currentWeekStart.valueOf()],
  //       queryFn: () =>
  //         serverGET(
  //           getEmployeesWithTasks +
  //             `?page=0&start_time=${currentWeekStart.valueOf()}`
  //         ),
  //     });
  //   }
  // }, [data, isPreviousData, queryClient]);

  useEffect(() => {
    gatherData();
  }, [data]);

  const gatherData = async () => {
    if (data && data?.length > 0) {
      let userData = [...data];
      for (let i = 0; i < data.length; i++) {
        let tasks = data[i].tasks;
        if (tasks.length > 0) {
          const groupedData = groupWeekView(tasks, null);
          userData[i].total_hrs = groupedData.reduce(
            (total, a) => a.total_hrs + total,
            0
          );
          userData[i].bill_hrs = groupedData.reduce(
            (total, a) => a.bill_hrs + total,
            0
          );
          userData[i].pending_hrs = groupedData.reduce(
            (total, a) => a.pending_hrs + total,
            0
          );
          userData[i].accepted_hrs = groupedData.reduce(
            (total, a) => a.accepted_hrs + total,
            0
          );
          userData[i].denied_hrs = groupedData.reduce(
            (total, a) => a.denied_hrs + total,
            0
          );
          userData[i].tasks[0] = groupedData;
        }
      }
      setTasksData(userData);
    }
  };

  if (isLoading) {
    // return createElement("div", null, "Loading....");
    return <Spinner />;
  }

  if (isError) {
    return createElement("div", null, "Something went wrong");
  }

  if (data?.constructor === String) {
    return <h1 className="text-lg text-center w-full mt-4">{data}</h1>;
  }

  const getNextWeek = () => {
    const nextWeekStart = currentWeekStart.clone().add(1, "weeks");
    const nextWeekEnd = currentWeekEnd.clone().add(1, "weeks");
    setCurrentWeekStart(nextWeekStart);
    setCurrentWeekEnd(nextWeekEnd);
  };

  const getPrevWeek = () => {
    const prevWeekStart = currentWeekStart.clone().subtract(1, "weeks");
    const prevWeekEnd = currentWeekEnd.clone().subtract(1, "weeks");
    setCurrentWeekStart(prevWeekStart);
    setCurrentWeekEnd(prevWeekEnd);
  };

  const handleDownload = async () => {
    console.log(currentWeekStart, exportedDates);
    let params = `start_time=${currentWeekStart.valueOf()}`;
    if (exportedDates.start && exportedDates.end) {
      params = `start_time=${exportedDates.start.getTime()}&end_time=${
        exportedDates.end.getTime() + 86400000
      }`; //adding plus 1 day to make it 12 am of next day
    }
    downloadExcel(`/task/downloadWeek?${params}`);
  };

  const handleModal = () => {
    setExportModal((prev) => !prev);
  };

  return (
    <ContentContainer>
      <div>
        <p className="flex justify-center text-xl font-semibold">Timesheet</p>
        <div className="flex py-2">
          <div className="flex justify-left items-center px-2">
            {currentWeekStart.format("MMM DD YYYY")} -{" "}
            {currentWeekEnd.format("MMM DD YYYY")}
            {/* <FaCalendarAlt className="ml-2" /> */}
          </div>

          <div>
            <button
              className="px-3 py-1 border border-customPurple-400 text-customPurple-400 rounded-md enabled:secondary-hover-effect disabled:border-gray-400 disabled:text-gray-400"
              onClick={handleModal}
            >
              Export
            </button>
          </div>
          <div className="flex ml-auto items-center">
            <FaArrowLeft
              onClick={getPrevWeek}
              className="mr-4 cursor-pointer"
            />
            <FaArrowRight onClick={getNextWeek} className="cursor-pointer" />
          </div>
        </div>
      </div>
      <WeekTable
        currentWeekStart={currentWeekStart}
        data={tasksData}
        isAdmin={true}
      />
      {exportModal && (
        <ExportDate
          handleModal={handleModal}
          exportedDates={exportedDates}
          setExportedDates={setExportedDates}
          handleDownload={handleDownload}
        />
      )}

      <Alert />
    </ContentContainer>
  );
}

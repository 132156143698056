import React, { ReactElement } from "react";
import { APPROVED, DISAPPROVED, PENDING } from "src/utils/Constants";
import { IoCheckmark, IoCloseOutline } from "react-icons/io5";
import { HiOutlineDotsHorizontal } from "react-icons/hi";
import { BiRevision } from "react-icons/bi";
import { TbCurrencyDollar, TbCurrencyDollarOff, TbReceipt, TbReceiptOff } from "react-icons/tb";

export default function Chip({
  color,
  title,
  onClick,
}: {
  color: string;
  title: string;
  onClick?;
}) {
  return (
    <div
      className={`rounded-full p-1 my-2 ${color} max-w-fit`}
      style={{
        fontSize: "18px",
      }}
      onClick={onClick}
    >
      {title === PENDING ? (
        <HiOutlineDotsHorizontal />
      ) : title === DISAPPROVED || title === "Denied" ? (
        <IoCloseOutline />
      ) : title === APPROVED ? (
        <IoCheckmark />
      ) : (
        <BiRevision />
      )}
    </div>
  );
}

export function PaymentChip({
  paid,
  sicon,
  bgColor,
  onClick,
}: {
  paid: boolean;
  bgColor: string;
  sicon: string;
  onClick?: () => void;
}) {
  return (
    <div
      className={`rounded-full p-1 my-2 text-sm ${bgColor} ${
        paid ? "text-paleGreen-200" : "text-customRed-200"
      } max-w-fit cursor-pointer`}
      style={{
        fontSize: "18px",
      }}
      onClick={onClick}
    >
      { sicon === "invoice"  ? 
        ( paid ? <TbReceipt className=" rounded-full  text-paleGreen-200 border-paleGreen-200" />
        : (<TbReceiptOff className=" rounded-full bg-customRed-100 text-customRed-200 border-customRed-200" />))
      : 
        (paid && sicon === "not" ? <TbCurrencyDollar className="border border-solid rounded-full  text-paleGreen-200 border-paleGreen-200" />
        : 
        <TbCurrencyDollarOff className="border border-solid rounded-full bg-customRed-100 text-customRed-200 border-customRed-200" />
      )}
    </div>
  );
}

import axios from "axios";

export const getToken = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user).token;
  }
};

export const serverGET = async (path) => {
  let response = await axios.get(process.env.REACT_APP_HOST + path, {
    crossDomain: true,
    mode: "no-cors",
    headers: { token: getToken() },
    params: { currentTime: new Date().getTime() },
  });
  if (response) {
    if (response.data.code === 200) {
      return response.data.data;
    } else if (response.data.code === 401) {
      localStorage.removeItem("user");
      window.location.reload();
    } else {
      return response.data.message;
    }
  } else {
    return undefined;
  }
};

export const serverPOST = async (path, body) => {
  let response = await axios.post(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: getToken() },
  });
  if (response) {
    if (response.data.code === 201) {
      return response.data.data;
    } else if (response.data.code === 401) {
      localStorage.removeItem("user");
      window.location.reload();
    } else {
      return response.data.message;
    }
  } else {
    return undefined;
  }
};

export const serverPUT = async (path, body) => {
  let response = await axios.put(process.env.REACT_APP_HOST + path, body, {
    crossDomain: true,
    headers: { token: getToken() },
  });
  if (response) {
    if (response.data.code === 200) {
      return response.data.data;
    } else if (response.data.code === 401) {
      localStorage.removeItem("user");
      window.location.reload();
    } else {
      return response.data.message;
    }
  } else {
    return undefined;
  }
};

export const serverDELETE = async (path) => {
  let response = await axios.delete(process.env.REACT_APP_HOST + path, {
    crossDomain: true,
    headers: { token: getToken() },
  });
  if (response) {
    if (response.data.code === 200) {
      return response.data.data;
    } else if (response.data.code === 401) {
      localStorage.removeItem("user");
      window.location.reload();
    } else {
      return response.data.message;
    }
  } else {
    return undefined;
  }
};

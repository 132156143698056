import AWS from "aws-sdk";
import { v4 as uuidv4 } from "uuid";

export function minutesToHHMM(minutes: number) {
  let hours = Math.floor(minutes / 60);
  let remainingMinutes = minutes % 60;

  let formattedHours = hours.toString().padStart(2, "0");
  let formattedMinutes = remainingMinutes.toString().padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export async function uploadToBucket(attachments) {
  const S3_BUCKET = "jpcl-files";
  // S3 Region
  const REGION = "us-east-1";

  // S3 Credentials
  AWS.config.update({
    accessKeyId: process.env.REACT_APP_AWS_ACCESS,
    secretAccessKey: process.env.REACT_APP_AWS_SECRET,
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  // Files Parameters
  const urls = await Promise.all(
    attachments.map(async (file) => {
      let uniqueId = uuidv4();
      const fileNameWithoutSpaces = file.name.replace(/\s/g, "_");
      const url = uniqueId + "_" + fileNameWithoutSpaces;
      // const url = encodeURIComponent(uniqueId + "_" + file.name);
      const params = {
        Bucket: S3_BUCKET,
        Key: url,
        Body: file,
      };

      // Uploading file to s3
      try {
        var upload = await s3
          .putObject(params)
          .on("httpUploadProgress", (evt) => {
            // File uploading progress
            // console.log(
            //   "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
            // );
            console.log("Uploading....");
          })
          .promise();

        console.log("🚀 ~ urls ~ upload:", upload);
        return `https://jpcl-files.s3.amazonaws.com/${url}`;
      } catch (err) {
        console.error(err);
        return undefined;
      }
    })
  );
  return urls;
}

export async function deleteFromBucket(keys) {
  const S3_BUCKET = "jpcl-files";
  // S3 Region
  const REGION = "us-east-1";

  // S3 Credentials
  AWS.config.update({
    accessKeyId: "AKIA262BSQFUC43DE2WJ",
    // AKIA262BSQFUMD6LAD6E
    secretAccessKey: "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",
    // SDjM1IHOxnrkhaefzpkA38MvSpQeepZpOvk3w/ST
  });
  const s3 = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION,
  });

  const payload = keys.map((key) => {
    return { Key: new URL(key).pathname.substring(1) };
  });
  const params = {
    Bucket: S3_BUCKET,
    Delete: {
      Objects: payload,
      Quiet: false, // Set to true to suppress errors in case of partial deletion
    },
  };

  s3.deleteObjects(params, (err, data) => {
    if (err) {
      console.error("Error deleting objects:", err);
    } else {
      console.log("Objects deleted successfully:", data);
    }
  });
}

export function convertMinutesToHoursAndMinutes(totalMinutes) {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;
  return { hours, minutes };
}

export function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}
// Function to check if token is expired
function isTokenExpired(token) {
  const payload = parseJwt(token);
  const expiry = payload.exp;
  const now = Math.floor(Date.now() / 1000); // Current time in seconds
  return now > expiry;
}

// Function to get token and validate
function getToken() {
  const token = localStorage.getItem("jwt");
  if (token && isTokenExpired(token)) {
    // logoutUser(); // Log out the user if the token is expired
    return null;
  }
  return token;
}

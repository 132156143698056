import React, { useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import Pagination from "../../components/Global/Pagination";
import { serverGET, serverPUT } from "../../HttpFunctions";
import { useSearchParams } from "react-router-dom";
import UserTable from "../../components/User/UserTable";
import { APPROVED, DISAPPROVED } from "../../utils/Constants";
import { successNotfiy } from "../../components/Global/Toasts";
import TimeLogDetails from "../../components/Admin/TimeLogDetails";
import ContentContainer from "../../components/Global/ContentContainer";
import Spinner from "../../components/Global/Spinner";

export default function User() {
  const [searchParams] = useSearchParams();
  const userId = searchParams.get("userId");
  const [selectedTask, setSelectedTask] = useState(null);
  const [page, setPage] = useState(0);
  const queryClient = useQueryClient();
  const { error, isLoading, data, isPreviousData } = useQuery({
    queryKey: ["user-tasks", userId, page],
    queryFn: () => serverGET(`/user/taskdetails?userId=${userId}&page=0`),
    enabled: !!userId,
    keepPreviousData: true,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  const mutation = useMutation({
    mutationFn: (updatedTask) => {
      return serverPUT(`/task/${updatedTask._id}`, updatedTask);
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData, variables, context) => {
      queryClient.setQueryData(["user-tasks", userId, page], (oldData) => {
        return {
          ...oldData,
          tasks: oldData?.tasks?.map((data) => {
            return data._id === updatedData.task._id
              ? {
                  ...data,
                  status: updatedData.task.status,
                  billable_minutes: updatedData.task?.billable_minutes,
                }
              : data;
          }),
        };
      });
      //showing message
      successNotfiy("Record has been updated successfully");
      closeClick();
    },
  });

  useEffect(() => {
    if (!isPreviousData) {
      queryClient.prefetchQuery({
        queryKey: ["user-tasks", userId, page + 1],
        queryFn: () =>
          serverGET(`/user/taskdetails?userId=${userId}&page=${page + 1}`),
      });
    }
  }, [data, isPreviousData, page, queryClient]);

  if (isLoading) {
    return <Spinner/>
  }

  if (error) {
    return "Something went wrong";
  }

  if (data?.constructor === String) {
    return <h1 className="text-lg text-center w-full mt-4">{data}</h1>;
  }

  const handleClick = (task) => {
    setSelectedTask(task);
  };
  const closeClick = () => {
    setSelectedTask(null);
  };
  const handleDisapprove = (selectedTask) => {
    let updatedTask = {
      ...selectedTask,
      status: DISAPPROVED,
    };

    mutation.mutate(updatedTask);
  };
  const handleApprove = (selectedTask) => {
    let updatedTask = {
      ...selectedTask,
      status: APPROVED,
    };

    mutation.mutate(updatedTask);
  };

  const handleBillableHours = (billable_minutes) => {};

  return (
    <ContentContainer>
      <UserTable
        data={data}
        page={page}
        handleClick={handleClick}
        isAdmin={true}
        setSelectedTask={setSelectedTask}
      />
      <Pagination
        page={page}
        data={data}
        setPage={setPage}
        isPreviousData={isPreviousData}
      />
      {!!selectedTask && (
        <TimeLogDetails
          selectedTimeLog={selectedTask}
          closeClick={closeClick}
          handleDisapprove={handleDisapprove}
          handleApprove={handleApprove}
          handleBillableHours={handleBillableHours}
        />
      )}
    </ContentContainer>
  );
}

import React from "react";

type CheckboxProps = {
  label: string;
  placeholder?: string;
  type?: string;
  name: string;
  error?: string;
  regProp: {};
  isDisabled?: boolean;
  isChecked?: boolean;
};

export default function Checkbox({
  label,
  name,
  error,
  regProp,
  isDisabled,
  isChecked,
}: CheckboxProps) {
  return (
    <div className="flex">
      <input
        type="checkbox"
        name={name}
        {...regProp}
        disabled={isDisabled}
        checked={isChecked}
      />
      <label className="block text-sm font-medium text-slate-700 pl-2">
        {label}
      </label>
      <p className="text-sm text-red-500">{error}</p>
    </div>
  );
}

import React, { createElement, useEffect, useState } from "react";
import TimeOffRequest from "../../components/Admin/TimeOffRequest";
import TimeoffTable from "../../components/User/TimeoffTable";
import { serverGET, serverPUT } from "../../HttpFunctions";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useSearchParams } from "react-router-dom";
import { APPROVED, DISAPPROVED } from "../../utils/Constants";
import { useMutation } from "react-query";
import Pagination from "../../components/Global/Pagination";
import Alert, { successNotfiy } from "../../components/Global/Toasts";
import ContentContainer from "../../components/Global/ContentContainer";
import Spinner from "src/components/Global/Spinner";

export default function AdminTimeoff() {
  const navigate = useNavigate();
  const [selectedTimeOffRequest, setSelectedTimeOffRequest] = useState(null);
  const [page, setPage] = useState(0);
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (status: { status: string }) =>
      serverPUT(`/timeoff/update/${selectedTimeOffRequest._id}`, status),
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(["user-timeoffs", page], (oldData: any) => {
        return {
          ...oldData,
          timeOffs: oldData?.timeOffs?.map((data) => {
            return data._id === updatedData.timeOff._id
              ? {
                  ...data,
                  status: updatedData.timeOff.status,
                }
              : data;
          }),
        };
      });
      //showing message
      successNotfiy("TimeOff has been updated successfully");
    },
  });
  const { error, isLoading, data, isPreviousData } = useQuery({
    queryKey: ["user-timeoffs", page],
    queryFn: () => serverGET(`/timeoff/all?page=0`),
    keepPreviousData: true,
    staleTime: Infinity,
    cacheTime: Infinity,
  });

  useEffect(() => {
    if (!isPreviousData) {
      queryClient.prefetchQuery({
        queryKey: ["user-timeoffs", page + 1],
        queryFn: () => serverGET(`/timeoff/all?page=${page + 1}`),
      });
    }
  }, [data, isPreviousData, page, queryClient]);

  if (isLoading) {
    // return createElement("div", null, "Loading....");
    return <Spinner />;
  }

  if (error) {
    return createElement("div", null, "Something went wrong");
  }

  if (data?.constructor === String) {
    return <h1 className="text-lg text-center w-full mt-4">{data}</h1>;
  }

  const handleClick = (timeOffRequest) => {
    setSelectedTimeOffRequest(timeOffRequest);
  };
  const closeClick = () => {
    setSelectedTimeOffRequest(null);
  };

  return (
    <ContentContainer>
      <TimeoffTable isAdmin={true} handleClick={handleClick} data={data} />
      <Pagination
        page={page}
        data={data}
        setPage={setPage}
        isPreviousData={isPreviousData}
      />

      {!!selectedTimeOffRequest && (
        <TimeOffRequest
          selectedTimeOffRequest={selectedTimeOffRequest}
          closeClick={closeClick}
          onApprove={() => {
            const status = { status: APPROVED };
            mutation.mutate(status);
          }}
          onReject={() => {
            const status = { status: DISAPPROVED };
            mutation.mutate(status);
          }}
        />
      )}
      <Alert />
    </ContentContainer>
  );
}

import React, { useState } from "react";
import moment from "moment";
import { FaExternalLinkAlt, FaPlus, FaTrashAlt } from "react-icons/fa";
import ReactTable from "react-table-6";
import { APPROVED, DISAPPROVED, PENDING } from "../../utils/Constants";
import { SecondaryIconButton } from "../Global/Button";
import DeleteDialog from "../Global/DeleteDialog";
import {
  ApprovedChip,
  DisapprovedChip,
  PendingChip,
} from "../Global/StatusChip";
import RequestALeave, { TimeOffRequest } from "./RequestALeave";
import { useMutation } from "react-query";
import { serverDELETE } from "../../HttpFunctions";
import "./time-off-table.css";
import Chip from "../Global/Chip";
import Alert, { successNotfiy } from "../Global/Toasts";

type TimeoffTableProps = {
  data: any;
  isAdmin?: boolean;
  handleClick?: (index: number) => void;
  refetch?: () => void;
};

export default function TimeoffTable({
  data,
  isAdmin,
  handleClick,
  refetch,
}: TimeoffTableProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [requestDeleteId, setRequestDeleteId] = useState();
  const [selectedOption, setSelectedOption] = useState("");

  const columns = [
    {
      Header: "Name",
      show: !!isAdmin,
      accessor: "employeeName",
    },
    {
      Header: "Leave Type",
      accessor: "leaveType",
    },
    {
      Header: "Days",
      accessor: "days",
    },
    {
      Header: "Period",
      accessor: "period",
    },
    {
      Header: "Duration",
      accessor: "duration",
    },
    {
      Header: "Reason",
      accessor: "description",
      show: !!!isAdmin,
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ row }) => (
        <div>
          {
            <Chip
              color={
                row?.status === PENDING
                  ? "bg-customGrey-400 text-customeGrey-500"
                  : row?.status === APPROVED
                  ? "bg-paleGreen-100 text-paleGreen-200"
                  : "bg-customRed-100 text-customRed-200"
              }
              title={row?.status === DISAPPROVED ? "Denied" : row?.status}
            />
          }
        </div>
      ),
    },
    {
      Header: "Actions",
      Cell: ({ row }) => (
        <div>
          {isAdmin ? (
            <FaExternalLinkAlt
              className="cursor-pointer"
              onClick={() => {
                handleClick(data.timeOffs[row._index]);
              }}
            />
          ) : row?.status === PENDING ? (
            <button
              onClick={() => {
                setRequestDeleteId(row._original.id);
                setOpenDeleteDialog(true);
              }}
              className="cursor-pointer"
            >
              <FaTrashAlt className="text-red-500" />
            </button>
          ) : (
            <button className="cursor-pointer">
              <FaTrashAlt className="text-slate-500" />
            </button>
          )}
        </div>
      ),
    },
  ];

  const mutation = useMutation({
    mutationFn: () => serverDELETE(`/timeoff/delete/${requestDeleteId}`),
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data, variables, context) => {
      refetch();
      successNotfiy("TimeOff Request has been deleted");
    },
  });

  function convertMinutesToHours(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return (
      hours +
      ":" +
      remainingMinutes.toLocaleString([], { minimumIntegerDigits: 2 }) +
      " hrs"
    );
  }

  function calculatePeriod(
    start_time: number,
    end_time: number,
    no_of_days: number
  ) {
    let endDate = start_time + (no_of_days - 1) * 86400000;

    if (no_of_days === 1) return moment(start_time).format("MMM DD, YYYY");
    else
      return `${moment(start_time).format("MMM DD")} - ${moment(
        end_time
      ).format("MMM DD, YYYY")}`;
  }

  let tableData = data?.timeOffs?.map(
    (timeoff: TimeOffRequest, index: number) => {
      return {
        id: timeoff._id,
        employeeId: index,
        employeeName:
          `${timeoff.user.first_name} ${timeoff.user.last_name}`.replace(
            /\b\w/g,
            (match) => match.toUpperCase()
          ),
        leaveType: timeoff.leave_type,
        days: timeoff.no_of_days,
        description: timeoff.description,
        status: timeoff.status,
        period: calculatePeriod(
          timeoff.start_time,
          timeoff.end_time,
          timeoff.no_of_days
        ),
        duration: convertMinutesToHours(timeoff.duration_minutes),
      };
    }
  );

  return (
    <div>
      <div className="flex pb-5 items-center">
        <h1 className="text-xl font-semibold">TimeOff Request</h1>
        <div className="ml-auto">
          {isAdmin ? null : (
            <SecondaryIconButton
              text="Request a Leave"
              icon={<FaPlus />}
              onClick={() => setOpenDialog(true)}
            />
          )}
        </div>
      </div>
      {data.timeOffs.length > 0 ? (
        <ReactTable
          columns={columns}
          data={tableData}
          pageSize={tableData?.length}
          showPagination={false}
        />
      ) : (
        <h1 className="text-lg text-center w-full mt-4">No Leave Requests</h1>
      )}
      {!openDialog ? null : (
        <RequestALeave
          handleClose={() => {
            setOpenDialog(false);
          }}
          refetch={refetch}
        />
      )}
      {!openDeleteDialog ? null : (
        <DeleteDialog
          handleClose={() => setOpenDeleteDialog(false)}
          handleDelete={() => {
            mutation.mutate();
            setOpenDeleteDialog(false);
          }}
        />
      )}
      <Alert />
    </div>
  );
}

import React, { useContext, useEffect, useState } from "react";
import Avatar from "./Avatar";
import moment from "moment";
import { PrimaryButton, SecondaryButton } from "./Button";
import DeleteDialog from "./DeleteDialog";
import { Comment } from "./CommentList";
import { useAuth } from "src/context/Auth";

export default function TaskComment({
  comments,
  commentInfo,
  updateComment,
  deleteComment,
  isApproved,
}: {
  comments: Comment[];
  commentInfo: Comment;
  updateComment: (comment: Comment) => void;
  deleteComment: (commentId: string) => void;
  isApproved: boolean;
}) {
  const employeeName = `${commentInfo?.user?.first_name} ${commentInfo?.user?.last_name}`;
  const initials = `${commentInfo?.user?.first_name[0].toUpperCase()}${commentInfo?.user?.last_name[0].toUpperCase()}`;
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [editComment, setEditComment] = useState(false);
  const [comment, setComment] = useState(commentInfo.comment);
  const { user } = useAuth();
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (openDeleteDialog) {
      comments = [
        ...comments,
        comments?.find((x) => {
          if (x) {
            return x._id !== commentInfo._id;
          }
        }),
      ];
    }
  }, [openDeleteDialog]);

  return (
    <>
      <div className="flex mt-3.5">
        <Avatar initials={initials} color={"bg-pink-500"} size={"8"} />
        <div className="ml-2.5 w-full">
          <div className="flex gap-1 md:gap-3.5 items-center">
            <p className="text-sm text-customGrey-500 font-semibold capitalize">
              {employeeName}
            </p>
            <p className="text-xs text-customGrey-500">
              {moment(commentInfo.updatedAt).format("MMMM DD YYYY")}
            </p>
            <p className="text-xs text-customGrey-500">
              {moment(commentInfo.updatedAt).format("hh:mm A")}
            </p>
            {isMobileScreen || isApproved ? null : (
              <CommentActions
                commentInfo={commentInfo}
                userId={user._id}
                setOpenDeleteDialog={setOpenDeleteDialog}
                setEditComment={setEditComment}
              />
            )}
          </div>
          {!editComment ? (
            <p className="text-sm">{commentInfo.comment}</p>
          ) : (
            <div className="w-full">
              <div>
                <input
                  className=" placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3
                shadow-sm focus:outline-none focus:border-sky-500
                focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-gray-300"
                  placeholder={"Add comment here"}
                  type={"text"}
                  name={"name"}
                  onChange={(event) => setComment(event.target.value)}
                  value={comment}
                />
              </div>
              <div className="w-full mt-3.5">
                {comment === "" ? null : (
                  <div className="">
                    <SecondaryButton
                      text="Cancel"
                      onClick={() => setEditComment(false)}
                    />
                    <PrimaryButton
                      text="Save"
                      className="ml-3"
                      onClick={() => {
                        updateComment({
                          _id: commentInfo._id,
                          // task: commentInfo.task,
                          comment: comment,
                        });
                        setEditComment(false);
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
          )}

          <div className="mt-2">
            {!isMobileScreen || isApproved ? null : (
              <CommentActions
                commentInfo={commentInfo}
                userId={user._id}
                setOpenDeleteDialog={setOpenDeleteDialog}
                setEditComment={setEditComment}
              />
            )}
          </div>
        </div>
      </div>

      {!openDeleteDialog ? null : (
        <DeleteDialog
          title="Delete Comment"
          text="Are you sure you want to continue?"
          handleClose={() => setOpenDeleteDialog(false)}
          handleDelete={() => {
            deleteComment(commentInfo._id);
            setOpenDeleteDialog(false);
          }}
        />
      )}
    </>
  );
}

function CommentActions({
  commentInfo,
  userId,
  setOpenDeleteDialog,
  setEditComment,
}: {
  commentInfo: Comment;
  userId: string;
  setOpenDeleteDialog: React.Dispatch<React.SetStateAction<boolean>>;
  setEditComment: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="flex">
      {moment(commentInfo.updatedAt).valueOf() !==
      moment(commentInfo.createdAt).valueOf() ? (
        <p className="text-xs rounded-full px-2 py-0.5 items-center flex bg-customGrey-400 max-w-fit text-customGrey-500 mr-2">
          Edited
        </p>
      ) : null}
      {userId === commentInfo.user._id ? (
        <div className="flex item">
          <p
            className="text-sm text-customGrey-600 font-medium cursor-pointer"
            onClick={() => setOpenDeleteDialog(true)}
          >
            Delete
          </p>
          <>
            <div className="w-px bg-customGrey-400 mx-1"></div>

            <p
              className="text-sm text-customGrey-600 font-medium  cursor-pointer"
              onClick={() => setEditComment(true)}
            >
              Edit
            </p>
          </>
        </div>
      ) : null}
    </div>
  );
}

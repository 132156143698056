import React, { useState } from "react";
import { FaPlus } from "react-icons/fa";
import { useQuery } from "react-query";
import CreateServiceDiaglog from "src/components/Admin/CreateServiceDiaglog";
import ProjectServicesList from "src/components/Admin/ProjectServicesList";
import { SecondaryIconButton } from "src/components/Global/Button";
import ContentContainer from "src/components/Global/ContentContainer";
import { serverGET } from "../../HttpFunctions";
import Spinner from "src/components/Global/Spinner";

export default function ServiceList() {
  const [openDialog, setOpenDialog] = useState(false);
  const [editDialog, setEditDialog] = useState("");

  const { data, isLoading, refetch } = useQuery(`services`, () =>
    serverGET(`/service/global`)
  );

  const handleClose = () => {
    setOpenDialog(false);
    setEditDialog("");
  };

  if (isLoading)  {
    return <Spinner/> 
  }

  return (
    <ContentContainer>
      <>
         <div className="flex">
        <h1 className="text-xl pb-5 font-semibold">Services</h1>
        <div className="ml-auto">
          <SecondaryIconButton
            text="Service"
            icon={<FaPlus />}
            onClick={() => setOpenDialog(true)}
          />
        </div>
      </div>
      <h1 className="text-lg text-center w-full">
        {data && data?.services?.length > 0 ? (
          <ProjectServicesList
            services={data.services}
            refetch={refetch}
            setEditDialog={setEditDialog}
            global={true}
          />
        ) : null}
      </h1>
      {openDialog || !!editDialog ? (
        <CreateServiceDiaglog
          handleClose={handleClose}
          refetch={refetch}
          global={true}
          editDialog={editDialog}
        />
      ) : null}
      </>
    </ContentContainer>
  )
}

import React, { useState } from "react";
import Autocomplete from "react-autocomplete";

export default function TaskTypeAutoComplete({
  taskTypeData,
  watch,
  setValue,
  type,
  setType,
  setServiceType,
}: {
  taskTypeData: { taskTypes: any[] };
  watch: any;
  setValue: any;
  type: string;
  setType: any;
  setServiceType: any;
}) {
  const handleSelect = (val) => {
    setType(val);
    const t = taskTypeData.taskTypes.find((t) => t.name === val);
    if (t) {
      setValue("taskType", t._id);
      setValue("is_billable", t.is_billable);
      if (t.is_billable) {
        setServiceType(0);
      } else {
        setServiceType(-1);
      }
    } else {
      setValue("taskType", "");
      setValue("is_billable", false);
      setServiceType(-1);
    }
  };
  return (
    <>
      <label className="flex text-sm mb-2">
        Type
        <p className="text-sm text-red-500 ml-1">*</p>
      </label>
      {taskTypeData && (
        <Autocomplete
          getItemValue={(item) => item?.name}
          items={taskTypeData?.taskTypes}
          shouldItemRender={(item, value) =>
            item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
          }
          wrapperStyle={{ display: "block", position: "relative" }}
          inputProps={{
            placeholder: "Select task type",
            className:
              "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2",
          }}
          renderItem={(item, highlighted) => (
            <div
              key={item.name}
              style={{
                backgroundColor: highlighted ? "#eee" : "transparent",
              }}
              className="p-2"
            >
              {item.name}
            </div>
          )}
          value={type}
          onChange={(e) => handleSelect(e.target.value)}
          onSelect={(val) => handleSelect(val)}
          menuStyle={{
            zIndex: 999,
            boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
            position: "absolute",
            left: 0,
            top: 44,
            backgroundColor: "white",
            borderRadius: "8px",
            overflow: "auto",
            maxHeight: "200px",
          }}
        />
      )}
    </>
  );
}

import React, { createElement, useEffect, useState } from "react";
import TimeoffTable from "../../components/User/TimeoffTable";
import { useAuth } from "../../context/Auth";
import { useQuery, useQueryClient } from "react-query";
import { serverGET } from "../../HttpFunctions";
import Pagination from "../../components/Global/Pagination";
import ContentContainer from "../../components/Global/ContentContainer";
import Spinner from "src/components/Global/Spinner";

export default function Timeoff() {
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const queryClient = useQueryClient();

  const { error, data, isLoading, isPreviousData, refetch, status } = useQuery({
    queryKey: ["user-timeoff", user?._id, page],
    queryFn: () => serverGET(`/timeoff?page=${page}`, user.token),
    keepPreviousData: true,
    // staleTime: Infinity,
    // cacheTime: Infinity,
  });

  // useEffect(() => {
  //   if (!isPreviousData) {
  //     queryClient.prefetchQuery({
  //       queryKey: ["user-timeoff-user", user?._id, page + 1],
  //       queryFn: () => serverGET(`/timeoff?page=${page + 1}`),
  //     });
  //   }
  // }, [data, isPreviousData, page, queryClient]);

  if (error) {
    return createElement("div", null, "error");
  }

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <ContentContainer>
      <TimeoffTable data={data} refetch={refetch} />

      {data.timeOffs.length === 0 ? null : (
        <Pagination
          page={page}
          data={data}
          setPage={setPage}
          isPreviousData={isPreviousData}
        />
      )}
    </ContentContainer>
  );
}

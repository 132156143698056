import React from "react";
import { IoCheckmark, IoCloseOutline } from "react-icons/io5";
import {
  TbCurrencyDollar,
  TbCurrencyDollarOff,
  TbReceipt,
  TbReceiptOff,
} from "react-icons/tb";
import { useAuth } from "src/context/Auth";

type ComponentProps = {
  checked: boolean;
  selectedRows: string[];
  toggleSelectAll: () => void;
  handleBulkStatus: (status: number) => void;
  handlePaymentStatus: (isPaid: boolean) => void;
  handleInvoicedStatus: (is_invoiced: boolean) => void;
  pmApproval?: boolean;
  onChangePMAproval?: (e) => void;
  disabled?: boolean;
};

export default function CheckHeader({
  checked,
  selectedRows,
  toggleSelectAll,
  handleBulkStatus,
  handlePaymentStatus,
  handleInvoicedStatus,
  pmApproval,
  onChangePMAproval,
  disabled,
}: ComponentProps) {
  const { user } = useAuth();

  return (
    <div className=" justify-between flex gap-2">
      <div className="font-normal ml-2.5 flex gap-2 mb-2">
        <input
          type="checkbox"
          checked={checked}
          onChange={toggleSelectAll}
          disabled={disabled}
          className="accent-customPurple-400 bg-gray-100 border-gray-300 rounded"
        />

        <button
          className="cursor-pointer"
          onClick={() => handleBulkStatus(1)}
          disabled={selectedRows.length === 0}
          data-te-toggle="tooltip"
          data-te-placement="bottom"
          data-te-ripple-init
          data-te-ripple-color="light"
          title="Mark as approved"
        >
          <IoCheckmark
            className={`text-sm ml-3 border border-solid rounded-full ${
              selectedRows.length > 0
                ? "text-customPurple-400 border-customPurple-400"
                : "text-gray-300"
            }`}
          />
        </button>
        <button
          className="cursor-pointer"
          onClick={() => handleBulkStatus(0)}
          disabled={selectedRows.length === 0}
          data-te-toggle="tooltip"
          data-te-placement="bottom"
          data-te-ripple-init
          data-te-ripple-color="light"
          title="Mark as denied"
        >
          <IoCloseOutline
            className={`text-sm ml-3 border border-solid rounded-full  ${
              selectedRows.length > 0
                ? "text-customPurple-400 border-customPurple-400"
                : "text-gray-300"
            }`}
          />
        </button>
        {user?.type_of_user.includes("admin") && (
          <>
            <button
              className="cursor-pointer"
              onClick={() => handlePaymentStatus(true)}
              disabled={selectedRows.length === 0}
              data-te-toggle="tooltip"
              data-te-placement="bottom"
              data-te-ripple-init
              data-te-ripple-color="light"
              title="Mark as paid"
            >
              <TbCurrencyDollar
                className={`text-sm ml-3 border border-solid rounded-full  ${
                  selectedRows.length > 0
                    ? "text-customPurple-400 border-customPurple-400"
                    : "text-gray-300"
                }`}
              />
            </button>
            <button
              className="cursor-pointer"
              onClick={() => handlePaymentStatus(false)}
              disabled={selectedRows.length === 0}
              data-te-toggle="tooltip"
              data-te-placement="bottom"
              data-te-ripple-init
              data-te-ripple-color="light"
              title="Mark as unpaid"
            >
              <TbCurrencyDollarOff
                className={`text-sm ml-3 border border-solid rounded-full  ${
                  selectedRows.length > 0
                    ? "text-customPurple-400 border-customPurple-400"
                    : "text-gray-300"
                }`}
              />
            </button>

            <button
              className="cursor-pointer"
              onClick={() => handleInvoicedStatus(true)}
              disabled={selectedRows.length === 0}
              data-te-toggle="tooltip"
              data-te-placement="bottom"
              data-te-ripple-init
              data-te-ripple-color="light"
              title="Mark as approved"
            >
              <TbReceipt
                className={`text-sm ml-3 rounded-full  ${
                  selectedRows.length > 0
                    ? "text-customPurple-400 border-customPurple-400"
                    : "text-gray-300"
                }`}
              />
            </button>
            <button
              className="cursor-pointer"
              onClick={() => handleInvoicedStatus(false)}
              disabled={selectedRows.length === 0}
              data-te-toggle="tooltip"
              data-te-placement="bottom"
              data-te-ripple-init
              data-te-ripple-color="light"
              title="Mark as disapproved"
            >
              <TbReceiptOff
                className={`text-sm ml-3  ${
                  selectedRows.length > 0
                    ? "text-customPurple-400 border-customPurple-400"
                    : "text-gray-300"
                }`}
              />
            </button>
          </>
        )}
      </div>
      {user?.type_of_user.includes("admin") &&
        user?.type_of_user.includes("pm") &&
        onChangePMAproval && (
          <div className=" flex gap-1">
            <input
              type="checkbox"
              checked={pmApproval}
              onChange={onChangePMAproval}
              className="accent-customPurple-400 bg-gray-100 border-gray-300 rounded"
            />
            <label htmlFor="">PM approval view</label>
          </div>
        )}
    </div>
  );
}

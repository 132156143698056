import React, { createElement, useState } from "react";
import ProjectTable from "../../components/Admin/ProjectTable";
import { useQuery, useQueryClient } from "react-query";
import { serverGET } from "../../HttpFunctions";
import Pagination from "../../components/Global/Pagination";
import EditProject from "../../components/Admin/EditProject";
import { useParams } from "react-router";
import Alert from "../../components/Global/Toasts";
import { useSearchParams } from "react-router-dom";
import { SecondaryIconButton } from "../../components/Global/Button";
import { FaEdit, FaPlus } from "react-icons/fa";
import AddProject from "../../components/Admin/AddProject";
import ContentContainer from "../../components/Global/ContentContainer";
import Spinner from "src/components/Global/Spinner";

export default function Projects() {
  const [searchParams] = useSearchParams();

  const { isLoading, isError, status, data, error } = useQuery({
    queryKey: ["pm-projects"],
    queryFn: () => serverGET("/pm/projects"),
  });

  if (isLoading) {
    // return createElement("div", null, "Loading....");
    return <Spinner />;
  }

  if (error) {
    return createElement("div", null, "Something went wrong");
  }

  return (
    <ContentContainer>
      <div className="flex">
        <h1 className="text-xl pb-5 font-semibold capitalize">Projects</h1>
      </div>
      {data?.constructor === String || !data ? (
        <h1 className="text-lg text-center w-full mt-4">{data}</h1>
      ) : (
        <ProjectTable data={data} isPm={true} />
      )}
    </ContentContainer>
  );
}

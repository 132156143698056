import React, { useState } from "react";
import { useQuery } from "react-query";
import { serverGET } from "../../HttpFunctions";
import { getEmployees } from "../../api";
import EmployeeTable from "src/components/Admin/EmployeeTable";
import ContentContainer from "src/components/Global/ContentContainer";
import { SecondaryIconButton } from "src/components/Global/Button";
import { FaPlus } from "react-icons/fa";
import AddEmployee from "src/components/Admin/AddEmployee";
import Alert from "../../components/Global/Toasts";
import EditEmployee from "src/components/Admin/EditEmployee";
import ChangePassword from "src/components/Admin/ChangePassword";
import DeleteUser from "src/components/Admin/DeleteUser";
import Spinner from "src/components/Global/Spinner";

export type Employee = {
  emp_id?: string;
  _id: string;
  email: string;
  type_of_user: [string];
  first_name: string;
  last_name: string;
  labour_rate: number;
  phone_number: string;
  is_active: boolean;
};

export default function EmployeeList() {
  const [openDialog, setOpenDialog] = useState(false);
  const [editEmployee, setEditEmployee] = useState({} as Employee);
  const [passModal, setPassModal] = useState({} as Employee);
  const [delModal, setDelModal] = useState({} as Employee);
  const { isLoading, data, error, refetch } = useQuery({
    queryKey: ["employees"],
    queryFn: () => serverGET(getEmployees),
  });

  const handleClick = (index) => {
    if (data?.employees) setEditEmployee(data.employees[index]);
    else setEditEmployee(data[index]);
  };
  const handlePassClick = (index) => {
    if (data?.employees) setPassModal(data.employees[index]);
    else setPassModal(data[index]);
  };
  const handleDeleteClick = (index) => {
    if (data?.employees) setDelModal(data.employees[index]);
    else setDelModal(data[index]);
  };

  if(isLoading) return <Spinner/>
  
  return (
    <ContentContainer>
      <div className="flex">
        <h1 className="text-xl pb-5 font-semibold">Employee List</h1>
        <div className="ml-auto">
          <SecondaryIconButton
            text="Team member"
            icon={<FaPlus />}
            onClick={() => setOpenDialog(true)}
          />
        </div>
      </div>
      {Object.keys(editEmployee).length === 0 ? null : (
        <EditEmployee
          editEmployee={editEmployee}
          setEditEmployee={setEditEmployee}
        />
      )}
      {Object.keys(passModal).length === 0 ? null : (
        <ChangePassword passModal={passModal} setPassModal={setPassModal} />
      )}
      {Object.keys(delModal).length === 0 ? null : (
        <DeleteUser delModal={delModal} setDelModal={setDelModal} />
      )}

      <EmployeeTable
        employeeList={data?.employees ? data?.employees : data}
        handleClick={handleClick}
        handlePassClick={handlePassClick}
        handleDeleteClick={handleDeleteClick}
      />
      {!openDialog ? null : (
        <AddEmployee
          handleClose={() => setOpenDialog(false)}
          refetch={refetch}
        />
      )}
      <Alert />
    </ContentContainer>
  );
}

import React, { useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Select from "../Global/Select";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { HOLIDAY, PTO, VACATION } from "../../utils/Constants";
import Input from "../Global/Input";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment/moment";
import { useNavigate } from "react-router";
import { useMutation, useQuery } from "react-query";
import { useAuth } from "../../context/Auth";
import { serverPOST } from "../../HttpFunctions";
import Dialogue from "../Global/Dialogue";
import { successNotfiy } from "../Global/Toasts";

type TimeOffRequestForm = {
  leaveType: string;
  numberOfDays: number;
  description: string;
  hours: number;
  minutes: number;
  status?: string;
};

export type TimeOffRequest = {
  _id: number;
  user: { _id: number; first_name: string; last_name: string };
  leave_type: string;
  no_of_days: number;
  description: string;
  hours: number | string;
  minutes: number | string;
  status?: string;
  duration_minutes: number;
  start_time: number;
  end_time: number;
};

const schema = yup
  .object({
    leaveType: yup.string().required(),
    numberOfDays: yup.number().required().min(1).max(10),
    description: yup.string(),
    hours: yup.number().required().min(0).max(8),
    minutes: yup.number().required().min(0).max(59),
    // fullDay: yup.bool().default(false),
    // halfDay: yup.bool().default(false),
  })
  .required();

type RequestALeaveProps = {
  handleClose: () => void;
  refetch: () => void;
};

export default function RequestALeave({
  handleClose,
  refetch,
}: RequestALeaveProps) {
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      leaveType: "",
      numberOfDays: 1,
      description: "",
      hours: 8,
      minutes: 0,
      // halfDay: false,
      // fullDay: false,
    },
  });

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(new Date());
  const [startTime, setStartTime] = useState(() => {
    const date = new Date();
    date.setHours(9, 0, 0, 0);
    return date;
  });
  const minTime = moment().hours(9).minutes(0); // set minimum time to 9:00 AM
  const maxTime = moment().hours(17).minutes(0); // set maximum time to 5:00 PM

  useEffect(() => {
    if (watch("hours") >= 8) {
      setValue("minutes", 0);
    }
  }, [watch("hours")]);

  // useEffect(() => {
  //   if (watch("halfDay")) {
  //     setValue("hours", 4);
  //     setValue("minutes", 0);
  //     setStartTime(() => {
  //       const date = new Date();
  //       date.setHours(9, 0, 0, 0);
  //       calculateDuration(true, date);
  //       return date;
  //     });
  //   }
  // }, [watch("halfDay")]);

  useEffect(() => {
    // if (watch(["numberOfDays"]) > 1) {
    //   setValue("fullDay", false);
    //   setValue("halfDay", false);
    //   //calculate duration numberOfDays*8
    //setValue("hours", watch("numberOfDays") * 8);
    // }
  }, [watch("numberOfDays")]);

  function calculateDuration(selectedTime: Date) {
    const endTime = new Date();
    endTime.setHours(17, 0, 0, 0);
    const duration = moment.duration(
      moment(endTime).diff(moment(selectedTime))
    );
    setValue("hours", duration.hours());
    setValue("minutes", duration.minutes());

    //This commented out code below is the check for half day. To use again: place this chunk in the start of the above code
    // if (!isHalfDay) {
    //   endTime.setHours(17, 0, 0, 0);
    // }
    //  else {
    //   if (selectedTime.getHours() === 9) endTime.setHours(13, 0, 0, 0);
    // }
  }

  const mutation = useMutation({
    mutationFn: (timeOffRequest: TimeOffRequest) => {
      return serverPOST("/timeoff/create", timeOffRequest);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data, variables, context) => {
      refetch();
      successNotfiy("TimeOff Request has been sent");

      handleClose();
    },
  });

  const onSubmit = (data: TimeOffRequestForm) => {
    startTime.setFullYear(startDate.getFullYear());
    startTime.setMonth(startDate.getMonth());
    startTime.setDate(startDate.getDate());

    let daysToAdd = 0;
    let startDay = startDate.getDay();

    for (let i = 0; i < data.numberOfDays - 1; i++) {
      startDay = startDay + 1;

      daysToAdd = daysToAdd + 1;
      if (startDay === 6) {
        startDay = startDay + 1;
        daysToAdd = daysToAdd + 2;
        startDay = startDay % 6;
      }
    }
    const endTime = new Date(
      startTime.getTime() + daysToAdd * 24 * 60 * 60 * 1000
    );

    let timeOffRequest = {
      // user: Number(user._id),
      leave_type: data.leaveType,
      no_of_days: data.numberOfDays,
      description: data.description,
      start_time: new Date(startTime).getTime(),
      end_time: endTime.getTime(),
      duration_minutes:
        data.numberOfDays === 1
          ? parseFloat(data.hours.toString()) * 60 +
            parseFloat(data.minutes.toString())
          : 8 * 60 * data.numberOfDays,
      status: data.status,
    } as TimeOffRequest;

    mutation.mutate(timeOffRequest);
  };

  const isWeekday = (date: Date) => {
    const day = date.getDay();
    return day !== 0 && day !== 6; // Exclude Sunday and Saturday
  };

  return (
    <Dialogue
      title="Request a Leave"
      description="Input relevant data and click on Submit to request a leave."
      handleClose={handleClose}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative p-6 flex-auto">
          <div className="grid md:grid-cols-2 gap-8 my-2">
            <div>
              <Select
                label={"Leave Type"}
                id="leaveTypes"
                regProp={{ ...register("leaveType") }}
                options={[PTO, VACATION, HOLIDAY]}
                error={errors.leaveType?.message}
                style={{
                  height: "37px",
                }}
              />
            </div>
            <div>
              <Input
                label={"Number of Days"}
                placeholder="Enter days"
                type={"number"}
                name="numberOfDays"
                regProp={{ ...register("numberOfDays") }}
                error={errors.numberOfDays?.message}
              />
            </div>
          </div>

          {/* Half day full day component. For now commented out */}
          {/* <div className="flex">
                  <div className="pr-20">
                    <Checkbox
                      label={"Full Day"}
                      name="fullDay"
                      isChecked={
                        watch(["numberOfDays"]) > 1
                          ? false
                          : watch(["halfDay"][0])
                          ? false
                          : watch(["fullDay"][0])
                      }
                      isDisabled={watch(["numberOfDays"]) > 1}
                      regProp={{ ...register("fullDay") }}
                      error={errors.fullDay?.message}
                    />
                  </div>
                  <div>
                    <Checkbox
                      label={"Half Day"}
                      name="halfDay"
                      isDisabled={watch(["numberOfDays"]) > 1}
                      regProp={{ ...register("halfDay") }}
                      error={errors.halfDay?.message}
                    />
                  </div>
                </div> */}

          {/* Make this component reusable. used in request a leave and add task */}
          <div className="grid md:grid-cols-3 gap-8 my-8">
            <div>
              <label className="block">
                <span className="block text-sm font-medium text-slate-700 mb-2">
                  Start Date
                </span>
                <DatePicker
                  className="h-9 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2.5 p-2.5"
                  placeholderText="Select date"
                  selected={startDate}
                  onChange={(date: Date) => setStartDate(date)}
                  // showIcon={true}
                  //excludeDates={[new Date().getDay()]}
                  filterDate={isWeekday} // Exclude weekends
                />
              </label>
            </div>
            <div>
              <label className="block">
                <span className="block text-sm font-medium text-slate-700  mb-2">
                  Start time
                </span>
                <DatePicker
                  selected={startTime}
                  onChange={(date: Date) => {
                    // if (
                    //   !(
                    //     startTime.getHours() !== 9 &&
                    //     startTime.getMinutes() !== 0 &&
                    //     startTime.getSeconds() !== 0
                    //   )
                    // ) {
                    //   setValue("fullDay", false);
                    // }

                    calculateDuration(date);
                    setStartTime(date);
                  }}
                  minTime={minTime.toDate()}
                  maxTime={maxTime.toDate()}
                  showTimeSelect
                  showTimeSelectOnly
                  timeIntervals={15}
                  timeCaption="Start Time"
                  dateFormat="h:mm aa"
                  disabled={watch("numberOfDays") > 1}
                  className="h-9 border border-customGrey-400 disabled:opacity-50 disabled:bg-customGrey-100 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2.5 p-2.5"
                />
              </label>
            </div>

            <div className="grid md:grid-cols-2 gap-4">
              <div>
                <Input
                  label={"Hours"}
                  placeholder="Hours"
                  type={"number"}
                  name="hours"
                  regProp={{ ...register("hours") }}
                  error={errors.hours?.message}
                  isDisabled={watch("numberOfDays") > 1}
                />
              </div>
              <div>
                <Input
                  label={"Minutes"}
                  placeholder="Minutes"
                  type={"number"}
                  name="minutes"
                  regProp={{ ...register("minutes") }}
                  isDisabled={
                    Number(watch("hours")) >= 8 || watch("numberOfDays") > 1
                      ? true
                      : false
                  }
                  error={errors.minutes?.message}
                />
              </div>
            </div>
          </div>

          <div className="mt-8">
            <label
              //  for="description"
              className="block mb-2 text-sm font-medium text-slate-700"
            >
              Description
            </label>
            <textarea
              id="description"
              rows={5}
              className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
              placeholder="Enter reason here"
              {...register("description")}
            ></textarea>
            <p className="text-sm text-red-500">
              {errors.description?.message}
            </p>
          </div>
        </div>

        {/*footer*/}
        <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <PrimaryButton text="Submit Request" />
        </div>
      </form>
    </Dialogue>
  );
}

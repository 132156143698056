import React from "react";

export default function PopupHeader({
  tasktype,
  client,
  service,
  project,
}: {
  tasktype: string;
  client: string;
  service: string;
  project: string;
}) {
  return (
    <div className="grid grid-cols-2 gap-4 border border-customGrey-400 rounded-lg mb-5 p-5">
      <div>
        <p className="text-sm font-medium text-customGrey-500 leading-6">
          Task Type
        </p>
        <p className="text-sm">{tasktype}</p>
      </div>
      <div className="text-right">
        <p className="text-sm font-medium text-customGrey-500 leading-6">
          Serivce
        </p>
        <p className="text-sm">{service ? service : "-"}</p>
      </div>

      <div>
        <p className="text-sm font-medium text-customGrey-500 leading-6">
          Client
        </p>
        <p className="text-sm">{client}</p>
      </div>

      <div className="text-right">
        <p className="text-sm font-medium text-customGrey-500 leading-6">
          Project
        </p>
        <p className="text-sm">{project}</p>
      </div>
    </div>
  );
}

import React, {
  useEffect,
  useState,
  Dispatch,
  SetStateAction,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useQuery } from "react-query";
import { getClients, getTaskTypes } from "../../api";
import { serverGET } from "../../HttpFunctions";
import "react-toastify/dist/ReactToastify.css";
import Chip from "../../components/Global/Chip";
import Autocomplete from "react-autocomplete";
import { FaTrashAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import moment, { duration } from "moment";
import TimePicker from "rc-time-picker";
import "../../utils/styles/picker.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { ApprovedTask } from "./ApprovedTask";
import CommentList, { Comment } from "src/components/Global/CommentList";
import { APPROVED, DISAPPROVED, REVISED } from "../../utils/Constants";
import TaskTimeCalculator from "src/components/Admin/TaskTimeCalculator";
import TaskDescription from "src/components/Admin/TaskDescription";
import { RiAddCircleLine } from "react-icons/ri";
import { ApprovedTaskWeeklyPopup } from "./ApprovedTaskWeeklyPopup";
import { TaskFileUpload } from "./TaskFileUpload";

export default function EditTaskList({
  index,
  watch,
  data,
  errors,
  register,
  customError,
  setCustomError,
  onCopy,
  onDelete,
  setValue,
  deleteTasks,
  overlaps,
  setOverlaps,
  slotErrors,
  onSubmitClicked,
  comments,
  commentRefetch,
  isWeekView,
  handleAddTask,
  setDeletedAttachments,
}: {
  index: number;
  watch: any;
  data: any;
  errors: any;
  register: any;
  customError: string;
  setCustomError: Dispatch<SetStateAction<string>>;
  onCopy: () => void;
  onDelete: () => void;
  setValue: any;
  deleteTasks: any;
  overlaps: Array<number>;
  setOverlaps: any;
  slotErrors: Array<number>;
  onSubmitClicked?: boolean;
  comments: Comment[];
  commentRefetch: any;
  isWeekView: boolean;
  handleAddTask?: () => void;
  setDeletedAttachments: any;
}) {
  const [client, setClient] = useState("");
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [addDescription, setAddDescription] = useState(
    !!watch(`tasks.${index}.description`) ? true : false
  );
  const { data: clientData } = useQuery("clients", () => serverGET(getClients));
  const { data: taskTypeData } = useQuery("taskTypes", () =>
    serverGET("/tasktype?is_active=true")
  );
  const [project, setProject] = useState("");
  const [type, setType] = useState("");
  const hours = Array.from({ length: 17 }, (_, i) => i);
  const minutes = Array.from({ length: 4 }, (_, i) => i * 15);
  const [service, setService] = useState("");
  const [services, setServices] = useState("");
  const firstUpdate = useRef(true);

  const [typeDetail, setTypeDetail] = useState(
    () =>
      watch(`tasks.${index}.taskType`) ?? {
        name: "",
        is_billable: null,
      }
  );
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    setProject(watch(`tasks.${index}.projectDetail.project_name`));
    setType(watch(`tasks.${index}.taskType.name`));
    setClient(watch(`tasks.${index}.projectDetail.client.name`));
    setService(watch(`tasks.${index}.service.name`));
  }, [index, deleteTasks]);

  const { data: servicesData } = useQuery({
    queryKey: [services],
    queryFn: () => serverGET(`/service/projecttasktype/${services}`),
    enabled: !!services,
  });

  const [projectDetail, setProjectDetail] = useState(
    () =>
      watch(`tasks.${index}.projectDetail`) ?? {
        project_name: "",
        client: { _id: "", name: "" },
        _id: "",
      }
  );
  useEffect(() => {
    if (projectDetail) {
      setValue(`tasks.${index}.projectDetail`, projectDetail, {
        shouldValidate: true,
      });
    }
  }, [projectDetail]);

  useEffect(() => {
    const project = watch(`tasks.${index}.projectDetail`);
    const tasktype = watch(`tasks.${index}.taskType`);

    if (Object.keys(project).length > 0 && Object.keys(type).length > 0) {
      setServices(project._id + "-" + tasktype._id);
    } else {
      setServices("");
    }
  }, [watch(`tasks.${index}.projectDetail`), watch(`tasks.${index}.taskType`)]);

  // useLayoutEffect(() => {
  //   if (firstUpdate.current) {
  //     firstUpdate.current = false;
  //     return;
  //   }

  //   setService("");
  //   setValue(`tasks.${index}.service`, { name: "", _id: "" });
  // }, [services]);

  useEffect(() => {
    if (typeDetail) {
      setValue(`tasks.${index}.taskType`, typeDetail, {
        shouldValidate: true,
      });
    }
  }, [typeDetail]);

  useEffect(() => {
    setCustomError("");
    if (project) {
      const p = data?.projects.find((p) => p.project_name === project);
      if (p) {
        setProjectDetail(p);
        setClient(p.client.name);
      } else {
        // setProjectDetail({ project_name: "", client: "", _id: "" });
        // setClient("");
      }
    }
    // else {
    //   setClient("");
    // }
  }, [project]);

  useEffect(() => {
    if (client) {
      const p = data?.projects.filter((p) => {
        if (p.client.name === client) {
          return {
            _id: p._id,
            project_name: p.project_name,
            client: p.client.name,
          };
        }
      });

      if (p) {
        setFilteredProjects(p);
      }
    } else {
      setProject("");
    }
  }, [client]);

  useEffect(() => {
    if (watch(`tasks.${index}.projectDetail.project_name`)) {
      setProject(watch(`tasks.${index}.projectDetail.project_name`));
    }
  }, [watch(`tasks.${index}.projectDetail.project_name`)]);

  useEffect(() => {
    if (type) {
      const t = taskTypeData?.taskTypes?.find((t) => t.name === type);

      if (t) {
        setTypeDetail(t);
      } else {
        setTypeDetail({ name: "", is_billable: null });
      }
    } else {
      setTypeDetail({ name: "", is_billable: null });
    }
  }, [type]);

  useEffect(() => {
    if (servicesData?.services?.length > 0) {
      setValue(`tasks.${index}.serviceAddedCheck`, true);
    } else {
      setValue(`tasks.${index}.serviceAddedCheck`, false);
    }
  }, [servicesData]);

  useEffect(() => {
    const currentTask = watch(`tasks.${index}`);
    const taskData = watch("tasks");

    if (currentTask?.startDate) {
      let indexes = [];
      taskData.forEach((task, i) => {
        let st = moment(task.startTime).valueOf();
        let et = moment(task.endTime).valueOf();

        for (let j = i + 1; j < taskData.length; j++) {
          if (!taskData[j].startDate) {
            continue;
          }

          const startTask = taskData[j].startTime;
          const endTask = taskData[j].endTime;

          if (
            (moment(startTask).valueOf() >= st &&
              moment(startTask).valueOf() < et) ||
            (moment(endTask).valueOf() > st &&
              moment(endTask).valueOf() < et) ||
            (moment(startTask).valueOf() < st &&
              moment(endTask).valueOf() >= et)
          ) {
            indexes.push(i);
            indexes.push(j);
          }
        }
      });
      if (indexes.length > 0) {
        setOverlaps([...new Set(indexes)]);
        return;
      } else {
        setOverlaps([]);
      }
    }
  }, [watch(`tasks.${index}.startTime`), watch(`tasks.${index}.endTime`)]);

  const calculateDuration = useMemo(() => {
    let start = watch(`tasks.${index}.startTime`);
    let end = watch(`tasks.${index}.endTime`);
    let copy = new Date(end);
    if (
      copy.getHours() === 0 &&
      copy.getMinutes() === 0 &&
      copy.getSeconds() === 0
    ) {
      copy.setHours(23);
      copy.setMinutes(59);
      copy.setSeconds(0);
    }

    const duration = moment.duration(moment(copy).diff(moment(start)));

    return ![0, 15, 30, 45].includes(Math.abs(duration.minutes()))
      ? `${
          duration.hours() === 0 && duration.minutes() === 59
            ? duration.hours() + 1
            : duration.hours() === 0 ||
              (duration.hours() === 23 && duration.minutes() !== 59)
            ? duration.hours()
            : duration.hours() + 1
        }:${(duration.minutes() === 59
          ? 0
          : duration.minutes() + 1
        ).toLocaleString([], {
          minimumIntegerDigits: 2,
        })}`
      : `${duration.hours()}:${duration
          .minutes()
          .toLocaleString([], { minimumIntegerDigits: 2 })}`;
  }, [watch(`tasks.${index}.startTime`), watch(`tasks.${index}.endTime`)]);

  const handleServiceClick = (val) => {
    setService(val);
    const s = servicesData.services.find((t) => t.name === val);
    if (s) {
      setValue(`tasks.${index}.service`, s);
    } else {
      setValue(`tasks.${index}.service`, { name: "", _id: "" });
    }
  };

  const handleTime = (time, timeType) => {
    const selectedDate = watch(`tasks.${index}.startDate`);

    if (selectedDate) {
      time.year(selectedDate.getFullYear());
      time.month(selectedDate.getMonth());
      time.date(selectedDate.getDate());
    }

    setValue(`tasks.${index}.${timeType}`, time.toDate());
  };
  return isWeekView ? (
    [APPROVED].includes(watch(`tasks.${index}.status`)) ? (
      <ApprovedTaskWeeklyPopup
        index={index}
        calculateDuration={calculateDuration}
        register={register}
        watch={watch}
        comments={comments}
        commentRefetch={commentRefetch}
        services={!!watch(`tasks.${index}.service`)._id}
      />
    ) : (
      <div className="border border-customGrey-400 rounded-lg p-5 mb-5">
        {overlaps.includes(index) && (
          <p className="text-red-500 text-sm  py-2 ">{"Slots overlap"}</p>
        )}
        {slotErrors.includes(index) && (
          <p className="text-red-500 text-sm  py-2 ">
            {"Invalid start/end time entered"}
          </p>
        )}
        <div className="mb-4 flex items-center">
          <p className="font-bold">Task {index + 1}</p>
          <FaTrashAlt
            className="cursor-pointer text-red-600  mx-3"
            onClick={onDelete}
          />
          {watch("tasks.length") - 1 === index ? (
            <RiAddCircleLine
              onClick={handleAddTask}
              className="cursor-pointer"
            />
          ) : null}
          <></>
          {[DISAPPROVED, REVISED].includes(watch(`tasks.${index}.status`)) && (
            <div className="ml-auto flex">
              <Chip
                color={
                  watch(`tasks.${index}.status`) === DISAPPROVED
                    ? "bg-customRed-100 text-customRed-200"
                    : "bg-customYellow-400 text-customYellow-900"
                }
                title={
                  watch(`tasks.${index}.status`) === DISAPPROVED
                    ? "Denied"
                    : REVISED
                }
              />
            </div>
          )}
        </div>

        <TaskTimeCalculator
          startTime={watch(`tasks.${index}.startTime`)}
          endTime={watch(`tasks.${index}.endTime`)}
          duration={calculateDuration}
          startTimeOnChange={handleTime}
          endTimeOnChange={handleTime}
        />
        <TaskFileUpload
          watch={watch}
          index={index}
          setValue={setValue}
          setDeletedAttachments={setDeletedAttachments}
        />

        <TaskDescription
          addDescription={addDescription}
          setAddDescription={setAddDescription}
          regProp={register(`tasks.${index}.description`)}
          error={errors.description?.message}
        />
        {watch(`tasks.${index}._id`) ? (
          <CommentList
            taskId={watch(`tasks.${index}._id`)}
            comments={comments}
            commentRefetch={commentRefetch}
          />
        ) : null}
      </div>
    )
  ) : [
      APPROVED,
      // DISAPPROVED
    ].includes(watch(`tasks.${index}.status`)) ? (
    <ApprovedTask
      index={index}
      calculateDuration={calculateDuration}
      register={register}
      watch={watch}
      comments={comments}
      commentRefetch={commentRefetch}
      services={!!watch(`tasks.${index}.service`)._id}
    />
  ) : (
    <div
      className={`border p-4 ${
        overlaps.includes(index) ? "border-red-600" : "border-gray-200"
      }   mb-4 rounded-lg`}
    >
      <div className="mb-4 flex items-center">
        <p className="font-bold">Task {index + 1}</p>
        {/* <FaRegCopy className="cursor-pointer mr-5" onClick={onCopy} /> */}
        <FaTrashAlt
          className="cursor-pointer text-red-600  mx-3"
          onClick={onDelete}
        />
        <div className=" ml-auto flex items-center">
          {[DISAPPROVED, REVISED].includes(watch(`tasks.${index}.status`)) ? (
            <div className="ml-auto">
              <Chip
                color={
                  watch(`tasks.${index}.status`) === DISAPPROVED
                    ? "bg-customRed-100 text-customRed-200"
                    : "bg-customYellow-400 text-customYellow-900"
                }
                title={
                  watch(`tasks.${index}.status`) === DISAPPROVED
                    ? "Denied"
                    : REVISED
                }
              />
            </div>
          ) : null}

          <div className="cursor-pointer">
            {isOpen ? (
              <IoIosArrowUp onClick={toggleAccordion} />
            ) : (
              <IoIosArrowDown onClick={toggleAccordion} />
            )}
          </div>
        </div>
      </div>

      {overlaps.includes(index) && (
        <p className="text-red-500 text-sm  py-2 ">{"Slots overlap"}</p>
      )}
      {slotErrors.includes(index) && (
        <p className="text-red-500 text-sm  py-2 ">
          {"Invalid start/end time entered"}
        </p>
      )}
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } transition ease-out duration-300 flex-shrink-0`}
      >
        <div
          className={`grid ${
            servicesData?.services?.length > 0
              ? "md:grid-cols-7"
              : "md:grid-cols-6"
          } gap-8 my-2 `}
        >
          <div>
            <label className="block">
              <span className="block text-sm font-medium text-slate-700">
                Date
              </span>
              <DatePicker
                className="border border-gray-300 text-gray-900 text-sm rounded-lg block w-full pl-2.5 p-2.5 disabled:opacity-50 disabled:bg-customGrey-100"
                placeholderText="Select date"
                selected={watch(`tasks.${index}.startDate`)}
                dateFormat={"MM/dd/yyyy"}
                onChange={() => console.log()}
                disabled
              />
            </label>
          </div>
          <div>
            <label className="flex text-sm ">
              Type
              <p className="text-sm text-red-500 ml-1">*</p>
            </label>
            {taskTypeData && (
              <Autocomplete
                getItemValue={(item) => item?.name}
                items={taskTypeData?.taskTypes}
                shouldItemRender={(item, value) =>
                  item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select task type",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.name}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item.name}
                  </div>
                )}
                value={type}
                onChange={(e) => setType(e.target.value)}
                onSelect={(val) => setType(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
            )}

            {errors?.tasks?.length > 0 && onSubmitClicked ? (
              <p className="text-sm text-red-500">
                {errors?.tasks[index]?.taskType?.name?.message}
              </p>
            ) : null}
          </div>

          <div>
            <span className="block text-sm font-medium text-slate-700">
              Client
            </span>

            {clientData && (
              <Autocomplete
                getItemValue={(item) => item?.name}
                items={clientData?.clients ?? []}
                shouldItemRender={(item, value) =>
                  item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select client",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item?.key}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item?.name}
                  </div>
                )}
                value={client}
                onChange={(e) => setClient(e.target.value)}
                onSelect={(val) => setClient(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
            )}
            {errors?.tasks?.length > 0 ? (
              <p className="text-sm text-red-500">
                {errors?.tasks[index]?.projectDetail?.client?.name?.message
                  ? errors?.tasks[index]?.projectDetail?.client?.name?.message
                  : errors?.tasks[index]?.projectDetail?.client?.message}
              </p>
            ) : null}
          </div>
          <div>
            <span className="flex text-sm font-medium text-slate-700">
              Project
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>
            {data && (
              <Autocomplete
                getItemValue={(item) => item?.project_name}
                items={
                  client
                    ? filteredProjects.length > 0
                      ? filteredProjects
                      : data?.projects
                    : data?.projects
                }
                shouldItemRender={(item, value) =>
                  item?.project_name
                    ?.toLowerCase()
                    .indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select project",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item?.id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item?.project_name}
                  </div>
                )}
                value={project}
                onChange={(e) => setProject(e.target.value)}
                onSelect={(val) => setProject(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
            )}

            {errors?.tasks?.length > 0 && onSubmitClicked ? (
              <p className="text-sm text-red-500">
                {errors?.tasks[index]?.projectDetail?.project_name?.message}
              </p>
            ) : null}
          </div>

          {servicesData?.services?.length > 0 && (
            <div>
              <label className="flex text-sm ">
                <span className="flex text-sm font-medium text-slate-700">
                  Service
                  <p className="text-sm text-red-500 ml-1">*</p>
                </span>
              </label>
              <Autocomplete
                getItemValue={(item) => item?.name}
                items={servicesData?.services}
                shouldItemRender={(item, value) =>
                  item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select service",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.name}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item.name}
                  </div>
                )}
                value={service}
                onChange={(e) => handleServiceClick(e.target.value)}
                onSelect={(val) => handleServiceClick(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
              {errors?.tasks?.length > 0 && onSubmitClicked ? (
                <p className="text-sm text-red-500">
                  {errors?.tasks[index]?.service?.message}
                </p>
              ) : null}
            </div>
          )}

          <TaskTimeCalculator
            startTime={watch(`tasks.${index}.startTime`)}
            endTime={watch(`tasks.${index}.endTime`)}
            duration={calculateDuration}
            startTimeOnChange={handleTime}
            endTimeOnChange={handleTime}
          />
        </div>
        <TaskFileUpload
          watch={watch}
          index={index}
          setValue={setValue}
          setDeletedAttachments={setDeletedAttachments}
        />

        <TaskDescription
          addDescription={addDescription}
          setAddDescription={setAddDescription}
          regProp={register(`tasks.${index}.description`)}
          error={errors.description?.message}
        />
      </div>
      {watch(`tasks.${index}._id`) ? (
        <CommentList
          taskId={watch(`tasks.${index}._id`)}
          comments={comments}
          commentRefetch={commentRefetch}
        />
      ) : null}
    </div>
  );
}

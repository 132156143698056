import axios from "axios";
import moment from "moment";
import { fetchData } from "./FetchFilteredData";

const getToken = () => {
  const user = localStorage.getItem("user");
  if (user) {
    return JSON.parse(user).token;
  }
};

export const downloadExcel = async (
  url: string,
  optionsSelected?: {
    status: string[];
    type: string[];
    employee: string[];
    project: string[];
    client: string[];
    date: Date | null;
    endDate: Date | null;
  }
) => {
  let urlQuery: string = "";
  if (optionsSelected) {
    urlQuery = await fetchData(url, optionsSelected, true);
    url = process.env.REACT_APP_HOST + urlQuery;
  } else {
    url = process.env.REACT_APP_HOST + url;
  }
  downloadCall(url);
};

export const downloadCall = async (
  url: string,
  name = `${moment().format("MM-DD-YYYY")}.xlsx`
) => {
  return axios
    .get(url, {
      responseType: "blob",
      headers: { token: getToken() },
    })
    .then((response) => {
      if (response && response.constructor !== String) {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.xls`);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    })
    .catch((error) => {
      console.error("Download failed", error);
    });
};

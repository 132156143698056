import React, { useState } from "react";
import Input from "../../components/Global/Input";
import "react-datepicker/dist/react-datepicker.css";
import "react-toastify/dist/ReactToastify.css";
import Chip from "../../components/Global/Chip";
import { APPROVED } from "../../utils/Constants";
import moment from "moment";
import "../../utils/styles/picker.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import CommentList, { Comment } from "src/components/Global/CommentList";
import AttachmentLink from "src/components/Admin/AttachmentLink";

type ApprovedTaskProps = {
  index: number;
  watch: any;
  register: any;
  calculateDuration: string;
  comments: Comment[];
  commentRefetch: any;
  services: boolean;
};

export function ApprovedTask({
  index,
  watch,
  register,
  calculateDuration,
  comments,
  commentRefetch,
  services,
}: ApprovedTaskProps) {
  const [isOpen, setIsOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  return (
    <div className={`border p-4 border-gray-200  mb-4 rounded-lg`}>
      <div className="mb-4 flex items-center">
        <p className="font-bold">Tasks {index + 1}</p>
        <div className=" ml-auto flex items-center">
          <Chip
            color={
              watch(`tasks.${index}.status`) === APPROVED
                ? "bg-paleGreen-100 text-paleGreen-200"
                : "bg-customRed-100 text-customRed-200"
            }
            title={watch(`tasks.${index}.status`)}
          />
          <div className="ml-4 cursor-pointer">
            {isOpen ? (
              <IoIosArrowUp onClick={toggleAccordion} />
            ) : (
              <IoIosArrowDown onClick={toggleAccordion} />
            )}
          </div>
        </div>
      </div>
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } transition ease-out duration-300 flex-shrink-0`}
      >
        <div
          className={`grid ${
            services ? "md:grid-cols-7" : "md:grid-cols-6"
          } gap-8 my-2 `}
        >
          <label className="block">
            <span className="block text-sm font-medium text-slate-700 mb-2">
              Date
            </span>
            <input
              className=" placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3
       shadow-sm  sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
              type={"text"}
              name={"date"}
              disabled={true}
              value={moment(watch(`tasks.${index}.startDate`)).format(
                "MM/DD/YYYY"
              )}
            />
          </label>
          <Input
            label={"Type"}
            placeholder={"Type of task"}
            type="text"
            name="type"
            isDisabled={true}
            regProp={{ ...register(`tasks.${index}.taskType.name`) }}
          />
          <Input
            label={"Client"}
            placeholder={"Client"}
            type="text"
            name="client"
            isDisabled={true}
            regProp={{
              ...register(`tasks.${index}.projectDetail.client.name`),
            }}
          />
          <Input
            label={"Project"}
            placeholder={"Project"}
            type="text"
            name="project"
            isDisabled={true}
            regProp={{
              ...register(`tasks.${index}.projectDetail.project_name`),
            }}
          />
          {watch(`tasks.${index}.service.name`) ? (
            <Input
              label={"Service"}
              placeholder={"Service"}
              type="text"
              name="service"
              isDisabled={true}
              regProp={{
                ...register(`tasks.${index}.service.name`),
              }}
            />
          ) : null}

          <div className="grid md:grid-cols-3 col-span-2 gap-8 ">
            <div>
              <label className="block">
                <span className="block text-sm font-medium text-slate-700 mb-2">
                  Start Time
                </span>
                <input
                  className=" placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3
       shadow-sm  sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
                  type={"text"}
                  name={"start"}
                  disabled={true}
                  value={moment(watch(`tasks.${index}.startTime`)).format(
                    "hh:mm A"
                  )}
                />
              </label>
            </div>

            <div>
              <label className="block">
                <span className="block text-sm font-medium text-slate-700 mb-2">
                  End Time
                </span>
                <input
                  className=" placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3
       shadow-sm  sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
                  type={"text"}
                  name={"end"}
                  disabled={true}
                  value={moment(watch(`tasks.${index}.endTime`)).format(
                    "hh:mm A"
                  )}
                />
              </label>
            </div>

            <div>
              <label className="block">
                <span className="block text-sm font-medium text-slate-700 mb-2">
                  Duration
                </span>
                <input
                  className=" placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2 pl-3 pr-3
       shadow-sm  sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
                  type={"text"}
                  name={"duration"}
                  disabled={true}
                  value={calculateDuration}
                />
              </label>
            </div>
          </div>
        </div>

        <div className="mt-1">
          <AttachmentLink
            attachments={watch(`tasks.${index}.attachments`)}
            user={true}
            disabled={true}
          />
        </div>

        <div>
          {watch(`tasks.${index}.description`) ? (
            <>
              <p className="text-sm my-4 text-customPurple-400 font-semibold cursor-pointer ">
                Add Task description -
              </p>
              <div className="mt-4">
                <textarea
                  id="description"
                  rows={5}
                  className="block p-2.5 w-full text-sm text-slate-400 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 disabled:bg-customGrey-100"
                  placeholder="Task Description"
                  value={watch(`tasks.${index}.description`)}
                  disabled
                ></textarea>
              </div>
            </>
          ) : (
            <p className="text-sm my-4 text-customGrey-600 font-semibold cursor-pointer ">
              Add Task description +
            </p>
          )}
        </div>
      </div>
      <CommentList
        taskId={watch(`tasks.${index}._id`)}
        comments={comments}
        commentRefetch={commentRefetch}
        isApproved={true}
      />
    </div>
  );
}

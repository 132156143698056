import React from "react";

type SelectProps = {
  label: string;
  id: string;
  regProp: {};
  isProjects?: boolean;
  isTypes?: boolean;
  error?: string;
  options?: any;
  style?: any;

  // | [{ project_name: string }]
  // | [{ name: string }]
  // | string[];
};

export default function Select({
  label,
  id,
  regProp,
  options,
  isProjects,
  isTypes,
  error,
  style,
}: SelectProps) {
  return (
    <>
      <label
        //  for="countries"
        className="block text-sm font-medium text-slate-700 mb-2"
      >
        {label}
      </label>
      <select
        id={id}
        className=" first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
        {...regProp}
        style={style}
      >
        {options &&
          options.map((option, i) =>
            isProjects ? (
              <option
                selected={i === 0 ? true : false}
                value={option.project_name}
              >
                {option.project_name}
              </option>
            ) : isTypes ? (
              <option selected={i === 0 ? true : false} value={option.name}>
                {option.name}
              </option>
            ) : (
              <option selected={i === 0 ? true : false} value={option}>
                {option}
              </option>
            )
          )}
      </select>
      <p className="text-sm text-red-500">{error}</p>
    </>
  );
}

import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router";
import Chip, { PaymentChip } from "../Global/Chip";
import ReactTable from "react-table-6";
import "react-table-6/react-table.css";
import "./user-table.css";
import { FaEdit, FaPlus, FaTrashAlt } from "react-icons/fa";
import { AiOutlineCalendar } from "react-icons/ai";
import { useSearchParams } from "react-router-dom";
import { APPROVED, DISAPPROVED, PENDING, REVISED } from "../../utils/Constants";
import Alert, { successNotfiy, errorNotfiy } from "../Global/Toasts";
import DeleteDialog from "../Global/DeleteDialog";
import { useMutation, useQueryClient } from "react-query";
import { serverDELETE, serverPUT } from "../../HttpFunctions";
import { SecondaryIconButton } from "../Global/Button";
import BreadCrumb from "../Global/BreadCrumb";
import TimePicker from "rc-time-picker";
import { IoCheckmark, IoCloseOutline } from "react-icons/io5";
import { TbCurrencyDollar, TbCurrencyDollarOff } from "react-icons/tb";
import CheckHeader from "../Admin/CheckHeader";
import StatusDropdown from "../Admin/StatusDropdown";
import { useDebounce } from "../../hooks/useDebounce";
import { TimePickerComponent } from "./TimePickerComponent";
import PaymentToggler from "../Admin/PaymentToggler";
import momentTimezone from "moment-timezone";


type UserTableProps = {
  data: any;
  page: number;
  handleClick?: (data: any) => void;
  isAdmin?: boolean;
  user?: any;
  refetch?: any;
  form?: any;
  optionsSelected: any;
  handleViewChange?: () => void;
  cardDetails?: any;
};

export default function UserTable({
  data,
  page,
  handleClick,
  isAdmin,
  user,
  form,
  optionsSelected,
  handleViewChange,
  cardDetails,
}: UserTableProps) {
  const navigate = useNavigate();
  const [editTask, setEditTask] = useState("");
  const [deleteTask, setDeleteTask] = useState("");
  const queryClient: any = useQueryClient();
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const routeParams = useParams();
  const [selectedRows, setSelectedRows] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    setSelectedRows([]);
  }, [data]);

  const nestedArrays = useMemo(() => {
    return data?.tasks?.reduce((result, t) => {
      return result.concat(t.tasks.map((ts) => ts._id));
    }, []);
  }, [data]);

  const deleteMutation = useMutation({
    mutationFn: () => serverDELETE(`/task/${deleteTask}`),
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any, variables, context) => {
      if (data.is_deleted) {
        queryClient.setQueryData(
          ["user-tasks-user", user?._id, page, optionsSelected],
          (oldData: any) => {
            return {
              count: oldData.count,
              tasks: oldData?.tasks?.filter((data) => {
                return data._id !== deleteTask;
              }),
            };
          }
        );

        successNotfiy("Record has been deleted successfully");
        setDeleteTask("");
      }
    },
  });

  const { isLoading: rowLoading, mutate: updateStatusMutate } = useMutation({
    mutationFn: (body: { taskIds: string[]; status: number }) => {
      return serverPUT(`/task/editAll`, { ...body, billableHrsList: [] });
    },
    onError: (error, variables, context: any) => {
      errorNotfiy("Something went wrong while updating tasks");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating tasks");
        return;
      }
      successNotfiy("Tasks has been updated successfully");
      // refetch();
      queryClient.refetchQueries({
        queryKey: ["user-tasks", routeParams?.id, page, optionsSelected],
        type: "active",
        exact: true,
      });

      setSelectedRows([]);
    },
  });

  const { mutate: paidMutate } = useMutation({
    mutationFn: (body: { taskIds: string[]; is_paid: boolean }) => {
      return serverPUT(`/task/paymentstatus`, { ...body });
    },
    onError: (error, variables, context: any) => {
      errorNotfiy("Something went wrong while updating tasks");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating tasks");
        return;
      }
      successNotfiy("Tasks has been updated successfully");
      queryClient.refetchQueries({
        queryKey: ["user-tasks", routeParams?.id, page, optionsSelected],
        type: "active",
        exact: true,
      });

      setSelectedRows([]);
    },
  });

  const { mutate: invoicedMutate } = useMutation({
    mutationFn: (body: { taskIds: string[]; is_invoiced: boolean }) => {
      return serverPUT(`/task/invoicestatus`, { ...body });
    },
    onError: (error, variables, context: any) => {
      errorNotfiy("Something went wrong while updating tasks");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating tasks");
        return;
      }
      successNotfiy("Tasks has been updated successfully");
      queryClient.refetchQueries({
        queryKey: ["user-tasks", routeParams?.id, page, optionsSelected],
        type: "active",
        exact: true,
      });

      setSelectedRows([]);
    },
  });

  const handleDelete = () => {
    deleteMutation.mutate();
  };

  function convertMinutesToHours(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return (
      hours +
      ":" +
      remainingMinutes.toLocaleString([], { minimumIntegerDigits: 2 })
    );
  }

  function getBillableDate(minutes: number) {
    const date = moment();
    if (minutes === undefined) {
      date.hours(0);
      date.minutes(0);
    } else if (minutes === 1440) {
      date.hours(23);
      date.minutes(59);
    } else {
      const hours = minutes / 60;
      const mins = minutes % 60;
      date.hours(hours);
      date.minutes(mins);
    }
    return date;
  }

  const mutation = useMutation({
    mutationFn: ({ _id, billable_minutes }: any) => {
      return serverPUT(`/task/billablehours/${_id}`, {
        billable_minutes: billable_minutes,
      });
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      //showing message
      successNotfiy("Task has been updated successfully");
      queryClient.setQueryData(
        ["user-tasks", routeParams?.id, page, optionsSelected],
        (oldData: any) => {
          return {
            ...oldData,
            tasks: oldData?.tasks?.map((data) => {
              return data._id === updatedData.task._id
                ? {
                    ...data,
                    billable_minutes: updatedData.task?.billable_minutes,
                  }
                : data;
            }),
          };
        }
      );
    },
  });

  const singleTaskMutation = useMutation({
    mutationFn: ({ id, status }: any) => {
      return serverPUT(`/task/${id}`, {
        status: status,
      });
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      //showing message
      successNotfiy("Task has been updated successfully");
      queryClient.setQueryData(
        ["user-tasks", routeParams?.id, page, optionsSelected],
        (oldData: any) => {
          return {
            ...oldData,
            tasks: oldData?.tasks?.map((data) => {
              return data._id === updatedData.task._id
                ? {
                    ...data,
                    status: updatedData.task?.status,
                  }
                : data;
            }),
          };
        }
      );
    },
  });

  const handleBillableHrChange = (id, e) => {
    const newMinutes = e.hours() * 60 + e.minutes();
    mutation.mutate({ _id: id, billable_minutes: newMinutes });
  };

  const handleStatusChange = (id, status) => {
    singleTaskMutation.mutate({ id, status });
  };

  const handlePaymentChange = (id, status) => {
    let body = {
      taskIds: [id],
      is_paid: status,
    };
    paidMutate(body);
  };

  const handleInvoicedStatus = (is_invoiced: boolean) => {
    invoicedMutate({
      taskIds: selectedRows,
      is_invoiced,
    });
  };


  const handleInvoiceChange = (id, status) => {
    let body = {
      taskIds: [id],
      is_invoiced: status,
    };
    invoicedMutate(body);
  };

  const toggleRowSelection = (e, task) => {
    const rowIndex = selectedRows.findIndex((r) => r === task._id);
    if (rowIndex === -1) {
      setSelectedRows([...selectedRows, task._id]);
    } else {
      setSelectedRows([
        ...selectedRows.slice(0, rowIndex),
        ...selectedRows.slice(rowIndex + 1),
      ]);
    }
  };

  const toggleSelectAll = () => {
    if (selectedRows.length === nestedArrays.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(nestedArrays);
    }
  };

  const handleBulkStatus = (status: number) => {
    updateStatusMutate({
      taskIds: selectedRows,
      status,
    });
  };

  const handlePaymentStatus = (is_paid: boolean) => {
    paidMutate({
      taskIds: selectedRows,
      is_paid,
    });
  };

  const checkHeader = useCallback(() => {
    return {
      width: 40,
      style: {
        display: "block",
      },
      show: !!isAdmin,
      Cell: (row) => {
        return row.original?.tasks.map((task, index) => (
          <div key={index} className="py-4">
            <input
              type="checkbox"
              checked={selectedRows.some((r) => r === task._id)}
              onChange={(e) => toggleRowSelection(e, task)}
              className="accent-customPurple-400 bg-gray-100 border-gray-300 rounded"
            />
          </div>
        ));
      },
    };
  }, [selectedRows, toggleRowSelection]);

  const columns = [
    checkHeader(),
    {
      Header: "Date",
      accessor: "date",
      width: 100,
      style: {
        display: "flex",
        alignItems: "center",
      },
      Cell: (row) => {
        return (
          <div className="text-center font-normal">
            <div>{moment(row.value).format("ddd")}</div>
            <div className="font-semibold">{row.value}</div>
          </div>
        );
      },
    },
    {
      Header: "Task type",
      accessor: "tasks",
      Cell: (row) => (
        <div>
          {row.value?.map((task, index) => (
            <div className="py-4" key={index}>
              {task?.taskType}
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Project",
      accessor: "tasks",
      Cell: (row) => (
        <div>
          {row.value?.map((task, index) => (
            <div className="py-4" key={index}>
              {task?.project}
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Service",
      accessor: "tasks",
      Cell: (row) => (
        <div>
          {row.value?.map((task, index) => (
            <div key={index} className="py-4">
              {task.service ? task.service : "-"}
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Client",
      accessor: "tasks",
      Cell: (row) => (
        <div>
          {row.value?.map((task, index) => (
            <div key={index} className="py-4">
              {task?.client}
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Task Time",
      accessor: "tasks",
      Cell: (row) => (
        <div>
          {row.value?.map((task, index) => (
            <div key={index} className="py-4">
              <h6 className="text-xs">{task?.timezone}</h6>
              {task?.startTime} - {task?.endTime}
            </div>
          ))}
        </div>
      ),
    },
    // {
    //   Header: "End Time",
    //   width: 100,
    //   accessor: "tasks",
    //   Cell: (row) => (
    //     <div>
    //       {row.value?.map((task, index) => (
    //         <div key={index} className="py-4">
    //           {task?.endTime}
    //         </div>
    //       ))}
    //     </div>
    //   ),
    // },
    {
      Header: "Duration",
      width: 100,
      accessor: "tasks",
      Cell: (row) => (
        <div>
          {row.value?.map((task, index) => (
            <div key={index} className="py-4">
              {task?.totalHrs}
            </div>
          ))}
        </div>
      ),
    },
    {
      Header: "Billable Hrs",
      width: 100,
      show: !!isAdmin,
      accessor: "tasks",
      Cell: (row) => (
        <TimePickerComponent
          getBillableDate={getBillableDate}
          handleBillableHrChange={handleBillableHrChange}
          isAdmin={isAdmin}
          row={row}
        />
      ),
      // <div className="z-10">
      //   {!isAdmin
      //     ? null
      //     : row.value?.map((task, index) => {
      //         const val = useDebounce(
      //           getBillableDate(task?.billableHrs),
      //           2000
      //         );
      //         return (
      //           <div key={index} className="py-1.5">
      //             <TimePicker
      //               value={useDebounce(
      //                 getBillableDate(task?.billableHrs),
      //                 2000
      //               )}
      //               focusOnOpen
      //               showSecond={false}
      //               clearIcon={<></>}
      //               showMinute={true}
      //               minuteStep={15}
      //               inputReadOnly
      //               onChange={(e) => handleBillableHrChange(task, e)}
      //               className="custom-time-picker"
      //             />
      //           </div>

      // })}
      // </div>
    },

    {
      Header: "Paid",
      width: 80,
      accessor: "tasks",
      show: !!isAdmin,
      Cell: (row) => (
        <PaymentToggler row={row} handleChange={handlePaymentChange} />
      ),
    },

    {
      Header: "Invoice",
      width: 80,
      accessor: "tasks",
      show: !!isAdmin,
      Cell: (row) => (
        <PaymentToggler
          row={row}
          handleChange={handleInvoiceChange}
          isInvoice= {true}
        />
      ),
    },

    {
      Header: "Status",
      width: 80,
      accessor: "tasks",
      Cell: (row) =>
        !!isAdmin ? (
          <StatusDropdown row={row} handleStatusChange={handleStatusChange} />
        ) : (
          <div>
            {row.value?.map((task, index) => (
              <div key={index} className="py-2">
                <Chip
                  color={
                    task?.status === PENDING
                      ? "bg-customGrey-400 text-customeGrey-500"
                      : task?.status === APPROVED
                      ? "bg-paleGreen-100 text-paleGreen-200"
                      : task?.status === REVISED
                      ? "bg-customYellow-400 text-customYellow-900"
                      : "bg-customRed-100 text-customRed-200"
                  }
                  title={task?.status}
                />
              </div>
            ))}
          </div>
        ),
    },
    // Edit and Delete buttons
    {
      Header: "Actions",
      maxWidth: 100,
      Cell: ({ row }) =>
        !isAdmin ? (
          <div className="grid grid-flow-col">
            <button
              onClick={() => {
                setEditTask(data?.tasks[row._index]);
                navigate("/user/edittimelog", {
                  state: {
                    editTask: data?.tasks[row._index],
                    page: page,
                    index: row._index,
                  },
                });
              }}
            >
              <FaEdit />
            </button>
          </div>
        ) : (
          <button>
            <FaEdit
              className={"text-black"}
              onClick={() => handleClick(data?.tasks[row?._index])}
            />
          </button>
        ),
    },
  ];

  const tableData = useMemo(() => {
    return data?.tasks?.map((task) => {
      // console.log(task.date.split("-").reverse().join("-"));
      return {
        // _id: task._id,
        // taskId: task?.task_id ?? index + 1 + parseInt(page.toString()) * 5,
        date: moment(task.date?.split("-").reverse().join("-")).format(
          "MM/DD/YY"
        ),
        tasks: task.tasks?.map((t) => {
          return {
            _id: t._id,
            project: t?.project?.project_name,
            service: t?.service?.name,
            client: t?.project?.client?.name,
            taskType: t?.type?.name,
            timezone: t?.timezone,
            startTime: t?.timezone
              ? momentTimezone(t.start_time)
                  .tz(t.timezone || "America/New_York")
                  .format(`hh:mm  A`)
              : new Date(t.start_time).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }),

            endTime: t?.timezone
              ? momentTimezone(t.end_time)
                  .tz(t.timezone || "America/New_York")
                  .format("hh:mm  A")
              : new Date(t.end_time).toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                }),
            totalHrs: convertMinutesToHours(t?.duration_minutes),
            billableHrs: t?.billable_minutes,
            status: t?.status,
            isPaid: t?.is_paid,
            isInvoiced: t?.is_invoiced,
          };
        }),
      };
    });
  }, [data]);

  return (
    <div>
      <div
        className={`block md:flex items-center justify-center ${
          isAdmin ? "pb-2" : "pb-5"
        } sticky top-0 ${
          isMobileScreen ? "bg-customGrey-200  z-[45] py-1" : ""
        }`}
      >
        {!isAdmin && (
          <h1 className="capitalize text-xl flex md:justify-center items-center font-semibold my-2 md:my-0">
            {user?.first_name + " " + user?.last_name + "'s" + " Timesheet"}{" "}
            {isMobileScreen ? (
              <></>
            ) : (
              <span>
                <button
                  className="flex items-center ml-4 text-sm gap-2 text-customPurple-400 cursor-pointer hover:text-customPurple-900"
                  onClick={() => handleViewChange()}
                >
                  Switch to weekly view <AiOutlineCalendar />
                </button>
              </span>
            )}
          </h1>
        )}
        {isAdmin ? null : (
          <div className="ml-auto">
            <SecondaryIconButton
              text="Time Log"
              icon={<FaPlus />}
              onClick={() => navigate("/user/addtask")}
            />
          </div>
        )}
      </div>
      {cardDetails && cardDetails}
      {form && form}
        
      {data?.tasks?.length > 0 ? (
        <>
          {isAdmin && (
            <CheckHeader
              checked={selectedRows.length === nestedArrays.length}
              selectedRows={selectedRows}
              toggleSelectAll={toggleSelectAll}
              handleBulkStatus={handleBulkStatus}
              handlePaymentStatus={handlePaymentStatus}
              handleInvoicedStatus={handleInvoicedStatus}
            />
          )}

          <ReactTable
            data={tableData}
            columns={columns}
            className="rounded-lg"
            showPagination={false}
            minRows={tableData?.length ? tableData?.length : 5}
            pageSize={tableData?.length}
            style={{ maxHeight: "calc(100vh - 290px)" }}
          />
        </>
      ) : (
        <h1 className="text-lg text-center w-full mt-4">Tasks not found</h1>
      )}

      {!!deleteTask && (
        <DeleteDialog
          handleClose={() => setDeleteTask("")}
          handleDelete={handleDelete}
          text={"Are you sure you want to delete the this task?"}
          title={"Delete Task"}
        />
      )}
      <Alert />
    </div>
  );
}

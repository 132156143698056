import React, { useState } from "react";
import { RxCross2 } from "react-icons/rx";
import Dialogue from "../Global/Dialogue";
import { PrimaryButton, SecondaryButton } from "../Global/Button";

export default function AttachmentLink({
  attachments,
  user,
  handleConfirm,
  disabled,
}: {
  attachments: [any];
  user?: boolean;
  handleConfirm?: any;
  disabled?: boolean;
}) {
  const [modal, setModal] = useState(null);

  const openModal = (attachment: string) => {
    setModal(attachment);
  };
  const closeModal = () => {
    setModal(null);
  };

  return (
    <div className="flex gap-2 flex-wrap mb-2 items-center">
      {attachments.map((attachment, index) => {
        const parsedUrl = new URL(attachment);
        const pathnameParts = parsedUrl.pathname.split("/");
        const filename = pathnameParts[pathnameParts.length - 1].split("_")[1];
        return (
          <div className="group relative">
            <div
              className={`border ${
                disabled ? "border-gray-300" : "border-customPurple-400"
              } px-2 rounded-md max-w-32 overflow-x-hidden text-ellipsis hover:bg-slate-50`}
            >
              <a
                href={attachment}
                target="_blank"
                className={`${
                  disabled ? "text-gray-400" : "text-purple-600"
                } text-xs p-0 m-0`}
              >
                {filename || `Attachment-${index + 1}`}
              </a>
              {user && !disabled && (
                <div
                  className="hidden group-hover:inline-block absolute -top-3 -right-3 m-0.5 p-0.5 bg-customPurple-400 rounded-full cursor-pointer"
                  onClick={() => openModal(attachment)}
                >
                  <RxCross2 className="text-white" />
                </div>
              )}
            </div>
          </div>
        );
      })}
      {!!modal ? (
        <Dialogue
          handleClose={closeModal}
          title={"Delete attachment"}
          description="Are you sure you want to remove this attachment"
          central={true}
        >
          <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
            <SecondaryButton
              text="Confirm"
              onClick={() => {
                handleConfirm(modal);
                setModal(null);
              }}
            />
          </div>
        </Dialogue>
      ) : null}
    </div>
  );
}

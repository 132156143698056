import React, { useEffect, useMemo, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { serverGET } from "../../HttpFunctions";
import "rc-time-picker/assets/index.css";
import "react-toastify/dist/ReactToastify.css";
import Autocomplete from "react-autocomplete";
import {
  FaAcquisitionsIncorporated,
  FaCross,
  FaRegCopy,
  FaTrashAlt,
} from "react-icons/fa";
import DatePicker from "react-datepicker";
import moment from "moment";
import TimePicker from "rc-time-picker";
import "../../utils/styles/picker.css";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { useQuery } from "react-query";
import TaskDescription from "src/components/Admin/TaskDescription";
import TaskTimeCalculator from "src/components/Admin/TaskTimeCalculator";
import { RiAddCircleLine } from "react-icons/ri";
import { TaskFileUpload } from "./TaskFileUpload";

export default function TaskList({
  index,
  watch,
  data,
  errors,
  register,
  onCopy,
  onDelete,
  setValue,
  overlaps,
  setOverlaps,
  clientData,
  taskTypeData,
  slotErrors,
  onSubmitClicked,
  isWeekView,
  handleAddTask,
}: {
  index: number;
  watch: any;
  data: any;
  errors: any;
  register: any;
  onCopy: () => void;
  onDelete: () => void;
  setValue: any;
  overlaps: Array<number>;
  setOverlaps: any;
  clientData: { clients: [] };
  taskTypeData: { taskTypes: any[] };
  slotErrors: Array<{}>;
  onSubmitClicked?: boolean;
  isWeekView?: boolean;
  handleAddTask?: () => void;
}) {
  const [client, setClient] = useState(
    () => watch(`tasks.${index}.projectDetail.client.name`) ?? ""
  );
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [addDescription, setAddDescription] = useState(false);
  const [service, setService] = useState("");
  const [services, setServices] = useState("");

  const [project, setProject] = useState(
    () => watch(`tasks.${index}.projectDetail.project_name`) ?? ""
  );

  const [type, setType] = useState(
    () => watch(`tasks.${index}.taskType.name`) ?? ""
  );

  const [typeDetail, setTypeDetail] = useState(() =>
    watch(`tasks.${index}.taskType`)
      ? watch(`tasks.${index}.taskType`)
      : {
          name: "",
          is_billable: null,
        }
  );

  const [projectDetail, setProjectDetail] = useState(
    () =>
      watch(`tasks.${index}.projectDetail`) ?? {
        project_name: "",
        client: { name: "", _id: "" },
        _id: "",
      }
  );

  const [isOpen, setIsOpen] = useState(true);

  const { data: servicesData } = useQuery({
    queryKey: [services],
    queryFn: () => serverGET(`/service/projecttasktype/${services}`),
    enabled: !!services,
  });

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };
  // const minStartTime = moment().hours(0).minutes(0); // set minimum time to 9:00 AM
  // let maxStartTime = moment()
  //   .hours(watch(`tasks.${index}.endTime`).getHours())
  //   .minutes(watch(`tasks.${index}.endTime`).getMinutes());
  // const maxTime = moment().hours(23).minutes(59); // set maximum time to 5:00 PM
  const [dateError, setDateError] = useState("");

  // let minEndTime = moment()
  //   .hours(watch(`tasks.${index}.startTime`).getHours())
  //   .minutes(watch(`tasks.${index}.startTime`).getMinutes());

  useEffect(() => {
    setType(watch(`tasks.${index}.taskType.name`));
    setProject(watch(`tasks.${index}.projectDetail.project_name`));
    setClient(watch(`tasks.${index}.projectDetail.client.name`));
  }, [watch("tasks")]);

  useEffect(() => {
    if (projectDetail) {
      setValue(`tasks.${index}.projectDetail`, projectDetail, {
        shouldValidate: true,
      });
      //service to clear everytime project is changed
      if (!isWeekView) {
        setService("");
        setValue(`tasks.${index}.service`, "");
      }
    }
  }, [projectDetail]);

  useEffect(() => {
    const project = watch(`tasks.${index}.projectDetail`);
    const tasktype = watch(`tasks.${index}.taskType`);

    if (Object.keys(project)?.length > 0 && Object.keys(type).length > 0) {
      setServices(project._id + "-" + tasktype._id);
    } else {
      setServices("");
      console.log("achooo");
      setService("");
      setValue(`tasks.${index}.service`, "");
    }
  }, [watch(`tasks.${index}.projectDetail`), watch(`tasks.${index}.taskType`)]);

  useEffect(() => {
    if (typeDetail) {
      setValue(`tasks.${index}.taskType`, typeDetail, { shouldValidate: true });
    }
  }, [typeDetail]);

  useEffect(() => {
    if (project) {
      const p = data?.projects.find((p) => p.project_name === project);
      if (p) {
        setProjectDetail(p);
        setClient(p.client.name);
      } else {
        setProjectDetail({ project_name: "", client: "", _id: "" });
        setClient("");
      }
    } else {
      setProjectDetail({ project_name: "", client: "", _id: "" });
      // setClient("");
    }
  }, [project]);

  useEffect(() => {
    if (client) {
      const p = data?.projects.filter((p) => {
        if (p.client.name === client) {
          return {
            _id: p._id,
            project_name: p.project_name,
            client: p.client.name,
          };
        }
      });

      if (p) {
        setFilteredProjects(p);
      }
    } else {
      setProject("");
    }
  }, [client]);

  useEffect(() => {
    if (type) {
      const t = taskTypeData?.taskTypes?.find((t) => t.name === type);

      if (t) {
        setTypeDetail(t);
      } else {
        setTypeDetail({ name: "", is_billable: null });
      }
    } else {
      setTypeDetail({ name: "", is_billable: null });
    }
  }, [type, taskTypeData]);

  useEffect(() => {
    const currentTask = watch(`tasks.${index}`);
    const taskData = watch("tasks");

    if (currentTask?.startDate) {
      let indexes = [];
      for (let i = 0; i < taskData.length; i++) {
        const task = taskData[i];
        if (!task?.startDate) {
          continue;
        }
        //new

        task.startTime.setFullYear(task.startDate.getFullYear());
        task.startTime.setMonth(task.startDate.getMonth());
        task.startTime.setDate(task.startDate.getDate());

        task.endTime.setFullYear(task.startDate.getFullYear());
        task.endTime.setMonth(task.startDate.getMonth());
        task.endTime.setDate(task.startDate.getDate());

        let st = moment(task.startTime).valueOf();
        let et = moment(task.endTime).valueOf();

        for (let j = i + 1; j < taskData.length; j++) {
          if (!taskData[j].startDate) {
            continue;
          }
          const startTask = taskData[j].startTime;
          const endTask = taskData[j].endTime;

          startTask.setFullYear(taskData[j].startDate.getFullYear());
          startTask.setMonth(taskData[j].startDate.getMonth());
          startTask.setDate(taskData[j].startDate.getDate());

          endTask.setFullYear(taskData[j].startDate.getFullYear());
          endTask.setMonth(taskData[j].startDate.getMonth());
          endTask.setDate(taskData[j].startDate.getDate());

          if (
            (moment(startTask).valueOf() >= st &&
              moment(startTask).valueOf() < et) ||
            (moment(endTask).valueOf() > st &&
              moment(endTask).valueOf() < et) ||
            (moment(startTask).valueOf() < st &&
              moment(endTask).valueOf() >= et)
          ) {
            indexes.push(i);
            indexes.push(j);
          }
        }
      }

      if (indexes.length > 0) {
        setOverlaps([...new Set(indexes)]);
        return;
      } else {
        setOverlaps([]);
      }
    }
  }, [
    watch(`tasks.${index}.startTime`),
    watch(`tasks.${index}.endTime`),
    watch(`tasks.${index}.startDate`),
  ]);

  useEffect(() => {
    let timer;
    if (dateError) {
      timer = setTimeout(() => {
        setDateError("");
      }, 4000);
    }
    return () => clearTimeout(timer);
  }, [dateError]);

  useEffect(() => {
    if (servicesData?.services?.length > 0) {
      setValue(`tasks.${index}.serviceAddedCheck`, true);
    } else {
      setValue(`tasks.${index}.serviceAddedCheck`, false);
    }
  }, [servicesData]);

  const handleStartDate = async (date: Date) => {
    setDateError("");
    try {
      const response = await serverGET(`/task?start_date=${date.getTime()}`);
      if (response && response.constructor !== String) {
        if (response?.tasks?.length > 0) {
          setDateError(
            "To add another task to a date that already has tasks, you need to go to that date from list of tasks and add it through the action button"
          );
          return;
        }
      }
      setValue(`tasks.${index}.startDate`, date, { shouldValidate: true });
    } catch (e) {
      console.log(e);
    }
  };
  const calculateDuration = useMemo(() => {
    let start = watch(`tasks.${index}.startTime`);
    let end = watch(`tasks.${index}.endTime`);

    let copy = new Date(end);
    if (
      copy.getHours() === 0 &&
      copy.getMinutes() === 0 &&
      copy.getSeconds() === 0
    ) {
      copy.setHours(23);
      copy.setMinutes(59);
      copy.setSeconds(0);
    }

    const duration = moment.duration(moment(copy).diff(moment(start)));

    return ![0, 15, 30, 45].includes(Math.abs(duration.minutes()))
      ? `${
          duration.hours() === 0 && duration.minutes() === 59
            ? duration.hours() + 1
            : duration.hours() === 0 ||
              (duration.hours() === 23 && duration.minutes() !== 59)
            ? duration.hours()
            : duration.hours() + 1
        }:${(duration.minutes() === 59
          ? 0
          : duration.minutes() + 1
        ).toLocaleString([], {
          minimumIntegerDigits: 2,
        })}`
      : `${duration.hours()}:${duration
          .minutes()
          .toLocaleString([], { minimumIntegerDigits: 2 })}`;
  }, [watch(`tasks.${index}.startTime`), watch(`tasks.${index}.endTime`)]);

  const handleServiceClick = (val) => {
    console.log("valll", val);
    console.log("project", project);
    setService(val);
    const s = servicesData.services.find((t) => t.name === val);
    if (s) {
      setValue(`tasks.${index}.service`, s._id, { shouldValidate: true });
    } else {
      setValue(`tasks.${index}.service`, "");
    }
  };

  const handleTime = (time, timeType) => {
    const selectedDate = watch(`tasks.${index}.startDate`);

    if (selectedDate) {
      time.year(selectedDate.getFullYear());
      time.month(selectedDate.getMonth());
      time.date(selectedDate.getDate());
    }

    setValue(`tasks.${index}.${timeType}`, time.toDate());
  };

  return isWeekView ? (
    <div
      className={`border ${
        overlaps.includes(index) ? "border-red-600" : "border-gray-200"
      } rounded-lg p-5 mb-5`}
    >
      {overlaps.includes(index) && (
        <p className="text-red-500 text-sm  py-2 ">{"Slots overlap"}</p>
      )}
      {slotErrors.includes(index) && (
        <p className="text-red-500 text-sm  py-2 ">
          {"Invalid start/end time entered"}
        </p>
      )}
      <div className="mb-4 flex items-center">
        <p className="font-bold">Task {index + 1}</p>
        <FaTrashAlt
          className="cursor-pointer text-red-600  mx-3"
          onClick={onDelete}
        />
        {watch("tasks.length") - 1 === index ? (
          <RiAddCircleLine onClick={handleAddTask} className="cursor-pointer" />
        ) : null}
      </div>

      <TaskTimeCalculator
        startTime={watch(`tasks.${index}.startTime`)}
        endTime={watch(`tasks.${index}.endTime`)}
        duration={calculateDuration}
        startTimeOnChange={handleTime}
        endTimeOnChange={handleTime}
      />
      <TaskFileUpload watch={watch} index={index} setValue={setValue} />

      <TaskDescription
        addDescription={addDescription}
        setAddDescription={setAddDescription}
        regProp={register(`tasks.${index}.description`)}
        error={errors.description?.message}
      />
    </div>
  ) : (
    <div
      className={`w-full border p-4 ${
        overlaps.includes(index) ? "border-red-600" : "border-gray-200"
      }   mb-4 rounded-lg`}
    >
      <div className="mb-4 flex items-center">
        <p className="font-bold">Task {index + 1}</p>
        <FaRegCopy className="cursor-pointer mx-3" onClick={onCopy} />

        {watch("tasks.length") !== 1 ? (
          <FaTrashAlt
            className="cursor-pointer text-red-600"
            onClick={onDelete}
          />
        ) : (
          <FaTrashAlt className="text-gray-300" />
        )}
        <div className="ml-auto flex items-center cursor-pointer">
          {isOpen ? (
            <IoIosArrowUp onClick={toggleAccordion} />
          ) : (
            <IoIosArrowDown onClick={toggleAccordion} />
          )}
        </div>
      </div>
      {!!dateError && (
        <p className="text-red-500 text-sm  py-2 ">{dateError}</p>
      )}
      {overlaps.includes(index) && (
        <p className="text-red-500 text-sm  py-2 ">{"Slots overlap"}</p>
      )}
      {slotErrors.includes(index) && (
        <p className="text-red-500 text-sm  py-2 ">
          {"Invalid start/end time entered"}
        </p>
      )}
      <div
        className={`${
          isOpen ? "block" : "hidden"
        } transition ease-out duration-300 flex-shrink-0`}
      >
        <div
          className={`grid ${
            servicesData?.services?.length > 0
              ? "md:grid-cols-7"
              : "md:grid-cols-6"
          } gap-8 my-2 `}
        >
          <div>
            <label className="block">
              <span className="flex text-sm text-slate-700">
                Date
                <p className="text-sm text-red-500 ml-1">*</p>
              </span>
              <DatePicker
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2.5 p-2.5"
                placeholderText="Select date"
                selected={watch(`tasks.${index}.startDate`)}
                onChange={(date) => handleStartDate(date)}
                injectTimes={[new Date(10, 10, 10, 10, 50, 0, 0)]}
              />
            </label>
            {errors?.tasks?.length > 0 && onSubmitClicked ? (
              <p className="text-sm text-red-500">
                {errors?.tasks[index]?.startDate?.message}
              </p>
            ) : null}
          </div>
          <div>
            <label className="flex text-sm ">
              Type
              <p className="text-sm text-red-500 ml-1">*</p>
            </label>
            {taskTypeData && (
              <Autocomplete
                getItemValue={(item) => item?.name}
                items={taskTypeData?.taskTypes}
                shouldItemRender={(item, value) =>
                  item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select task type",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.name}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item.name}
                  </div>
                )}
                value={type}
                onChange={(e) => setType(e.target.value)}
                onSelect={(val) => setType(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
            )}
            {errors?.tasks?.length > 0 && onSubmitClicked ? (
              <p className="text-sm text-red-500">
                {errors?.tasks[index]?.taskType?.name?.message}
              </p>
            ) : null}
          </div>

          <div>
            <span className="block text-sm font-medium text-slate-700">
              Client
            </span>

            {clientData && (
              <Autocomplete
                getItemValue={(item) => item?.name}
                items={clientData?.clients ?? []}
                shouldItemRender={(item, value) =>
                  item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select a client",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item?._id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item?.name}
                  </div>
                )}
                value={client}
                onChange={(e) => setClient(e.target.value)}
                onSelect={(val) => setClient(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
            )}
            {errors?.tasks?.length > 0 ? (
              <p className="text-sm text-red-500">
                {errors?.tasks[index]?.projectDetail?.client?.name?.message
                  ? errors?.tasks[index]?.projectDetail?.client?.name?.message
                  : errors?.tasks[index]?.projectDetail?.client?.message}
              </p>
            ) : null}
          </div>
          <div>
            <span className="flex text-sm font-medium text-slate-700">
              Project
              <p className="text-sm text-red-500 ml-1">*</p>
            </span>
            {data && (
              <Autocomplete
                getItemValue={(item) => item?.project_name}
                items={
                  client
                    ? filteredProjects.length > 0
                      ? filteredProjects
                      : data?.projects
                    : data?.projects
                }
                shouldItemRender={(item, value) =>
                  item?.project_name
                    ?.toLowerCase()
                    .indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select a project",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item?.id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item?.project_name}
                  </div>
                )}
                value={project}
                onChange={(e) => setProject(e.target.value)}
                onSelect={(val) => setProject(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
            )}
            {errors?.tasks?.length > 0 && onSubmitClicked ? (
              <p className="text-sm text-red-500">
                {errors?.tasks[index]?.projectDetail?.project_name?.message}
              </p>
            ) : null}
          </div>

          {servicesData?.services?.length > 0 && (
            <div>
              <label className="flex text-sm ">
                <span className="flex text-sm font-medium text-slate-700">
                  Service
                  <p className="text-sm text-red-500 ml-1">*</p>
                </span>
              </label>
              <Autocomplete
                getItemValue={(item) => item?.name}
                items={servicesData?.services}
                shouldItemRender={(item, value) =>
                  item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{ display: "block", position: "relative" }}
                inputProps={{
                  placeholder: "Select service",
                  className:
                    "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item.name}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item.name}
                  </div>
                )}
                value={service}
                onChange={(e) => handleServiceClick(e.target.value)}
                onSelect={(val) => handleServiceClick(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
              {errors?.tasks?.length > 0 && onSubmitClicked ? (
                <p className="text-sm text-red-500">
                  {errors?.tasks[index]?.service?.message}
                </p>
              ) : null}
            </div>
          )}

          <TaskTimeCalculator
            startTime={watch(`tasks.${index}.startTime`)}
            endTime={watch(`tasks.${index}.endTime`)}
            duration={calculateDuration}
            startTimeOnChange={handleTime}
            endTimeOnChange={handleTime}
          />
        </div>
        <TaskFileUpload watch={watch} index={index} setValue={setValue} />

        <TaskDescription
          addDescription={addDescription}
          setAddDescription={setAddDescription}
          regProp={register(`tasks.${index}.description`)}
          error={errors.description?.message}
        />
      </div>
    </div>
  );
}

import { useState } from "react";
import { FaFileUpload } from "react-icons/fa";

function FileUpload({
  attachments,
  setAttachments,
  index,
}: {
  attachments: [any];
  setAttachments: any;
  index: number;
}) {
  const [error, setError] = useState("");
  // Function to handle file and store it to file state
  const handleFileChange = (e) => {
    setError("");
    // const maxSize = 15 * 1024 * 1024;
    const files1 = [...e.target?.files];

    if (files1?.length + attachments?.length > 15) {
      setError("You can add upto 15 attachments");
      return;
    }
    let result = [...files1, ...attachments];
    setAttachments(result);
  };
  return (
    <div className="relative">
      <span className="py-1 text-red-600 text-xs">{error}</span>
      <input
        type="file"
        id={`fileInput-${index}`}
        onChange={handleFileChange}
        multiple
        className="hidden"
      />
      <label
        htmlFor={`fileInput-${index}`}
        className=" bg-transparent text-customPurple-900 border border-customPurple-900 items-center gap-2 p-2 rounded-md text-xs flex font-medium disabled:border-customGrey-600 disabled:text-customGrey-600"
      >
        Upload Files
        <FaFileUpload />
      </label>
    </div>
  );
}

export default FileUpload;

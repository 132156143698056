import React, { useEffect, useRef, useState } from "react";
import Logo from "../../assets/logo.png";
import HamburgerButton from "./HamburgerButton";
import { useLocation, useNavigate } from "react-router";
import Avatar from "./Avatar";
import { useAuth } from "src/context/Auth";
import { PM, USER } from "src/utils/Constants";

export default function Navbar({ handleClick }: { handleClick: () => void }) {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <div>
      {["/user/addtask", "/user/edittimelog"].includes(
        location.pathname
      ) ? null : (
        <div className="flex items-center">
          <>
            <div className="flex items-center pl-5 md:pl-0">
              <HamburgerButton onClick={handleClick} />
            </div>
            <img
              className="p-custom1"
              src={Logo}
              alt="banner"
              style={{
                width: "75px",
                cursor: "pointer",
              }}
              // onClick={() =>
              //   user.type_of_user === PM
              //     ? navigate("/user/approval")
              //     : navigate("/")
              // }
              onClick={() => navigate("/")}
            />
          </>
          <div className="ml-auto mr-6 cursor-pointer">
            <AccountDetailsPopup />
          </div>
        </div>
      )}
    </div>
  );
}

const AccountDetailsPopup = () => {
  const { user, logout } = useAuth();
  const [isPopupOpen, setPopupOpen] = useState(false);
  const navigate = useNavigate();
  const initials =
    user?.first_name?.charAt(0) + user?.last_name?.charAt(0).toUpperCase();

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };
  const popupRef = useRef(null);

  const handleOutsideClick = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setPopupOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleOutsideClick);
    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="relative z-[50]">
      <div onClick={togglePopup}>
        <Avatar initials={initials} color={"pink"} size={"8"} />
      </div>
      {isPopupOpen && (
        <div
          ref={popupRef}
          className="absolute right-0 mt-2 w-60 h-72 bg-white rounded-lg shadow-lg z-10"
        >
          <div>
            <div className=" h-full p-4 flex flex-col items-center">
              <div
                className={`w-20 h-20 flex items-center justify-center rounded-full bg-customPurple-400`}
              >
                <span className="text-2xl font-semibold capitalize">
                  {initials}
                </span>
              </div>
              <p className="font-bold text-xl capitalize">
                {user.first_name} {user.last_name}
              </p>
              <p className="text-base font-normal text-customGrey-300 capitalize">
                {user.type_of_user.map((type, ind) => {
                  return (
                    <>
                      {type === "pm" ? "Project Manager" : type}
                      {ind !== user.type_of_user.length - 1 && ", "}
                    </>
                  );
                })}
              </p>
            </div>
            <div className="border-t border-gray-300 my-4"></div>
            <div className="flex flex-col items-center gap-3">
              <p
                className="font-semibold"
                onClick={() => {
                  navigate("/settings");
                  togglePopup();
                }}
              >
                Settings
              </p>
              <p
                className="font-semibold"
                onClick={() => {
                  {
                    logout();
                    navigate("/login");
                  }
                }}
              >
                Logout
              </p>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

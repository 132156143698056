import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getClients, getProjects, getTaskTypes } from "../../api";
import { serverGET, serverPOST, serverPUT } from "../../HttpFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../context/Auth";
import "react-toastify/dist/ReactToastify.css";
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryIconButton,
} from "../../components/Global/Button";
import { useNavigate } from "react-router";
import moment from "moment";
import { successNotfiy, errorNotfiy } from "../../components/Global/Toasts";
import TaskList from "./TaskList";
import ContentContainer from "../../components/Global/ContentContainer";
import BreadCrumb from "../../components/Global/BreadCrumb";
import { FaPlus } from "react-icons/fa";
import PopupHeader from "src/components/Global/PopupHeader";
import { HeaderInfo } from "./AddTimelogWeek";
import { PENDING } from "src/utils/Constants";
import { uploadToBucket } from "src/utils/CommonFunctions";
import PayrollModal from "src/components/User/PayrollModal";

type projectType = {
  project_name: "";
  client: { _id: ""; name: "" };
  _id: "";
};
type taskType = {
  name: "";
  is_billable?: boolean | null;
};

export type TimeLogTask = {
  taskType: taskType;
  projectDetail?: projectType;
  hours: number;
  minutes: number;
  description: string;
};

const schema = yup
  .object({
    tasks: yup.array().of(
      yup.object().shape({
        startDate: yup
          .date()
          .nullable()
          .required("Start date is required")
          .typeError("Start date is required"),
        projectDetail: yup
          .object({
            project_name: yup.string().required("Project is required"),
            client: yup
              .object({
                _id: yup.string(),
                // .required("Client is required"),
                name: yup.string(),
                // .required("Client is required"),
              })
              .typeError(""),
            _id: yup.string().required(),
          })
          .typeError("")
          .required("Project and client is required"),
        taskType: yup
          .object({
            name: yup.string().required("Type is required"),
            is_billable: yup.mixed().nullable().oneOf([true, false, null]),
          })
          .required(),

        service: yup.string().when("serviceAddedCheck", {
          is: true,
          then: (schema) => schema.required("Service is required field"),
          otherwise: (schema) => schema,
        }),
        description: yup.string(),
        startTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(9, 0, 0, 0);
          return date;
        }),
        endTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(17, 0, 0, 0);
          return date;
        }),
        serviceAddedCheck: yup.boolean(),
        attachments: yup.array(),
      })
    ),
  })
  .required();

const weekSchema = yup
  .object({
    // mode: yup.string().required(),
    // location: yup.string().required(),
    tasks: yup.array().of(
      yup.object().shape({
        // projectDetail: yup.object(),
        startDate: yup
          .date()
          .nullable()
          .required("Start date is required")
          .typeError("Start date is required"),
        description: yup.string(),

        startTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(9, 0, 0, 0);
          return date;
        }),
        endTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(17, 0, 0, 0);
          return date;
        }),
        status: yup.string().required(),
        attachments: yup.array(),
      })
    ),
  })
  .required();

export default function AddTimeLog({
  isWeekView,
  headerInfo,
  handleClose,
  weeklyRefetch,
}: {
  isWeekView?: boolean;
  headerInfo?: HeaderInfo;
  handleClose?: () => void;
  weeklyRefetch?: any;
}) {
  const { user } = useAuth();
  const { data: clientData } = useQuery("clients", () => serverGET(getClients));
  const { data: taskTypeData } = useQuery("tasktypes", () =>
    serverGET("/tasktype?is_active=true")
  );

  const navigate = useNavigate();
  const [dateError, setDateError] = useState("");
  const [overlaps, setOverlaps] = useState([]);
  const [slotErrors, setSlotErrors] = useState([]);
  const [showError, setShowError] = useState(false);
  const [onSubmitClicked, setOnSubmitClicked] = useState(false);
  const [popupDate, setPopupDate] = useState(new Date(headerInfo?.taskDate));
  const [payrollError, setPayrollError] = useState(null);

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isWeekView ? weekSchema : schema),
    defaultValues: isWeekView
      ? {
          tasks: [
            {
              startDate: popupDate,
              description: "",
              startTime: new Date(
                popupDate.getFullYear(),
                popupDate.getMonth(),
                popupDate.getDate(),
                9,
                0,
                0,
                0
              ),
              // null,
              endTime: new Date(
                popupDate.getFullYear(),
                popupDate.getMonth(),
                popupDate.getDate(),
                17,
                0,
                0,
                0
              ),
              // null,
              serviceAddedCheck: false,
              projectDetail: {
                project_name: headerInfo.project.value,
                client: {
                  _id: headerInfo.client.id,
                  name: headerInfo.client.value,
                },
                _id: headerInfo.project.id,
              },
              taskType: {
                name: headerInfo.taskType.value,
                is_billable: headerInfo.taskType.is_billable,
              },
              service: headerInfo.service.id,
              status: PENDING,
              attachments: [],
            },
          ],
        }
      : {
          tasks: [
            {
              startDate: null,
              projectDetail: {
                project_name: "",
                client: { _id: "", name: "" },
                _id: "",
              },
              taskType: {
                name: "",
                is_billable: null,
              },
              service: "",
              description: "",
              startTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                9,
                0,
                0,
                0
              ),
              // null,
              endTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                17,
                0,
                0,
                0
              ),
              // null,
              serviceAddedCheck: false,
              attachments: [],
            },
          ],
        },
  });

  const { isLoading, isError, data, error } = useQuery("projects", () =>
    serverGET("/project?is_active=true")
  );
  const handleAddTask = () => {
    let temp = watch("tasks");
    let obj;

    if (isWeekView) {
      let start = popupDate;
      start.setHours(9, 0, 0, 0);
      let end = popupDate;
      end.setHours(17, 0, 0, 0);

      obj = {
        projectDetail: {
          project_name: headerInfo.project.value,
          client: { _id: headerInfo.client.id, name: headerInfo.client.value },
          _id: headerInfo.project.id,
        },
        taskType: {
          name: headerInfo.taskType.value,
          is_billable: headerInfo.taskType.is_billable,
        },
        service: headerInfo.service.id,
        description: "",
        startTime: start,
        endTime: end,
        startDate: popupDate,
        serviceAddedCheck: false,
        status: PENDING,
        attachments: [],
      };
    } else {
      let start = new Date();
      start.setHours(9, 0, 0, 0);
      let end = new Date();
      end.setHours(17, 0, 0, 0);

      obj = {
        projectDetail: {
          project_name: "",
          client: { _id: "", name: "" },
          _id: "",
        },
        taskType: {
          name: "",
          is_billable: null,
        },
        service: "",
        description: "",
        startTime: start,
        endTime: end,
        startDate: null,
        serviceAddedCheck: false,
        attachments: [],
      };
    }

    temp = [...temp, obj];
    setValue("tasks", temp);
    // setValue(`tasks.${index}`, obj);
  };

  const handleCopyTask = (index: number) => {
    const temp = watch("tasks");
    const copied = watch(`tasks.${index}`);
    let item = Object.assign({}, copied) as any;
    item.startDate = null;
    temp.push(item);
    setValue(`tasks`, temp);
  };
  const handleDelete = (index: number) => {
    const temp = watch("tasks");
    // const ov = [...overlaps];
    // const err = [...slotErrors];
    // if (overlaps.includes(index)) {
    //   ov.splice(index, 1);
    //   setOverlaps(ov);
    // }
    // if (slotErrors.includes(index)) {
    //   err.splice(index, 1);
    //   setSlotErrors(err);
    // }
    if (index !== -1) {
      temp.splice(index, 1);
    }
    setValue(`tasks`, temp);
  };

  // useEffect(() => {
  //   minEndTime = moment()
  //     .hours(startTime.getHours())
  //     .minutes(startTime.getMinutes());

  //   maxStartTime = moment()
  //     .hours(endTime.getHours())
  //     .minutes(endTime.getMinutes());

  //   const duration = moment.duration(moment(endTime).diff(moment(startTime)));
  //   setTotalWorkingHours(
  //     `${duration.hours()}:${duration
  //       .minutes()
  //       .toLocaleString([], { minimumIntegerDigits: 2 })}`
  //   );
  // }, [startTime, endTime]);

  //for now task's type is any. later change to Task
  const mutation = useMutation({
    mutationFn: (task: any) => {
      return serverPOST("/task/create", task);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: async (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor !== String) {
        //showing message

        if (updatedData?.payrollError) {
          setPayrollError(updatedData?.payrollError);
          return;
        }

        setTimeout(() => {
          successNotfiy("Tasks have been added successfully");
        }, 2000);
        if (isWeekView) {
          navigate("/user/timesheet");
          await weeklyRefetch();
          handleClose();
        } else {
          navigate("/user/timesheet");
        }

        return;
      }
      errorNotfiy(
        "Some of your tasks is overlapping with other task on same day"
      );
    },
  });

  const onSubmit = async (data) => {
    console.log("on submit data", data);
    if (dateError) {
      return;
    }
    if (overlaps.length > 0) {
      return;
    }
    setSlotErrors([]);
    // startTime.setFullYear(startDate.getFullYear());
    // startTime.setMonth(startDate.getMonth());
    // startTime.setDate(startDate.getDate());

    let startMilliseconds: number;
    let endMilliseconds: number;
    let totalTaskTime = 0;
    let totalWorkingMilliseconds = 0;
    let errors = [];

    let tasks = data.tasks?.map((task, i) => {
      //new
      task.startTime.setFullYear(task.startDate.getFullYear());
      task.startTime.setMonth(task.startDate.getMonth());
      task.startTime.setDate(task.startDate.getDate());

      task.endTime.setFullYear(task.startDate.getFullYear());
      task.endTime.setMonth(task.startDate.getMonth());
      task.endTime.setDate(task.startDate.getDate());

      startMilliseconds = moment(task.startTime).valueOf();
      endMilliseconds = moment(task.endTime).valueOf();

      if (
        task.startTime.getHours() === 0 &&
        task.startTime.getMinutes() === 0 &&
        task.startTime.getSeconds() === 0 &&
        task.endTime.getHours() === 0 &&
        task.endTime.getMinutes() === 0 &&
        task.endTime.getSeconds() === 0
      ) {
        task.endTime.setDate(task.startDate.getDate() + 1);
        endMilliseconds = moment(task.endTime).valueOf();
      } else if (
        task.endTime.getHours() === 0 &&
        task.endTime.getMinutes() === 0 &&
        task.endTime.getSeconds() === 0
      ) {
        task.endTime.setDate(task.startDate.getDate() + 1);
        endMilliseconds = moment(task.endTime).valueOf();
      } else {
        if (startMilliseconds === endMilliseconds) {
          errors.push(i);
        } else if (startMilliseconds > endMilliseconds) {
          errors.push(i);
        }
      }

      let duration = moment
        .duration(moment(endMilliseconds).diff(moment(startMilliseconds)))
        .asMinutes();

      return {
        project: task.projectDetail._id,
        type: task.taskType,
        service: task.service,
        description: task.description,
        start_time: startMilliseconds,
        end_time: endMilliseconds,
        duration_minutes: duration,
        attachments: task.attachments,
      };
    });
    if (errors.length > 0) {
      setSlotErrors([...errors]);
      return;
    }

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      if (task.attachments.length > 0) {
        const url = await uploadToBucket(task.attachments);
        if (url.length > 0) {
          tasks[i].attachments = [...url];
        }
      }
    }

    let task = {
      user: user._id,
      tasks: tasks,
      isWeekView,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    // console.log("🚀 ~ file: AddTimeLog.tsx:336 ~ onSubmit ~ task:", task);
    // return;
    // if (totalTaskTime === totalWorkingMilliseconds) {
    // setShowError(false);
    mutation.mutate(task);
    // } else {
    //   setShowError(true);
    // }
  };

  const handleClosePayroll = async () => {
    setPayrollError(null);
    if (isWeekView) {
      navigate("/user/timesheet");
      await weeklyRefetch();
      handleClose();
    } else {
      navigate("/user/timesheet");
    }
  };

  return (
    <>
      {!isWeekView ? (
        <ContentContainer>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="overflow-auto "
            style={{ height: "80vh" }}
          >
            {!showError ? null : (
              <p className="text-red-500 text-sm flex justify-center p-2 italic">
                There is a conflict between the total number of working hours
                and the total number of task hours
              </p>
            )}
            {!!dateError && (
              <p className="text-red-500 text-sm flex justify-center p-2 italic">
                {dateError}
              </p>
            )}
            <div className="sticky top-0 bg-white z-10 px-2 pb-1">
              <div className="py-6">
                <BreadCrumb
                  data={[
                    { name: "My Timesheet", link: "/user/timesheet" },
                    { name: "Time Log", link: "#" },
                  ]}
                />
              </div>
              <div className="flex items-center mb-4">
                <p className="font-bold">Time Log</p>
                <div className="ml-auto flex items-center">
                  <SecondaryIconButton
                    text="Task"
                    icon={<FaPlus />}
                    onClick={handleAddTask}
                  />
                </div>
              </div>
            </div>
            <div className="my-4  w-full bg-white">
              <div className="lg:pb-10 ">
                {watch("tasks").map((x, i) => {
                  return (
                    <TaskList
                      key={i}
                      index={i}
                      data={data}
                      watch={watch}
                      errors={errors}
                      register={register}
                      setValue={setValue}
                      onCopy={() => handleCopyTask(i)}
                      onDelete={() => handleDelete(i)}
                      overlaps={overlaps}
                      setOverlaps={setOverlaps}
                      clientData={clientData}
                      taskTypeData={taskTypeData}
                      slotErrors={slotErrors}
                      onSubmitClicked={onSubmitClicked}
                    />
                  );
                })}
              </div>
            </div>
            <div className="flex justify-center items-center fixed bottom-0 bg-customGrey-100 w-screen py-5 px-10">
              <SecondaryButton
                text="Cancel"
                onClick={() => navigate("/user/timesheet")}
              />
              <span className="mx-3" />
              <PrimaryButton
                text="Submit"
                onClick={() => setOnSubmitClicked(true)}
                mutation={mutation}
              />
            </div>
          </form>
        </ContentContainer>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="overflow-auto "
          // style={{ height: "80vh" }}
        >
          <div className="my-4 bg-white">
            <div className="px-10">
              <PopupHeader
                tasktype={headerInfo.taskType.value}
                client={headerInfo.client.value}
                project={headerInfo.project.value}
                service={headerInfo.service.value}
              />
              {watch("tasks").map((x, i) => {
                return (
                  <TaskList
                    key={i}
                    index={i}
                    data={data}
                    watch={watch}
                    errors={errors}
                    register={register}
                    setValue={setValue}
                    onCopy={() => handleCopyTask(i)}
                    onDelete={() => handleDelete(i)}
                    overlaps={overlaps}
                    setOverlaps={setOverlaps}
                    clientData={clientData}
                    taskTypeData={taskTypeData}
                    slotErrors={slotErrors}
                    onSubmitClicked={onSubmitClicked}
                    isWeekView={true}
                    handleAddTask={handleAddTask}
                  />
                );
              })}
              <div className="flex justify-center">
                <SecondaryButton text="Cancel" onClick={handleClose} />
                <span className="mx-3" />
                <PrimaryButton
                  text="Submit"
                  onClick={() => setOnSubmitClicked(true)}
                  mutation={mutation}
                />
              </div>
            </div>
          </div>
        </form>
      )}
      {!!payrollError && (
        <PayrollModal
          payrollError={payrollError}
          handleClose={handleClosePayroll}
        />
      )}
    </>
  );
}

import React, { createElement, useEffect, useState } from "react";
import WeekTable from "../../components/Admin/WeekTable";
import moment from "moment";
import { serverGET } from "../../HttpFunctions";
import { useQuery, useQueryClient } from "react-query";
import UserTable from "../../components/User/UserTable";
import { useCookies } from "react-cookie";
import Pagination from "../../components/Global/Pagination";
import { useAuth } from "../../context/Auth";
import ContentContainer from "../../components/Global/ContentContainer";
import { groupWeekView } from "../../utils/GroupTable";
import Alert from "../../components/Global/Toasts";
import FilteredChecks from "src/components/Global/FilteredChecks";
import useClientProjects from "src/hooks/useClientProjects";
import { downloadExcel } from "src/utils/DownloadExcel";
import { fetchData } from "src/utils/FetchFilteredData";
import Spinner from "src/components/Global/Spinner";
import CardDetails from "src/pages/Dashboard/CardsDetails";

export default function ListView({
  handleViewChange,
}: {
  handleViewChange: () => void;
}) {
  const { user } = useAuth();
  const [page, setPage] = useState(0);
  const [tasksData, setTasksData] = useState(null);
  const [optionsSelected, setOptionsSelected] = useState({
    status: [],
    type: [],
    employee: [],
    project: [],
    client: [],
    date: null,
    endDate: null,
    create_startDate: null,
    create_endDate: null,
  });
  const [projectOptions, clientOptions] = useClientProjects(
    user,
    optionsSelected
  );

  const { error, data, isLoading, isPreviousData, refetch } = useQuery({
    queryKey: ["user-tasks-user", user?._id, page, optionsSelected],
    queryFn: () => fetchData(`/task?page=${page}`, optionsSelected),
    keepPreviousData: true,
    enabled: !!user,
  });

  useEffect(() => {
    gatherData();
  }, [data]);

  const gatherData = async () => {
    if (data && data?.tasks?.length > 0) {
      let groupedData = [];
      let tasks = [...data?.tasks];
      groupedData = groupWeekView(tasks, true);
      setTasksData({ ...data, tasks: groupedData });
    } else {
      setTasksData({ count: 0, tasks: [] });
    }
  };

  if (error) {
    return createElement("div", null, "error");
  }
  if (isLoading) {
    return <Spinner />;
  }

  const handleDownload = async () => {
    downloadExcel(
      `/task/download?currentTime=${new Date().getTime()}`,
      optionsSelected
    );
  };

  return (
    <div>
      <>
        <UserTable
          data={tasksData}
          page={page}
          user={user}
          refetch={refetch}
          optionsSelected={optionsSelected}
          handleViewChange={handleViewChange}
          form={
            <FilteredChecks
              optionsSelected={optionsSelected}
              setOptionsSelected={setOptionsSelected}
              projectOptions={projectOptions}
              clientOptions={clientOptions}
              isAdmin={true}
              setPage={setPage}
              handleDownload={handleDownload}
              taskLength={data?.tasks?.length}
            />
          }
          cardDetails={
            <CardDetails data={data} showCards={[true, true, false, false]} />
          }
        />
        {data && data?.tasks?.length > 0 ? (
          <Pagination
            page={page}
            data={data}
            setPage={setPage}
            isPreviousData={isPreviousData}
          />
        ) : null}
        <Alert />
      </>
    </div>
  );
}

import React, { useState } from "react";
import moment from "moment";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import { useQuery } from "react-query";
import { serverGET } from "../../HttpFunctions";
import { getClients, getProjects, getTaskTypes } from "../../api";
import TaskDetails from "./TaskDetails";
import Dialogue from "../Global/Dialogue";
import PopupHeader from "../Global/PopupHeader";

export type Task = {
  _id: string;
  description: string;
  status: string;
  project: {
    project_name: string;
    client: {
      name: string;
    };
  };
  start_time: number;
  end_time: number;
  duration_minutes: number;
  billable_minutes: number;
  timezone?: string;
  type: { name: string; is_billable: boolean };
  location: { address: string; _id: string };
  work_mode: string;
  action_by: {
    first_name: string;
    last_name: string;
    email: string;
    type_of_user: [string];
  };
  service: {
    name: string;
    _id: string;
  };
  attachments?: [string];
};

type TimeLog = {
  date: string;
  total_hrs: number;
  tasks: Task[];
  startDate?: string;
};

type TimeLogDetailsProps = {
  closeClick: () => void;
  handleStatus: (selectedTimeLog: Task, status: string) => void;
  selectedTimeLog: TimeLog;
  handleApproveAll: (
    taskIds: string[],
    billableHrsList: BillableType[]
  ) => void;
  handleBillableHours: (selectedTask: Task) => void;
  refetch?: any;
  weekView?: boolean;
};

export type BillableType = { _id: string; billable_minutes: number };

export default function TimeLogDetails({
  closeClick,
  selectedTimeLog,
  handleStatus,
  handleApproveAll,
  handleBillableHours,
  refetch,
  weekView,
}: TimeLogDetailsProps) {
  //have to make sure that the tasks are in order so that we can calculate clock in and clock out time and see if there are gaps
  //in the time when tasks are created
  const [approveAll, setApproveAll] = useState(false);
  const [denyAll, setDenyAll] = useState(false);
  const [statusList, setStatusList] = useState([] as any[]);
  const [billableHrsList, setBillableHrsList] = useState([] as BillableType[]);
  const taskLength = selectedTimeLog.tasks?.length;
  const [isLoading, setIsLoading] = useState(false);
  const taskIds = selectedTimeLog.tasks.reduce((a, b, index) => {
    if (index === taskLength - 1) return a + `taskId=${b._id}`;
    else return a + `taskId=${b._id}&`;
  }, "");

  const { data: commentData, refetch: commentRefetch } = useQuery({
    queryKey: ["comments"],
    queryFn: () => serverGET(`/comment?${taskIds}`),
    enabled: !!taskIds,
  });

  const { data } = useQuery("projects", () =>
    serverGET("/project?is_active=true")
  );

  const { data: clientData } = useQuery("clients", () => serverGET(getClients));

  const { data: serviceData } = useQuery("services", () =>
    serverGET("/service")
  );

  const { data: taskTypeData } = useQuery("taskTypes", () =>
    serverGET("/tasktype?is_active=true")
  );

  const handleSubmit = () => {
    handleApproveAll(statusList, billableHrsList);
    setIsLoading(true);
  };
  const handleSubmitDisable = () => {
    if (statusList.length > 0 || billableHrsList.length > 0) return false;
    else return true;
  };
  //   const minutes = time
  //     .getMinutes()
  //     .toLocaleString([], { minimumIntegerDigits: 2 });

  //   const amOrPm = hours < 12 ? "AM" : "PM";

  //   return `${hours12}:${minutes} ${amOrPm}`;
  // }

  return (
    <Dialogue
      title={`Time Log Details for
      ${
        weekView
          ? selectedTimeLog?.startDate
          : selectedTimeLog?.date.includes("(")
          ? selectedTimeLog?.date
          : moment(
              selectedTimeLog?.date?.split("-").reverse().join("-")
            ).format("MM/DD/YYYY")
      }`}
      handleClose={closeClick}
    >
      <div className="md:px-10 px-5 pb-5">
        {/* <p className="flex justify-center font-semibold">Tasks</p> */}
        <div className="flex justify-end">
          <p
            className={
              denyAll
                ? "rounded-full px-3 py-2 bg-customRed-100 max-w-fit text-sm text-red-500 font-semibold mr-1 hover:cursor-pointer"
                : "flex items-center px-3 py-2 text-sm text-red-500 font-semibold mr-1 hover:cursor-pointer"
            }
            onClick={() => {
              setDenyAll(true);
              setStatusList(
                selectedTimeLog.tasks.map((x) => {
                  return { id: x._id, status: 0 };
                })
              );

              setApproveAll(false);
            }}
          >
            {denyAll ? "Denied All " : "Deny All"}
          </p>
          <div className="w-px bg-customGrey-400 mr-1"></div>

          <p
            className={
              approveAll
                ? "rounded-full px-3 py-2 bg-paleGreen-100 max-w-fit text-sm text-green-500 font-semibold mr-1 hover:cursor-pointer"
                : "flex items-center px-3 py-2 text-sm text-green-500 font-semibold mr-1 hover:cursor-pointer"
            }
            onClick={() => {
              setApproveAll(true);
              setStatusList(
                selectedTimeLog.tasks.map((x) => {
                  return { id: x._id, status: 1 };
                })
              );
              setDenyAll(false);
            }}
          >
            {approveAll ? "Approved All " : "Approve All"}
          </p>
        </div>
        {weekView && selectedTimeLog?.tasks?.length > 0 && (
          <PopupHeader
            tasktype={selectedTimeLog?.tasks[0].type.name}
            client={selectedTimeLog?.tasks[0].project.client.name}
            project={selectedTimeLog?.tasks[0].project.project_name}
            service={selectedTimeLog?.tasks[0]?.service?.name}
          />
        )}
        {selectedTimeLog?.tasks?.map((tl, index) => {
          return (
            <TaskDetails
              clientData={clientData}
              taskTypeData={taskTypeData}
              projectData={data}
              serviceData={serviceData}
              handleStatus={handleStatus}
              handleBillableHours={handleBillableHours}
              index={index}
              tl={tl}
              refetch={refetch}
              statusList={statusList}
              setStatusList={setStatusList}
              comments={commentData?.comments[tl._id]}
              commentRefetch={commentRefetch}
              weekView={weekView}
              billableHrsList={billableHrsList}
              setBillableHrsList={setBillableHrsList}
            />
          );
        })}
      </div>
      <div className="flex items-center justify-center p-6 bg-customGrey-100 rounded-b">
        <SecondaryButton text={"Cancel"} onClick={closeClick} />
        <span className="mx-4" />
        <PrimaryButton
          text={"Submit"}
          onClick={handleSubmit}
          disabled={handleSubmitDisable()}
          isLoading={isLoading}
        />
      </div>
    </Dialogue>
  );
}

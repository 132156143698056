import React, { Dispatch, SetStateAction, useMemo } from "react";
import { PAGE_COUNT } from "../../utils/Constants";

type PaginationProps = {
  page: number;
  data: any;
  setPage: Dispatch<SetStateAction<number>>;
  isPreviousData: boolean;
};

export default function Pagination({
  page,
  data,
  setPage,
  isPreviousData,
}: PaginationProps) {
  const start = page === 0 ? page + 1 : page * PAGE_COUNT;
  const end = page * PAGE_COUNT + PAGE_COUNT;

  const pageNumber = useMemo(() => {
    return end <= data?.count
      ? start + " - " + end + " of " + data?.count
      : start + " - " + data?.count + " of " + data?.count;
  }, [page, data]);

  return (
    <div className="flex justify-center mt-4">
      <div className="inline-flex -space-x-px">
        <button
          onClick={() => setPage((prev) => Math.max(prev - 1, 0))}
          disabled={page === 0}
          className="disabled:bg-slate-50 cursor-pointer px-3 py-2 ml-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-l-lg enabled:hover:bg-gray-100 enabled:hover:text-gray-700 "
        >
          Previous
        </button>
        <button className="cursor-pointer  px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 ">
          {pageNumber}
        </button>
        <button
          onClick={() => {
            setPage((prev) => prev + 1);
          }}
          disabled={isPreviousData || end >= data?.count}
          className="disabled:bg-slate-50 cursor-pointer px-3 py-2 leading-tight text-gray-500 bg-white border border-gray-300 rounded-r-lg enabled:hover:bg-gray-100 enabled:hover:text-gray-700"
        >
          Next
        </button>
      </div>
    </div>
  );
}

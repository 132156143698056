import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { useAuth } from "./context/Auth";

function AuthRoute({ children }) {
  const { isLogged, user } = useAuth();
  const location = useLocation();

  if (isLogged)
    return (
      <Navigate
        replace
        to={{
          pathname: location.state?.from
            ? location.state.from
            : user?.type_of_user.includes("admin")
            ? "/dashboard"
            : user?.type_of_user.includes("user")
            ? "/user/timesheet"
            : "/user/approval",
        }}
      />
    );
  return children;
}

export default AuthRoute;

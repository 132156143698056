import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const successNotfiy = (text) => {
  toast.success(text, {
    toastId: "success",
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    theme: "light",
  });
};
export const errorNotfiy = (text) => {
  toast.error(text, {
    toastId: "error",
    position: "bottom-center",
    autoClose: 3000,
    hideProgressBar: true,
    newestOnTop: false,
    closeOnClick: true,
    rtl: false,
    pauseOnFocusLoss: false,
    draggable: false,
    pauseOnHover: false,
    theme: "light",
  });
};
export default function Alert() {
  return <ToastContainer className={"text-sm"} />;
}

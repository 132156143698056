import moment from "moment";
import React, { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import WeekTable from "../../components/Admin/WeekTable";
import { serverGET } from "../../HttpFunctions";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Spinner from "../../components/Global/Spinner";

export default function PmHome() {
  const [currentWeekStart, setCurrentWeekStart] = useState(
    moment()
      .day(6 - 7)
      .startOf("day")
  );
  const [currentWeekEnd, setCurrentWeekEnd] = useState(() =>
    moment()
      .day(6 - 7)
      .add(7, "days")
      .startOf("day")
  );
  const queryClient = useQueryClient();

  const { isLoading, isError, data, error, isPreviousData } = useQuery({
    queryKey: ["pm-tasks", currentWeekStart.valueOf()],
    keepPreviousData: true,
    staleTime: Infinity,
    cacheTime: Infinity,
    queryFn: () =>
      serverGET(
        "/user/users/taskdetails" +
          `?page=0&start_time=${currentWeekStart.valueOf()}&type=pm`
      ),
  });

  useEffect(() => {
    if (!isPreviousData) {
      queryClient.prefetchQuery({
        queryKey: ["pm-tasks", currentWeekStart.valueOf()],
        queryFn: () =>
          serverGET(
            "/user/users/taskdetails" +
              `?page=0&start_time=${currentWeekStart.valueOf()}&type=pm`
          ),
      });
    }
  }, [data, isPreviousData, queryClient]);

  if (isLoading) {
    return <Spinner/>;
  }

  if (isError) {
    return "Something went wrong";
  }

  if (data?.constructor === String) {
    return <h1 className="text-lg text-center w-full mt-4">{data}</h1>;
  }

  const getNextWeek = () => {
    const nextWeekStart = currentWeekStart.clone().add(1, "weeks");
    const nextWeekEnd = currentWeekEnd.clone().add(1, "weeks");
    setCurrentWeekStart(nextWeekStart);
    setCurrentWeekEnd(nextWeekEnd);
  };

  const getPrevWeek = () => {
    const prevWeekStart = currentWeekStart.clone().subtract(1, "weeks");
    const prevWeekEnd = currentWeekEnd.clone().subtract(1, "weeks");
    setCurrentWeekStart(prevWeekStart);
    setCurrentWeekEnd(prevWeekEnd);
  };

  return (
    <>
      <div className="flex-grow p-4">
        <div className="flex-grow p-6 bg-white">
          <div>
            <p className="flex justify-center text-xl font-semibold">
              Timesheet
            </p>
            <div className="flex py-2">
              <div className="flex justify-left items-center px-2">
                {currentWeekStart.format("MMM DD YYYY")} -{" "}
                {currentWeekEnd.format("MMM DD YYYY")}
                {/* <FaCalendarAlt className="ml-2" /> */}
              </div>
              <div className="flex ml-auto items-center">
                <FaArrowLeft
                  onClick={getPrevWeek}
                  className="mr-4 cursor-pointer"
                />
                <FaArrowRight
                  onClick={getNextWeek}
                  className="cursor-pointer"
                />
              </div>
            </div>
          </div>
          <WeekTable
            currentWeekStart={currentWeekStart}
            data={data}
            isAdmin={true}
          />
        </div>
      </div>
    </>
  );
}

import moment from "moment";
import React, {
  createElement,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { serverGET, serverPUT, serverDELETE } from "../../HttpFunctions";
import Spinner, { ButtonSpinner } from "src/components/Global/Spinner";
import { useNavigate, useParams } from "react-router";
import ReactTable from "react-table-6";
import { useAuth } from "src/context/Auth";
import { FaRegCopy, FaTrashAlt } from "react-icons/fa";
import { minutesToHHMM } from "src/utils/CommonFunctions";
import WeekViewDropdowns from "src/components/User/WeekViewDropdowns";
import { PrimaryButton, SecondaryButton } from "src/components/Global/Button";
import {
  APPROVED,
  DISAPPROVED,
  PENDING,
  SECONDS_IN_A_DAY,
} from "src/utils/Constants";
import { successNotfiy, errorNotfiy } from "../../components/Global/Toasts";
import Alert from "../../components/Global/Toasts";
import Chip from "../Global/Chip";
import { HeaderInfo } from "src/pages/Task/AddTimelogWeek";
import DeleteDialog from "../Global/DeleteDialog";
import TimeLogDetails, { BillableType } from "../Admin/TimeLogDetails";
import momentTimezone from "moment-timezone";

type WeekTableProps = {
  data: { tasks: Array<any>; count: number };
  currentWeekStart: moment.Moment;
  setSelectedTasks: React.Dispatch<
    React.SetStateAction<{
      tasks: any[];
      startDate: any;
    }>
  >;
  setTasksData: any;
  isAdmin?: boolean;
  setAddTasks?: React.Dispatch<React.SetStateAction<boolean>>;
  setHeaderInfo?: React.Dispatch<React.SetStateAction<HeaderInfo>>;
  handleAddTimesheetRow?: ({ isEmpty }: { isEmpty: boolean }) => void;
  refetch?: any;
  setShouldRefetch?: React.Dispatch<React.SetStateAction<boolean>>;
  loading: boolean;
};
export default function UserWeekView({
  data,
  currentWeekStart,
  setSelectedTasks,
  setTasksData,
  isAdmin,
  setAddTasks,
  setHeaderInfo,
  handleAddTimesheetRow,
  refetch,
  setShouldRefetch,
  loading,
}: WeekTableProps) {
  const { user } = useAuth();

  const { data: taskTypeData } = useQuery("taskTypes", () =>
    serverGET("/tasktype?isWeekView=1")
  );

  const { data: projectTypes } = useQuery("projects", () =>
    serverGET("/project?isWeekView=1")
  );

  const { data: clientData } = useQuery("clients", () =>
    serverGET("/project/clients?isWeekView=1")
  );

  const { data: serviceData } = useQuery("services", () =>
    serverGET("/service")
  );

  const { isLoading: updateLoading, mutate: updateMutate } = useMutation({
    mutationFn: (body: { user: string; tasks: any }) => {
      return serverPUT(`/task/editAllTaskDetails`, {
        task: body,
        deleteTasks: [],
      });
    },
    onError: (error, variables, context: any) => {
      errorNotfiy("Something went wrong while creating service");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating tasks");
        return;
      }
      successNotfiy("Tasks has been updated successfully");
      refetch();
    },
  });

  const { isLoading: rowLoading, mutate: updateStatusMutate } = useMutation({
    mutationFn: (body: { taskIds: string[]; status: number }) => {
      return serverPUT(`/task/editAll`, { ...body, billableHrsList: [] });
    },
    onError: (error, variables, context: any) => {
      errorNotfiy("Something went wrong while updating tasks");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating tasks");
        return;
      }
      successNotfiy("Tasks has been updated successfully");

      setIsApprovedLoading(false);
      setIsDeniedLoading(false);
      refetch();
    },
  });

  const { mutate: deleteMutation } = useMutation({
    mutationFn: (body: { taskIds: string[] }) => {
      return serverPUT(`/task/deletemultiple`, body);
    },
    onError: (error, variables, context: any) => {
      errorNotfiy("Something went wrong while deleting tasks");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while deleting tasks");
        return;
      }
      successNotfiy("Tasks has been deleted successfully");
      const temp = { ...data };
      temp.tasks.splice(delDialog?._index, 1);
      setTasksData(temp);
      setDelDialog("");
    },
  });
  const [projectOptions, setProjectOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [serviceOptions, setServiceOptions] = useState([]);
  const [delDialog, setDelDialog] = useState(null);
  const [dayTasks, setDayTasks] = useState(null);
  const [servicesAddedCheck, setServicesAddedCheck] = useState([]);
  const queryClient = useQueryClient();
  const [isApprovedLoading, setIsApprovedLoading] = useState(false);
  const [isDeniedLoading, setIsDeniedLoading] = useState(false);

  useEffect(() => {
    if (projectTypes) {
      const result = projectTypes?.projects?.map((p) => {
        return {
          value: p._id,
          label: p.project_name,
          client: p.client._id,
          is_active: p.is_active,
        };
      });
      setProjectOptions(result);
    }
    if (clientData) {
      const result = clientData?.clients?.map((c) => {
        return { value: c._id, label: c.name, is_active: c.is_active };
      });
      setClientOptions(result);
    }

    if (serviceData) {
      const result = serviceData?.services?.map((s) => {
        return {
          value: s._id,
          label: s.name,
          tasktype: s.tasktype,
          project: s.project,
          is_active: s.is_active,
        };
      });
      setServiceOptions(result);
    }
  }, [projectTypes, clientData, serviceData]);

  const getEmp = (task, number: number) => {
    const date = moment(currentWeekStart)
      .add(number, "days")
      .format("DD-MM-YYYY");
    let dayHours = 0;

    task.tasks.length > 0 &&
      task.tasks.map((t) => {
        return date == moment(t.start_time).tz(t?.timezone).format("DD-MM-YYYY")
          ? (dayHours = dayHours + t.duration_minutes)
          : "";
      });
    return convertMinutesToHours(dayHours);
  };
  console.log(currentWeekStart);

  function WeekName(number: number, format: string) {
    const date = moment(currentWeekStart);
    return date.add(number, "days").format(format);
    // return moment(
    //   currentWeekStart.valueOf() + SECONDS_IN_A_DAY * number
    // ).format(format);
  }

  function convertMinutesToHours(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return (
      hours +
      ":" +
      remainingMinutes.toLocaleString([], { minimumIntegerDigits: 2 })
    );
  }

  function calculateTaskStatus(row) {
    const pending = row._original.task.every((item) => item.status === PENDING);

    const approved = row._original.task.every(
      (item) => item.status === APPROVED
    );

    const denied = row._original.task.every(
      (item) => item.status === DISAPPROVED
    );

    if (pending) {
      return PENDING;
    }

    if (approved) {
      return APPROVED;
    }

    if (denied) {
      return DISAPPROVED;
    }
  }
  function calculateTotalPerDay(props, dayNumber: number) {
    let arraySum = 0;
    props.data.forEach((element) => {
      arraySum =
        arraySum +
        element._original.task.reduce((sum, element) => {
          if (
            moment(element.start_time)
              .tz(element?.timezone || "America/New_York")
              .format("MM-DD-YYYY (ddd)") ===
            WeekName(dayNumber, "MM-DD-YYYY (ddd)")
          ) {
            return sum + element.duration_minutes;
          }
          return sum;
        }, 0);
    });
    return minutesToHHMM(arraySum);
  }
  function calculateOverallTotal(props) {
    let arraySum = 0;
    props.data.forEach((element) => {
      arraySum =
        arraySum +
        element._original.task.reduce((sum, element) => {
          return sum + element.duration_minutes;
        }, 0);
    });
    return minutesToHHMM(arraySum);
  }

  function calculateTotalPerRow(row) {
    let sum = row._original.task.reduce((sum, element) => {
      return sum + element.duration_minutes;
    }, 0);
    return minutesToHHMM(sum);
  }

  const changeRowStatus = (row, status: number) => {
    const temp = { ...data };
    const selectedRow = temp.tasks[row?._index];
    if (selectedRow) {
      const taskIds = selectedRow.tasks.map((t) => t._id);
      if (taskIds.length > 0) {
        const body = {
          status,
          taskIds,
        };

        updateStatusMutate(body);
      }
    }
  };

  const changeAllStatus = (status: number) => {
    const temp = { ...data };
    const tasks = [...temp.tasks];
    let taskIds = [];
    for (let i = 0; i < tasks.length; i++) {
      let element = tasks[i];
      for (let j = 0; j < element.tasks.length; j++) {
        let t = element.tasks[j];
        taskIds.push(t._id);
      }
    }
    if (taskIds.length > 0) {
      const body = {
        status,
        taskIds,
      };

      updateStatusMutate(body);
    }
  };

  const deleteRow = (row) => {
    //popup to confrim
    setDelDialog(row);
  };
  const handleRowDelete = () => {
    console.log("dhsjdh", delDialog);
    if (delDialog?._index !== undefined) {
      const temp = { ...data };
      const selectedRow = temp.tasks[delDialog?._index];
      if (selectedRow) {
        console.log("inside", selectedRow);

        const taskIds = selectedRow.tasks.map((t) => t._id);
        if (taskIds.length > 0) {
          deleteMutation(taskIds);
        } else {
          temp.tasks.splice(delDialog?._index, 1);
          setTasksData(temp);
          setDelDialog("");
        }
      }
    }
  };

  const actionFunction = (row) => {
    const status = calculateTaskStatus(row);
    return (
      <div className="flex gap-4 relative">
        {isAdmin ? (
          <>
            <button onClick={() => changeRowStatus(row, 1)}>
              <Chip
                color={`${
                  status === APPROVED ? "bg-paleGreen-100" : ""
                } text-paleGreen-200`}
                title={APPROVED}
              />
            </button>
            <div className=" w-0.5 h-8 bg-gray-200"></div>
            <button
              className="cursor-pointer"
              onClick={() => changeRowStatus(row, 0)}
            >
              <Chip
                color={`${
                  status === DISAPPROVED ? "bg-customRed-100" : ""
                }  text-customRed-200`}
                title={DISAPPROVED}
              />
            </button>
          </>
        ) : (
          <>
            {" "}
            {/* <button
              onClick={() => {
                const temp = { ...data };
                const selectedRow = temp.tasks[row?._index];
                if (selectedRow) {
                  setTasksData((prev) => {
                    return {
                      count: prev.count + 1,
                      tasks: [...prev.tasks, selectedRow],
                    };
                  });
                }
              }}
            >
              <FaRegCopy />
            </button>
            <div className=" w-0.5 h-8 bg-gray-200"></div> */}
            <button
              className="cursor-pointer"
              onClick={() => {
                deleteRow(row);
              }}
            >
              <FaTrashAlt className="text-red-600" />
            </button>
          </>
        )}
      </div>
    );
  };

  const typeOptions = useMemo(
    () =>
      taskTypeData?.taskTypes?.map((x) => ({
        value: x._id,
        label: x.name,
        is_billable: x.is_billable,
        is_active: x.is_active,
      })),
    [taskTypeData]
  );

  const handleChange = (
    fullRow: any,
    selected: { value: string; label: string; is_billable?: boolean },
    label: string
  ) => {
    if (!selected) {
      if (label === "client") {
        return;
      }
      const newData = {
        count: data.count,
        tasks: data.tasks.map((task) => {
          return {
            ...task,
            tasks: [...task.tasks], // Make a deep copy of the nested tasks array
          };
        }),
      };

      const key = newData.tasks[fullRow.index].date.split("-");
      let index =
        label === "type"
          ? 0
          : label === "project"
          ? 1
          : label === "client"
          ? 2
          : 3;
      key[index] = "undefined";
      if (index === 1) {
        key[2] = "undefined";
      }
      if (index === 0 || index === 1) {
        key[3] = "undefined";
      }

      const newKey = key.join("-");

      // newData.tasks[fullRow.index].tasks = tasks;
      newData.tasks[fullRow.index].date = newKey;

      setTasksData(newData);
      return;
    }

    const row = { ...data.tasks[fullRow.index] };

    if (row.tasks.length > 0) {
      if (label === "client") {
        return;
      }
      let tasks = row.tasks?.map((task) => {
        let taskUpdate = {
          ...task,
        };

        if (label === "service") {
          taskUpdate[label] = selected.value;
        } else {
          taskUpdate[label] = selected
            ? label === "type"
              ? { name: selected.label, is_billable: selected.is_billable }
              : selected.value
            : null;
          taskUpdate.service = null;
        }

        return taskUpdate;
      });
      const task = {
        user: user._id,
        tasks,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      console.log("tasskkkkkk", task);
      updateMutate(task);
    } else {
      const newData = {
        count: data.count,
        tasks: data.tasks.map((task) => {
          return {
            ...task,
            tasks: [...task.tasks], // Make a deep copy of the nested tasks array
          };
        }),
      };

      const key = newData.tasks[fullRow.index].date.split("-");
      let index =
        label === "type"
          ? 0
          : label === "project"
          ? 1
          : label === "client"
          ? 2
          : 3;
      key[index] = label === "type" ? selected.label : selected.value;
      const newKey = key.join("-");

      // newData.tasks[fullRow.index].tasks = tasks;
      newData.tasks[fullRow.index].date = newKey;

      setTasksData(newData);
    }
  };

  const checkIfTaskExists = (fullRow, column) => {
    const { _original, _index } = fullRow;
    let tasksOnDate = [];

    let isServiceExist = [];
    if (_original.taskInfo.project && _original.taskInfo.type) {
      isServiceExist = serviceOptions.filter(
        (s) =>
          s.project === _original.taskInfo.project?.value &&
          s.tasktype === _original.taskInfo.type?.value
      );
    }
    if (isServiceExist.length > 0) {
      if (
        [
          _original.taskInfo.client,
          _original.taskInfo.project,
          _original.taskInfo.service,
          _original.taskInfo.type,
        ].includes("-")
      ) {
        errorNotfiy("Please fill mandatory fields first to open a task");
        return;
      }
    } else {
      if (
        [
          _original.taskInfo.client,
          _original.taskInfo.project,
          _original.taskInfo.type,
        ].includes("-")
      ) {
        errorNotfiy("Please fill mandatory fields first to open a task");
        return;
      }
    }

    for (let i = 0; i < _original.task.length; i++) {
      const task = _original.task[i];
      if (
        moment(task.start_time)
          .tz(task?.timezone || "America/New_York")
          .format("MM-DD-YYYY (ddd)") === column
      ) {
        tasksOnDate.push(task);
      }
    }

    if (tasksOnDate.length > 0) {
      setSelectedTasks({ tasks: tasksOnDate, startDate: column });
    } else {
      if (setAddTasks && setHeaderInfo) {
        setAddTasks(true);
        setHeaderInfo({
          client: {
            id: _original.taskInfo.client.value,
            value: _original.taskInfo.client.label,
          },
          project: {
            id: _original.taskInfo.project.value,
            value: _original.taskInfo.project.label,
          },
          service: {
            id: _original.taskInfo.service.value,
            value: _original.taskInfo.service.label,
          },
          taskDate: column,
          taskType: {
            id: _original.taskInfo.type.value,
            value: _original.taskInfo.type.label,
            is_billable: _original.taskInfo.type.is_billable,
          },
        });
      }
    }
  };

  const rowStatusCount = (row, weekNumber) => {
    let isApproved = false;
    let isDenied = false;
    let approvedCount = 0;
    let deniedCount = 0;
    let totalCountPerDay = 0;
    for (let i = 0; i < row.original.task.length; i++) {
      const task = row.original.task[i];
      if (
        moment(task.start_time)
          .tz(task?.timezone || "America/New_York")
          .format("MM-DD-YYYY (ddd)") ===
        WeekName(weekNumber, "MM-DD-YYYY (ddd)")
      ) {
        if (task.status === APPROVED) approvedCount = approvedCount + 1;
        if (task.status === DISAPPROVED) deniedCount = deniedCount + 1;

        totalCountPerDay = totalCountPerDay + 1;
      }
    }
    if (row.original.task.length !== 0 && totalCountPerDay !== 0) {
      if (approvedCount === totalCountPerDay) isApproved = true;
      if (deniedCount === totalCountPerDay) isDenied = true;
    }
    return (
      <div
        className={`overflow-hidden text-ellipsis ${
          isApproved
            ? "bg-paleGreen-100  rounded-lg"
            : isDenied
            ? "bg-customRed-100  rounded-lg"
            : isAdmin
            ? ""
            : " border border-customGrey-400 rounded-lg"
        } py-1 px-2 box-border`}
      >{`${row.value ? row.value : "0:00"}`}</div>
    );
  };

  const checkService = (index: number, toRemove: boolean) => {
    const temp = [...servicesAddedCheck];
    if (toRemove) {
      var i = temp.indexOf(index);
      if (i !== -1) {
        temp.splice(i, 1);
      }
    } else {
      temp.push(index);
    }
    setServicesAddedCheck([...new Set(temp)]);
  };

  const handleColumnClick = (props) => {
    if (!isAdmin) {
      return;
    }
    const { id } = props.column;
    let columnTasks = [];
    for (let i = 0; i < props.data.length; i++) {
      const row = props.data[i];
      for (let j = 0; j < row._original.task.length; j++) {
        const task = row._original.task[j];
        if (
          moment(task.start_time)
            .tz(task?.timezone || "America/New_York")
            .format("MM-DD-YYYY (ddd)") === id
        ) {
          columnTasks.push(task);
        }
      }
    }
    if (columnTasks.length > 0) {
      setDayTasks({
        ...dayTasks,
        date: id,
        tasks: columnTasks,
      });
    }
  };

  //put the formats in a variable
  const columns = [
    {
      getProps: () => {
        return {
          style: {
            padding: 10,
          },
        };
      },
      Header: "   ",
      accessor: "taskInfo",
      show: !isAdmin,
      minWidth: 630,
      Cell: (row) => {
        const isDisabled = row.original.task.some((o) => o.status === APPROVED);
        return (
          <WeekViewDropdowns
            typeOptions={typeOptions}
            projectOptions={projectOptions}
            clientOptions={clientOptions}
            serviceOptions={serviceOptions}
            value={row.value}
            isDisabled={isDisabled}
            handleChange={(selected, label) =>
              handleChange(row, selected, label)
            }
          />
        );
      },

      Footer: (props) => (
        <div
          className="cursor-pointer text-purple-600 py-2 font-medium"
          onClick={() => handleAddTimesheetRow({ isEmpty: false })}
        >
          {isAdmin ? "" : "Add timesheet row"}
        </div>
      ),
    },
    {
      Header: "Task Type",
      accessor: "taskInfo",
      show: isAdmin,
      Cell: (row) => {
        const label = !row.value.type.label ? "-" : row.value.type.label;
        return (
          <div className="overflow-hidden text-ellipsis">{`${label}`}</div>
        );
      },
    },
    {
      Header: "Client",
      accessor: "taskInfo",
      show: isAdmin,
      Cell: (row) => {
        const label = !row.value.client.label ? "-" : row.value.client.label;
        return (
          <div className="overflow-hidden text-ellipsis">{`${label}`}</div>
        );
      },
    },
    {
      Header: "Project",
      accessor: "taskInfo",
      show: isAdmin,
      Cell: (row) => {
        const label = !row.value.project.label ? "-" : row.value.project.label;
        return (
          <div className="overflow-hidden text-ellipsis">{`${label}`}</div>
        );
      },
    },
    {
      Header: "Service",
      accessor: "taskInfo",
      show: isAdmin,
      Cell: (row) => {
        const label = !row.value.service.label ? "-" : row.value.service.label;
        return (
          <div className="overflow-hidden text-ellipsis">{`${label}`}</div>
        );
      },
    },
    {
      Header: "id",
      accessor: "task.tasks._id",
      show: false,
    },
    {
      getProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      Header: `${WeekName(0, "DD ddd")}`,
      maxWidth: 80,
      accessor: `${WeekName(0, "MM-DD-YYYY (ddd)")}`,
      Cell: (row) => rowStatusCount(row, 0),
      Footer: (props) => (
        <div
          className="flex items-center h-full font-semibold"
          onClick={() => handleColumnClick(props)}
        >
          {calculateTotalPerDay(props, 0)}
        </div>
      ),
    },
    {
      getProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      Header: `${WeekName(1, "DD ddd")}`,
      maxWidth: 80,
      accessor: `${WeekName(1, "MM-DD-YYYY (ddd)")}`,
      Cell: (row) => rowStatusCount(row, 1),
      Footer: (props) => (
        <div
          className="flex items-center h-full font-semibold"
          onClick={() => handleColumnClick(props)}
        >
          {calculateTotalPerDay(props, 1)}
        </div>
      ),
    },
    {
      getProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      Header: `${WeekName(2, "DD ddd")}`,
      maxWidth: 80,
      accessor: `${WeekName(2, "MM-DD-YYYY (ddd)")}`,
      Cell: (row) => rowStatusCount(row, 2),
      Footer: (props) => (
        <div
          className="flex items-center h-full font-semibold"
          onClick={() => handleColumnClick(props)}
        >
          {calculateTotalPerDay(props, 2)}
        </div>
      ),
    },
    {
      getProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      Header: `${WeekName(3, "DD ddd")}`,
      maxWidth: 80,
      accessor: `${WeekName(3, "MM-DD-YYYY (ddd)")}`,
      Cell: (row) => rowStatusCount(row, 3),
      Footer: (props) => (
        <div
          className="flex items-center h-full font-semibold"
          onClick={() => handleColumnClick(props)}
        >
          {calculateTotalPerDay(props, 3)}
        </div>
      ),
    },
    {
      getProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      Header: `${WeekName(4, "DD ddd")}`,
      maxWidth: 80,
      accessor: `${WeekName(4, "MM-DD-YYYY (ddd)")}`,
      Cell: (row) => rowStatusCount(row, 4),

      Footer: (props) => (
        <div
          className="flex items-center h-full font-semibold"
          onClick={() => handleColumnClick(props)}
        >
          {calculateTotalPerDay(props, 4)}
        </div>
      ),
    },
    {
      getProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      Header: `${WeekName(5, "DD ddd")}`,
      maxWidth: 80,
      accessor: `${WeekName(5, "MM-DD-YYYY (ddd)")}`,
      Cell: (row) => rowStatusCount(row, 5),
      Footer: (props) => (
        <div
          className="flex items-center h-full font-semibold"
          onClick={() => handleColumnClick(props)}
        >
          {calculateTotalPerDay(props, 5)}
        </div>
      ),
    },
    {
      getProps: () => {
        return {
          style: {
            cursor: "pointer",
          },
        };
      },
      Header: `${WeekName(6, "DD ddd")}`,
      maxWidth: 80,
      accessor: `${WeekName(6, "MM-DD-YYYY (ddd)")}`,
      Cell: (row) => rowStatusCount(row, 6),
      Footer: (props) => (
        <div
          className="flex items-center h-full font-semibold"
          onClick={() => handleColumnClick(props)}
        >
          {calculateTotalPerDay(props, 6)}
        </div>
      ),
    },
    {
      Header: `Total`,
      maxWidth: 80,
      Cell: ({ row }) => (
        <div className="font-semibold">{calculateTotalPerRow(row)}</div>
      ),
      Footer: (props) => {
        return (
          <div className="flex items-center h-full font-semibold">
            {calculateOverallTotal(props)}
          </div>
        );
      },
    },
    {
      Header: "Actions",
      maxWidth: isAdmin ? 100 : 80,
      Cell: ({ row }) => (
        <div>
          {isAdmin && data?.tasks?.length === 0 ? null : actionFunction(row)}
        </div>
      ),
      show: isAdmin,
    },
  ];

  let tableData = useMemo(
    () =>
      data?.tasks?.map((task: any) => {
        const weekName1 = WeekName(0, "MM-DD-YYYY (ddd)");
        const weekName2 = WeekName(1, "MM-DD-YYYY (ddd)");
        const weekName3 = WeekName(2, "MM-DD-YYYY (ddd)");
        const weekName4 = WeekName(3, "MM-DD-YYYY (ddd)");
        const weekName5 = WeekName(4, "MM-DD-YYYY (ddd)");
        const weekName6 = WeekName(5, "MM-DD-YYYY (ddd)");
        const weekName7 = WeekName(6, "MM-DD-YYYY (ddd)");
        const project = projectOptions.find(
          (p) => p.value === task.date.split("-")[1]
        );

        const client = clientOptions.find(
          (p) => p.value === task.date.split("-")[2]
        );

        const service = serviceOptions.find(
          (p) => p.value === task.date.split("-")[3]
        );

        const type = typeOptions.find(
          (t) => t.label === task.date.split("-")[0]
        );

        return {
          task: task?.tasks,
          taskInfo: {
            type: type ? type : "-",
            project: project ? project : "-",
            client: client ? client : "-",
            service: service ? service : "-",
          },
          [weekName1]: getEmp(task, 0),
          [weekName2]: getEmp(task, 1),
          [weekName3]: getEmp(task, 2),
          [weekName4]: getEmp(task, 3),
          [weekName5]: getEmp(task, 4),
          [weekName6]: getEmp(task, 5),
          [weekName7]: getEmp(task, 6),
        };
      }),
    [data, projectOptions]
  );

  if (isAdmin && data?.tasks?.length === 0) {
    const weekName1 = WeekName(0, "ddd");
    const weekName2 = WeekName(1, "ddd");
    const weekName3 = WeekName(2, "ddd");
    const weekName4 = WeekName(3, "ddd");
    const weekName5 = WeekName(4, "ddd");
    const weekName6 = WeekName(5, "ddd");
    const weekName7 = WeekName(6, "ddd");

    tableData = [
      {
        task: [],
        taskInfo: {
          type: "-",
          project: "-",
          client: "-",
          service: "-",
        },
        [weekName1]: "00:00",
        [weekName2]: "00:00",
        [weekName3]: "00:00",
        [weekName4]: "00:00",
        [weekName5]: "00:00",
        [weekName6]: "00:00",
        [weekName7]: "00:00",
      },
    ];
  }

  const mutation = useMutation({
    mutationFn: (updatedTask: any) => {
      return serverPUT(`/task/${updatedTask._id}`, updatedTask);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      //showing message
      successNotfiy("Record has been updated successfully");
      refetch();
      setShouldRefetch(true);

      // closeClick();
    },
  });

  const approveAllMutation = useMutation({
    mutationFn: (approveAllTasks: {
      listView: boolean;
      statusIds: {}[];
      billableHrsList: BillableType[];
    }) => {
      return serverPUT("/task/editAll", approveAllTasks);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      //showing message
      refetch();
      successNotfiy("Record has been updated successfully");
      closeClick();
    },
  });

  const closeClick = () => {
    setDayTasks(null);
  };

  const handleStatus = (selTask, status) => {
    delete selTask?.billable_minutes;
    let updatedTask = {
      ...selTask,
      status: status,
    };

    let copy = Object.assign({}, dayTasks);
    let t = [...copy.tasks];
    let a = t.find((x) => selTask._id === x._id);
    a.status = status;
    copy.tasks = t;

    setDayTasks(copy);
    mutation.mutate(updatedTask);
  };

  const handleApproveAll = (
    statusIds: {}[],
    billableHrsList: BillableType[]
  ) => {
    let approveAllTasks = {
      listView: true,
      statusIds: statusIds,
      billableHrsList,
    };

    approveAllMutation.mutate(approveAllTasks);
  };

  const handleBillableHours = (selectedTask) => {
    mutation.mutate(selectedTask);
  };

  return (
    <div>
      <ReactTable
        className="rounded-lg"
        data={tableData}
        columns={columns}
        minRows={tableData?.length}
        resizable={true}
        showPagination={false}
        pageSize={tableData?.length}
        loading={updateLoading || loading}
        loadingText={<ButtonSpinner />}
        getTdProps={(state, rowInfo, column, instance) => ({
          onClick: () => {
            if (["Total", "Actions", "   "].includes(column.Header)) {
              return;
            }
            checkIfTaskExists(rowInfo.row, column.id);
          },
        })}
      />

      {isAdmin ? (
        <div className="flex justify-center items-center py-5">
          <SecondaryButton
            text="Deny all"
            onClick={() => {
              setIsDeniedLoading(true);
              changeAllStatus(0);
            }}
            isLoading={isDeniedLoading}
          />
          <span className="mx-3" />
          <PrimaryButton
            text="Approve all"
            onClick={() => {
              setIsApprovedLoading(true);

              changeAllStatus(1);
            }}
            isLoading={isApprovedLoading}
          />
        </div>
      ) : (
        <div className="flex justify-center items-center py-5">
          {/* <SecondaryButton
            text="Cancel"
            onClick={() => navigate("/user/timesheet")}
          />
          <span className="mx-3" />
          <PrimaryButton
            text="Submit"
            onClick={handleSubmit}
            // mutation={mutation}
          /> */}
        </div>
      )}

      {delDialog && (
        <DeleteDialog
          text="Are you sure you want to delete tasks of this row?"
          title="Delete tasks?"
          handleClose={() => setDelDialog("")}
          handleDelete={handleRowDelete}
        />
      )}

      {dayTasks && (
        <TimeLogDetails
          selectedTimeLog={dayTasks}
          closeClick={closeClick}
          handleStatus={handleStatus}
          handleApproveAll={handleApproveAll}
          handleBillableHours={handleBillableHours}
          refetch={refetch}
          weekView={false}
        />
      )}

      <Alert />
    </div>
  );
}

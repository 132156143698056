import moment from "moment";
import { createElement, useEffect, useRef, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { serverGET, serverPUT } from "../../HttpFunctions";
import ContentContainer from "../../components/Global/ContentContainer";
import PMApprovalTable, { PMTask } from "./PMApprovalTable";
import { APPROVED, DISAPPROVED } from "../../utils/Constants";
import TaskDetails from "../../components/Admin/TaskDetails";
import Dialogue from "../../components/Global/Dialogue";
import { getClients, getProjects, getTaskTypes } from "../../api";
import { PrimaryButton, SecondaryButton } from "../../components/Global/Button";
import Alert, { successNotfiy } from "../../components/Global/Toasts";
import Pagination from "../../components/Global/Pagination";
import FilteredChecks from "../../components/Global/FilteredChecks";
import { fetchData } from "src/utils/FetchFilteredData";
import { downloadExcel } from "src/utils/DownloadExcel";
import Spinner from "src/components/Global/Spinner";
import { useAuth } from "src/context/Auth";
import { AiOutlineCalendar } from "react-icons/ai";
import { HiOutlineViewList } from "react-icons/hi";
import EmployeesList from "./EmployeesList";
import { useSearchParams } from "react-router-dom";
import CardDetails from "../Dashboard/CardsDetails";

export default function Approval() {
  const [searchParams] = useSearchParams();
  const isWeekly = searchParams.get("weekly");
  const [selectedTask, setSelectedTask] = useState(null);
  const queryClient = useQueryClient();
  const [employeeOptions, setEmployeeOptions] = useState([]);
  const [projectOptions, setProjectOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const [weekView, setWeekView] = useState(() =>
    isWeekly === "true" ? true : false
  );
  const [optionsSelected, setOptionsSelected] = useState({
    status: [],
    type: [],
    employee: [],
    project: [],
    client: [],
    date: null,
    endDate: null,
    create_startDate: null,
    create_endDate: null,
  });
  const clientRef = useRef([]);
  const projectRef = useRef([]);

  const [page, setPage] = useState(0);
  const employeeName =
    `${selectedTask?.user.first_name} ${selectedTask?.user.last_name}`
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  const { user } = useAuth();

  const [isMobileScreen, setIsMobileScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const { data: projectData } = useQuery("projects", () =>
    serverGET(getProjects)
  );

  const { data: empData } = useQuery({
    queryKey: ["employees"],
    queryFn: () => serverGET("/user/emps"),
  });

  const { data: projectFilteredData } = useQuery({
    queryKey: ["user-projects"],
    queryFn: () => serverGET(`/project/userprojects`),
  });

  const { data: clientData } = useQuery("clients", () => serverGET(getClients));
  const { data: serviceData } = useQuery("services", () =>
    serverGET("/service")
  );

  const { data: taskTypeData } = useQuery("taskTypes", () =>
    serverGET(getTaskTypes)
  );
  const [pmApproval, setPMApproval] = useState(false);

  const onChangePMAproval = (e) => {
    setPage(0);
    setPMApproval(e.target.checked);
  };

  useEffect(() => {
    setTimeout(() => {
      taskRefetch();
    }, 100);
  }, [pmApproval]);

  const {
    isLoading,
    isError,
    data,
    isPreviousData,
    refetch: taskRefetch,
  }: {
    isLoading: any;
    isError: any;
    data: { tasks: PMTask[]; count: number };
    error: any;
    isPreviousData: any;
    refetch: any;
  } = useQuery({
    queryKey: ["pm-tasks", page, optionsSelected],
    keepPreviousData: true,
    queryFn: () =>
      fetchData(
        `/pm?page=${page}&user=${
          user.type_of_user.includes("admin") && pmApproval == false
            ? "admin"
            : user.type_of_user.includes("pm")
            ? "pm"
            : ""
        }`,
        optionsSelected
      ),
    enabled: !!user && !weekView,
  });

  const { data: commentData, refetch } = useQuery({
    queryKey: ["comments"],
    queryFn: () => serverGET(`/comment?taskId=${selectedTask?._id}`),
    enabled: !!selectedTask,
  });

  const mutation = useMutation({
    mutationFn: (updatedTask: any) => {
      return serverPUT(`/task/${updatedTask._id}`, updatedTask);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(
        ["pm-tasks", page, optionsSelected],
        (oldData: any) => {
          return {
            ...oldData,
            tasks: oldData?.tasks?.map((data) => {
              return data._id === updatedData.task._id
                ? {
                    ...data,
                    status: updatedData.task.status,
                    billable_minutes: updatedData.task.billable_minutes,
                  }
                : data;
            }),
          };
        }
      );
      //showing message
      successNotfiy("Record has been updated successfully");
    },
  });

  const billableMutation = useMutation({
    mutationFn: (updatedTask: any) => {
      return serverPUT(`/task/billablehours/${updatedTask._id}`, {
        billable_minutes: updatedTask?.billable_minutes,
      });
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(
        ["pm-tasks", page, optionsSelected],
        (oldData: any) => {
          return {
            ...oldData,
            tasks: oldData?.tasks?.map((data) => {
              return data._id === updatedData.task._id
                ? {
                    ...data,
                    billable_minutes: updatedData.task.billable_minutes,
                  }
                : data;
            }),
          };
        }
      );
      successNotfiy("Record has been updated successfully");
    },
  });

  useEffect(() => {
    let formattedProjects = [];
    let formattedClients = [];
    let renderClients = [];
    if (user && user.type_of_user.includes("admin")) {
      formattedProjects = projectData?.projects?.map((pro) => {
        return {
          value: pro?._id,
          label: pro?.project_name,
          client: pro?.client?._id,
        };
      });
      formattedClients = projectData?.projects?.map((pro) => {
        return {
          value: pro?.client?._id,
          label: pro?.client?.name,
          project: pro?._id,
        };
      });
    } else {
      formattedProjects = projectFilteredData?.distinctProjects?.map((pro) => {
        return {
          value: pro.project?._id,
          label: pro.project?.project_name,
          client: pro?.project?.client?._id,
        };
      });
      formattedClients = projectFilteredData?.distinctProjects?.map((pro) => {
        return {
          value: pro.project?.client?._id,
          label: pro.project?.client?.name,
          project: pro?.project?._id,
        };
      });
    }

    renderClients = [
      ...new Set(formattedClients?.map((obj) => obj?.value)),
    ].map((id) => {
      return formattedClients?.find((obj) => obj?.value === id);
    });

    setProjectOptions(formattedProjects);
    setClientOptions(renderClients);
    clientRef.current = formattedClients;
    projectRef.current = formattedProjects;

    if (empData?.employees?.length > 0) {
      setEmployeeOptions(empData?.employees);
    }
  }, [empData, projectFilteredData, user, projectData]);

  useEffect(() => {
    if (clientRef.current !== undefined) {
      const project = [...optionsSelected?.project];
      if (project.length > 0) {
        let filtered = clientRef.current.filter((item) =>
          project.includes(item.project.toString())
        );

        setClientOptions(filtered);
      } else {
        let renderClients = [
          ...new Set(clientRef?.current?.map((obj) => obj?.value)),
        ].map((id) => {
          return clientRef?.current?.find((obj) => obj?.value === id);
        });
        setClientOptions(renderClients);
      }
    }
  }, [optionsSelected?.project]);

  useEffect(() => {
    if (projectRef.current !== undefined) {
      const client = [...optionsSelected?.client];
      if (client.length > 0) {
        let filtered = projectRef.current.filter((item) =>
          client.includes(item.client.toString())
        );

        setProjectOptions(filtered);
      } else {
        setProjectOptions(projectRef.current);
      }
    }
  }, [optionsSelected?.client]);

  if (isLoading) {
    return <Spinner />;
  }

  if (isError) {
    return createElement("div", null, "Something went wrong");
  }
  const handleDownload = async () => {
    downloadExcel(
      `/task/downloadApproval?isApproval=true&user=${
        user.type_of_user.includes("admin")
          ? "admin"
          : user.type_of_user.includes("pm")
          ? "pm"
          : ""
      }`,
      optionsSelected
    );
  };

  const handleClick = (task) => {
    setSelectedTask(task);
  };

  const handleDisapprove = (selTask) => {
    delete selTask?.billable_minutes;
    let updatedTask = {
      ...selTask,
      status: DISAPPROVED,
    };
    let copy = Object.assign({}, selectedTask);
    copy.status = DISAPPROVED;
    copy.action_by = {
      ...copy.action_by,
      first_name: user?.first_name,
      last_name: user?.last_name,
    };

    setSelectedTask(copy);

    mutation.mutate(updatedTask);
  };

  const handleApprove = (selTask) => {
    delete selTask?.billable_minutes;
    let updatedTask = {
      ...selTask,
      status: APPROVED,
    };

    let copy = Object.assign({}, selectedTask);
    copy.status = APPROVED;
    copy.action_by = {
      ...copy.action_by,
      first_name: user?.first_name,
      last_name: user?.last_name,
    };

    setSelectedTask(copy);

    mutation.mutate(updatedTask);
  };

  const handleStatus = (selTask, status) => {
    delete selTask?.billable_minutes;
    let updatedTask = {
      ...selTask,
      status: status,
    };

    let copy = Object.assign({}, selectedTask);
    copy.status = status;
    copy.action_by = {
      ...copy.action_by,
      first_name: user?.first_name,
      last_name: user?.last_name,
    };

    setSelectedTask(copy);

    mutation.mutate(updatedTask);
  };

  const handleBillableHours = (selectedTask) => {
    billableMutation.mutate(selectedTask);
  };

  const handleViewChange = () => {
    setWeekView((prev) => !prev);
  };

  return (
    <ContentContainer>
      <div>
        <div className="flex justify-center text-xl font-semibold mb-5 items-center">
          <p>
            {user?.type_of_user.includes("admin")
              ? "Project Report"
              : "Approvals"}
          </p>

          {/* week view disbaled for project report page for now */}
          {!user?.type_of_user.includes("admin") ? (
            <button
              className="flex items-center ml-4 text-sm gap-2 text-customPurple-400 cursor-pointer hover:text-customPurple-900"
              onClick={() => handleViewChange()}
            >
              {!weekView ? (
                isMobileScreen ? (
                  <></>
                ) : (
                  <>
                    Switch to weekly view <AiOutlineCalendar />
                  </>
                )
              ) : (
                <>
                  Switch to list view <HiOutlineViewList />
                </>
              )}
            </button>
          ) : null}
        </div>
        {!weekView ? (
          <>
            <div>
              <CardDetails
                data={data}
                showCards={[
                  true,
                  true,
                  true,
                  user?.type_of_user.includes("pm") &&
                  user?.type_of_user.length == 1
                    ? false
                    : true,
                ]}
              />
              <FilteredChecks
                optionsSelected={optionsSelected}
                setOptionsSelected={setOptionsSelected}
                employeeOptions={employeeOptions}
                projectOptions={projectOptions}
                clientOptions={clientOptions}
                setPage={setPage}
                isAdmin={false}
                handleDownload={handleDownload}
                taskLength={data?.tasks?.length}
              />
            </div>
                 <PMApprovalTable
                 empTaskData={data?.tasks}
                 handleClick={handleClick}
                 refetch={taskRefetch}
                 pmApproval={pmApproval}
                 onChangePMAproval={onChangePMAproval}
                 isLoading ={isLoading}
               />
  
            {data && data?.tasks?.length > 0 ? (
              <>
                <Pagination
                  page={page}
                  data={data}
                  setPage={setPage}
                  isPreviousData={isPreviousData}
                />
              </>
            ) : (
              <h1 className="text-lg text-center w-full mt-4">
                {"Tasks not found"}
              </h1>
            )}
          </>
        ) : (
          <EmployeesList employees={employeeOptions} />
        )}

        {!!selectedTask && (
          <Dialogue
            title={`Task details for ${employeeName} for (${moment(
              selectedTask.start_time
            ).format("MM/DD/YY")})`}
            description=""
            handleClose={() => setSelectedTask(null)}
          >
            <div className="md:px-12 p-5">
              <TaskDetails
                clientData={clientData}
                projectData={projectData}
                taskTypeData={taskTypeData}
                serviceData={serviceData}
                refetch={taskRefetch}
                handleBillableHours={handleBillableHours}
                handleStatus={handleStatus}
                tl={selectedTask}
                page={page}
                optionsSelected={optionsSelected}
                setSelectedTask={setSelectedTask}
                comments={
                  selectedTask !== null
                    ? commentData?.comments[selectedTask?._id]
                    : []
                }
                commentRefetch={refetch}
                isPm={true}
              />
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-6 bg-customGrey-100 rounded-b gap-5">
              <SecondaryButton
                text={"Cancel"}
                onClick={() => {
                  setSelectedTask(null);
                }}
              />
            </div>
          </Dialogue>
        )}
        <Alert />
      </div>
    </ContentContainer>
  );
}

import React, { useEffect } from "react";
import Dialogue from "../Global/Dialogue";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { serverPUT } from "../../HttpFunctions";
import { successNotfiy } from "../Global/Toasts";
import Input, { PasswordInput } from "../Global/Input";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Select from "../Global/Select";
import { ACTIVE, ARCHIVED, PM, USER } from "src/utils/Constants";
import { Employee } from "src/pages/User/EmployeeList";
import Checkbox from "../Global/Checkbox";

export const schema = yup
  .object({
    password: yup.string().required().min(8),
    cpassword: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref("password")], "Passwords do not match"),
    emailChecked: yup.boolean().required(),
  })
  .required();

type changePasswordProps = {
  passModal: Employee;
  setPassModal: React.Dispatch<React.SetStateAction<Employee>>;
};

export default function ChangePassword({
  passModal,
  setPassModal,
}: changePasswordProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      cpassword: "",
      emailChecked: true,
    },
  });

  const mutation = useMutation({
    mutationFn: (data: any) => {
      return serverPUT(`/user/updatePassword`, data);
    },
    onError: (context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData) => {
      if (updatedData && updatedData.constructor === String) {
        successNotfiy("Employee information successfully updated");
      }
      handleClose();
    },
  });

  const onSubmit = (data) => {
    let user = {
      new_password: data.password,
      emailChecked: data.emailChecked,
      userId: passModal._id,
    };

    mutation.mutate(user);
  };

  const handleClose = () => {
    setPassModal({} as Employee);
  };

  return (
    <Dialogue title="Change Password" handleClose={handleClose} central={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex p-4 gap-4">
          <div className="my-2">
            <PasswordInput
              label={"Password 8+"}
              placeholder="********"
              type={"password"}
              name="password"
              regProp={{ ...register("password") }}
              error={errors.password?.message}
            />
          </div>
          <div className="my-2">
            <PasswordInput
              label={"Confirm Password"}
              placeholder="********"
              type={"password"}
              name="cpassword"
              regProp={{ ...register("cpassword") }}
              error={errors.cpassword?.message}
            />
          </div>
        </div>
        <div className="px-4 py-2">
          <Checkbox
            label={"Send an email to user"}
            name="emailchecked"
            regProp={{ ...register("emailChecked") }}
            error={errors.emailChecked?.message}
          />
        </div>
        <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <PrimaryButton text="Save Changes" />
        </div>
      </form>
    </Dialogue>
  );
}

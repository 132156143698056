import React, { useMemo } from "react";

export default function Avatar({
  initials,
  color,
  size,
}: {
  initials: string;
  color: string;
  size: string;
}) {
  // Assuming the initials are passed as a prop
  const randomColor = useMemo(() => {
    const colors = [
      "bg-blue-500",
      "bg-red-500",
      "bg-green-500",
      "bg-yellow-500",
      "bg-pink-500",
      "bg-purple-500",
      "bg-indigo-500",
      "bg-teal-500",
    ];
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex];
  }, []);

  return (
    // ${randomColor}
    <div
      className={`w-${size} h-${size} flex items-center justify-center rounded-full bg-customPurple-400 
       `}
    >
      <span className="text-sm font-semibold capitalize">{initials}</span>
    </div>
  );
}

import moment from "moment/moment";
import React, { useRef } from "react";
import { useNavigate } from "react-router";
import ReactTable from "react-table-6";
import { ACTIVE, APPROVED, ARCHIVED, DISAPPROVED } from "src/utils/Constants";
import Chip from "../Global/Chip";

type WeekTableProps = {
  data: any[];
  currentWeekStart: moment.Moment;
  isAdmin?: boolean;
};

export default function WeekTable({
  data,
  currentWeekStart,
  isAdmin,
}: WeekTableProps) {
  const navigate = useNavigate();
  const secondsInDay = 86400000;

  const getEmp = (emp, number: number) => {
    return (
      emp.tasks.length > 0 &&
      emp.tasks[0].map((task) => {
        return task.date ===
          moment(currentWeekStart).add(number, "days").format("DD-MM-YYYY")
          ? convertMinutesToHours(task.total_hrs)
          : "";
      })
    );
  };

  function WeekName(number: number, format: string) {
    const date = moment(currentWeekStart);
    return date.add(number, "days").format(format);
  }

  function convertMinutesToHours(minutes) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return (
      hours +
      ":" +
      remainingMinutes.toLocaleString([], { minimumIntegerDigits: 2 })
    );
  }

  //put the formats in a variable
  const columns = [
    {
      Header: "Employee Name",
      accessor: "empName",
      minWidth: 120,
    },
    {
      Header: "Status",
      accessor: "status",
      minWidth: 70,
      Cell: (row) => (
        <div>
          <div className="py-2">
            <Chip
              color={
                row.original?.status
                  ? "bg-paleGreen-100 text-paleGreen-200"
                  : "bg-customRed-100 text-customRed-200"
              }
              title={row.original?.status ? APPROVED : DISAPPROVED}
            />
          </div>
        </div>
      ),
    },
    {
      Header: `${WeekName(0, "DD ddd")}`,
      accessor: `${WeekName(0, "ddd")}`,
      minWidth: 70,
    },
    {
      Header: `${WeekName(1, "DD ddd")}`,
      accessor: `${WeekName(1, "ddd")}`,
      minWidth: 70,
    },
    {
      Header: `${WeekName(2, "DD ddd")}`,
      accessor: `${WeekName(2, "ddd")}`,
      minWidth: 70,
    },
    {
      Header: `${WeekName(3, "DD ddd")}`,
      accessor: `${WeekName(3, "ddd")}`,
      minWidth: 70,
    },
    {
      Header: `${WeekName(4, "DD ddd")}`,
      accessor: `${WeekName(4, "ddd")}`,
      minWidth: 70,
    },
    {
      Header: `${WeekName(5, "DD ddd")}`,
      accessor: `${WeekName(5, "ddd")}`,
      minWidth: 70,
    },
    {
      Header: `${WeekName(6, "DD ddd")}`,
      accessor: `${WeekName(6, "ddd")}`,
      minWidth: 70,
    },
    {
      // Header: "Total weekly hours",
      Header: () => (
        <div style={{ textAlign: "center" }}>
          <span>Total</span>
          <br />
          <span>weekly hrs</span>
        </div>
      ),
      accessor: "totalHrs",
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>
          <span>Weekly</span>
          <br />
          <span>billable hrs</span>
        </div>
      ),
      // Header: "Weekly billable hours",
      accessor: "billableHrs",
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>
          <span>Weekly</span>
          <br />
          <span>pending hrs</span>
        </div>
      ),
      accessor: "pendingHrs",
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>
          <span>Weekly</span>
          <br />
          <span>accepted hrs</span>
        </div>
      ),
      accessor: "acceptedHrs",
    },
    {
      Header: () => (
        <div style={{ textAlign: "center" }}>
          <span>Weekly</span>
          <br />
          <span>denied hrs</span>
        </div>
      ),
      accessor: "deniedHrs",
    },
  ];

  const tableData = data?.map((emp: any) => {
    const weekName1 = WeekName(0, "ddd");
    const weekName2 = WeekName(1, "ddd");
    const weekName3 = WeekName(2, "ddd");
    const weekName4 = WeekName(3, "ddd");
    const weekName5 = WeekName(4, "ddd");
    const weekName6 = WeekName(5, "ddd");
    const weekName7 = WeekName(6, "ddd");

    return {
      empId: emp._id,
      empName: `${emp.first_name} ${emp.last_name}`.replace(/\b\w/g, (match) =>
        match.toUpperCase()
      ),
      status: emp.is_active,
      //Gives us the current day
      [weekName1]:
        emp.tasks.length > 0 &&
        emp.tasks[0].map((task) => {
          return task.date === moment(currentWeekStart).format("DD-MM-YYYY")
            ? (task.total_hrs / 60).toFixed(2)
            : "";
        }),

      [weekName2]: getEmp(emp, 1),
      [weekName3]: getEmp(emp, 2),
      [weekName4]: getEmp(emp, 3),
      [weekName5]: getEmp(emp, 4),
      [weekName6]: getEmp(emp, 5),
      [weekName7]: getEmp(emp, 6),
      totalHrs: convertMinutesToHours(emp.total_hrs),
      billableHrs: convertMinutesToHours(emp.bill_hrs),
      deniedHrs: convertMinutesToHours(emp.denied_hrs),
      pendingHrs: convertMinutesToHours(emp.pending_hrs),
      acceptedHrs: convertMinutesToHours(emp.accepted_hrs),
    };
  });

  return (
    <div className="overflow-x-hidden w-auto">
      <ReactTable
        className="rounded-lg"
        data={tableData}
        columns={columns}
        minRows={tableData.length}
        resizable={true}
        showPagination={false}
        pageSize={tableData.length}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: () => {
                if (isAdmin) {
                  const id = rowInfo.row._original.empId;
                  navigate(
                    // `/dashboard/user?userId=${id}&name=${rowInfo.row._original.empName}`
                    `/dashboard/user/${id}`
                  );
                } else {
                  const id = rowInfo.row._original.empId;
                  navigate(
                    `/user/approval/detail?userId=${id}&name=${rowInfo.row._original.empName}`
                  );
                }
              },
            };
          } else {
            return {};
          }
        }}
      />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context/Auth";
import { FiClock } from "react-icons/fi";
import { BsClipboardCheck } from "react-icons/bs";
import { MdLogout } from "react-icons/md";
import { RxLayout, RxAvatar } from "react-icons/rx";
import { RiLayoutMasonryFill, RiTeamLine } from "react-icons/ri";
import { TfiLayers } from "react-icons/tfi";
import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";
import { HiOutlineClipboardList } from "react-icons/hi";
import { IoList } from "react-icons/io5";
import { BiCog } from "react-icons/bi";
import { TbCurrencyDollar } from "react-icons/tb";

export default function Sidebar({
  children,
  handleClick,
  className,
}: {
  children: any;
  handleClick: () => void;
  className: string;
}) {
  const location = useLocation();
  const { user, logout } = useAuth();
  const [type] = useState(() => user?.type_of_user);
  const [selectedTab, setSelectedTab] = useState(() => {
    return location.pathname;
  });
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [expanded, setExpanded] = useState(isMobileScreen ? true : false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
      setExpanded(window.innerWidth < 768 ? true : false);
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleSidebar = () => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
    if (isMobileScreen) handleClick();
  };
  return (
    <div className="flex">
      {["/user/addtask", "/user/edittimelog"].includes(
        location.pathname
      ) ? null : (
        <div
          className={`${className} flex flex-col md:block py-3 bg-white shadow rounded-xl z-[60] transition-all duration-500 overflow-y-scroll overflow-x-hidden  ${
            isMobileScreen ? "" : "sticky"
          } top-8 ${expanded ? "w-64 px-5" : "w-16 px-2"}`}
          style={{ height: "calc(100vh - 50px)" }}
        >
          {isMobileScreen ? null : (
            <div>
              <button
                onClick={toggleSidebar}
                className=" text-black p-4 w-16 rounded-tl-md rounded-bl-md transition-all duration-500"
              >
                {expanded ? <AiOutlineArrowLeft /> : <AiOutlineArrowRight />}
              </button>
            </div>
          )}
          <div className="space-y-3">
            <div>
              <ul className="text-sm">
                {type.includes("admin") ? (
                  <div className="flex gap-y-2 flex-col">
                    <SidebarRow
                      RowIcon={<BsClipboardCheck />}
                      text="Projects Report"
                      selectedTab={selectedTab}
                      redirectTo="/user/approval"
                      handleTabClick={handleTabClick}
                      expanded={expanded}
                    />

                    <SidebarRow
                      RowIcon={<RiLayoutMasonryFill />}
                      text={
                        type.includes("admin")
                          ? "Team’s Timesheet"
                          : "Timesheet"
                      }
                      selectedTab={selectedTab}
                      redirectTo="/dashboard"
                      expanded={expanded}
                      handleTabClick={handleTabClick}
                    />
                    {(type.includes("user") || type.includes("pm")) && (
                      <>
                        <SidebarRow
                          RowIcon={<RxLayout />}
                          text="Personal Timesheet"
                          selectedTab={selectedTab}
                          redirectTo="/user/timesheet"
                          handleTabClick={handleTabClick}
                          expanded={expanded}
                        />
                        {/* <SidebarRow
                          RowIcon={<FiClock />}
                          text="TimeOff"
                          selectedTab={selectedTab}
                          redirectTo="/user/timeoff"
                          handleTabClick={handleTabClick}
                          expanded={expanded}
                        /> */}
                      </>
                    )}

                    {/* <SidebarRow
                      RowIcon={<FiClock />}
                      text={type.includes("admin") ? "Admin TimeOff" : "TimeOff"}
                      selectedTab={selectedTab}
                      redirectTo="/dashboard/timeoff"
                      expanded={expanded}
                      handleTabClick={handleTabClick}
                    /> */}
                    <SidebarRow
                      RowIcon={<IoList />}
                      text="Task Types"
                      selectedTab={selectedTab}
                      expanded={expanded}
                      redirectTo="/dashboard/taskType"
                      handleTabClick={handleTabClick}
                    />
                    <SidebarRow
                      RowIcon={<TfiLayers />}
                      text="Projects"
                      selectedTab={selectedTab}
                      expanded={expanded}
                      redirectTo="/dashboard/project"
                      handleTabClick={handleTabClick}
                    />
                    <SidebarRow
                      RowIcon={<RxAvatar />}
                      text="Clients"
                      selectedTab={selectedTab}
                      expanded={expanded}
                      redirectTo="/dashboard/client"
                      handleTabClick={handleTabClick}
                    />
                    <SidebarRow
                      RowIcon={<HiOutlineClipboardList />}
                      text="Services"
                      selectedTab={selectedTab}
                      expanded={expanded}
                      redirectTo="/dashboard/service"
                      handleTabClick={handleTabClick}
                    />
                    <SidebarRow
                      RowIcon={<RiTeamLine />}
                      text="Team"
                      selectedTab={selectedTab}
                      expanded={expanded}
                      redirectTo="/dashboard/employees"
                      handleTabClick={handleTabClick}
                    />
                    <SidebarRow
                      RowIcon={<TbCurrencyDollar />}
                      text="Payroll"
                      selectedTab={selectedTab}
                      expanded={expanded}
                      redirectTo="/dashboard/payroll"
                      handleTabClick={handleTabClick}
                    />

                    <SidebarRow
                      RowIcon={<BiCog />}
                      text="Settings"
                      selectedTab={selectedTab}
                      redirectTo="/settings"
                      handleTabClick={handleTabClick}
                      expanded={expanded}
                    />
                    <li
                      onClick={logout}
                      className={` ${
                        expanded ? "py-2 px-1" : " flex justify-center p-3"
                      }`}
                    >
                      <Link
                        to={"/login"}
                        className="flex items-center space-x-2 rounded-md font-medium text-customGrey-500"
                      >
                        <MdLogout className="scale-x-[-1] font-extrabold text-xl" />
                        {expanded && <p className="ml-2 text-sm">Logout </p>}
                      </Link>
                    </li>
                  </div>
                ) : type.includes("user") ? (
                  <div className="flex gap-y-2 flex-col">
                    <SidebarRow
                      RowIcon={<RxLayout />}
                      text="Timesheet"
                      selectedTab={selectedTab}
                      redirectTo="/user/timesheet"
                      handleTabClick={handleTabClick}
                      expanded={expanded}
                    />
                    {/* <SidebarRow
                      RowIcon={<FiClock />}
                      text="TimeOff"
                      selectedTab={selectedTab}
                      redirectTo="/user/timeoff"
                      handleTabClick={handleTabClick}
                      expanded={expanded}
                    /> */}
                    <SidebarRow
                      RowIcon={<BiCog />}
                      text="Settings"
                      selectedTab={selectedTab}
                      redirectTo="/settings"
                      handleTabClick={handleTabClick}
                      expanded={expanded}
                    />
                    <li
                      onClick={logout}
                      className={` ${
                        expanded ? "py-2 px-1" : " flex justify-center p-3"
                      }`}
                    >
                      <Link
                        to={"/login"}
                        className="flex items-center space-x-3 rounded-md font-medium text-customGrey-500"
                      >
                        <MdLogout className="scale-x-[-1] font-extrabold text-xl" />
                        {expanded && <p className="ml-2 text-sm">Logout </p>}
                      </Link>
                    </li>
                  </div>
                ) : (
                  <div className="flex gap-y-2 flex-col">
                    <SidebarRow
                      RowIcon={<BsClipboardCheck />}
                      text="Approvals"
                      expanded={expanded}
                      selectedTab={selectedTab}
                      redirectTo="/user/approval"
                      handleTabClick={handleTabClick}
                    />

                    <SidebarRow
                      RowIcon={<RxLayout />}
                      text="Timesheet"
                      expanded={expanded}
                      selectedTab={selectedTab}
                      redirectTo="/user/timesheet"
                      handleTabClick={handleTabClick}
                    />
                    {/* <SidebarRow
                      RowIcon={<FiClock />}
                      text="TimeOff"
                      expanded={expanded}
                      selectedTab={selectedTab}
                      redirectTo="/user/timeoff"
                      handleTabClick={handleTabClick}
                    /> */}
                    <SidebarRow
                      RowIcon={<TfiLayers />}
                      text="Projects"
                      expanded={expanded}
                      selectedTab={selectedTab}
                      redirectTo="/pm/projects"
                      handleTabClick={handleTabClick}
                    />
                    <SidebarRow
                      RowIcon={<BiCog />}
                      text="Settings"
                      selectedTab={selectedTab}
                      redirectTo="/settings"
                      handleTabClick={handleTabClick}
                      expanded={expanded}
                    />
                    <li
                      onClick={logout}
                      className={` ${
                        expanded ? "py-2 px-1" : " flex justify-center p-3"
                      }`}
                    >
                      <Link
                        to={"/login"}
                        className="flex items-center space-x-3 rounded-md font-medium text-customGrey-500"
                      >
                        <MdLogout className="scale-x-[-1] font-extrabold text-xl" />
                        {expanded && <p className="ml-2 text-sm">Logout </p>}
                      </Link>
                    </li>
                  </div>
                )}
              </ul>
            </div>
          </div>
        </div>
      )}
      {children}
    </div>
  );
}

function SidebarRow({
  RowIcon,
  text,
  selectedTab,
  redirectTo,
  handleTabClick,
  expanded,
}: {
  RowIcon: any;
  text: string;
  selectedTab: string;
  redirectTo: string;
  handleTabClick: (redirectTo: string) => void;
  expanded: boolean;
}) {
  const navigate = useNavigate();
  return (
    <div
      onClick={() => navigate(redirectTo)}
      className={`cursor-pointer ${expanded ? "w-40" : ""}`}
    >
      <li
        className={`${
          selectedTab === redirectTo
            ? "bg-customPurple-200 rounded-xl"
            : "ml-0.5"
        } h-11 ${
          expanded ? "flex justify-start pl-1" : " flex justify-center"
        }`}
        onClick={() => handleTabClick(redirectTo)}
      >
        <div
          className={`${
            selectedTab === redirectTo
              ? "text-customPurple-400 "
              : "text-customGrey-500"
          } flex items-center space-x-3 rounded-md font-medium`}
        >
          <div className="font-extrabold text-xl">{RowIcon}</div>
          {expanded && <p className="ml-2 text-sm">{text} </p>}
        </div>
      </li>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getProjects } from "../../api";
import { serverGET, serverPUT } from "../../HttpFunctions";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useAuth } from "../../context/Auth";
import "react-toastify/dist/ReactToastify.css";
import { APPROVED, DISAPPROVED, PENDING, REVISED } from "../../utils/Constants";
import {
  PrimaryButton,
  SecondaryButton,
  SecondaryIconButton,
} from "../../components/Global/Button";
import { useNavigate } from "react-router";
import moment from "moment";
import { successNotfiy, errorNotfiy } from "../../components/Global/Toasts";
import EditTaskList from "./EditTaskList";
import BreadCrumb from "../../components/Global/BreadCrumb";
import { FaPlus } from "react-icons/fa";
import ContentContainer from "../../components/Global/ContentContainer";
import PopupHeader from "src/components/Global/PopupHeader";
import { deleteFromBucket, uploadToBucket } from "src/utils/CommonFunctions";
import PayrollModal from "../../components/User/PayrollModal";
type projectType = {
  project_name: "";
  client: { _id: ""; name: "" };
  _id: "";
};

type taskType = {
  name: "";
  is_billable?: boolean | null;
};
export type TimeLogTask = {
  taskType: taskType;
  projectDetail?: projectType;
  hours: number;
  minutes: number;
  description: string;
  status: string;
};

const schema = yup
  .object({
    // mode: yup.string().required(),
    // location: yup.string().required(),
    tasks: yup.array().of(
      yup.object().shape({
        // projectDetail: yup.object(),
        startDate: yup
          .date()
          .nullable()
          .required("Start date is required")
          .typeError("Start date is required"),

        projectDetail: yup
          .object({
            project_name: yup.string().required("Project is required"),
            client: yup.object({
              _id: yup.string(),
              // .required(),
              name: yup.string(),
              // .required(),
            }),
            _id: yup.string().required(),
          })
          .typeError("")
          .required("Project and client is required"),
        taskType: yup
          .object({
            name: yup.string().required("Type is required"),
            is_billable: yup.mixed().nullable().oneOf([true, false, null]),
          })
          .required(),
        description: yup.string(),

        service: yup.object({
          name: yup.string(),
          _id: yup.string(),
        }),
        startTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(9, 0, 0, 0);
          return date;
        }),
        endTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(17, 0, 0, 0);
          return date;
        }),
        status: yup.string().required(),
        serviceAddedCheck: yup.boolean(),
        attachments: yup.array(),
      })
    ),
  })
  .required();

const weekSchema = yup
  .object({
    // mode: yup.string().required(),
    // location: yup.string().required(),
    tasks: yup.array().of(
      yup.object().shape({
        // projectDetail: yup.object(),
        projectDetail: yup
          .object({
            project_name: yup.string(),
            client: yup
              .object({
                _id: yup.string(),
                name: yup.string(),
              })
              .typeError(""),
            _id: yup.string().required(),
          })
          .typeError(""),
        taskType: yup.object({
          name: yup.string(),
          is_billable: yup.mixed().nullable().oneOf([true, false, null]),
        }),

        service: yup.object({
          name: yup.string(),
          _id: yup.string(),
        }),
        startDate: yup
          .date()
          .nullable()
          .required("Start date is required")
          .typeError("Start date is required"),
        description: yup.string(),

        startTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(9, 0, 0, 0);
          return date;
        }),
        endTime: yup.date().default(() => {
          const date = new Date();
          date.setHours(17, 0, 0, 0);
          return date;
        }),
        status: yup.string().required(),
        attachments: yup.array(),
      })
    ),
  })
  .required();

type EditTaskProps = {
  editTask?: any;
  isWeekView?: boolean;
  handleClose?: () => void;
  weeklyRefetch?: any;
};
export default function EditTimeLog({
  editTask,
  isWeekView,
  handleClose,
  weeklyRefetch,
}: EditTaskProps) {
  const { user } = useAuth();
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(isWeekView ? weekSchema : schema),
    defaultValues: isWeekView
      ? {
          tasks: [
            {
              _id: "",
              startDate: new Date(),
              description: "",
              status: PENDING,
              startTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                9,
                0,
                0,
                0
              ),
              endTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                17,
                0,
                0,
                0
              ),
              projectDetail: {
                project_name: "",
                client: { _id: "", name: "" },
                _id: "",
              },
              taskType: {
                name: "",
                is_billable: null,
              },
              service: {
                name: "",
                _id: "",
              },
              serviceAddedCheck: false,
              attachments: [],
            },
          ],
        }
      : {
          // mode: "",
          // location: "",
          tasks: [
            {
              _id: "",
              startDate: new Date(),
              projectDetail: {
                project_name: "",
                client: { _id: "", name: "" },
                _id: "",
              },
              taskType: {
                name: "",
                is_billable: null,
              },
              description: "",
              service: {
                name: "",
                _id: "",
              },
              // hours: 1,
              // minutes: 0,
              status: PENDING,
              startTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                9,
                0,
                0,
                0
              ),
              endTime: new Date(
                new Date().getFullYear(),
                new Date().getMonth(),
                new Date().getDate(),
                17,
                0,
                0,
                0
              ),
              serviceAddedCheck: false,
              attachments: [],
            },
          ],
        },
  });

  // useEffect(() => {
  //   setTaskDate(
  //     `${moment(editTask?.date?.split("-").reverse().join("-")).format(
  //       "MM/DD/YYYY"
  //     )}`
  //   );
  // }, []);

  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment(editTask.tasks[0]?.start_time).toDate()
  );

  const [startTime, setStartTime] = useState(() => {
    const taskListLength = editTask.tasks.length - 1;
    const date = new Date(editTask.tasks[taskListLength]?.start_time);
    return date;
  });
  const [endTime, setEndTime] = useState(() => {
    const date = new Date(editTask.tasks[0]?.end_time);
    return date;
  });
  const [customError, setCustomError] = useState("");
  const queryClient = useQueryClient();
  const [totalWorkingHours, setTotalWorkingHours] = useState("");
  const [slotErrors, setSlotErrors] = useState([]);

  const [deletedAttachments, setDeletedAttachments] = useState([]);

  const minStartTime = moment().hours(0).minutes(0); // set minimum time to 9:00 AM
  let maxStartTime = moment()
    .hours(endTime.getHours())
    .minutes(endTime.getMinutes());
  const maxTime = moment().hours(23).minutes(59); // set maximum time to 5:00 PM
  const [deniedTasks, setDeniedTasks] = useState([]);

  let minEndTime = moment()
    .hours(startTime.getHours())
    .minutes(startTime.getMinutes());

  const { isLoading, isError, data, error } = useQuery("projects", () =>
    serverGET("/project?is_active=true")
  );
  const [flag, setFlag] = useState(false);
  const [deleteTasks, setDeleteTasks] = useState([]);
  const [showError, setShowError] = useState(false);
  const [overlaps, setOverlaps] = useState([]);
  const [onSubmitClicked, setOnSubmitClicked] = useState(false);
  const [taskIds, setTaskIds] = useState("");
  const [payrollError, setPayrollError] = useState(null);
  useEffect(() => {
    // setTaskDate(
    //   `${moment(editTask?.date?.split("-").reverse().join("-")).format(
    //     "MM/DD/YYYY"
    //   )}`
    // );
    const timeout = setTimeout(() => {
      let taskIds = watch("tasks").map((x) => {
        if (!!x._id) {
          return `taskId=${x._id}`;
        }
        return;
      });

      taskIds = taskIds.filter((element) => element !== undefined);
      setTaskIds(taskIds.join("&"));
    }, 200);
    return () => {
      clearTimeout(timeout); // Clear the timeout when the component unmounts
    };
  }, [watch("tasks")]);

  const { data: commentData, refetch: commentRefetch } = useQuery({
    queryKey: ["comments"],
    queryFn: () => serverGET(`/comment?${taskIds}`),
    enabled: !!taskIds,
  });

  useEffect(() => {
    if (editTask) {
      let rejectedTasks = [];
      editTask.tasks.forEach((task, index) => {
        console.log("use effect status", task?.status);
        let obj = {
          _id: task?._id,
          startDate: new Date(task?.start_time),
          // hours: Math.floor(task.duration_minutes / 60),
          taskType: {
            name: task?.type.name,
            is_billable: task?.type?.is_billable,
          },
          description: task?.description,
          service: {
            name: task?.service?.name ?? "",
            _id: task?.service?._id ?? "",
          },
          // minutes: task?.duration_minutes % 60,
          projectDetail: {
            client: task?.project?.client,
            project_name: task?.project?.project_name,
            _id: task?.project?._id,
          },
          status: task?.status,
          startTime: new Date(task?.start_time),
          endTime: new Date(task?.end_time),
          serviceAddedCheck: true,
          attachments: task.attachments,
        };
        if (obj.status === DISAPPROVED) {
          rejectedTasks.push(obj);
        }
        if (obj.service._id == "") {
          obj.serviceAddedCheck = false;
        }

        console.log(obj);
        setValue(`tasks.${index}`, obj);
      });

      setDeniedTasks(rejectedTasks);

      // setValue(`mode`, editTask?.tasks[0]?.work_mode);
      // setValue(`location`, editTask?.tasks[0]?.location?.address);

      setFlag(true);
    }
  }, [editTask]);

  const handleAddTask = () => {
    let obj;
    let temp = watch("tasks");

    if (isWeekView) {
      let start = temp[0].startDate;
      start.setHours(9, 0, 0, 0);
      let end = temp[0].startDate;
      end.setHours(17, 0, 0, 0);

      obj = {
        _id: "",
        projectDetail: temp[0].projectDetail,
        taskType: temp[0].taskType,
        description: "",
        service: temp[0].service,
        // hours: 1,
        // minutes: 0,
        status: PENDING,
        startTime: start,
        endTime: end,
        startDate: temp[0].startDate,
        serviceAddedCheck: temp[0].serviceAddedCheck,
        attachments: [],
      };
    } else {
      let start = moment(
        editTask?.date?.split("-").reverse().join("-")
      ).toDate();
      start.setHours(9, 0, 0, 0);
      let end = moment(editTask?.date?.split("-").reverse().join("-")).toDate();
      end.setHours(17, 0, 0, 0);

      obj = {
        _id: "",
        projectDetail: {
          project_name: "",
          client: { _id: "", name: "" },
          _id: "",
        },
        taskType: {
          name: "",
          is_billable: null,
        },
        description: "",
        service: { name: "", _id: "" },
        // hours: 1,
        // minutes: 0,
        status: PENDING,
        startTime: start,
        endTime: end,
        startDate: moment(
          editTask?.date?.split("-").reverse().join("-")
        ).toDate(),
        serviceAddedCheck: false,
        attachments: [],
      };
    }

    temp = [...temp, obj];
    setValue("tasks", temp);
  };

  const handleCopyTask = (index: number) => {
    const temp = watch("tasks");
    let copied = watch(`tasks.${index}`) as any;
    temp.push(copied);
    setValue(`tasks`, temp);
  };

  const handleDelete = (index: number) => {
    const temp = watch("tasks");
    const toDelete = watch(`tasks.${index}`);

    if (index !== -1) {
      temp.splice(index, 1);
    }
    setValue(`tasks`, temp);
    setDeleteTasks([...deleteTasks, toDelete._id]);
  };

  useEffect(() => {
    minEndTime = moment()
      .hours(startTime.getHours())
      .minutes(startTime.getMinutes());

    maxStartTime = moment()
      .hours(endTime.getHours())
      .minutes(endTime.getMinutes());

    const duration = moment.duration(moment(endTime).diff(moment(startTime)));
    setTotalWorkingHours(
      `${duration.hours()}:${duration
        .minutes()
        .toLocaleString([], { minimumIntegerDigits: 2 })}`
    );
  }, [startTime, endTime]);

  const editMutation = useMutation({
    mutationFn: (task: any) => {
      return serverPUT("/task/editAllTaskDetails", { task, deleteTasks });
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: async (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy(
          "Some of your tasks is overlapping with other task on same day"
        );
        return;
      }
      if (updatedData?.payrollError) {
        setPayrollError(updatedData?.payrollError);
        return;
      }
      successNotfiy("Tasks have been updated successfully");
      if (isWeekView) {
        navigate("/user/timesheet");
        await weeklyRefetch();
        handleClose();
      } else navigate("/user/timesheet");
      //closing popup
      // setEditTask(null);
    },
  });

  const checkEquality = (original, modified) => {
    // Get the values of both objects
    const values1 = Object.values(original) as any;

    const values2 = Object.values(modified) as any;

    // console.log("value 1", values1);
    // console.log("value 2", values2);
    // If the number of values is different, the objects are not equal
    if (values1.length !== values2.length) {
      return false;
    }

    // Iterate through the values
    for (let i = 0; i < values1.length; i++) {
      // If any corresponding values are different, the objects are not equal
      // console.log("value 1", values1[i]);
      // console.log("value 2", values2[i]);

      if (i === 2) {
        if (values1[i]._id !== values2[i]._id) {
          return false;
        }
      } else {
        if (values1[i] !== values2[i]) {
          return false;
        }
      }
    }

    // All values are equal, the objects are considered equal
    return true;
  };

  const onSubmit = async (data) => {
    if (overlaps.length > 0) {
      return;
    }
    startTime.setFullYear(startDate.getFullYear());
    startTime.setMonth(startDate.getMonth());
    startTime.setDate(startDate.getDate());

    // if (!projectDetail?._id) {
    //   setCustomError("Project is required");
    //   return;
    // }
    setSlotErrors([]);
    let startMilliseconds: number;
    let endMilliseconds: number;
    let totalTaskTime = 0;
    let totalWorkingMilliseconds = 0;
    let errors = [];
    // let filter = data.tasks?.filter(function (item) {
    //   return !deleteTasks.includes(item);
    // });

    for (let i = 0; i < data?.tasks.length; i++) {
      const element = data.tasks[i];
      if (element.status === APPROVED) {
        continue;
      }

      if (element.serviceAddedCheck && !element.service._id) {
        return;
      }
    }

    let tasks = data.tasks?.map((task, i) => {
      //new
      task.startTime.setFullYear(task.startDate.getFullYear());
      task.startTime.setMonth(task.startDate.getMonth());
      task.startTime.setDate(task.startDate.getDate());

      task.endTime.setFullYear(task.startDate.getFullYear());
      task.endTime.setMonth(task.startDate.getMonth());
      task.endTime.setDate(task.startDate.getDate());

      startMilliseconds = moment(task.startTime).valueOf();
      endMilliseconds = moment(task.endTime).valueOf();

      if (
        task.startTime.getHours() === 0 &&
        task.startTime.getMinutes() === 0 &&
        task.startTime.getSeconds() === 0 &&
        task.endTime.getHours() === 0 &&
        task.endTime.getMinutes() === 0 &&
        task.endTime.getSeconds() === 0
      ) {
        task.endTime.setDate(task.startDate.getDate() + 1);
        endMilliseconds = moment(task.endTime).valueOf();
      } else if (
        task.endTime.getHours() === 0 &&
        task.endTime.getMinutes() === 0 &&
        task.endTime.getSeconds() === 0
      ) {
        task.endTime.setDate(task.startDate.getDate() + 1);
        endMilliseconds = moment(task.endTime).valueOf();
      } else {
        if (startMilliseconds === endMilliseconds) {
          errors.push(i);
        } else if (startMilliseconds > endMilliseconds) {
          errors.push(i);
        }
      }

      // if (i === 0) {
      //   st = moment(startTime).valueOf();
      //   et = moment(startTime)
      //     .add(
      //       parseFloat(task.hours) * 60 + parseFloat(task.minutes),
      //       "minutes"
      //     )
      //     .toDate()
      //     .getTime();
      // } else {
      //   st = moment(et).valueOf();
      //   et = moment(et)
      //     .add(
      //       parseFloat(task.hours) * 60 + parseFloat(task.minutes),
      //       "minutes"
      //     )
      //     .toDate()
      //     .getTime();
      // }

      // totalTaskTime =
      //   totalTaskTime +
      //   moment.duration(moment(et).diff(moment(st))).asMilliseconds();

      let duration = moment
        .duration(moment(endMilliseconds).diff(moment(startMilliseconds)))
        .asMinutes();

      const [hours, minutes] = totalWorkingHours.split(":").map(Number);
      totalWorkingMilliseconds = (hours * 60 + minutes) * 60 * 1000;

      let result = {
        _id: task._id,
        project: task.projectDetail._id,
        service: task?.service?._id ? task.service._id : undefined,
        type: task.taskType,
        description: task.description,
        start_time: startMilliseconds,
        end_time: endMilliseconds,
        duration_minutes:
          // parseFloat(task.hours) * 60 + parseFloat(task.minutes),
          duration,
        status: task.status,
        attachments: task.attachments,
      };

      let tasktoCompare = deniedTasks.find((t) => t._id === task._id);
      if (tasktoCompare) {
        if (task?.status === DISAPPROVED) {
          const check = checkEquality(
            {
              _id: tasktoCompare._id,
              project: tasktoCompare.projectDetail._id,
              service: tasktoCompare.service,
              type: tasktoCompare.taskType.name,
              description: tasktoCompare.description,
              start_time: tasktoCompare.startTime.getTime(),
              end_time: tasktoCompare.endTime.getTime(),
              attachments: JSON.stringify(tasktoCompare.attachments),
            },
            {
              _id: task._id,
              project: task.projectDetail._id,
              service: task.service,
              type: task.taskType.name,
              description: task.description,
              start_time: startMilliseconds,
              end_time: endMilliseconds,
              attachments: JSON.stringify(task.attachments),
            }
          );
          if (!check) {
            result = { ...result, status: REVISED };
          }
        }
      }

      return result;
    });
    if (errors.length > 0) {
      setSlotErrors([...errors]);
      return;
    }

    for (let i = 0; i < tasks.length; i++) {
      const task = tasks[i];
      const { filesToUpload, alreadyFiles } = task.attachments.reduce(
        (result, file) => {
          if (typeof file !== "string") {
            result.filesToUpload.push(file);
          } else {
            result.alreadyFiles.push(file);
          }
          return result;
        },
        { filesToUpload: [], alreadyFiles: [] }
      );

      if (filesToUpload.length > 0) {
        const url = await uploadToBucket(filesToUpload);
        if (url.length > 0) {
          tasks[i].attachments = [...alreadyFiles, ...url];
        }
      }
    }
    if (deletedAttachments.length > 0) {
      deleteFromBucket(deletedAttachments);
    }

    let task = {
      user: user._id,
      // location: { address: data.location },
      // work_mode: data.mode,
      tasks: tasks,
      isWeekView,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    // if (editTask && totalTaskTime === totalWorkingMilliseconds) {
    // setShowError(false);
    editMutation.mutate(task);
    // } else {
    // setShowError(true);
    // }
  };

  const handleCancelClick = () => {
    navigate("/user/timesheet");
  };

  const handleClosePayroll = async () => {
    setPayrollError(null);
    if (isWeekView) {
      navigate("/user/timesheet");
      await weeklyRefetch();
      handleClose();
    } else {
      navigate("/user/timesheet");
    }
  };

  // console.log("errrorss", errors);
  return (
    <>
      {!isWeekView ? (
        <ContentContainer>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="overflow-auto"
            style={{ height: "80vh" }}
          >
            {!showError ? null : (
              <p className="text-red-500 text-sm flex justify-center p-2 italic">
                There is a conflict between the total number of working hours
                and the total number of task hours
              </p>
            )}
            <div className="sticky top-0  bg-white z-10">
              <div className="py-6">
                <BreadCrumb
                  data={[
                    { name: "My Timesheet", link: "/user/timesheet" },
                    { name: "Time Log Details", link: "#" },
                  ]}
                />
              </div>
              <div className="flex items-center mb-4">
                <p className="font-bold">
                  Time Log Details for{" "}
                  {moment(
                    editTask?.date?.split("-").reverse().join("-")
                  ).format("MM/DD/YYYY")}
                </p>
                <div className="ml-auto flex items-center">
                  <SecondaryIconButton
                    text="Add Task"
                    icon={<FaPlus />}
                    onClick={handleAddTask}
                  />
                </div>
              </div>
            </div>

            <div className="my-4 bg-white">
              {flag &&
                watch("tasks")?.map((x, i) => {
                  return (
                    <EditTaskList
                      index={i}
                      data={data}
                      watch={watch}
                      errors={errors}
                      register={register}
                      customError={customError}
                      setCustomError={setCustomError}
                      setValue={setValue}
                      onCopy={() => handleCopyTask(i)}
                      onDelete={() => handleDelete(i)}
                      deleteTasks={deleteTasks}
                      overlaps={overlaps}
                      setOverlaps={setOverlaps}
                      slotErrors={slotErrors}
                      onSubmitClicked={onSubmitClicked}
                      comments={commentData?.comments[x._id]}
                      commentRefetch={commentRefetch}
                      isWeekView={false}
                      setDeletedAttachments={setDeletedAttachments}
                    />
                  );
                })}
            </div>

            <div className="flex justify-center items-center fixed bottom-0 bg-customGrey-100 w-screen py-5 px-10">
              <SecondaryButton text="Cancel" onClick={handleCancelClick} />
              <span className="mx-3" />
              <PrimaryButton
                text="Update Changes"
                onClick={() => setOnSubmitClicked(true)}
                mutation={editMutation}
              />
            </div>
          </form>
        </ContentContainer>
      ) : (
        <form
          onSubmit={handleSubmit(onSubmit)}
          className="overflow-auto"
          // style={{ height: "80vh" }}
        >
          <div className="my-4 bg-white">
            {flag && (
              <div className="px-10">
                {/* contain info about service,type, project and client */}
                <PopupHeader
                  tasktype={watch(`tasks.0.taskType.name`)}
                  client={watch(`tasks.0.projectDetail.client.name`)}
                  project={watch(`tasks.0.projectDetail.project_name`)}
                  service={watch(`tasks.0.service.name`)}
                />

                {watch("tasks")?.map((x, i) => {
                  return (
                    <EditTaskList
                      index={i}
                      data={data}
                      watch={watch}
                      errors={errors}
                      register={register}
                      customError={customError}
                      setCustomError={setCustomError}
                      setValue={setValue}
                      onCopy={() => handleCopyTask(i)}
                      onDelete={() => handleDelete(i)}
                      deleteTasks={deleteTasks}
                      overlaps={overlaps}
                      setOverlaps={setOverlaps}
                      slotErrors={slotErrors}
                      onSubmitClicked={onSubmitClicked}
                      comments={commentData?.comments[x._id]}
                      commentRefetch={commentRefetch}
                      isWeekView={isWeekView}
                      handleAddTask={handleAddTask}
                      setDeletedAttachments={setDeletedAttachments}
                    />
                  );
                })}

                <div className="flex justify-center">
                  <SecondaryButton text="Cancel" onClick={handleClose} />
                  <span className="mx-3" />
                  <PrimaryButton
                    text="Submit"
                    onClick={() => setOnSubmitClicked(true)}
                    mutation={editMutation}
                  />
                </div>
              </div>
            )}
          </div>
        </form>
      )}
      {!!payrollError && (
        <PayrollModal
          payrollError={payrollError}
          handleClose={handleClosePayroll}
        />
      )}
    </>
  );
}

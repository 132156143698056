import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import {
  ACTIVE,
  ARCHIVED,
  COST_FEE_TYPE,
  HOURLY_RATE,
  HOURLY_RATE_TYPE,
  MULTIPLIER,
  UNIT_PRICE,
  UNIT_PRICE_TYPE,
} from "src/utils/Constants";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Dialogue from "../Global/Dialogue";
import Input from "../Global/Input";
import Select from "../Global/Select";
import TaskTypeAutoComplete from "../Global/TaskTypeAutoComplete";
import { serverGET, serverPUT } from "../../HttpFunctions";
import Toggler from "../Global/Toggler";
import HourlyRates from "./HourlyRates";
import CostUnit from "./CostUnit";
import UnitPrice from "./UnitPrice";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { serverPOST } from "../../HttpFunctions";
import { useParams } from "react-router";
import { successNotfiy, errorNotfiy } from "../Global/Toasts";

const schema = yup
  .object({
    name: yup.string().required(),
    taskType: yup.string().required(),
    status: yup.mixed().oneOf([ACTIVE, ARCHIVED]),
    is_billable: yup.boolean(),
    hourlyRate: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null ? undefined : value
      )
      .when(
        "$serviceType",
        ([serviceType], schema) =>
          serviceType === 0 ? schema.required().positive() : schema
        // [1, 2].includes(serviceType) ? schema : schema.required().positive()
      ),
    costType: yup.mixed().oneOf([HOURLY_RATE, UNIT_PRICE]),
    costRate: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null ? undefined : value
      )
      .when(
        "$serviceType",
        ([serviceType], schema) =>
          serviceType === 1 ? schema.required().positive() : schema
        // [0, 2].includes(serviceType) ? schema : schema.required().positive()
      ),
    feeType: yup.mixed().oneOf([UNIT_PRICE, MULTIPLIER]),

    feeRate: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null ? undefined : value
      )
      .when(
        "$serviceType",
        ([serviceType], schema) =>
          serviceType === 1 ? schema.required().positive() : schema
        // [0, 2].includes(serviceType) ? schema : schema.required().positive()
      ),

    halfDayPrice: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null ? undefined : value
      )
      .when(
        "$serviceType",
        ([serviceType], schema) =>
          serviceType === 2 ? schema.required().positive() : schema
        // [0, 1].includes(serviceType) ? schema : schema.required().positive()
      ),
    fullDayPrice: yup
      .number()
      .transform((value) =>
        isNaN(value) || value === null ? undefined : value
      )
      .when(
        "$serviceType",
        ([serviceType], schema) =>
          serviceType === 2 ? schema.required().positive() : schema
        // [0, 1].includes(serviceType) ? schema : schema.required().positive()
      ),
  })
  .required();

export default function CreateServiceDiaglog({
  handleClose,
  refetch,
  editDialog,
  global,
}: {
  handleClose: () => void;
  refetch?: any;
  editDialog: string;
  global?: boolean;
}) {
  const [serviceType, setServiceType] = useState(-1);
  const { projectId } = useParams();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      taskType: "",
      status: ACTIVE,
      is_billable: false,
      feeType: UNIT_PRICE,
      costType: HOURLY_RATE,
    },
    context: { serviceType },
  });

  // console.log(errors);

  const [type, setType] = useState("");

  const { data: taskTypeData } = useQuery("tasktypes", () =>
    serverGET("/tasktype?is_active=true")
  );
  const { isLoading: createLoading, mutate: createMutate } = useMutation({
    mutationFn: (service: any) => {
      return global
        ? serverPOST(`/service/create/global`, { service })
        : serverPOST(`/service/create`, { service });
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
      errorNotfiy("Something went wrong while creating service");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while creating service");
        return;
      }
      successNotfiy("Serive has been created successfully");
      refetch();
      handleClose();
    },
  });

  const { isLoading: updateLoading, mutate: editMutate } = useMutation({
    mutationFn: (data: any) => {
      return global
        ? serverPUT(`/service/global/${data.id}`, data.service)
        : serverPUT(`/service/${data.id}`, data.service);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
      errorNotfiy("Something went wrong while updating service");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating service");
        return;
      }
      successNotfiy("Serive has been updated successfully");
      refetch();
      handleClose();
    },
  });

  const { data: serviceData } = useQuery({
    queryKey: [`service-${editDialog}`],
    queryFn: () =>
      global
        ? serverGET(`/service/global/${editDialog}`)
        : serverGET(`/service/${editDialog}`),
    enabled: !!editDialog,
  });

  async function onSubmit(data) {
    let obj: any = {
      name: data.name,
      tasktype: data.taskType,
      is_billable: data.is_billable,
      is_active: data.status === ACTIVE ? true : false,
      // overtime: parseFloat(data.overtime?.split(" ")[1]), // format is x 1
      overtime: data.overtime, // format is x 1
      project: global ? undefined : projectId,
      overwrite: true,
    };
    if (serviceType === 0) {
      obj = {
        ...obj,
        service_type: HOURLY_RATE_TYPE,
        hourly_rate: data.hourlyRate,
      };
    } else if (serviceType === 1) {
      obj = {
        ...obj,
        service_type: COST_FEE_TYPE,
        rate_type:
          data.costType === HOURLY_RATE ? HOURLY_RATE_TYPE : UNIT_PRICE_TYPE,
        cost_rate: data.costRate,
        fee_type: data.feeType === UNIT_PRICE ? UNIT_PRICE_TYPE : MULTIPLIER,
        fee_rate:
          typeof data.feeRate === "string"
            ? parseFloat(data.feeRate?.split(" ")[1])
            : data.feeRate,
      };
    } else if (serviceType === 2) {
      obj = {
        ...obj,
        service_type: UNIT_PRICE_TYPE,
        half_day_price: data.halfDayPrice,
        full_day_price: data.fullDayPrice,
      };
    }

    if (!!editDialog) {
      editMutate({ service: obj, id: editDialog });
    } else {
      createMutate(obj);
    }
  }

  const handleTypeClick = (type: number) => {
    setServiceType(type);
    // setType("");
    // reset();
  };

  const handleToggle = () => {
    setValue("is_billable", !watch("is_billable"));
  };

  useEffect(() => {
    if (serviceData?.service) {
      const cloneObj = Object.assign({}, serviceData?.service);
      console.log("oevrvtime", cloneObj.overtime);

      let copyObj = {
        name: cloneObj.name,
        taskType: cloneObj.tasktype._id,
        status: cloneObj.is_active ? ACTIVE : ARCHIVED,
        is_billable: cloneObj.is_billable,
        hourlyRate: cloneObj.hourly_rate,
        costType:
          cloneObj.rate_type === HOURLY_RATE_TYPE ? HOURLY_RATE : UNIT_PRICE,
        costRate: cloneObj.cost_rate,
        feeType:
          cloneObj.fee_type === UNIT_PRICE ? UNIT_PRICE_TYPE : MULTIPLIER,
        feeRate: cloneObj.fee_rate,
        halfDayPrice: cloneObj.half_day_price,
        fullDayPrice: cloneObj.full_day_price,
        overtime: cloneObj.overtime,
      };
      // console.log(
      //   "🚀 ~ file: CreateServiceDiaglog.tsx:244 ~ useEffect ~ copyObj:",
      //   copyObj,
      //   serviceData?.service
      // );
      if (cloneObj.service_type === HOURLY_RATE_TYPE) {
        setServiceType(0);
      } else if (cloneObj.service_type === COST_FEE_TYPE) {
        setServiceType(1);
      } else if (cloneObj.service_type === UNIT_PRICE_TYPE) {
        setServiceType(2);
      } else {
        setServiceType(-1);
      }

      setType(cloneObj.tasktype.name);

      reset(copyObj);
    }
  }, [serviceData]);

  return (
    <Dialogue
      handleClose={handleClose}
      title={!!editDialog ? "Update a Service" : "Add a New Service"}
      central={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative p-4 flex-auto md:w-[620px]">
          <div className="grid md:grid-cols-2 gap-8 my-2">
            <div className="h-[42px]">
              <Input
                label={"Name"}
                placeholder="Enter service name"
                type={"text"}
                name="name"
                regProp={{ ...register("name") }}
                error={errors.name?.message}
                required={true}
              />
            </div>
            <div>
              <TaskTypeAutoComplete
                taskTypeData={taskTypeData}
                setValue={setValue}
                watch={watch}
                type={type}
                setType={setType}
                setServiceType={setServiceType}
              />
            </div>
            <div>
              <Select
                label={"Status"}
                id="status"
                regProp={{ ...register("status") }}
                options={[ACTIVE, ARCHIVED]}
                error={errors.status?.message}
              />
            </div>

            <div>
              <Toggler
                handleToggle={handleToggle}
                value={watch("is_billable")}
              />
            </div>
          </div>
          {watch("is_billable") && (
            <>
              <div>
                <label className="flex text-sm mt-4">
                  Select Type <p className="text-sm text-red-500 ml-1">*</p>
                </label>
                <div className="grid md:grid-cols-3 gap-4 mt-2">
                  <div className="w-full">
                    <button
                      type="button"
                      className={`${
                        serviceType === 0
                          ? "p-custom1 rounded-md text-sm hover:bg-customPurple-900 text-white border-2 border-customPurple-900 bg-customPurple-800 primary-hover-effect"
                          : "secondary-button secondary-hover-effect"
                      }   w-full`}
                      onClick={() => handleTypeClick(0)}
                    >
                      Hourly rates
                    </button>
                  </div>
                  <div>
                    <div className="w-full">
                      <button
                        type="button"
                        className={`${
                          serviceType === 1
                            ? "p-custom1 rounded-md text-sm hover:bg-customPurple-900 text-white border-2 border-customPurple-900 bg-customPurple-800 primary-hover-effect"
                            : "secondary-button secondary-hover-effect"
                        }   w-full`}
                        onClick={() => handleTypeClick(1)}
                      >
                        Cost plus fee
                      </button>
                    </div>
                  </div>
                  <div>
                    <div className="w-full">
                      <button
                        type="button"
                        className={`${
                          serviceType === 2
                            ? "p-custom1 rounded-md text-sm hover:bg-customPurple-900 text-white border-2 border-customPurple-900 bg-customPurple-800 primary-hover-effect"
                            : "secondary-button secondary-hover-effect"
                        }   w-full`}
                        onClick={() => handleTypeClick(2)}
                      >
                        Unit price
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {serviceType === 0 ? (
                <HourlyRates register={register} />
              ) : serviceType === 1 ? (
                <CostUnit register={register} watch={watch} errors={errors} />
              ) : (
                <UnitPrice register={register} />
              )}
            </>
          )}
        </div>

        <div className="flex justify-center py-6 rounded-b">
          <PrimaryButton text={!!editDialog ? "Update" : "Create"} />
        </div>
      </form>
    </Dialogue>
  );
}

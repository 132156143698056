import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Input from "../Global/Input";
import { useMutation, useQueryClient } from "react-query";
import { serverPOST } from "../../HttpFunctions";
import Dialogue from "../Global/Dialogue";
import { successNotfiy } from "../Global/Toasts";

type AddClientProps = {
  handleClose: () => void;
};

export const schema = yup
  .object({
    name: yup.string().required(),
    email: yup.string(),
    phone_number: yup.string(),
  })
  .required();

export default function AddClient({ handleClose }: AddClientProps) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      email: "",
      phone_number: "",
    },
  });
  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (client: any) => {
      return serverPOST(`/project/client/create`, client);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(["clients"], (oldData: any) => {
        return { clients: [updatedData.client, ...oldData.clients] };
      });

      successNotfiy("Client has been updated successfully");
    },
  });

  function onSubmit(data) {
    mutation.mutate(data);
    handleClose();
  }

  return (
    <>
      <Dialogue
        title="Create Client"
        description="Fill in the required fields below to create a client"
        handleClose={handleClose}
        central={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="relative p-6 flex-auto">
            <div className="grid md:grid-cols-2 gap-8 my-2">
              <div>
                <Input
                  label={"Client Name"}
                  placeholder="Enter client name"
                  type={"text"}
                  name="name"
                  regProp={{ ...register("name") }}
                  error={errors.name?.message}
                />
              </div>
              <div>
                <Input
                  label={"Email"}
                  placeholder="Enter email"
                  type={"text"}
                  name="email"
                  regProp={{ ...register("email") }}
                  error={errors.email?.message}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-8 my-2">
              <div>
                <Input
                  label={"Phone Number"}
                  placeholder="Enter phone number"
                  type={"text"}
                  name="phoneNo"
                  regProp={{ ...register("phone_number") }}
                  error={errors.phone_number?.message}
                />
              </div>
            </div>
          </div>

          {/*footer*/}
          <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
            <div className="pr-4">
              <SecondaryButton onClick={handleClose} text="Cancel" />
            </div>
            <PrimaryButton text="Create" mutation={mutation} />
          </div>
        </form>
      </Dialogue>
    </>
  );
}

import React, { useEffect, useState } from "react";
import Dialogue from "../Global/Dialogue";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQueryClient } from "react-query";
import { serverPUT } from "../../HttpFunctions";
import { successNotfiy, errorNotfiy } from "../../components/Global/Toasts";
import Input from "../Global/Input";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Select from "../Global/Select";
import { ACTIVE, ARCHIVED, PM, USER } from "src/utils/Constants";
import { Employee } from "src/pages/User/EmployeeList";
import ReactSelect from "react-select";
import { Option } from "../Global/MultiSelect";
import axios from "axios";
import { useAuth } from "src/context/Auth";

export const schema = yup
  .object({
    empId: yup.string().required(),
    firstname: yup.string().required().max(16),
    lastname: yup.string().required().max(16),
    email: yup.string().email().required(),
    phone: yup.string().required().min(8),
    status: yup.string().required(),
    userTypes: yup
      .array()
      .of(yup.string().required())
      .required()
      .min(1, "At least one userTypes is required"),
  })
  .required();

type editEmployeeProps = {
  editEmployee: Employee;
  setEditEmployee: React.Dispatch<React.SetStateAction<Employee>>;
};

export default function EditEmployee({
  editEmployee,
  setEditEmployee,
}: editEmployeeProps) {
  const [showPopup, setShowPopup] = useState(false);
  const [inputPassword, setInputPassword] = useState("");
  const [isDisabled, setIsDisabled] = useState(true);
  const { user } = useAuth();
  const [passwordError, setPasswordError] = useState("");
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      empId: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      status: ACTIVE,
      userTypes: [USER],
    },
  });

  const queryEmployee = useQueryClient();

  useEffect(() => {
    reset(editEmployee);
    setValue("empId", editEmployee.emp_id);
    setValue("firstname", editEmployee.first_name);
    setValue("lastname", editEmployee.last_name);
    setValue("phone", editEmployee.phone_number);
    setValue("userTypes", editEmployee.type_of_user);
    setValue("status", editEmployee.is_active === true ? ACTIVE : ARCHIVED);
  }, [editEmployee]);

  const mutation = useMutation({
    mutationFn: (user: any) => {
      return serverPUT(`/user/editEmployee/${user._id}`, user.data);
    },
    onError: (context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: { employee: Employee }) => {
      if (updatedData && updatedData.constructor !== String) {
        queryEmployee.setQueryData(["employees"], (oldData: any) => {
          if (oldData.employees) oldData = oldData.employees;
          const employees = oldData.map((employee) => {
            return employee._id === updatedData.employee._id
              ? updatedData.employee
              : employee;
          });

          return employees;
        });

        successNotfiy("Employee information successfully updated");
        handleClose();
      } else {
        if (updatedData.toString() === "Employee Id is not unique.") {
          errorNotfiy("Employee with same id already exists");
        } else {
          errorNotfiy("There was some issue. Please try again");
        }
      }
    },
  });

  const onSubmit = (data) => {
    let user = {
      _id: editEmployee._id,
      data: {
        emp_id: data.empId,
        first_name: data.firstname,
        last_name: data.lastname,
        phone_number: data.phone,
        type_of_user: data.userTypes,
        is_active: data.status === ACTIVE ? true : false,
      },
    };

    mutation.mutate(user);
  };

  const handleClose = () => {
    setEditEmployee({} as Employee);
  };

  const handleTypeChange = (selected) => {
    setValue(
      "userTypes",
      selected.map((x) => x.value)
    );
  };

  const handleButtonClick = () => {
    setShowPopup(true);
  };

  const handlePasswordChange = (e) => {
    setInputPassword(e.target.value);
  };

  const handlePasswordSubmit = () => {
    let data = {
      email: user.email,
      password: inputPassword,
    };
    mutate(data);
  };

  const { mutate, isLoading } = useMutation({
    mutationFn: async (user: any) => {
      return await axios.post(
        process.env.REACT_APP_HOST + "/auth/check-password",
        user
      );
    },
    onError: (error, variables, context) => {
      // console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response.code === 200) {
        setIsDisabled(false);
        setShowPopup(false);
      } else {
        errorNotfiy("Incorrect Password. Try again");
      }
    },
  });

  return (
    <>
      <Dialogue
        title="Edit Team Member"
        handleClose={handleClose}
        central={true}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-4">
            <div className="grid md:grid-cols-1 my-2">
              <Input
                label={"Employee ID"}
                placeholder="Employee ID"
                type={"text"}
                name="empId"
                regProp={{ ...register("empId") }}
                error={errors.empId?.message}
                isDisabled={isDisabled}
              />
              <p
                className="mt-4 text-customPurple-400 cursor-pointer"
                onClick={handleButtonClick}
              >
                Edit Employee ID
              </p>

              {showPopup && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50 ">
                  <div className="bg-white p-6 rounded-lg  w-80">
                    <div className="flex items-center flex-col justify-center rounded-t">
                      <button
                        className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                        onClick={() => setShowPopup(false)}
                      >
                        <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                          ×
                        </span>
                      </button>
                      <h3 className="text-xl font-semibold text-center">
                        Enter Password
                      </h3>
                    </div>
                    <input
                      type="password"
                      className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sky-500 focus:border-sky-500 sm:text-sm"
                      value={inputPassword}
                      onChange={handlePasswordChange}
                    />
                    <div className="flex gap-4 pt-4 justify-center">
                      <SecondaryButton
                        onClick={() => setShowPopup(false)}
                        text="Cancel"
                      />

                      <p
                        className={`p-custom1 rounded-md text-sm cursor-pointer hover:bg-customPurple-900 text-white border-2 border-customPurple-900 bg-customPurple-800 disabled:bg-customPurple-400 `}
                        onClick={handlePasswordSubmit}
                      >
                        Submit
                      </p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="grid md:grid-cols-2 gap-4 my-2">
              <div>
                <Input
                  label={"First Name"}
                  placeholder="First Name"
                  type={"text"}
                  name="firstname"
                  regProp={{ ...register("firstname") }}
                  error={errors.firstname?.message}
                />
              </div>
              <div>
                <Input
                  label={"Last Name"}
                  placeholder="Last Name"
                  type={"text"}
                  name="lastname"
                  regProp={{ ...register("lastname") }}
                  error={errors.lastname?.message}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4 my-2">
              <div className="my-2">
                <Input
                  isDisabled={true}
                  label={"Email"}
                  placeholder="Email"
                  type={"email"}
                  name="email"
                  regProp={{ ...register("email") }}
                  error={errors.email?.message}
                />
              </div>
              <div className="my-2">
                <Input
                  label={"Phone Number"}
                  placeholder="Phone Number"
                  type={"text"}
                  name="phone"
                  regProp={{ ...register("phone") }}
                  error={errors.phone?.message}
                />
              </div>
            </div>
            <div className="grid md:grid-cols-2 gap-4 my-2 capitalize">
              <RoleSelect
                handleTypeChange={handleTypeChange}
                userTypes={watch("userTypes")}
                errors={errors.userTypes?.message}
              />
              <div>
                <Select
                  label={"Status"}
                  id="status"
                  regProp={{ ...register("status") }}
                  options={[ACTIVE, ARCHIVED]}
                  error={errors.status?.message}
                  style={{
                    height: "37px",
                    marginTop: "8px",
                  }}
                />
              </div>
            </div>
          </div>
          <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
            <div className="pr-4">
              <SecondaryButton onClick={handleClose} text="Cancel" />
            </div>
            <PrimaryButton text="Update" />
          </div>
        </form>
      </Dialogue>
    </>
  );
}

export const RoleSelect = ({ handleTypeChange, userTypes, errors }) => {
  const typeOptions = [
    {
      value: PM,
      label: PM.toUpperCase(),
      isDisabled: userTypes.includes("user") ? true : false,
    },
    {
      value: USER,
      label: USER,
      isDisabled: userTypes.includes("pm") ? true : false,
    },
    { value: "admin", label: "Admin" },
  ];

  return (
    <div>
      <span className="text-sm font-medium text-slate-700 mb-2 flex">Role</span>
      <ReactSelect
        options={typeOptions}
        styles={{
          control: (styles) => ({
            ...styles,
            borderRadius: 8,
          }),
          multiValue: (styles, { data }) => ({
            ...styles,
            minWidth: "60px",
            maxWidth: "80px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          valueContainer: (provided, state) => ({
            ...provided,
            textOverflow: "ellipsis",
            maxWidth: "113px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            display: "flex",
            flexWrap: "nowrap",
          }),
        }}
        value={userTypes.map((t) => {
          return { value: t, label: t };
        })}
        isMulti
        closeMenuOnSelect={false}
        controlShouldRenderValue={true}
        isSearchable={false}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        placeholder={"Role"}
        onChange={handleTypeChange}
      />
      <p className="text-sm text-red-500">{errors}</p>
    </div>
  );
};

import React, { useEffect, useState } from "react";
import ContentContainer from "../Global/ContentContainer";
import Select from "../Global/Select";
import Input from "../Global/Input";
import { ACTIVE, ARCHIVED } from "src/utils/Constants";
import ManagerAutoComplete from "../Global/AutoComplete";
import Autocomplete from "react-autocomplete";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation, useQuery } from "react-query";
import { serverGET, serverPOST, serverPUT } from "../../HttpFunctions";
import DatePicker from "react-datepicker";
import { PrimaryButton, SecondaryIconButton } from "../Global/Button";
import { FaPlus } from "react-icons/fa";
import CreateServiceDiaglog from "./CreateServiceDiaglog";
import ProjectServicesList from "./ProjectServicesList";
import { useNavigate, useParams } from "react-router";
import { successNotfiy, errorNotfiy } from "../Global/Toasts";
import Alert from "../../components/Global/Toasts";
import Spinner from "../Global/Spinner";

const Tab1Content = () => <ProjectServices />;
const Tab2Content = () => <ProjectSettings />;
const Tab3Content = () => <div>Tab 3 Content</div>;

export const schema = yup
  .object({
    name: yup.string().required(),
    client: yup.string().required(),
    status: yup.string().required(),
    startDate: yup
      .date()
      .nullable()
      .required("Start date is required")
      .typeError("Start date is required"),
    endDate: yup
      .date()
      .nullable()
      .required("End date is required")
      .typeError("End date is required"),
  })
  .required();

export default function CreateProject() {
  const { projectId } = useParams();
  const isAdd = !projectId;

  const [activeTab, setActiveTab] = useState(isAdd ? 2 : 1);
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const tabComponents = [Tab1Content, Tab2Content, Tab3Content];

  const ActiveComponent = tabComponents[activeTab - 1];

  return (
    <ContentContainer>
      <h2 className="text-2xl font-bold">
        {isAdd ? "Create Project" : "Edit Project"}
      </h2>
      <div className="flex gap-3 pt-4">
        <TabName
          text="Services"
          activeTab={activeTab}
          currentTabNo={1}
          handleTabClick={handleTabClick}
        />
        <TabName
          text="Settings"
          activeTab={activeTab}
          currentTabNo={2}
          handleTabClick={handleTabClick}
        />
      </div>
      <div className="pt-[20px]">
        <ActiveComponent />
      </div>
    </ContentContainer>
  );
}

function ProjectServices() {
  const [serviceDialog, setServiceDialog] = useState(false);
  const [editDialog, setEditDialog] = useState("");

  const { projectId } = useParams();

  const { data, refetch, isLoading } = useQuery(`service-${projectId}`, () =>
    serverGET(`/service/project/${projectId}`)
  );

  const { data: projectData } = useQuery({
    queryKey: [`project-${projectId}`],
    queryFn: () => serverGET(`/project/${projectId}`),
    enabled: !!projectId,
  });
  const handleClose = () => {
    setServiceDialog(false);
    setEditDialog("");
  };
  return (
    <div>
      <div className="flex">
        <h1 className="text-xl pb-5 font-semibold capitalize">
          {projectData?.project?.project_name + " " + "Services"}
        </h1>
        <div className="ml-auto">
          <SecondaryIconButton
            text="New service"
            icon={<FaPlus />}
            onClick={() => {
              setServiceDialog((prev) => !prev);
            }}
            disabled={!!!projectId}
          />
        </div>
      </div>
      <h1 className="text-lg text-center w-full">
        {data?.services?.length > 0 ? (
          <ProjectServicesList
            services={data.services}
            refetch={refetch}
            setEditDialog={setEditDialog}
          />
        ) : isLoading ? (
          <Spinner />
        ) : (
          "No service added to this project yet"
        )}
      </h1>
      {serviceDialog || !!editDialog ? (
        <CreateServiceDiaglog
          handleClose={handleClose}
          refetch={refetch}
          editDialog={editDialog}
        />
      ) : null}
      <Alert />
    </div>
  );
}

function ProjectSettings() {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      client: "",
      status: ACTIVE,
      startDate: new Date(),
      endDate: new Date(),
    },
  });
  const { projectId } = useParams();
  const navigate = useNavigate();

  const [clientName, setClientName] = useState("");
  const [pmNames, setPmNames] = useState(null);
  const isAdd = !projectId;

  const { data: clientData } = useQuery("clients", () =>
    serverGET(`/project/clients?isAdmin=1`)
  );

  const { data: projectData } = useQuery({
    queryKey: [`project-${projectId}`],
    queryFn: () => serverGET(`/project/${projectId}`),
    enabled: !!projectId,
  });

  useEffect(() => {
    if (projectData?.project) {
      setValue(
        "status",
        projectData?.project.is_active === true ? ACTIVE : ARCHIVED
      );
      setValue("name", projectData?.project?.project_name);
      setValue("client", projectData?.project?.client?._id);
      setValue(
        "startDate",
        projectData?.project?.start_date
          ? new Date(projectData?.project?.start_date)
          : new Date()
      );
      setValue(
        "endDate",
        projectData?.project?.end_date
          ? new Date(projectData?.project?.end_date)
          : new Date()
      );
      setClientName(projectData?.project?.client?.name);

      let formatted = projectData?.project?.project_manager.map((pm) => {
        return {
          label: pm.first_name + " " + pm.last_name,
          value: pm._id,
        };
      });

      setPmNames(formatted?.length > 0 ? formatted : null);
    }
  }, [projectData]);

  const { data: pmData } = useQuery("pm", () => serverGET("/user/pms"));

  const mutation = useMutation({
    mutationFn: (project: any) => {
      return serverPUT(`/project/${projectId}`, project);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
      errorNotfiy("Something went wrong while creating project");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating project");
        return;
      }
      successNotfiy("Project has been updated successfully");
    },
  });

  const createMutation = useMutation({
    mutationFn: (project: any) => {
      return serverPOST(`/project/create`, project);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
      errorNotfiy("Something went wrong while creating project");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while creating project");
        return;
      }
      successNotfiy("Project has been added successfully");
      navigate(`/dashboard/project/${updatedData?.project?._id}`);
    },
  });

  const handlePmChange = (value) => {
    setPmNames(value);
  };

  function onSubmit(data) {
    let project_manager = [];

    console.log("🚀 ~ onSubmit ~ pmNames:", pmNames);
    if (pmNames && Object.keys(pmNames).length > 0) {
      // project_manager = pmNames.map((pm) => pm.value);
      project_manager.push();
    }

    let requestData = {
      project_name: data.name,
      client: data.client,
      project_manager: pmNames.map((x) => x.value),
      is_active: data.status === ACTIVE ? true : false,
      start_date: data.startDate.getTime(),
      end_date: data.endDate.getTime(),
    };

    if (!!projectId) {
      mutation.mutate(requestData);
    } else {
      createMutation.mutate(requestData);
    }
  }

  const handleClientChange = (val) => {
    setClientName(val);
    const cli = clientData.clients;
    const found = cli.find((c) => c.name === val);
    if (found) {
      setValue("client", found._id);
    } else {
      setValue("client", "");
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="">
        <div className="flex gap-10">
          <div className="w-[320px]">
            <Input
              label={"Project Name"}
              placeholder="Enter project name"
              type={"text"}
              name="name"
              regProp={{ ...register("name") }}
              error={errors.name?.message}
            />
          </div>
          <div className="w-[320px]">
            <span className="block text-sm font-medium text-slate-700 mb-2">
              Client
            </span>

            {clientData && (
              <Autocomplete
                getItemValue={(item) => item?.name}
                items={clientData?.clients ?? []}
                shouldItemRender={(item, value) =>
                  item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
                }
                wrapperStyle={{
                  display: "block",
                  position: "relative",
                }}
                inputProps={{
                  className:
                    "w-[320px] first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                }}
                renderItem={(item, highlighted) => (
                  <div
                    key={item?._id}
                    style={{
                      backgroundColor: highlighted ? "#eee" : "transparent",
                    }}
                    className="p-2"
                  >
                    {item?.name}
                  </div>
                )}
                value={clientName}
                onChange={(e) => handleClientChange(e.target.value)}
                onSelect={(val) => handleClientChange(val)}
                menuStyle={{
                  zIndex: 999,
                  boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                  position: "absolute",
                  left: 0,
                  top: 44,
                  backgroundColor: "white",
                  borderRadius: "8px",
                  overflow: "auto",
                  maxHeight: "200px",
                }}
              />
            )}
            <p className="text-sm text-red-500">{errors?.client?.message}</p>
          </div>
        </div>
        <div className="flex gap-10 mt-6">
          <div className="w-[320px]">
            <Select
              label={"Status"}
              id="status"
              regProp={{ ...register("status") }}
              options={[ACTIVE, ARCHIVED]}
              error={errors.status?.message}
              style={{
                height: "37px",
                marginTop: "8px",
              }}
            />
          </div>
          <div className="w-[320px]">
            <span className="block text-sm font-medium text-slate-700 mb-2">
              Project Manager
            </span>

            <ManagerAutoComplete
              data={pmData}
              handlePmChange={handlePmChange}
              pmNames={pmNames}
            />
          </div>
        </div>

        <div className="flex gap-10 mt-6">
          <div className="w-[320px]">
            <label className="block">
              <span className="block text-sm font-medium text-slate-700 mb-2">
                Start Date
              </span>

              <DatePicker
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2.5 p-2.5"
                placeholderText="Select date"
                selected={watch(`startDate`)}
                onChange={(date) => setValue(`startDate`, date)}
              />
            </label>
          </div>
          <div className="w-[320px]">
            <label className="block">
              <span className="block text-sm font-medium text-slate-700 mb-2">
                End Date
              </span>
              <DatePicker
                className="border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full pl-2.5 p-2.5"
                placeholderText="Select date"
                selected={watch(`endDate`)}
                onChange={(date) => setValue(`endDate`, date)}
              />
            </label>
          </div>
        </div>

        <hr className="border-t-grey-100 mt-6" />
        <div className="flex gap-10 mt-3">
          {/* <div className="w-[320px]">
            <p className="text-base font-semibold">Billable by default</p>
            <p className="text-sm text-customGrey-500 leading-6">
              All services will be billable by default
            </p>
            <span className="ml-2 text-sm font-medium text-gray-900">
              Billable
            </span>
            <div className="relative flex flex-col items-center justify-center overflow-hidden">
              <div className="flex">
                <label className="inline-flex relative items-center mr-5 cursor-pointer">
                  <input
                    type="checkbox"
                    className="sr-only peer"
                    checked={true}
                    readOnly
                  />
                  <div
                    onClick={() => {
                      // setEnabled(!enabled);
                    }}
                    className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                  ></div>
                </label>
              </div>
            </div>
          </div> */}

          {/* <div className="w-[320px]">
            <Input
              label={"Project Budget"}
              placeholder="$"
              type={"text"}
              name="budget"
              regProp={{ ...register("name") }}
              error={errors.name?.message}
            />
          </div> */}
        </div>
        <div className="  justify-center py-6 rounded-b">
          <PrimaryButton text={isAdd ? "Create" : "Update"} />
        </div>
      </div>
      <Alert />
    </form>
  );
}

function TabName({
  text,
  handleTabClick,
  activeTab,
  currentTabNo,
}: {
  text: string;
  handleTabClick: (tabNo: number) => void;
  activeTab: number;
  currentTabNo: number;
}) {
  return (
    <div onClick={() => handleTabClick(currentTabNo)}>
      <p
        className={`${
          activeTab === currentTabNo
            ? "bg-customPurple-200 text-customPurple-400"
            : "bg-customGrey-100 text-customGrey-500"
        } text-sm text-center font-bold rounded-lg w-[87px] px-[10px] py-[16px] cursor-pointer`}
      >
        {text}
      </p>
    </div>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { schema } from "../../components/Admin/AddTaskType";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Dialogue from "../Global/Dialogue";
import Input from "../Global/Input";
import { useMutation, useQueryClient } from "react-query";
import { serverPUT } from "../../HttpFunctions";
import { successNotfiy } from "../Global/Toasts";
import Select from "../Global/Select";
import { ACTIVE, ARCHIVED } from "src/utils/Constants";

type editTaskTypeProps = {
  editTaskType: any;
  setEditTaskType: any;
};
export default function EditTaskType({
  editTaskType,
  setEditTaskType,
}: editTaskTypeProps) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      status: "",
      is_billable: "",
    },
  });

  const queryTaskType = useQueryClient();
  useEffect(() => {
    reset(editTaskType);
    setValue("status", editTaskType.is_active === true ? ACTIVE : ARCHIVED);
    setValue("is_billable", editTaskType.is_billable === true ? "Yes" : "No");
  }, [editTaskType]);

  const handleClose = () => {
    setEditTaskType("");
  };

  const mutation = useMutation({
    mutationFn: (taskType: any) => {
      return serverPUT(`/taskType/${taskType._id}`, taskType);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any) => {
      console.log("updated", updatedData);
      queryTaskType.setQueryData(["taskTypes"], (oldData: any) => {
        const taskTypes = oldData.taskTypes.map((taskType) => {
          return taskType._id === updatedData.task._id
            ? updatedData.task
            : taskType;
        });

        return {
          taskTypes,
        };
      });

      successNotfiy("Task type has been updated successfully");
    },
  });

  function onSubmit(data) {
    let requestData = {
      _id: data._id,
      name: data.name,
      is_billable: data.is_billable === "Yes" ? true : false,
      is_active: data.status === ACTIVE ? true : false,
    };

    mutation.mutate(requestData);
    handleClose();
  }

  return (
    <Dialogue
      title="Edit task type"
      description="Input relevant data and click on Update to save your changes"
      handleClose={handleClose}
      central={true}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative p-6 flex-auto">
          <div className="grid md:grid-cols-1">
            <div className="mb-2">
              <Input
                label={"Task type name"}
                placeholder="Enter taskType name"
                type={"text"}
                name="name"
                regProp={{ ...register("name") }}
                error={errors.name?.message}
              />
            </div>
            <div className="mb-2">
              <Select
                label={"Billable"}
                id="is_billable"
                regProp={{ ...register("is_billable") }}
                options={["Yes", "No"]}
                error={errors.is_billable?.message}
                style={{
                  height: "37px",
                  marginTop: "8px",
                }}
              />
            </div>

            <div className="mb-2">
              <Select
                label={"Status"}
                id="status"
                regProp={{ ...register("status") }}
                options={[ACTIVE, ARCHIVED]}
                error={errors.status?.message}
                style={{
                  height: "37px",
                  marginTop: "8px",
                }}
              />
            </div>
          </div>
        </div>

        {/*footer*/}
        <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <PrimaryButton text="Update" />
        </div>
      </form>
    </Dialogue>
  );
}

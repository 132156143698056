import React, { useState } from "react";
import { AiFillEyeInvisible, AiFillEye } from "react-icons/ai";

type InputProps = {
  label: string;
  placeholder: string;
  type: string;
  name: string;
  error?: string;
  regProp: {};
  isDisabled?: boolean;
  required?: boolean;
};

export default function Input({
  label,
  placeholder,
  type,
  name,
  error,
  regProp,
  isDisabled,
  required,
}: InputProps) {
  return (
    <label className="block">
      <span className="text-sm font-medium text-slate-700 mb-2 flex">
        {label}
        {required && <p className="text-sm text-red-500 ml-1">*</p>}
      </span>
      <input
        className="placeholder:text-slate-400 block bg-white w-full border border-customGrey-400 rounded-md py-2 pl-3 pr-3
         shadow-sm focus:outline-none focus:border-sky-500
          focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
        placeholder={placeholder}
        type={type}
        name={name}
        {...regProp}
        disabled={isDisabled}
      />
      <p className="text-sm text-red-500">{error}</p>
    </label>
  );
}

export function PasswordInput({
  label,
  placeholder,
  type,
  name,
  error,
  regProp,
  isDisabled,
}: InputProps) {
  const [togglePassword, setTogllePassword] = useState(false);
  const handleToggle = (e) => {
    setTogllePassword((prev) => !prev);
  };
  return (
    <label className="block">
      <span className="block text-sm font-medium text-slate-700 mb-2">
        {label}
      </span>
      <div className="flex items-center relative">
        <input
          className=" placeholder:text-slate-400 block bg-white w-full box-border border border-slate-300 rounded-md py-2 pl-3 pr-5
         shadow-sm focus:outline-none focus:border-sky-500
          focus:ring-sky-500 focus:ring-1 sm:text-sm disabled:opacity-50 disabled:bg-gray-300"
          placeholder={placeholder}
          type={togglePassword ? "text" : type}
          name={name}
          {...regProp}
          disabled={isDisabled}
        />
        <div className="absolute right-5">
          {togglePassword ? (
            <AiFillEyeInvisible
              onClick={handleToggle}
              className="cursor-pointer text-gray-400"
            />
          ) : (
            <AiFillEye
              onClick={handleToggle}
              className="cursor-pointer text-gray-400"
            />
          )}
        </div>
      </div>

      <p className="text-sm text-red-500">{error}</p>
    </label>
  );
}

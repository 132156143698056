import React, { createElement, useState } from "react";
import ReactTable from "react-table-6";
import { SecondaryIconButton } from "../Global/Button";
import { FaPlus } from "react-icons/fa";
import AddClient from "./AddClient";
import { useQuery } from "react-query";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import EditClient from "./EditClient";
import { useNavigate } from "react-router";
import { downloadCall, downloadExcel } from "src/utils/DownloadExcel";

type ClientTableProps = {
  data: any;
  handleClick: (client) => void;
};

export default function ClientTable({ data, handleClick }: ClientTableProps) {
  const [openDialog, setOpenDialog] = useState(false);
  const navigate = useNavigate();

  const handleDownload = async () => {
    downloadCall(
      `${process.env.REACT_APP_HOST}/project/clients/download`,
      "Clients.xls"
    );
  };

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    // {
    //   Header: "Email",
    //   accessor: "email",
    // },
    // {
    //   Header: "Phone Number",
    //   accessor: "phone_number",
    // },
    {
      Header: "Actions",
      maxWidth: 100,
      Cell: ({ row }) => (
        <div>
          <FaEdit
            className={"text-black cursor-pointer"}
            onClick={(e) => {
              e.stopPropagation();
              handleClick(data?.clients[row._index]);
            }}
          />
        </div>
      ),
    },
  ];

  return (
    <div className="">
      <div className="flex items-center">
        <h1 className="text-xl font-semibold">Clients</h1>

        <div className="ml-auto flex py-2">
          <div className="flex justify-left items-center px-2 gap-2">
            <SecondaryIconButton text="Export" onClick={handleDownload} />
            <SecondaryIconButton
              text="Client"
              icon={<FaPlus />}
              onClick={() => setOpenDialog(true)}
            />
          </div>
        </div>
      </div>
      <ReactTable
        data={data?.clients}
        columns={columns}
        pageSize={data?.clients?.length}
        showPagination={false}
        style={{ maxHeight: "calc(100vh - 160px)" }}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: () => {
                const id = rowInfo.original._id;
                navigate(
                  `/dashboard/client-projects/${id}?client=${rowInfo.original.name}`
                );
              },
            };
          } else {
            return {};
          }
        }}
      />
      {!openDialog ? null : (
        <AddClient handleClose={() => setOpenDialog(false)} />
      )}
    </div>
  );
}

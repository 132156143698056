import React, { useRef, useState } from "react";
import Chip from "../Global/Chip";
import { APPROVED, DISAPPROVED, PENDING, REVISED } from "src/utils/Constants";

export default function StatusDropdown({
  row,
  handleStatusChange,
  singleTask,
}: {
  row;
  handleStatusChange;
  singleTask?: boolean;
}) {
  return (
    <div>
      {singleTask ? (
        <ChipDropdown
          index={0}
          id={row.original._id}
          status={row.original.status}
          handleStatusChange={handleStatusChange}
        />
      ) : (
        row.value?.map((task, index) => (
          <ChipDropdown
            index={index}
            id={task._id}
            status={task.status}
            handleStatusChange={handleStatusChange}
          />
        ))
      )}
    </div>
  );
}

function ChipDropdown({ index, status, id, handleStatusChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef: any = useRef();

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    // Add a slight delay before hiding the content
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200); // Adjust the delay (in milliseconds) as needed
  };
  return (
    <div
      key={index}
      className="py-1.5 relative z-1"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isOpen ? (
        <ul className="flex gap-2">
          <Chip
            color={`${
              status === APPROVED ? "bg-paleGreen-100" : "bg-transparent"
            } bg-tra text-paleGreen-200 cursor-pointer`}
            title={APPROVED}
            onClick={() => {
              if (status === APPROVED) handleStatusChange(id, PENDING);
              else handleStatusChange(id, APPROVED);
            }}
          />
          <Chip
            color={`${
              status === DISAPPROVED ? "bg-customRed-100" : "bg-transparent"
            } text-customRed-200 cursor-pointer`}
            title={DISAPPROVED}
            onClick={() => {
              if (status === DISAPPROVED) handleStatusChange(id, PENDING);
              else handleStatusChange(id, DISAPPROVED);
            }}
          />
        </ul>
      ) : (
        <Chip
          color={
            status === PENDING
              ? "bg-customGrey-400 text-customeGrey-500"
              : status === APPROVED
              ? "bg-paleGreen-100 text-paleGreen-200"
              : status === REVISED
              ? "bg-customYellow-400 text-customYellow-900"
              : "bg-customRed-100 text-customRed-200"
          }
          title={status}
        />
      )}
    </div>
  );
}

import React, { useState } from "react";
import LoginImage from "../../assets/portraitLogin.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import axios from "axios";
import Input from "../../components/Global/Input";
import { useNavigate, useSearchParams } from "react-router-dom";
import Alert, {
  successNotfiy,
  errorNotfiy,
} from "../../components/Global/Toasts";
import { PrimaryButton } from "../../components/Global/Button";

const schema = yup
  .object({
    password: yup.string().required().min(8),
    confirmPassword: yup.string().required().min(8),
  })
  .required();
export default function ResetPassword() {
  const [error, setError] = useState("");
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [success, setSuccess] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      password: "",
      confirmPassword: "",
    },
  });

  const mutation = useMutation({
    mutationFn: async (password) => {
      return await axios.post(
        process.env.REACT_APP_HOST + "/auth/reset-password",
        password,
        {
          crossDomain: true,
          headers: { token: token },
        }
      );
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (response, variables, context) => {
      if (response) {
        // notified
        if (response.data.code === 204) {
          // successNotfiy("Password has been updated successfully");
          setSuccess(true);
        } else {
          errorNotfiy(response.data.message);
        }
      } else {
        console.log("error");
      }
    },
  });

  const onSubmit = (data) => {
    setError("");
    if (data.password !== data.confirmPassword) {
      setError("Password and confirm password must match");
      return;
    }
    mutation.mutate({
      password: data.confirmPassword,
    });
  };

  return (
    <div>
      <div>
        <img
          className="inline max-w-full h-auto"
          src={LoginImage}
          alt="banner"
        />
      </div>
      <div className="w-full md:w-1/3 text-left absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-20 bg-white bg-opacity-30 backdrop-blur-sm rounded drop-shadow-lg">
        {success ? (
          <div className="text-center">
            <h1 className="text-2xl font-bold">Password Reset!</h1>
            <p className="text-gray-400 mt-1">
              You have successfully reset a new password
            </p>
            <div className="mt-2">
              <PrimaryButton
                text="Back to login page"
                onClick={() => navigate("/login")}
              />
            </div>
          </div>
        ) : (
          <>
            <div>
              <h1 className="text-3xl font-bold">Reset Password</h1>
            </div>
            <div className="mt-8">
              <p className="text-sm text-red-500">{error}</p>
              <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <div className="mt-4 mb-4">
                  <Input
                    label={"Password"}
                    placeholder="Password"
                    type={"password"}
                    name="password"
                    regProp={{ ...register("password") }}
                    error={errors.password?.message}
                  />
                </div>
                <div className="mt-4 mb-4">
                  <Input
                    label={"Confirm Password"}
                    placeholder="Confirm Password"
                    type={"password"}
                    name="confirmPassword"
                    regProp={{
                      ...register("confirmPassword"),
                    }}
                    error={errors.confirmPassword?.message}
                  />
                </div>
                <input
                  type="submit"
                  className="rounded bg-customPurple-900 w-full py-2 my-2 text-white cursor-pointer hover:bg-customPurple-800"
                  value="Submit"
                />
              </form>
            </div>
          </>
        )}
      </div>
      <Alert />
    </div>
  );
}

import React from "react";
import LoginImage from "../../assets/portraitLogin.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import axios from "axios";
import Input from "../../components/Global/Input";
import { useSearchParams } from "react-router-dom";
import Alert, {
  errorNotfiy,
  successNotfiy,
} from "../../components/Global/Toasts";

const schema = yup
  .object({
    email: yup.string().email().required(),
  })
  .required();
export default function ForgotPassword() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
    },
  });

  const mutation = useMutation({
    mutationFn: async (email) => {
      return await axios.post(
        process.env.REACT_APP_HOST + "/auth/forgot-password",
        email
      );
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (response, variables, context) => {
      if (response) {
        // notified
        if (response.data.code === 204) {
          successNotfiy("Reset password link sent to your email");
        } else {
          errorNotfiy(response.data.message);
        }
      } else {
        console.log("error");
      }
    },
  });

  const onSubmit = (data) => {
    mutation.mutate({
      email: data.email,
    });
  };
  return (
    <div>
      <div>
        <img
          className="inline max-w-full h-auto"
          src={LoginImage}
          alt="banner"
        />
      </div>
      <div className="text-left absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-20 bg-white bg-opacity-20 backdrop-blur-sm rounded drop-shadow-lg">
        <div>
          <h1 className="text-3xl font-bold">Forgot Password?</h1>
          <h4 className="text-sm font-light mt-2">
            Enter your company’s email and request a new password
          </h4>
        </div>
        <div className="mt-8">
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <div className="mt-4 mb-4">
              <Input
                label={"Company Email"}
                placeholder="Email"
                type={"email"}
                name="email"
                regProp={{ ...register("email") }}
                error={errors.email?.message}
              />
            </div>
            <input
              type="submit"
              className="rounded bg-customPurple-900 w-full py-2 my-2 text-white cursor-pointer hover:bg-customPurple-800"
              value="Send Request"
            />
          </form>
        </div>
      </div>
      <Alert />
    </div>
  );
}

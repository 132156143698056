import React from "react";
import Input from "../Global/Input";
import Select from "../Global/Select";

export default function UnitPrice({ register }) {
  return (
    <div className="grid md:grid-cols-3 gap-4 mt-4">
      <div>
        <Input
          label={"Unit Price (Full day)"}
          placeholder="$"
          type={"text"}
          name="fullDayPrice"
          regProp={{ ...register("fullDayPrice") }}
          //   error={errors.name?.message}
        />
      </div>
      <div>
        <Input
          label={"Unit Price (Half day)"}
          placeholder="$"
          type={"text"}
          name="halfDayPrice"
          regProp={{ ...register("halfDayPrice") }}
          //   error={errors.name?.message}
        />
      </div>
      <div>
        <Select
          label={"Overtime"}
          id="overtime"
          regProp={{ ...register("overtime") }}
          options={["1", "1.5", "2", "2.5", "3"]}
          // error={errors.status?.message}
        />
      </div>
    </div>
  );
}

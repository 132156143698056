import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactTable from "react-table-6";
import Chip, { PaymentChip } from "../../components/Global/Chip";
import { FaEdit } from "react-icons/fa";
import { serverPUT } from "../../HttpFunctions";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { useMutation } from "react-query";
import { successNotfiy } from "../../components/Global/Toasts";
import CheckHeader from "src/components/Admin/CheckHeader";
import { useAuth } from "src/context/Auth";
import StatusDropdown from "src/components/Admin/StatusDropdown";
import { TaskBillableHours } from "src/components/User/TimePickerComponent";
import PaymentToggler from "src/components/Admin/PaymentToggler";
import { APPROVED, DISAPPROVED, PENDING } from "src/utils/Constants";
import momentTimezone from "moment-timezone";
import Spinner from "src/components/Global/Spinner";
import { RiContactsBookLine } from "react-icons/ri";
import CardDetails from "../Dashboard/CardsDetails";

export type PMTask = {
  _id?: string;
  project: {
    _id: number;
    project_name: string;
    client: { name: string; _id: string };
  };
  type: { _id: number; name: string; is_billable: boolean };
  start_time: number;
  end_time: number;
  billable_minutes: number;
  duration_minutes: number;
  status: string;
  user: { _id: number; first_name: string; last_name: string; email: string };
  service?: { name: string; _id: string };
  is_paid: boolean;
  is_invoiced: boolean;
  timezone: string;
};

export default function PMApprovalTable({
  empTaskData,
  handleClick,
  refetch,
  pmApproval,
  onChangePMAproval,
  isLoading
}: {
  empTaskData: PMTask[];
  handleClick?: (data: any) => void;
  refetch: any;
  pmApproval: boolean;
  onChangePMAproval: (e) => void;
  isLoading;
}) {
  const [selectedRows, setSelectedRows] = useState([]);

  const { user } = useAuth();

  useEffect(() => {
    setSelectedRows([]);
  }, [empTaskData]);

  const statusMutation = useMutation({
    mutationFn: (body: any) => {
      return serverPUT(`/task/multiuserstatus`, body);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        return;
      }
      successNotfiy("Tasks have been updated successfully");
      setSelectedRows([]);
      refetch();
    },
  });

  function convertMinutesToHours(minutes: number) {
    let hours = Math.floor(minutes / 60);
    let remainingMinutes = minutes % 60;
    return (
      hours +
      ":" +
      remainingMinutes.toLocaleString([], { minimumIntegerDigits: 2 })
    );
  }

  function getBillableDate(minutes: number) {
    const date = moment();
    if (minutes === undefined) {
      date.hours(0);
      date.minutes(0);
    } else if (minutes === 1440) {
      date.hours(23);
      date.minutes(59);
    } else {
      const hours = minutes / 60;
      const mins = minutes % 60;
      date.hours(hours);
      date.minutes(mins);
    }
    return date;
  }

  const mutation = useMutation({
    mutationFn: ({ _id, billable_minutes }: any) => {
      return serverPUT(`/task/billablehours/${_id}`, {
        billable_minutes: billable_minutes,
      });
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      //showing message
      successNotfiy("Task has been updated successfully");
      refetch();
    },
  });

  const { mutate: paidMutate } = useMutation({
    mutationFn: (body: { taskIds: string[]; is_paid: boolean }) => {
      return serverPUT(`/task/paymentstatus`, { ...body });
    },
    onError: (error, variables, context: any) => {},
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        return;
      }
      successNotfiy("Tasks has been updated successfully");
      setSelectedRows([]);
      refetch();
    },
  });

  const { mutate: invoicedMutate } = useMutation({
    mutationFn: (body: { taskIds: string[]; is_invoiced: boolean }) => {
      return serverPUT(`/task/invoicestatus`, { ...body });
    },
    onError: (error, variables, context: any) => {},
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        return;
      }
      successNotfiy("Tasks has been updated successfully");
      setSelectedRows([]);
      refetch();
    },
  });

  const handleBillableHrChange = (id, e) => {
    const newMinutes = e.hours() * 60 + e.minutes();
    mutation.mutate({ _id: id, billable_minutes: newMinutes });
  };

  const toggleRowSelection = (row) => {
    const rowIndex = selectedRows.findIndex((r) => r === row.original._id);
    if (rowIndex === -1) {
      setSelectedRows([...selectedRows, row.original._id]);
    } else {
      setSelectedRows([
        ...selectedRows.slice(0, rowIndex),
        ...selectedRows.slice(rowIndex + 1),
      ]);
    }
  };

  const toggleSelectAll = (data) => {
    if (selectedRows.length === data.length) {
      setSelectedRows([]);
    } else {
      const ids = data.map((d) => d._id);
      setSelectedRows(ids);
    }
  };

  const checkHeader = useCallback(() => {
    return {
      width: 40,
      Cell: (row) => {
        return (
          <div className="text-center font-normal">
            <input
              type="checkbox"
              checked={selectedRows.some((r) => r === row.original._id)}
              onChange={() => toggleRowSelection(row)}
              className="accent-customPurple-400 bg-gray-100 border-gray-300 rounded"
            />
          </div>
        );
      },
    };
  }, [selectedRows, toggleRowSelection]);

  const handleBulkStatus = (status: number) => {
    statusMutation.mutate({ taskIds: selectedRows, status });
  };

  const handlePaymentStatus = (is_paid: boolean) => {
    paidMutate({
      taskIds: selectedRows,
      is_paid,
    });
  };

  const handleInvoicedStatus = (is_invoiced: boolean) => {
    invoicedMutate({
      taskIds: selectedRows,
      is_invoiced,
    });
  };

  const handleStatusChange = (id, status) => {
    let body = {
      taskIds: [id],
      status: status === APPROVED ? 1 : PENDING ? -1 : 0,
    };
    statusMutation.mutate(body);
  };

  const handlePaymentChange = (id, status) => {
    let body = {
      taskIds: [id],
      is_paid: status,
    };
    paidMutate(body);
  };

  const handleInvoiceChange = (id, status) => {
    let body = {
      taskIds: [id],
      is_invoiced: status,
    };
    invoicedMutate(body);
  };
  

  const columns = [
    checkHeader(),
    {
      Header: "Date",
      accessor: "date",
      width: 100,
      Cell: (row) => {
        return (
          <div className="text-center font-normal">
            <div>{moment(row.value).format("ddd")}</div>
            <div className="font-semibold">{row.value}</div>
          </div>
        );
      },
    },
    {
      Header: "Name",
      accessor: "employeeName",
    },
    {
      Header: "Task type",
      accessor: "taskType",
    },
    {
      Header: "Project",
      accessor: "project",
    },
    {
      Header: "Service",
      accessor: "service",
      Cell: (row) => <div>{row.value ? row.value : "-"}</div>,
    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Task Time",
      accessor: "timezone",
      Cell: ({ row }) => (
        <div className="py-4">
          <h6 className="text-xs">{row?.timezone}</h6>
          {row?.startTime} - {row?.endTime}
        </div>
      ),
    },
    {
      Header: "Start Time",
      width: 100,
      accessor: "startTime",
      show: false,
    },
    {
      Header: "End Time",
      width: 100,
      accessor: "endTime",
      show: false,
    },
    {
      Header: "Duration",
      width: 80,
      accessor: "totalHrs",
    },
    {
      Header: "Billable Hrs",
      width: 100,
      accessor: "billableHrs",
      Cell: (row) => {
        return (
          <div className="z-10">
            <div className="py-4">
              {/* <TimePicker
                value={getBillableDate(row.value)}
                focusOnOpen
                showSecond={false}
                clearIcon={<></>}
                showMinute={true}
                minuteStep={15}
                inputReadOnly
                onChange={(e) => handleBillableHrChange(row.original._id, e)}
                className="custom-time-picker"
              /> */}
              <TaskBillableHours
                index={0}
                getBillableDate={getBillableDate}
                taskId={row.original._id}
                billableHrs={row.value}
                handleBillableHrChange={handleBillableHrChange}
              />
            </div>
          </div>
        );
      },
    },
    {
      Header: "Paid",
      width: 80,
      accessor: "tasks",
      show: user?.type_of_user.includes("admin"),
      Cell: (row) => (
        <PaymentToggler
          row={row}
          handleChange={handlePaymentChange}
          singleTask={true}
        />
      ),
    },
    {
      Header: "Invoice",
      width: 80,
      accessor: "invoice",
      show: user?.type_of_user.includes("admin"),
      Cell: (row) => (
        <PaymentToggler
          row={row}
          handleChange={handleInvoiceChange}
          singleTask={true}
          isInvoice={true}
        />
      ),
    },
    {
      Header: "Status",
      width: 100,
      accessor: "status",
      Cell: (row) => (
        <StatusDropdown
          row={row}
          handleStatusChange={handleStatusChange}
          singleTask={true}
        />
      ),
    },
    {
      Header: "Actions",
      maxWidth: 100,
      Cell: ({ row }) => (
        <div className="grid grid-flow-col">
          <button onClick={() => handleClick(empTaskData[row._index])}>
            <FaEdit />
          </button>
        </div>
      ),
    },
  ];

  const data = useMemo(() => {
    return empTaskData?.map((task: PMTask) => {
      return {
        _id: task._id,
        date: moment(task.start_time).format("MM/DD/YY"),
        project: task.project?.project_name,
        service: task?.service?.name,
        client: task.project?.client?.name,
        taskType: task?.type?.name,
        employeeName: `${task.user?.first_name} ${task.user?.last_name}`,

        timezone: task?.timezone,
        startTime: task?.timezone
          ? momentTimezone(task.start_time)
              .tz(task.timezone || "America/New_York")
              .format(`hh:mm  A`)
          : new Date(task.start_time).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),

        endTime: task?.timezone
          ? momentTimezone(task.end_time)
              .tz(task.timezone || "America/New_York")
              .format("hh:mm  A")
          : new Date(task.end_time).toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            }),
        totalHrs: convertMinutesToHours(task?.duration_minutes),
        billableHrs: task?.billable_minutes,
        status: task?.status,
        isPaid: task?.is_paid,
        isInvoiced: task?.is_invoiced,
      };
    });
  }, [empTaskData]);

  if(isLoading){ return <Spinner/>}

  return  <>
      <CheckHeader
        checked={selectedRows?.length === data?.length}
        selectedRows={selectedRows}
        toggleSelectAll={() => toggleSelectAll(data)}
        handleBulkStatus={handleBulkStatus}
        handlePaymentStatus={handlePaymentStatus}
        handleInvoicedStatus={handleInvoicedStatus}
        pmApproval={pmApproval}
        onChangePMAproval={onChangePMAproval}
        disabled={data?.length == 0 || !data}
      />
      
      {data?.length > 0 && 
       <ReactTable
        columns={columns}
        data={data}
        className="rounded-lg"
        showPagination={false}
        minRows={data?.length ? data?.length : 5}
        style={{ maxHeight: "calc(100vh - 280px)" }}
        pageSize={data?.length}
      />}
    </>
  
}
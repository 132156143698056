import React, { useState, useContext, useEffect } from "react";
import LoginImage from "../../assets/login-page.png";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import axios from "axios";
import Input from "../../components/Global/Input";
import { useAuth } from "../../context/Auth";
import Alert from "../../components/Global/Toasts";
import Spinner from "src/components/Global/Spinner";

const schema = yup
  .object({
    email: yup.string().email().required(),
    password: yup.string().required(),
  })
  .required();

export default function Login() {
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { login } = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: async (user) => {
      return await axios.post(process.env.REACT_APP_HOST + "/auth/login", user);
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data, variables, context) => {
      const { data: response } = data;
      if (response.code === 200) {
        login(response.data);
      } else {
        setError(response.message);
      }
    },
  });

  const onSubmit = (data) => {
    setError("");
    let user = {
      email: data.email,
      password: data.password,
    };
    mutate(user);
  };

  if (isLoading) return <Spinner />;

  return (
    <div className="min-h-screen bg-white flex justify-center items-center">
      <div className="grid md:grid-cols-2 gap-4 w-full p-8">
        <div className="shadow-lg p-8 rounded sm:order-first order-last flex flex-col  justify-center h-full">
          <h1 className="text-3xl font-bold">Log In</h1>
          <h1 className="text-sm font-light">Sign in to access JPCL account</h1>
          <form onSubmit={handleSubmit(onSubmit)} className="w-full">
            <p className="text-sm text-red-500">{error}</p>
            <div className="mt-4 mb-2">
              <Input
                label={"Work Email"}
                placeholder="Email"
                type={"email"}
                name="email"
                regProp={{ ...register("email") }}
                error={errors.email?.message}
              />
            </div>
            <div className="mt-4 mb-2">
              <Input
                label={"Password"}
                placeholder="Password"
                type={"password"}
                name="password"
                regProp={{ ...register("password") }}
                error={errors.password?.message}
              />
            </div>
            <div className="mt-2 mb-2 text-xs text-gray-500 text-right cursor-pointer hover:underline">
              <Link to={"/forgot-password"}>Forgot Password</Link>
            </div>
            <input
              type="submit"
              className="rounded bg-customPurple-900 w-full py-2 my-2 text-white cursor-pointer hover:bg-customPurple-800"
              value="Login"
            />
          </form>
        </div>
        <div className="pl-10">
          <img className="inline" src={LoginImage} alt="banner" />
        </div>
      </div>
      <Alert />
    </div>
  );
}

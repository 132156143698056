import React from "react";
import { Link } from "react-router-dom";

export default function BreadCrumb({
  data,
}: {
  data: Array<{ name: string; link: string }>;
}) {
  return (
    <nav className="flex" aria-label="Breadcrumb">
      <ol className="inline-flex items-center space-x-1 md:space-x-3">
        {data.map((item, index) => {
          return (
            <li>
              <div className="flex items-center">
                {index === 0 ? null : (
                  <svg
                    aria-hidden="true"
                    className="w-6 h-6 text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                      clip-rule="evenodd"
                    ></path>
                  </svg>
                )}

                <Link to={item.link}>
                  <p
                    className={`ml-1 text-sm font-medium  md:ml-2 ${
                      index === data.length - 1
                        ? "text-gray-400"
                        : "text-customPurple-400"
                    } 
                    `}
                  >
                    {item.name}
                  </p>
                </Link>
              </div>
            </li>
          );
        })}
      </ol>
    </nav>
  );
}

import React, { useEffect, useMemo, useState } from "react";
import { default as ReactSelect, components } from "react-select";

const Option = (props) => {
  return (
    <div>
      <components.Option {...props}>
        <input
          type="checkbox"
          checked={props.isSelected}
          onChange={() => null}
        />{" "}
        <label>{props.label}</label>
      </components.Option>
    </div>
  );
};

const styles = {
  control: (styles) => ({
    ...styles,
    // width: "100%",
    minWidth: "11rem",
    maxWidth: "11rem",
  }),

  valueContainer: (provided, state) => ({
    ...provided,
    textOverflow: "ellipsis",
    maxWidth: "140px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    display: "flex",
    flexWrap: "nowrap",
  }),
  menu: () => ({
    zIndex: 9999,
    backgroundColor: "white",
  }),
};
type Info = {
  type: any;
  project: any;
  client: any;
  service: any;
};

type SelectProps = {
  value?: Info;
  handleChange: (selected: any, label: string) => void;
  projectOptions: any;
  clientOptions: any;
  typeOptions: any;
  serviceOptions: any;
  isDisabled: boolean;
};
export default function WeekViewDropdowns({
  value,
  handleChange,
  projectOptions,
  clientOptions,
  typeOptions,
  serviceOptions,
  isDisabled,
}: SelectProps) {
  const [selectedOptions, setSelectedOptions] = useState({
    type: "",
    service: "",
    client: "",
    project: "",
  });
  const [services, setServices] = useState([]);

  const clients = useMemo(
    () =>
      selectedOptions["project"]
        ? clientOptions.filter(
            (c) =>
              c.value === selectedOptions["project"]["client"] &&
              c.is_active === true
          )
        : [],
    [selectedOptions["project"]]
  );

  const projects = useMemo(
    () =>
      selectedOptions["client"]
        ? projectOptions.filter(
            (p) =>
              p.client === selectedOptions["client"]["value"] &&
              p.is_active === true
          )
        : [],
    [selectedOptions["client"]]
  );

  useEffect(() => {
    setSelectedOptions({
      ...selectedOptions,
      type: value.type,
      client: value.client,
      project: value.project,
      service: value.service,
    });
  }, [value]);

  useEffect(() => {
    const services =
      selectedOptions["project"] && selectedOptions["type"]
        ? serviceOptions.filter(
            (s) =>
              s.tasktype === selectedOptions["type"]["value"] &&
              s.project === selectedOptions["project"]["value"] &&
              s.is_active === true
          )
        : [];

    setServices(services);
  }, [selectedOptions["project"], selectedOptions["type"]]);

  const handleOnChange = (selected, type) => {
    setSelectedOptions({
      ...selectedOptions,
      [type]: selected,
    });
    // if (selected) {
    handleChange(selected, type);
    // }
  };

  return (
    <span className="flex gap-2">
      <ReactSelect
        options={typeOptions.filter((t) => t.is_active)}
        menuPortalTarget={document.body}
        styles={styles}
        value={selectedOptions["type"]}
        closeMenuOnSelect={false}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        isClearable
        isDisabled={isDisabled}
        components={{
          Option,
        }}
        placeholder={"Task type"}
        onChange={(s) => handleOnChange(s, "type")}
      />

      <ReactSelect
        options={clients.length > 0 ? clients : clientOptions}
        menuPortalTarget={document.body}
        styles={styles}
        value={selectedOptions["client"]}
        closeMenuOnSelect={false}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        isClearable
        isDisabled={isDisabled}
        components={{
          Option,
        }}
        placeholder={"Client"}
        onChange={(s) => handleOnChange(s, "client")}
      />
      <ReactSelect
        options={
          projects.length > 0
            ? projects
            : projectOptions.filter((p) => p.is_active)
        }
        menuPortalTarget={document.body}
        styles={styles}
        value={selectedOptions["project"]}
        isClearable
        closeMenuOnSelect={false}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        isDisabled={isDisabled}
        components={{
          Option,
        }}
        placeholder={"Project"}
        onChange={(s) => handleOnChange(s, "project")}
      />
      {/* {services.length > 0 && ( */}
      <ReactSelect
        options={services.length > 0 ? services : []}
        menuPortalTarget={document.body}
        styles={styles}
        value={selectedOptions["service"]}
        closeMenuOnSelect={false}
        isClearable
        isDisabled={isDisabled}
        controlShouldRenderValue={true}
        hideSelectedOptions={false}
        components={{
          Option,
        }}
        placeholder={"Service"}
        onChange={(s) => handleOnChange(s, "service")}
      />
      {/* )} */}
    </span>
  );
}

import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";
import ContentContainer from "src/components/Global/ContentContainer";
import { MdDelete } from "react-icons/md";
import { IoAddCircle } from "react-icons/io5";
import { useMutation, useQuery } from "react-query";
import { serverGET, serverPOST } from "../../HttpFunctions";
import { ButtonSpinner } from "src/components/Global/Spinner";
import Alert, { successNotfiy } from "../../components/Global/Toasts";

export default function Payroll() {
  const { data } = useQuery("payrolls", () => serverGET("/payroll/"));
  const [error, setError] = useState(false);
  const [dates, setDates] = useState([
    {
      start: null,
      end: null,
      creation: null,
    },
  ]);

  useEffect(() => {
    if (data?.payrolls?.length > 0) {
      const temp = data.payrolls.map((p) => {
        return {
          start: new Date(p.payroll_start),
          end: new Date(p.payroll_end),
          creation: new Date(p.timesheet_deadline),
        };
      });
      setDates(temp);
    }
  }, [data]);

  const handleDelete = (i) => {
    let d = [...dates];
    d.splice(i, 1);
    setDates(d);
  };

  const mutation = useMutation({
    mutationFn: (payroll: any) => {
      return serverPOST(`/payroll/`, payroll);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      successNotfiy("Payroll has been updated successfully");
    },
  });

  function handleSubmit() {
    const check = dates.filter((d) => !d.start || !d.end || !d.creation);
    if (check.length > 0) {
      setError(true);
      return;
    }
    const data = dates.map((d) => {
      return {
        payroll_start: d.start?.getTime(),
        payroll_end: d.end?.getTime(),
        timesheet_deadline: d.creation?.getTime(),
      };
    });

    mutation.mutate(data);
  }
  return (
    <ContentContainer>
      <div>
        {dates?.map((date, i) => {
          return (
            <div className="flex gap-6 my-4 items-end">
              <div>
                <h1 className="text-sm">Select Payroll Range</h1>
                <div className="flex">
                  <ReactDatePicker
                    selectsRange={true}
                    className={`
                     w-52 placeholder-gray-500 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block pl-2.5 p-2 customPicker`}
                    placeholderText="Select date range"
                    onChange={(date) => {
                      const [start, end] = date;
                      let startOfDay = null;
                      let endOfDay = null;
                      if (start) {
                        startOfDay = new Date(start);
                        startOfDay.setHours(23, 59, 59, 999);
                      }
                      if (end) {
                        endOfDay = new Date(end);
                        endOfDay.setHours(23, 59, 59, 999);
                      }

                      const d = [...dates];
                      d[i].start = start;
                      d[i].end = end;
                      setError(false);

                      setDates(d);
                    }}
                    startDate={dates[i].start}
                    endDate={dates[i].end}
                    isClearable={true}
                  />
                </div>
              </div>
              <div>
                <h1 className="text-sm">Select Creation Deadline</h1>
                <div className="flex">
                  <ReactDatePicker
                    selectsRange={false}
                    className={`
                      w-52 placeholder-gray-500 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block pl-2.5 p-2 customPicker`}
                    placeholderText="Select date range"
                    onChange={(date) => {
                      let endOfDay = null;
                      if (date) {
                        endOfDay = new Date(date);
                        endOfDay.setHours(23, 59, 59, 999);
                      }

                      const d = [...dates];
                      d[i].creation = endOfDay;
                      setError(false);
                      setDates(d);
                    }}
                    selected={dates[i].creation}
                    isClearable={true}
                  />
                </div>
              </div>
              {dates.length > 1 && (
                <MdDelete
                  onClick={() => handleDelete(i)}
                  className="text-customRed-200 cursor-pointer "
                />
              )}
            </div>
          );
        })}

        <IoAddCircle
          className="text-customPurple-400 cursor-pointer w-6 h-6"
          onClick={() => {
            setDates([...dates, { start: null, end: null, creation: null }]);
          }}
        />
      </div>
      {error && (
        <span className="text-sm text-customRed-200">Select All Dates</span>
      )}
      {/*footer*/}
      <div className="flex items-center p-6 rounded-b">
        <button
          className={`p-custom1 rounded-md text-sm hover:bg-customPurple-900 text-white border-2 border-customPurple-900 bg-customPurple-800  disabled:bg-customPurple-400 `}
          onClick={handleSubmit}
          disabled={mutation?.isLoading}
        >
          {mutation?.isLoading ? <ButtonSpinner /> : "Save"}
        </button>
      </div>
      <Alert />
    </ContentContainer>
  );
}

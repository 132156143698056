import React, {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { useLocalStorage } from "../hooks/useLocalStorage";
import { parseJwt } from "src/utils/CommonFunctions";

// export const AuthContext = createContext();
const AuthContext = createContext<any>({} as any);

export const AuthProvider = ({ children }: { children: any }) => {
  // const [user, setUser] = useLocalStorage("user", null);
  const [user, setUser] = useState<{}>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const storedAuth1 = localStorage.getItem("user");
    if (storedAuth1) {
      const user = JSON.parse(storedAuth1);
      setUser(user);
      if (user?.token) {
        const payload = parseJwt(user.token);
        const expiry = payload.exp;
        setLogoutTimer(expiry);
      }
    }
  }, []);

  // Function to set logout timer
  function setLogoutTimer(expiryTime) {
    const currentTime = Date.now();
    const timeLeft = expiryTime * 1000 - currentTime;

    if (timeLeft > 0) {
      setTimeout(() => {
        logout();
      }, timeLeft);
    } else {
      logout();
    }
  }

  const login = (currentUser) => {
    setUser(currentUser);
    localStorage.setItem("user", JSON.stringify(currentUser));
    navigate("/", { replace: true });
  };

  const updateUserInfo = (currentUser) => {
    setUser(currentUser);
    localStorage.setItem("user", JSON.stringify(currentUser));
  };

  const logout = () => {
    setUser(null);
    localStorage.removeItem("user");
  };

  const value = useMemo(
    () => ({
      isLogged: !!user,
      user,
      login,
      updateUserInfo,
      logout,
    }),
    [user]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext(AuthContext);
};

import { Dispatch, useEffect, useState } from "react";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import Alert from "../../components/Global/Toasts";
import { groupWeekViewType } from "../../utils/GroupTable";
import { useAuth } from "src/context/Auth";
import Dialogue from "src/components/Global/Dialogue";
import UserWeekView from "src/components/User/UserWeekView";
import { HiOutlineViewList } from "react-icons/hi";
import AddTimeLog from "../Task/AddTimeLog";
import CardDetails from "./CardsDetails";

export default function AdminTimelogWeek({
  handleViewChange,
  setSelectedTask,
  weeklyRefetch,
  data,
  getNextWeek,
  getPrevWeek,
  currentWeekStart,
  currentWeekEnd,
  loading,
  username,
  setUsername,
}: {
  handleViewChange: () => void;
  setSelectedTask: any;
  weeklyRefetch: any;
  data: any;
  getNextWeek: any;
  getPrevWeek: any;
  currentWeekStart: any;
  currentWeekEnd: any;
  loading: any;
  username: string;
  setUsername: Dispatch<React.SetStateAction<string>>;
}) {
  const [tasksData, setTasksData] = useState(null);
  const { user } = useAuth();
  const [addTasks, setAddTasks] = useState(false);
  // const [currentWeekStart, setCurrentWeekStart] = useState(
  //   moment()
  //     .day(6 - 7)
  //     .startOf("day")
  // );
  // const [shouldRefetch, setShouldRefetch] = useState(false);
  // const [currentWeekEnd, setCurrentWeekEnd] = useState(() =>
  //   moment()
  //     .day(6 - 7)
  //     .add(6, "days")
  //     .startOf("day")
  // );

  // const { data } = useQuery({
  //   queryKey: ["user-tasks", userData?.id, currentWeekStart.valueOf()],
  //   queryFn: () =>
  //     serverGET(
  //       `/auth/user/taskdetails?userId=${
  //         userData?.id
  //       }&startTime=${currentWeekStart.valueOf()}&endTime=${currentWeekEnd.valueOf()}`
  //     ),
  //   enabled: !!userData,
  // });

  useEffect(() => {
    gatherData();
  }, [data]);

  const gatherData = async () => {
    if (data && data?.tasks?.length > 0) {
      let groupedData = [];
      let tasks = [...data?.tasks];
      groupedData = groupWeekViewType(tasks, true);
      setTasksData({ count: data?.count, tasks: groupedData });
    } else {
      setTasksData({ count: 0, tasks: [] });
    }
    if (!username) {
      setUsername(data?.username);
    }
  };

  // const getNextWeek = () => {
  //   const nextWeekStart = currentWeekStart.clone().add(1, "weeks");
  //   const nextWeekEnd = currentWeekEnd.clone().add(1, "weeks");
  //   setCurrentWeekStart(nextWeekStart);
  //   setCurrentWeekEnd(nextWeekEnd);
  // };

  // const getPrevWeek = () => {
  //   const prevWeekStart = currentWeekStart.clone().subtract(1, "weeks");
  //   const prevWeekEnd = currentWeekEnd.clone().subtract(1, "weeks");
  //   setCurrentWeekStart(prevWeekStart);
  //   setCurrentWeekEnd(prevWeekEnd);
  // };

  // const handleDownload = async () => {
  //   downloadExcel(
  //     `/task/downloadWeek?start_time=${currentWeekStart.valueOf()}`
  //   );
  // };

  //   console.log("s", selectedTasks);
  return (
    <div>
      <div
        className={`block md:flex items-center justify-between pb-5 sticky top-0 sm:only:bg-customGrey-200 z-10 sm:only:py-1`}
      >
        <div className="flex justify-center items-center text-xl font-semibold">
          <p>Timesheet</p>
          {(!user?.type_of_user.includes("pm") ||
            user?.type_of_user.includes("admin")) && (
            <span>
              <button
                className="flex items-center ml-4 text-sm gap-2 text-customPurple-400 cursor-pointer hover:text-customPurple-900"
                onClick={() => handleViewChange()}
              >
                Switch to list view <HiOutlineViewList />
              </button>
            </span>
          )}
        </div>
        <div className="flex py-2">
          <div className="flex justify-left items-center px-2">
            {currentWeekStart.format("MMM DD YYYY")} -{" "}
            {currentWeekEnd.format("MMM DD YYYY")}
            {/* <FaCalendarAlt className="ml-2" /> */}
          </div>

          {/* <div>
            <button
              className="px-3 py-1 border border-customPurple-400 text-customPurple-400 rounded-md enabled:secondary-hover-effect disabled:border-gray-400 disabled:text-gray-400"
              onClick={handleDownload}
            >
              Export
            </button>
          </div> */}
          <div className="flex ml-auto items-center">
            <FaArrowLeft
              onClick={getPrevWeek}
              className="mr-4 cursor-pointer"
            />
            <FaArrowRight onClick={getNextWeek} className="cursor-pointer" />
          </div>
        </div>
      </div>

      <CardDetails data={data} showCards={[true, true, true, false]} />
      <UserWeekView
        currentWeekStart={currentWeekStart}
        data={tasksData}
        setTasksData={setTasksData}
        setSelectedTasks={setSelectedTask}
        isAdmin={true}
        refetch={weeklyRefetch}
        loading={loading}
      />

      {addTasks ? (
        <Dialogue
          handleClose={() => setAddTasks(false)}
          title="Add Task"
          description="Add tasks here"
        >
          <AddTimeLog />
        </Dialogue>
      ) : null}
      <Alert />
    </div>
  );
}

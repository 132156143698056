import React, { createElement, useState } from "react";
import ProjectTable from "../../components/Admin/ProjectTable";
import { useQuery, useQueryClient } from "react-query";
import { serverGET } from "../../HttpFunctions";
import Pagination from "../../components/Global/Pagination";
import EditProject from "../../components/Admin/EditProject";
import { useNavigate, useParams } from "react-router";
import Alert from "../../components/Global/Toasts";
import { useSearchParams } from "react-router-dom";
import { SecondaryIconButton } from "../../components/Global/Button";
import { FaEdit, FaPlus } from "react-icons/fa";
import AddProject from "../../components/Admin/AddProject";
import ContentContainer from "../../components/Global/ContentContainer";
import Spinner from "src/components/Global/Spinner";
import BreadCrumb from "src/components/Global/BreadCrumb";
import { downloadCall } from "src/utils/DownloadExcel";

export default function ProjectList({
  isClientProjects,
}: {
  isClientProjects?: boolean;
}) {
  const [page, setPage] = useState(0);
  const [editProject, setEditProject] = useState(null);
  const queryClient = useQueryClient();
  const [openDialog, setOpenDialog] = useState(false);
  const [searchParams] = useSearchParams();
  const client = searchParams.get("client");
  const projectName = client ? client + "'s" + " " + "Projects" : "Projects";
  const navigate = useNavigate();

  const { id } = useParams();
  let path = "/project/";
  if (isClientProjects) {
    path = path + `/clientprojects/${id}`;
  }

  const { isLoading,isError, status, data, error } = useQuery({
    queryKey: ["projects"],
    queryFn: () => serverGET(path),
    // keepPreviousData: true,
    // staleTime: Infinity,
    // cacheTime: Infinity,
  });

  // useEffect(() => {
  //   if (!isPreviousData) {
  //     queryClient.prefetchQuery({
  //       queryKey: ["projects", page + 1],
  //       queryFn: () => serverGET(`/project?page=${page + 1}`),
  //     });
  //   }
  // }, [data, isPreviousData, page, queryClient]);

  if (isLoading) {
    // return createElement("div", null, "Loading....");
    return <Spinner />;
  }

  if (error) {
    return createElement("div", null, "Something went wrong");
  }

  // if (data?.constructor === String) {
  //   return <h1 className="text-lg text-center w-full mt-4">{data}</h1>;
  // }

  const handleClick = (index) => {
    setEditProject(data?.projects[index]);
  };

  const handleExport = async () => {
    downloadCall(
      process.env.REACT_APP_HOST + "/project/download",
      "projects.xls"
    );
  };

  return (
    <ContentContainer>
      {!isClientProjects ? null : (
        <div className="capitalize text-xl py-2">
          <BreadCrumb
            data={[
              { name: "Clients", link: "/dashboard/client" },
              { name: projectName, link: "#" },
            ]}
          />
        </div>
      )}
      <div className="flex items-center">
        <h1 className="text-xl  font-semibold capitalize">{projectName}</h1>

        <div className="ml-auto flex py-2">
          <div className="flex justify-left items-center px-2 gap-2">
            <SecondaryIconButton text="Export" onClick={handleExport} />
            <SecondaryIconButton
              text="Project"
              icon={<FaPlus />}
              onClick={() => {
                navigate("/dashboard/project/create");
              }}
            />
          </div>
        </div>
      </div>
      {data?.constructor === String || !data ? (
        <h1 className="text-lg text-center w-full mt-4">{data}</h1>
      ) : (
        <ProjectTable data={data} handleClick={handleClick} clientId={id} />
      )}
      {!!editProject && (
        <EditProject
          editProject={editProject}
          setEditProject={setEditProject}
          clientId={id}
        />
      )}
      {!openDialog ? null : (
        <AddProject handleClose={() => setOpenDialog(false)} clientId={id} />
      )}
      <Alert />
    </ContentContainer>
  );
}

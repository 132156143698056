import React, { useState } from "react";
import Dialogue from "../Global/Dialogue";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import { serverPOST } from "../../HttpFunctions";
import Alert, {
  errorNotfiy,
  successNotfiy,
} from "../../components/Global/Toasts";
import Input, { PasswordInput } from "../Global/Input";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Select from "../Global/Select";
import { ACTIVE, ARCHIVED, PM, USER } from "src/utils/Constants";
import { RoleSelect } from "./EditEmployee";

export const schema = yup
  .object({
    empId: yup.string().required(),
    firstname: yup.string().required().max(16),
    lastname: yup.string().required().max(16),
    email: yup.string().email().required(),
    phone: yup.string().required().min(8),
    status: yup.string().required(),
    userTypes: yup
      .array()
      .of(yup.string().required())
      .required()
      .min(1, "At least one userTypes is required"),
    password: yup.string().required().min(8),
    cpassword: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();

export default function AddEmployee({
  handleClose,
  refetch,
}: {
  handleClose: () => void;
  refetch: any;
}) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      empId: "",
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      password: "",
      cpassword: "",
      status: ACTIVE,
      userTypes: [USER],
    },
  });

  const mutation = useMutation({
    mutationFn: (user: any) => {
      return serverPOST("/auth/register", user);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data: any, variables, context) => {
      if (data && data.constructor === String) {
        errorNotfiy("A user with this email/emp id already exists");
      } else {
        reset();
        refetch();
        successNotfiy("The user account has been created successfully");
        handleClose();
      }
    },
  });

  const onSubmit = (data) => {
    let user = {
      emp_id: data.empId,
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      phone_number: data.phone,
      password: data.password,
      type_of_user: data.userTypes,
    };

    mutation.mutate(user);
  };

  const handleTypeChange = (selected) => {
    setValue(
      "userTypes",
      selected.map((x) => x.value)
    );
  };

  return (
    <Dialogue title="Add Team Member" handleClose={handleClose} central={true}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="p-4">
          <div className="grid md:grid-cols-1 my-2">
            <Input
              label={"Employee ID"}
              placeholder="Employee ID"
              type={"text"}
              name="empId"
              regProp={{ ...register("empId") }}
              error={errors.empId?.message}
            />
          </div>
          <div className="grid md:grid-cols-2 gap-4 my-2">
            <div>
              <Input
                label={"First Name"}
                placeholder="First Name"
                type={"text"}
                name="firstname"
                regProp={{ ...register("firstname") }}
                error={errors.firstname?.message}
              />
            </div>
            <div>
              <Input
                label={"Last Name"}
                placeholder="Last Name"
                type={"text"}
                name="lastname"
                regProp={{ ...register("lastname") }}
                error={errors.lastname?.message}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-4 my-2">
            <div className="my-2">
              <Input
                label={"Email"}
                placeholder="Email"
                type={"email"}
                name="email"
                regProp={{ ...register("email") }}
                error={errors.email?.message}
              />
            </div>
            <div className="my-2">
              <Input
                label={"Phone Number"}
                placeholder="Phone Number"
                type={"text"}
                name="phone"
                regProp={{ ...register("phone") }}
                error={errors.phone?.message}
              />
            </div>
          </div>
          <div className="grid md:grid-cols-2 gap-4 my-2 capitalize">
            <div>
              <RoleSelect
                handleTypeChange={handleTypeChange}
                userTypes={watch("userTypes")}
                errors={errors.userTypes?.message}
              />
            </div>
            <div>
              <Select
                label={"Status"}
                id="status"
                regProp={{ ...register("status") }}
                options={[ACTIVE, ARCHIVED]}
                error={errors.status?.message}
                style={{
                  height: "37px",
                  marginTop: "8px",
                }}
              />
            </div>
          </div>

          <div className="grid md:grid-cols-2 gap-4 my-2">
            <div className="my-2">
              <PasswordInput
                label={"Password 8+"}
                placeholder="********"
                type={"password"}
                name="password"
                regProp={{ ...register("password") }}
                error={errors.password?.message}
              />
            </div>
            <div className="my-2">
              <PasswordInput
                label={"Confirm Password"}
                placeholder="********"
                type={"password"}
                name="cpassword"
                regProp={{ ...register("cpassword") }}
                error={errors.cpassword?.message}
              />
            </div>
          </div>
        </div>
        <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <PrimaryButton text="Create" />
        </div>
      </form>
    </Dialogue>
  );
}

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import Autocomplete from "react-autocomplete";
import { useMutation, useQueryClient } from "react-query";
import { serverPUT } from "../../HttpFunctions";
import { successNotfiy } from "../../components/Global/Toasts";
import { useAuth } from "src/context/Auth";
import Spinner, { ButtonSpinner } from "../Global/Spinner";
export default function EditClientProject({
  projectData,
  clientData,
  selectedTask,
  page,
  optionsSelected,
  setSelectedTask,
  taskTypeData,
  refetch,
  serviceData,
}: {
  projectData: any;
  clientData: any;
  selectedTask: any;
  page: number;
  optionsSelected: any;
  setSelectedTask: any;
  refetch: any;
  taskTypeData?: any;
  serviceData?: any;
}) {
  const queryClient = useQueryClient();

  const [project, setProject] = useState(
    () => selectedTask.project?.project_name
  );
  const [client, setClient] = useState(selectedTask.project?.client?.name);
  const [services, setServices] = useState([]);

  const [filteredProjects, setFilteredProjects] = useState([]);
  const [type, setType] = useState(() => selectedTask.type?.name);
  const [service, setService] = useState(() => selectedTask.service?.name);
  const { user } = useAuth();

  const [projectDetail, setProjectDetail] = useState(
    () =>
      selectedTask.project ?? {
        project_name: "",
        client: { name: "", _id: "" },
        _id: "",
      }
  );
  const [typeDetail, setTypeDetail] = useState(
    () =>
      selectedTask.type ?? {
        name: "",
        is_billable: null,
      }
  );
  const editMutation = useMutation({
    mutationFn: (task: any) => {
      return serverPUT(`/task/updateProjectClient/${selectedTask._id}`, task);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      //for admin this api fetches data again
      if (refetch) {
        successNotfiy("Tasks is updated successfully");
        refetch();
        return;
      }
      //for pm it updates on FE
      if (updatedData.isHide && user.type_of_user === "pm") {
        queryClient.setQueryData(
          ["pm-tasks", page, optionsSelected],
          (oldData: any) => {
            const temp = [...oldData?.tasks];
            const taskToRemove = temp?.find(
              (singleTask) => singleTask._id == updatedData?.task?._id
            );
            const index = temp.indexOf(taskToRemove);
            if (index != -1) {
              temp.splice(index, 1);
            }
            return {
              ...oldData,
              tasks: temp,
            };
          }
        );
        // setSelectedTask(null);
        successNotfiy("Task is moved under the tasks of new project manager.");
      } else {
        console.log("task remain with in same pm");

        queryClient.setQueryData(
          ["pm-tasks", page, optionsSelected],
          (oldData: any) => {
            return {
              ...oldData,
              tasks: oldData?.tasks?.map((data) => {
                return data._id === updatedData.task._id
                  ? {
                      ...data,
                      project: updatedData.task.project,
                      type: updatedData.task.type,
                      service: updatedData.task.service,
                    }
                  : data;
              }),
            };
          }
        );
        successNotfiy("Task is updated successfully");
      }
    },
  });
  useEffect(() => {
    const servicesTemp =
      project && type
        ? serviceData?.services?.filter(
            (s) =>
              s.tasktype === typeDetail?._id && s.project === projectDetail?._id
          )
        : [];

    setServices(servicesTemp);
  }, [projectDetail, typeDetail]);

  useEffect(() => {
    if (selectedTask) {
      if (projectDetail._id) {
        if (services?.length > 0 && !service) {
          console.log("fill data");
          return;
        }
        const task = {
          project: projectDetail._id,
          project_manager:
            projectDetail?.project_manager?.length > 0
              ? projectDetail?.project_manager[0]?._id
              : undefined,
          service: null,
        };
        const taskToCompare = selectedTask?.project?._id === projectDetail._id;
        if (taskToCompare) {
          console.log("not changed");
        } else {
          editMutation.mutate(task);
        }
      }
    }
  }, [services]);

  const handleServiceClick = (val) => {
    setService(val);
    const s = serviceData?.services?.find((t) => t.name === val);
    if (selectedTask && s) {
      if (projectDetail._id && typeDetail.name) {
        const body = {
          project: projectDetail._id,
          project_manager:
            projectDetail?.project_manager?.length > 0
              ? projectDetail?.project_manager[0]?._id
              : undefined,
          type: {
            name: typeDetail.name,
            is_billable: typeDetail.is_billable,
          },
          service: s._id,
        };
        const taskToCompare = selectedTask?.service?._id === s._id;
        if (taskToCompare) {
          console.log("not changed");
        } else {
          editMutation.mutate(body);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedTask) {
      if (typeDetail.name) {
        if (services?.length > 0 && !service) {
          console.log("fill data");
          return;
        }
        const body = {
          type: {
            name: typeDetail.name,
            is_billable: typeDetail.is_billable,
          },
          service: null,
        };
        const taskToCompare = selectedTask?.type?.name === typeDetail.name;
        if (taskToCompare) {
          console.log("not changed");
        } else {
          editMutation.mutate(body);
        }
      }
    }
  }, [services]);

  useEffect(() => {
    if (type) {
      const t = taskTypeData?.taskTypes?.find((t) => t.name === type);

      if (t) {
        setTypeDetail(t);
      } else {
        setTypeDetail({ name: "", is_billable: null });
      }
    } else {
      setTypeDetail({ name: "", is_billable: null });
    }
  }, [type]);

  useEffect(() => {
    if (project) {
      const p = projectData?.projects.find((p) => p.project_name === project);
      if (p) {
        setProjectDetail(p);
        setClient(p.client.name);
      } else {
        setProjectDetail({ project_name: "", client: "", _id: "" });
        // setClient("");
      }
    } else {
      setProjectDetail({ project_name: "", client: "", _id: "" });
    }
  }, [project]);

  useEffect(() => {
    if (client) {
      const p = projectData?.projects.filter((p) => {
        if (p.client.name === client) {
          return {
            _id: p._id,
            project_name: p.project_name,
            client: p.client.name,
          };
        }
      });

      if (p) {
        setFilteredProjects(p);
      }
    } else {
      setProject("");
    }
  }, [client]);

  const handleType = (value: string) => {
    setService("");
    setType(value);
  };
  const handleProject = (value: string) => {
    setService("");
    setProject(value);
  };

  return editMutation.isLoading ? (
    <ButtonSpinner />
  ) : (
    <>
      <div className="w-80">
        <h2 className="text-sm">Task Type</h2>

        {taskTypeData && (
          <Autocomplete
            getItemValue={(item) => item?.name}
            items={taskTypeData?.taskTypes}
            shouldItemRender={(item, value) =>
              item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
            }
            wrapperStyle={{ display: "block", position: "relative" }}
            inputProps={{
              placeholder: "Select task type",
              className:
                "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
            }}
            renderItem={(item, highlighted) => (
              <div
                key={item.name}
                style={{
                  backgroundColor: highlighted ? "#eee" : "transparent",
                }}
                className="p-2"
              >
                {item.name}
              </div>
            )}
            value={type}
            onChange={(e) => handleType(e.target.value)}
            onSelect={(val) => handleType(val)}
            menuStyle={{
              zIndex: 999,
              boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
              position: "absolute",
              left: 0,
              top: 44,
              backgroundColor: "white",
              borderRadius: "8px",
              overflow: "auto",
              maxHeight: "200px",
            }}
          />
        )}
      </div>

      {services?.length > 0 && (
        <div className="w-80">
          <h2 className="text-sm">Service</h2>
          <Autocomplete
            getItemValue={(item) => item?.name}
            items={services.length > 0 ? services : []}
            shouldItemRender={(item, value) =>
              item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
            }
            wrapperStyle={{ display: "block", position: "relative" }}
            inputProps={{
              placeholder: "Select service",
              className: `border ${
                services?.length > 0 && !service
                  ? "border-red-400"
                  : "border-gray-300"
              } first-letter:uppercase bg-white text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5`,
            }}
            renderItem={(item, highlighted) => (
              <div
                key={item.name}
                style={{
                  backgroundColor: highlighted ? "#eee" : "transparent",
                }}
                className="p-2"
              >
                {item.name}
              </div>
            )}
            value={service}
            onChange={(e) => handleServiceClick(e.target.value)}
            onSelect={(val) => handleServiceClick(val)}
            menuStyle={{
              zIndex: 999,
              boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
              position: "absolute",
              left: 0,
              top: 44,
              backgroundColor: "white",
              borderRadius: "8px",
              overflow: "auto",
              maxHeight: "200px",
            }}
          />
        </div>
      )}
      <div className="w-80">
        <h2 className="text-sm">Client</h2>

        {clientData && (
          <Autocomplete
            getItemValue={(item) => item?.name}
            items={clientData?.clients ?? []}
            shouldItemRender={(item, value) =>
              item?.name?.toLowerCase()?.indexOf(value?.toLowerCase()) > -1
            }
            wrapperStyle={{ display: "block", position: "relative" }}
            inputProps={{
              placeholder: "Select a client",
              className:
                "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
            }}
            renderItem={(item, highlighted) => (
              <div
                key={item?._id}
                style={{
                  backgroundColor: highlighted ? "#eee" : "transparent",
                }}
                className="p-2"
              >
                {item?.name}
              </div>
            )}
            value={client}
            onChange={(e) => setClient(e.target.value)}
            onSelect={(val) => setClient(val)}
            menuStyle={{
              zIndex: 999,
              boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
              position: "absolute",
              left: 0,
              top: 44,
              backgroundColor: "white",
              borderRadius: "8px",
              overflow: "auto",
              maxHeight: "200px",
            }}
          />
        )}
      </div>

      <div className="w-80">
        <h2 className="text-sm">Project name</h2>

        {projectData && (
          <Autocomplete
            getItemValue={(item) => item?.project_name}
            items={
              client
                ? filteredProjects.length > 0
                  ? filteredProjects
                  : projectData?.projects
                : projectData?.projects
            }
            shouldItemRender={(item, value) =>
              item?.project_name?.toLowerCase().indexOf(value?.toLowerCase()) >
              -1
            }
            wrapperStyle={{
              display: "block",
              position: "relative",
            }}
            inputProps={{
              placeholder: "Select a project",
              className:
                "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
            }}
            renderItem={(item, highlighted) => (
              <div
                key={item?.id}
                style={{
                  backgroundColor: highlighted ? "#eee" : "transparent",
                }}
                className="p-2"
              >
                {item?.project_name}
              </div>
            )}
            value={project}
            onChange={(e) => handleProject(e.target.value)}
            onSelect={(val) => handleProject(val)}
            menuStyle={{
              zIndex: 999,
              boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
              position: "absolute",
              left: 0,
              top: 44,
              backgroundColor: "white",
              borderRadius: "8px",
              overflow: "auto",
              maxHeight: "200px",
            }}
          />
        )}
      </div>
    </>
  );
}

import React, { useState } from "react";
import ReactTable from "react-table-6";
import { SecondaryIconButton } from "../Global/Button";
import { FaEdit, FaPlus } from "react-icons/fa";
import AddProject from "./AddProject";
import Chip from "../Global/Chip";
import {
  ACTIVE,
  APPROVED,
  ARCHIVED,
  DISAPPROVED,
  PENDING,
  REVISED,
} from "../../utils/Constants";
import { useNavigate, useSearchParams } from "react-router-dom";

type ProjectTableProps = {
  data: any;
  handleClick?: (project) => void;
  clientId?: string;
  isPm?: boolean;
};

export default function ProjectTable({
  data,
  handleClick,
  clientId,
  isPm,
}: ProjectTableProps) {
  const navigate = useNavigate();

  const columns = [
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Client",
      accessor: "client",
    },
    {
      Header: "Project Manager",
      accessor: "pm",
      show: !isPm,
      Cell: (row) => (
        <div>
          <div className="py-2">
            {row.original?.pm?.length > 0 ? (
              row.original?.pm?.map((pm) => (
                <h3 className="p-1 bg-gray-200 border border-gray-100 rounded-md capitalize">
                  {pm.first_name + " " + pm.last_name}
                </h3>
              ))
            ) : (
              <h3>none</h3>
            )}
          </div>
        </div>
      ),
      // Cell: (row) => {
      //   return (
      //     <div>
      //       <div className="py-2">
      //         <h3 className="p-1 bg-gray-200 border border-gray-100 rounded-md capitalize">
      //           {row.value}
      //         </h3>
      //       </div>
      //     </div>
      //   );
      // },
    },
    // {
    //   Header: "Hours Spent",
    //   accessor: "totalHrs",
    // },
    // {
    //   Header: "Budget",
    //   accessor: "budget",
    // },
    {
      Header: "Status",
      accessor: "status",
      // show: !!clientId,
      show: !isPm,

      Cell: (row) => (
        <div>
          <div
            className="py-2"
            // onClick={() => console.log("roww", row.original.status)}
          >
            <Chip
              color={
                row.original?.status
                  ? "bg-paleGreen-100 text-paleGreen-200"
                  : "bg-customRed-100 text-customRed-200"
              }
              title={row.original?.status ? APPROVED : DISAPPROVED}
            />
          </div>
        </div>
      ),
    },
    {
      Header: "Actions",
      maxWidth: 100,
      // show: !!clientId,
      show: !isPm,

      Cell: ({ row }) => (
        <div>
          <FaEdit
            className={"text-black cursor-pointer"}
            onClick={() => {
              navigate(
                `/dashboard/project/${data?.projects[row?._index]?._id}`
              );
              // handleClick(row._index);
            }}
          />
        </div>
      ),
    },
  ];

  const tableData = data.projects.map((project) => {
    return {
      name: project.project_name,
      client: project.client.name,
      // pm: project?.project_manager
      //   ? project.project_manager.first_name +
      //     " " +
      //     project.project_manager.last_name
      //   : "none",
      pm: project?.project_manager,
      // pm:
      //   project?.project_manager.length > 0
      //     ? project?.project_manager[0]?.first_name +
      //       " " +
      //       project?.project_manager[0]?.last_name
      //     : "none",

      status: project.is_active,
    };
  });

  return (
    <div className="">
      <ReactTable
        data={tableData}
        columns={columns}
        showPagination={false}
        pageSize={tableData.length}
        style={{ maxHeight: "calc(100vh - 160px)" }}
      />
    </div>
  );
}

import React, { useRef, useState } from "react";
import { PaymentChip } from "../Global/Chip";

export default function PaymentToggler({
  row,
  handleChange,
  singleTask,
  isInvoice,
  invoice,
}: {
  row;
  handleChange;
  singleTask?: boolean;
  isInvoice?: boolean;
  invoice?: string;
}) {
  return (
    <div>
      {singleTask ? (
        <ChipDropdown
          index={0}
          id={row.original._id}
          status={isInvoice? row.original.isInvoiced : row.original.isPaid}
          invoice = {isInvoice ? "invoice" : "not"}
          handleChange={handleChange}
        />
      ) : (
        row.value?.map((task, index) => (
          <ChipDropdown
            index={index}
            id={task._id}
            status={isInvoice? task.isInvoiced : task.isPaid}
            invoice = {isInvoice ? "invoice" : "not"}
            handleChange={handleChange}
          />
        ))
      )}
    </div>
  );
}

function ChipDropdown({ index, status, invoice, id, handleChange }) {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef: any = useRef();

  const handleMouseEnter = () => {
    clearTimeout(timeoutRef.current);
    setIsOpen(true);
  };

  const handleMouseLeave = () => {
    // Add a slight delay before hiding the content
    timeoutRef.current = setTimeout(() => {
      setIsOpen(false);
    }, 200); // Adjust the delay (in milliseconds) as needed
  };
  return (
    <div
      key={index}
      className="py-1.5 relative z-1"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isOpen ? (
        <ul className="flex gap-2">
          <PaymentChip
            paid={true}
            sicon={invoice}
            bgColor={status ? "bg-paleGreen-100" : "bg-transparent"}
            onClick={() => handleChange(id, true)}
          />
          <PaymentChip
            paid={false}
            sicon={invoice}
            bgColor={status ? "bg-transparent" : "bg-customRed-100"}
            onClick={() => handleChange(id, false)}
          />
        </ul>
      ) : (
        <PaymentChip
          paid={status}
          sicon={invoice}
          bgColor={status ? "bg-paleGreen-100" : "bg-customRed-100"}
        />
      )}
    </div>
  );
}

import moment from "moment";
import TimePicker from "rc-time-picker";
import React from "react";

export default function TaskTimeCalculator({
  startTime,
  endTime,
  startTimeOnChange,
  endTimeOnChange,
  duration,
}: {
  startTime: Date;
  endTime: Date;
  startTimeOnChange: (time, timeType) => void;
  endTimeOnChange: (time, timeType) => void;
  duration: string;
}) {
  return (
    <div className="grid md:grid-cols-3 col-span-2 gap-8 ">
      <div>
        <label className="block">
          <span className="block text-sm text-slate-700 font-medium">
            Start Time
          </span>

          <TimePicker
            value={moment(startTime)}
            focusOnOpen
            showSecond={false}
            clearIcon={<></>}
            showMinute={true}
            minuteStep={15}
            use12Hours
            inputReadOnly
            onChange={(time) => startTimeOnChange(time, "startTime")}
            className="custom-time-picker"
          />
        </label>
      </div>

      <div>
        <label className="block">
          <span className="block text-sm text-slate-700 font-medium">
            End Time
          </span>

          <TimePicker
            value={moment(endTime)}
            focusOnOpen
            clearIcon={<></>}
            showSecond={false}
            showMinute={true}
            minuteStep={15}
            use12Hours
            inputReadOnly
            onChange={(time) => endTimeOnChange(time, "endTime")}
            className="custom-time-picker"
          />
        </label>
      </div>

      <div>
        <label className="block">
          <span className="block text-sm text-slate-700 font-medium">
            Duration
          </span>
          <input
            className="placeholder:text-slate-400 block bg-white w-full border border-slate-300 rounded-md py-2.5 pl-3 pr-3
              shadow-sm  sm:text-sm disabled:opacity-50 disabled:bg-customGrey-100"
            type={"text"}
            name={"duration"}
            disabled={true}
            value={duration}
          />
        </label>
      </div>
    </div>
  );
}

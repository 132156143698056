import { useEffect, useRef, useState } from "react";
import { useQuery } from "react-query";
import { serverGET } from "../HttpFunctions";

export default function useClientProjects(userData?, optionsSelected?) {
  const [projectOptions, setProjectOptions] = useState([]);
  const [clientOptions, setClientOptions] = useState([]);
  const clientRef = useRef([]);
  const projectRef = useRef([]);

  let url =
    userData && userData.id
      ? `/project/userprojects?userId=${userData?.id}`
      : `/project/userprojects?userId=${userData?._id}`;

  const { data: projectData } = useQuery({
    queryKey: ["user-projects"],
    queryFn: () => serverGET(url),
    enabled: !!userData,
  });

  useEffect(() => {
    if (projectData?.distinctProjects?.length > 0) {
      const formattedProjects = projectData?.distinctProjects.map((pro) => {
        return {
          value: pro?.project?._id,
          label: pro?.project?.project_name,
          client: pro?.project?.client?._id,
        };
      });

      const formattedClients = projectData?.distinctProjects.map((pro) => {
        return {
          value: pro?.project?.client?._id,
          label: pro?.project?.client?.name,
          project: pro?.project?._id,
        };
      });
      let renderClients = [
        ...new Set(formattedClients?.map((obj) => obj?.value)),
      ].map((id) => {
        return formattedClients?.find((obj) => obj?.value === id);
      });

      setProjectOptions(formattedProjects);
      setClientOptions(renderClients);
      clientRef.current = formattedClients;
      projectRef.current = formattedProjects;
    }
  }, [projectData]);

  useEffect(() => {
    if (clientRef.current !== undefined) {
      const project = [...optionsSelected?.project];

      if (project.length > 0) {
        let filtered = clientRef.current.filter((item) =>
          project.includes(item.project.toString())
        );

        setClientOptions(filtered);
      } else {
        let renderClients = [
          ...new Set(clientRef.current?.map((obj) => obj?.value)),
        ].map((id) => {
          return clientRef.current?.find((obj) => obj?.value === id);
        });
        setClientOptions(renderClients);
      }
    }
  }, [optionsSelected?.project]);

  useEffect(() => {
    if (projectRef.current !== undefined) {
      const client = [...optionsSelected?.client];
      if (client.length > 0) {
        let filtered = projectRef.current.filter((item) =>
          client.includes(item.client.toString())
        );

        setProjectOptions(filtered);
      } else {
        setProjectOptions(projectRef.current);
      }
    }
  }, [optionsSelected?.client]);

  return [projectOptions, clientOptions];
}

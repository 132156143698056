import React from "react";
import { DestructiveButton, SecondaryButton } from "./Button";

type DeleteDialogProps = {
  handleClose: () => void;
  handleDelete: () => void;
  text?: string;
  title?: string;
};

export default function DeleteDialog({
  handleClose,
  handleDelete,
  text,
  title,
}: DeleteDialogProps) {
  return (
    <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl bg-white shadow-xl rounded-lg">
        {/*content*/}
        <div className="border-0 rounded-lg relative flex flex-col w-full  outline-none focus:outline-none">
          {/*header*/}
          <div className="flex items-center flex-col justify-center p-5 border-b border-solid border-slate-200 rounded-t">
            <button
              className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={handleClose}
            >
              <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
            <h3 className="text-xl font-semibold">
              {title ?? "Delete Leave Request"}
            </h3>
            <h5 className="text-sm">
              {text ??
                "Are you sure you want to delete/undo the leave request? "}
            </h5>
            <h5 className="text-sm italic text-red-600">
              This action is permanent and cannot be undone!{" "}
            </h5>
          </div>
        </div>
        <div className="flex items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <DestructiveButton text={"Delete"} onClick={handleDelete} />
        </div>
      </div>
    </div>
  );
}

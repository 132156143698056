import React from "react";
import ReactSelect from "react-select";
import Select from "react-select";
import { Option } from "../Global/MultiSelect";

import makeAnimated from "react-select/animated";
type Props = {
  data: Array<{ label: String; value: String }>;
  handlePmChange: any;
  pmNames?: Array<{ label: String; value: String }>;
};
export default function ManagerAutoComplete({
  data,
  handlePmChange,
  pmNames,
}: Props) {
  return (
    <ReactSelect
      options={data}
      styles={{
        control: (styles) => ({
          ...styles,
          borderRadius: 8,
        }),
        multiValue: (styles, { data }) => ({
          ...styles,
          minWidth: "70px",
          maxWidth: "120px",
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
          display: "flex",
          flexWrap: "nowrap",
        }),
      }}
      value={pmNames}
      // defaultValue={[]}
      isMulti
      closeMenuOnSelect={false}
      controlShouldRenderValue={true}
      hideSelectedOptions={false}
      components={{
        Option,
      }}
      placeholder={"Project Manager"}
      onChange={handlePmChange}
    />
  );
}

import React, { createElement, useEffect, useState } from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { serverPUT, serverPOST, serverDELETE } from "../../HttpFunctions";
import UserTable from "../../components/User/UserTable";
import { APPROVED, DISAPPROVED, PENDING } from "../../utils/Constants";
import { successNotfiy } from "../../components/Global/Toasts";
import TimeLogDetails, {
  BillableType,
} from "../../components/Admin/TimeLogDetails";
import { useParams } from "react-router-dom";
import ContentContainer from "../../components/Global/ContentContainer";
import { groupWeekView } from "../../utils/GroupTable";
import Pagination from "../../components/Global/Pagination";
import { fetchData } from "src/utils/FetchFilteredData";
import FilteredChecks from "src/components/Global/FilteredChecks";
import BreadCrumb from "src/components/Global/BreadCrumb";
import useClientProjects from "src/hooks/useClientProjects";
import { downloadExcel } from "src/utils/DownloadExcel";
import Spinner from "src/components/Global/Spinner";
import { AiOutlineCalendar } from "react-icons/ai";
import AdminTimelogWeek from "./AdminTimelogWeek";
import { useAuth } from "src/context/Auth";
import moment from "moment";
import { serverGET } from "../../HttpFunctions";
import CardDetails from "./CardsDetails";

export default function User() {
  const { user } = useAuth();
  const routeParams = useParams();
  const [selectedTask, setSelectedTask] = useState(null);
  const [page, setPage] = useState(0);
  const [userData, setUserData] = useState(null);
  const queryClient = useQueryClient();
  const [tasksData, setTasksData] = useState(null);
  const [weekView, setWeekView] = useState(() =>
    user?.type_of_user.includes("pm") && !user?.type_of_user.includes("admin")
      ? true
      : false
  );
  const isPm = user?.type_of_user[0];

  const [optionsSelected, setOptionsSelected] = useState({
    status: [],
    type: [],
    employee: [],
    project: [],
    client: [],
    date: null,
    endDate: null,
    create_startDate: null,
    create_endDate: null,
  });
  const [projectOptions, clientOptions] = useClientProjects(
    userData,
    optionsSelected
  );
  const [username, setUsername] = useState("");
  const [currentWeekStart, setCurrentWeekStart] = useState(
    moment()
      .day(6 - 7)
      .startOf("day")
  );
  const [currentWeekEnd, setCurrentWeekEnd] = useState(() =>
    moment()
      .day(6 - 7)
      .add(6, "days")
      .startOf("day")
  );
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (routeParams) {
      setUserData(routeParams);
    }
  }, [routeParams]);

  const {
    isLoading: weeklyLoading,
    data: weeklyData,
    refetch: weeklyRefetch,
  } = useQuery({
    queryKey: ["user-tasks", userData?.id, currentWeekStart.valueOf()],
    queryFn: () =>
      serverGET(
        `/user/taskdetails?userId=${
          userData?.id
        }&startTime=${currentWeekStart.valueOf()}&endTime=${currentWeekEnd.valueOf()}`
      ),
    enabled: !!userData && weekView,
  });

  console.log("weeklyData", weeklyData);

  const { error, isLoading, data, isPreviousData, refetch } = useQuery({
    queryKey: ["user-tasks", userData?.id, page, optionsSelected],
    queryFn: () =>
      fetchData(
        `/user/taskdetails?userId=${userData?.id}&page=${page}`,
        optionsSelected
      ),
    enabled: !!userData && !weekView,
    keepPreviousData: true,
    // staleTime: Infinity,
    // cacheTime: Infinity,
  });

  useEffect(() => {
    gatherData();
  }, [data]);

  const gatherData = async () => {
    if (data && data?.tasks?.length > 0) {
      let groupedData = [];
      let tasks = [...data?.tasks];
      groupedData = groupWeekView(tasks, true);

      setTasksData({
        username: data?.username,
        count: data?.count,
        tasks: groupedData,
      });
      if (!username) {
        setUsername(data?.username);
      }
    }
  };

  const mutation = useMutation({
    mutationFn: (updatedTask: any) => {
      return serverPUT(`/task/${updatedTask._id}`, updatedTask);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(
        ["user-tasks", userData.id, page, optionsSelected],
        (oldData: any) => {
          return {
            ...oldData,
            tasks: oldData?.tasks?.map((data) => {
              return data._id === updatedData.task._id
                ? {
                    ...data,
                    status: updatedData.task.status,
                    billable_minutes: updatedData.task?.billable_minutes,
                  }
                : data;
            }),
          };
        }
      );

      successNotfiy("Record has been updated successfully");
      // closeClick();
    },
  });

  const approveAllMutation = useMutation({
    mutationFn: (approveAllTasks: {
      listView: boolean;
      statusIds: {}[];
      billableHrsList: BillableType[];
    }) => {
      return serverPUT("/task/editAll", approveAllTasks);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (weekView) weeklyRefetch();
      else refetch();
      successNotfiy("Record has been updated successfully");
      closeClick();
    },
  });

  if (isLoading) {
    // return createElement("div", null, "Loading....");
    return <Spinner />;
  }

  if (error) {
    return createElement("div", null, "Something went wrong");
  }

  const handleClick = (task) => {
    setSelectedTask(task);
  };

  const closeClick = () => {
    setSelectedTask(null);
  };

  const handleStatus = (selTask, status) => {
    delete selTask?.billable_minutes;
    let updatedTask = {
      ...selTask,
      status: status,
    };

    let copy = Object.assign({}, selectedTask);
    let t = [...copy.tasks];
    let a = t.find((x) => selTask._id === x._id);
    a.status = status;
    copy.tasks = t;

    setSelectedTask(copy);
    mutation.mutate(updatedTask);
  };

  const handleApproveAll = (
    statusIds: {}[],
    billableHrsList: BillableType[]
  ) => {
    let approveAllTasks = {
      listView: true,
      statusIds: statusIds,
      billableHrsList,
    };

    approveAllMutation.mutate(approveAllTasks);
  };

  const handleBillableHours = (selectedTask) => {
    mutation.mutate(selectedTask);
  };

  const handleDownload = async () => {
    downloadExcel(`/task/download?userId=${userData?.id}`, optionsSelected);
  };
  const handleViewChange = () => {
    setWeekView((prev) => !prev);
  };

  const getNextWeek = () => {
    const nextWeekStart = currentWeekStart.clone().add(1, "weeks");
    const nextWeekEnd = currentWeekEnd.clone().add(1, "weeks");
    setCurrentWeekStart(nextWeekStart);
    setCurrentWeekEnd(nextWeekEnd);
  };

  const getPrevWeek = () => {
    const prevWeekStart = currentWeekStart.clone().subtract(1, "weeks");
    const prevWeekEnd = currentWeekEnd.clone().subtract(1, "weeks");
    setCurrentWeekStart(prevWeekStart);
    setCurrentWeekEnd(prevWeekEnd);
  };

  return (
    <ContentContainer>
      <>
        <div className="capitalize text-xl py-2">
          <BreadCrumb
            data={[
              {
                name: isPm === "pm" ? "Approval" : "Dashboard",
                link: isPm === "pm" ? "/user/approval?weekly=true" : "/",
              },
              {
                name: username ? `${username}'s Timesheet` : "Timesheet",
                link: "#",
              },
            ]}
          />
        </div>
        {!weekView && (
          <div className="capitalize text-xl flex items-center font-semibold mb-3">
            <p>{username ? `${username}'s Timesheet` : "Timesheet"}</p>
            {isMobileScreen ? (
              <></>
            ) : (
              <span>
                <button
                  className="flex items-center ml-4 text-sm gap-2 text-customPurple-400 cursor-pointer hover:text-customPurple-900"
                  onClick={() => handleViewChange()}
                >
                  Switch to weekly view <AiOutlineCalendar />
                </button>
              </span>
            )}
          </div>
        )}

        {!weekView && (
          <CardDetails data={data} showCards={[true, true, true, false]} />
        )}

        {weekView ? (
          <AdminTimelogWeek
            handleViewChange={handleViewChange}
            setSelectedTask={setSelectedTask}
            weeklyRefetch={weeklyRefetch}
            data={weeklyData}
            getNextWeek={getNextWeek}
            getPrevWeek={getPrevWeek}
            currentWeekStart={currentWeekStart}
            currentWeekEnd={currentWeekEnd}
            loading={weeklyLoading}
            username={username}
            setUsername={setUsername}
          />
        ) : (
          <>
            <div className="mt-4">
              <FilteredChecks
                optionsSelected={optionsSelected}
                setOptionsSelected={setOptionsSelected}
                projectOptions={projectOptions}
                clientOptions={clientOptions}
                isAdmin={true}
                setPage={setPage}
                handleDownload={handleDownload}
                taskLength={data?.tasks?.length}
              />
            </div>
            {data && data?.tasks?.length > 0 ? (
              <>
                <UserTable
                  data={tasksData}
                  page={page}
                  handleClick={handleClick}
                  isAdmin={true}
                  optionsSelected={optionsSelected}
                />
                <Pagination
                  page={page}
                  data={data}
                  setPage={setPage}
                  isPreviousData={isPreviousData}
                />
              </>
            ) : (
              <h1 className="text-lg text-center w-full mt-4">
                {"Tasks not found"}
              </h1>
            )}
          </>
        )}

        {!!selectedTask && (
          <TimeLogDetails
            selectedTimeLog={selectedTask}
            closeClick={closeClick}
            handleStatus={handleStatus}
            handleApproveAll={handleApproveAll}
            handleBillableHours={handleBillableHours}
            refetch={refetch}
            weekView={weekView}
          />
        )}
      </>
    </ContentContainer>
  );
}

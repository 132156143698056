import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import EditTimeLog from "./EditTimeLog";

export default function EditTimeLogPage() {
  const [check, setCheck] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    if (state) {
      setCheck(true);
    } else {
      navigate("/user/timesheet");
    }
  }, [state]);
  return <>{state ? <EditTimeLog editTask={state.editTask} /> : "Loading"}</>;
}

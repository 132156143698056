import React from "react";
import { useNavigate } from "react-router";
import ReactTable from "react-table-6";

export default function EmployeesList({
  employees,
}: {
  employees: Array<{ value: string; label: string }>;
}) {
  const navigate = useNavigate();
  const columns = [
    {
      getProps: () => {
        return {
          style: {
            padding: 10,
            cursor: "pointer",
          },
        };
      },
      Header: "Employee Name",
      accessor: "name",
    },
  ];

  const tableData = employees?.map((emp: any) => {
    return {
      name: emp.label,
      empId: emp.value,
    };
  });
  return (
    <div>
      <ReactTable
        className="rounded-lg"
        data={tableData}
        columns={columns}
        minRows={tableData?.length}
        resizable={true}
        showPagination={false}
        pageSize={tableData?.length}
        getTrProps={(state, rowInfo) => {
          if (rowInfo && rowInfo.row) {
            return {
              onClick: () => {
                const id = rowInfo.row._original.empId;
                navigate(`/dashboard/user/${id}`);
              },
            };
          }
        }}
      />
    </div>
  );
}

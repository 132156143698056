import { useCallback, useState } from "react";
import ReactTable from "react-table-6";
import Chip from "../Global/Chip";
import {
  APPROVED,
  DISAPPROVED,
  HOURLY_RATE_TYPE,
  TableHeaders,
  UNIT_PRICE_TYPE,
} from "../../utils/Constants";
import Toggler from "../Global/Toggler";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import { useMutation } from "react-query";
import { serverDELETE, serverPUT } from "../../HttpFunctions";
import Spinner from "../Global/Spinner";
import DeleteDialog from "../Global/DeleteDialog";
import Alert from "../../components/Global/Toasts";
import { successNotfiy, errorNotfiy } from "../Global/Toasts";

export default function ProjectServicesList({
  services,
  refetch,
  setEditDialog,
  global,
}: {
  services: [{ serviceType: string; services: [] }];
  refetch?: any;
  setEditDialog: any;
  global?: boolean;
}) {
  const [delDialog, setDeleteDialog] = useState("");
  const [selectedRows, setSelectedRows] = useState({
    hourlyRate: [],
    nonBillable: [],
    costFee: [],
    unitPrice: [],
  });

  const { isLoading, mutate } = useMutation({
    mutationFn: (service: any) => {
      return serverPUT(`/service/${service._id}`, {
        is_billable: !service.is_billable,
      });
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
      errorNotfiy("Something went wrong while updating service");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while updating service");
        return;
      }
      successNotfiy("Service has been updated successfully");
      refetch();
    },
  });

  const { mutate: delMutate } = useMutation({
    mutationFn: (serviceId: any) => {
      return global
        ? serverDELETE(`/service/global/${serviceId}`)
        : serverDELETE(`/service/${serviceId}`);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
      errorNotfiy("Something went wrong while deleting project");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while deleting project");
        return;
      }
      successNotfiy("Service has been deleted successfully");
      setDeleteDialog("");
      refetch();
    },
  });

  const { mutate: deleteManyMutation } = useMutation({
    mutationFn: (body: any) => {
      return global
        ? serverPUT(`/service/global/deletebulk`, body)
        : serverPUT(`/service/deletebulk`, body);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
      errorNotfiy("Something went wrong while deleting services");
    },
    onSuccess: (updatedData: any, variables, context) => {
      if (updatedData && updatedData.constructor === String) {
        errorNotfiy("Something went wrong while deleting project");
        return;
      }
      successNotfiy("Services have been deleted successfully");
      setDeleteDialog("");
      setSelectedRows({
        hourlyRate: [],
        nonBillable: [],
        costFee: [],
        unitPrice: [],
      });

      refetch();
    },
  });

  const statusFucntion = (row) => {
    return (
      <div className="py-2">
        <Chip
          color={
            row.original?.is_active
              ? "bg-paleGreen-100 text-paleGreen-200"
              : "bg-customRed-100 text-customRed-200"
          }
          title={row.original?.is_active ? APPROVED : DISAPPROVED}
        />
      </div>
    );
  };

  const actionFunction = (row) => {
    return (
      <div className="flex gap-4">
        <button onClick={() => setEditDialog(row.original?._id)}>
          <FaEdit />
        </button>
        <div className=" w-0.5 h-8 bg-gray-400"></div>
        <button
          className="cursor-pointer"
          onClick={() => setDeleteDialog(row.original?._id)}
        >
          <FaTrashAlt className="text-red-600" />
        </button>
      </div>
    );
  };
  const handleToggle = (row) => {
    mutate(row.original);
  };

  const toggleRowSelection = (row, key) => {
    const rowIndex = selectedRows[key].findIndex((r) => r === row.original._id);
    if (rowIndex === -1) {
      setSelectedRows({
        ...selectedRows,
        [key]: [...selectedRows[key], row.original._id],
      });
    } else {
      setSelectedRows({
        ...selectedRows,
        [key]: [
          ...selectedRows[key].slice(0, rowIndex),
          ...selectedRows[key].slice(rowIndex + 1),
        ],
      });
    }
  };

  const toggleSelectAll = (data, key) => {
    if (selectedRows[key].length === data.length) {
      setSelectedRows({ ...selectedRows, [key]: [] });
    } else {
      const ids = data.map((d) => d._id);
      setSelectedRows({
        ...selectedRows,
        [key]: ids,
      });
    }
  };

  const checkHeader = useCallback(
    (key) => {
      return {
        width: 40,
        Cell: (row) => {
          return (
            <div className="text-center font-normal">
              <input
                type="checkbox"
                checked={selectedRows[key].some((r) => r === row.original._id)}
                onChange={() => toggleRowSelection(row, key)}
              />
            </div>
          );
        },
      };
    },
    [selectedRows, toggleRowSelection]
  );

  const handleBulkDelete = (key) => {
    // const flattenedArray = [].concat(...Object.values(selectedRows));
    // console.log(flattenedArray);
    const delIds: string[] = [...selectedRows[key]];
    const body = {
      ids: delIds,
    };
    deleteManyMutation(body);
  };

  const columns = [
    {
      nonBillable: [
        checkHeader("nonBillable"),
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Task Type",
          accessor: "tasktype.name",
        },
        {
          Header: "Billable",
          accessor: "is_billable",
          Cell: (row) => (
            <div>
              <Toggler
                value={row.original?.is_billable}
                handleToggle={() => handleToggle(row)}
                hideLabel={true}
                disabled={row.original?.is_billable ? false : true}
              />
            </div>
          ),
        },
        {
          Header: "Status",
          accessor: "status",

          Cell: (row) => <div>{statusFucntion(row)}</div>,
        },
        {
          Header: "Actions",
          maxWidth: 140,
          Cell: (row) => <div>{actionFunction(row)}</div>,
        },
      ],

      hourlyRate: [
        checkHeader("hourlyRate"),
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Task Type",
          accessor: "tasktype.name",
        },
        {
          Header: "Billable",
          accessor: "is_billable",
          Cell: (row) => (
            <div>
              <Toggler
                value={row.original?.is_billable}
                handleToggle={() => handleToggle(row)}
                hideLabel={true}
              />
            </div>
          ),
        },
        {
          Header: "Hourly Rate ($)",
          accessor: "hourly_rate",
          Cell: (row) => (
            <div>
              {row.value} <span className="font-medium"> $ </span>
            </div>
          ),
        },
        {
          Header: "Overtime",
          accessor: "overtime",
          Cell: (row) => (
            <div>
              <span className="font-medium"> x </span>{" "}
              {row.value ? row.value : 1}
            </div>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: (row) => <div>{statusFucntion(row)}</div>,
        },
        {
          Header: "Actions",
          maxWidth: 140,
          Cell: (row) => <div>{actionFunction(row)}</div>,
        },
      ],
      costFee: [
        checkHeader("costFee"),
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Task Type",
          accessor: "tasktype.name",
        },
        {
          Header: "Billable",
          accessor: "is_billable",
          Cell: (row) => (
            <div>
              <Toggler
                value={row.original?.is_billable}
                handleToggle={() => handleToggle(row)}
                hideLabel={true}
              />
            </div>
          ),
        },
        {
          Header: "Cost Type",
          accessor: "rate_type",
          show: false,
        },
        {
          Header: "Cost Rate ($)/hr",
          accessor: "cost_rate",
          Cell: (row) => (
            <div>
              {row.original?.rate_type === HOURLY_RATE_TYPE ? (
                <>
                  {row.value} <span className="font-medium"> $ </span>
                </>
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          Header: "Unit Price",
          accessor: "cost_rate",
          Cell: (row) => (
            <div>
              {row.original?.rate_type === UNIT_PRICE_TYPE ? (
                <>
                  {row.value} <span className="font-medium"> $ </span>
                </>
              ) : (
                "-"
              )}
            </div>
          ),
        },
        {
          Header: "Fee Type",
          accessor: "fee_type",
          show: false,
        },
        {
          Header: "Fee Rate($)",
          accessor: "fee_rate",
          Cell: (row) => (
            <div>
              {row.original?.fee_type === UNIT_PRICE_TYPE ? (
                <>
                  {row.value}
                  <span className="font-medium"> $ </span>
                </>
              ) : (
                <>
                  <span className="font-medium"> x </span> {row.value}
                </>
              )}
            </div>
          ),
        },
        {
          Header: "Overtime",
          accessor: "overtime",
          Cell: (row) => (
            <div>
              <span className="font-medium"> x </span>{" "}
              {row.value ? row.value : 1}
            </div>
          ),
        },
        {
          Header: "Status",
          accessor: "status",

          Cell: (row) => <div>{statusFucntion(row)}</div>,
        },
        {
          Header: "Actions",
          maxWidth: 140,
          Cell: (row) => <div>{actionFunction(row)}</div>,
        },
      ],

      unitPrice: [
        checkHeader("unitPrice"),
        {
          Header: "Name",
          accessor: "name",
        },
        {
          Header: "Task Type",
          accessor: "tasktype.name",
        },
        {
          Header: "Billable",
          Cell: (row) => (
            <div>
              <Toggler
                value={row.original?.is_billable}
                handleToggle={() => handleToggle(row)}
                hideLabel={true}
              />
            </div>
          ),
        },
        {
          Header: "Unit Price (Half day)",
          accessor: "half_day_price",
          Cell: (row) => (
            <div>
              {row.value} <span className="font-medium"> $ </span>
            </div>
          ),
        },
        {
          Header: "Unit Price (Full day)",
          accessor: "full_day_price",
          Cell: (row) => (
            <div>
              {row.value} <span className="font-medium"> $ </span>
            </div>
          ),
        },

        {
          Header: "Overtime",
          accessor: "overtime",
          Cell: (row) => (
            <div>
              <span className="font-medium"> x </span>{" "}
              {row.value ? row.value : 1}
            </div>
          ),
        },
        {
          Header: "Status",
          accessor: "status",
          Cell: (row) => <div>{statusFucntion(row)}</div>,
        },
        {
          Header: "Actions",
          maxWidth: 140,
          Cell: (row) => <div>{actionFunction(row)}</div>,
        },
      ],
    },
  ];

  if (isLoading) {
    return <Spinner />;
  }

  const handleServiceDelete = () => {
    delMutate(delDialog);
  };

  return (
    <div>
      {services.map((service) => (
        <div className="text-left" key={service.serviceType}>
          <h2 className="font-medium capitalize">{TableHeaders[service.serviceType]}</h2>
          <div className="font-normal ml-2.5">
            <input
              type="checkbox"
              checked={
                selectedRows[service.serviceType]?.length ===
                service?.services?.length
              }
              onChange={() =>
                toggleSelectAll(service?.services, service.serviceType)
              }
            />
            <button
              className="cursor-pointer"
              onClick={() => handleBulkDelete(service.serviceType)}
              disabled={selectedRows[service.serviceType]?.length === 0}
            >
              <FaTrashAlt
                className={`text-sm ml-3 ${
                  selectedRows[service.serviceType]?.length > 0
                    ? "text-red-600"
                    : "text-gray-300"
                }`}
              />
            </button>
          </div>
          <RenderTable
            type={service.serviceType}
            data={service?.services}
            columns={columns[0][service.serviceType] ?? []}
          />
        </div>
      ))}
      {delDialog && (
        <DeleteDialog
          text="Are you sure you want to delete/undo the this service?"
          title="Delete this service?"
          handleClose={() => setDeleteDialog("")}
          handleDelete={handleServiceDelete}
        />
      )}
      <Alert />
    </div>
  );
}

function RenderTable({ type, data, columns }) {
  // const SelectTreeTable = selectTableHOC(ReactTable);

  return (
    <div className="mb-8">
      {data && data?.length > 0 ? (
        <ReactTable
          data={data}
          columns={columns}
          showPagination={false}
          pageSize={data.length}
        />
      ) : null}
    </div>
  );
}

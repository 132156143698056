import React from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useMutation } from "react-query";
import axios from "axios";
import Input, { PasswordInput } from "../../components/Global/Input";
import Alert, {
  errorNotfiy,
  successNotfiy,
} from "../../components/Global/Toasts";
import ContentContainer from "../../components/Global/ContentContainer";

const schema = yup
  .object({
    firstname: yup.string().required().max(16),
    lastname: yup.string().required().max(16),
    email: yup.string().email().required(),
    phone: yup.string().required().min(8),
    password: yup.string().required().min(8),
    cpassword: yup
      .string()
      .required()
      .min(8)
      .oneOf([yup.ref("password")], "Passwords do not match"),
  })
  .required();
export default function Register() {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      password: "",
      cpassword: "",
    },
  });

  const mutation = useMutation({
    mutationFn: (user) => {
      return axios.post(process.env.REACT_APP_HOST + "/auth/register", user);
    },
    onError: (error, variables, context) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (data, variables, context) => {
      if (data?.data?.code === 201) {
        reset();
        successNotfiy("The user account has been created successfully");
      } // navigate("/dashboard");
      else errorNotfiy("A user with this email already exists");
    },
  });
  const onSubmit = (data) => {
    let user = {
      first_name: data.firstname,
      last_name: data.lastname,
      email: data.email,
      phone_number: data.phone,
      password: data.password,
    };
    mutation.mutate(user);
  };
  return (
    <ContentContainer>
      <div className=" p-8 lg:flex lg:flex-col items-center">
        <h1 className="text-3xl font-bold underline flex-item">
          Create an account
        </h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="grid md:grid-cols-2 gap-4 my-2">
            <div>
              <Input
                label={"First Name"}
                placeholder="First Name"
                type={"text"}
                name="firstname"
                regProp={{ ...register("firstname") }}
                error={errors.firstname?.message}
              />
            </div>

            <div>
              <Input
                label={"Last Name"}
                placeholder="Last Name"
                type={"text"}
                name="lastname"
                regProp={{ ...register("lastname") }}
                error={errors.lastname?.message}
              />
            </div>
          </div>
          <div className="my-2">
            <Input
              label={"Email"}
              placeholder="Email"
              type={"email"}
              name="email"
              regProp={{ ...register("email") }}
              error={errors.email?.message}
            />
          </div>
          <div className="my-2">
            <Input
              label={"Phone Number"}
              placeholder="Phone Number"
              type={"text"}
              name="phone"
              regProp={{ ...register("phone") }}
              error={errors.phone?.message}
            />
          </div>

          <div className="my-2">
            <PasswordInput
              label={"Password 8+"}
              placeholder="********"
              type={"password"}
              name="password"
              regProp={{ ...register("password") }}
              error={errors.password?.message}
            />
          </div>
          <div className="my-2">
            <PasswordInput
              label={"Confirm Password"}
              placeholder="********"
              type={"password"}
              name="cpassword"
              regProp={{ ...register("cpassword") }}
              error={errors.cpassword?.message}
            />
          </div>
          <input
            type="submit"
            className="rounded bg-customPurple-900 w-full py-2 my-2 text-white cursor-pointer hover:bg-customPurple-800"
            value="Create Account"
          />
        </form>
        <div className="relative">
          <Alert />
        </div>
      </div>
    </ContentContainer>
  );
}

import React from "react";

export default function Dialogue({
  children,
  handleClose,
  title,
  description,
  central,
  width,
}: {
  children: any;
  handleClose: any;
  title: string;
  description?: string;
  central?: boolean;
  width?: string;
}) {
  return (
    <div className="fixed inset-0 bg-customGrey-900 bg-opacity-25 z-50">
      <div
        className={
          central
            ? "justify-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
            : "overflow-y-auto sm:max-h-full fixed top-0 right-0 left-0 z-50 w-full inset-0 h-modal md:h-full"
        }
      >
        <div
          className={`relative w-auto my-6 mx-auto ${
            width ? width : " max-w-5xl"
          }`}
        >
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center flex-col justify-center p-5 rounded-t">
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleClose}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
              <h3 className="text-xl font-semibold text-center">{title}</h3>
              <h5 className="text-sm">{description}</h5>
            </div>
            {children}
          </div>
        </div>
      </div>
    </div>
  );
}

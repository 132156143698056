import React from "react";
import { FiMenu } from "react-icons/fi";

function HamburgerButton(props) {
  return (
    <div className="md:hidden">
      <FiMenu onClick={props.onClick} className="h-6 w-6 text-gray-500" />
    </div>
  );
}

export default HamburgerButton;

import "./App.css";
import React from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import Login from "./pages/Auth/Login";
import ForgotPassword from "./pages/Auth/ForgotPassword";
import ResetPassword from "./pages/Auth/ResetPassword";
import Register from "./pages/Auth/Register";
import { ProtectedRoute } from "./Protected";
import Dashboard from "./pages/Dashboard/Home";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import User from "./pages/Dashboard/User";
import TimeSheet from "./pages/User/TimeSheet";
import { AuthProvider, useAuth } from "./context/Auth";
import Timeoff from "./pages/User/Timeoff";
import AdminTimeoff from "./pages/Dashboard/AdminTimeoff";
import ApprovalDetail from "./pages/PM/ApprovalDetail";
import PmHome from "./pages/Dashboard/Pm";
import AuthRoute from "./AuthRoute";
import ProjectList from "./pages/Project/ProjectList";
import ClientList from "./pages/Client/ClientList";
import ClientProjectList from "./pages/Client/ClientProjectList";
import EditTimeLogPage from "./pages/Task/EditTimeLogPage";
import AddTimeLog from "./pages/Task/AddTimeLog";
import Projects from "./pages/PM/Projects";
import Approval from "./pages/PM/Approval";
// import Approval from "./pages/PM/Approval";
import "typeface-roboto";
import TaskTypeList from "./pages/TaskType/TaskTypeList";
import EmployeeList from "./pages/User/EmployeeList";
import Settings from "./pages/Global/Settings";
import CreateProject from "./components/Admin/CreateProject";
import AddTimelogWeek from "./pages/Task/AddTimelogWeek";
import ServiceList from "./pages/ProjectService/ServiceList";
import Payroll from "./pages/payroll";
import { ADMIN, PM, USER } from "./utils/Constants";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});
function App() {
  return (
    <div className="font-sans">
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          <Routes>
            <Route
              //  exact
              path="/"
              element={<Navigate to="/login" />}
            />
            <Route
              path="/login"
              element={
                <AuthRoute>
                  <Login />
                </AuthRoute>
              }
            />
            <Route
              path="/forgot-password"
              element={
                <AuthRoute>
                  <ForgotPassword />
                </AuthRoute>
              }
            />
            <Route
              path="/reset-password"
              element={
                <AuthRoute>
                  <ResetPassword />
                </AuthRoute>
              }
            />
            <Route
              path="/register"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <Register />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <Dashboard />
                </ProtectedRoute>
              }
            />
            <Route
              path="/settings"
              element={
                <ProtectedRoute allowedRoles={[ADMIN, PM, USER]}>
                  <Settings />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/pm"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <PmHome />
                </ProtectedRoute>
              }
            />
            <Route
              path="/dashboard/user/:id"
              element={
                <ProtectedRoute allowedRoles={[ADMIN, PM]}>
                  <User />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/timeoff"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <AdminTimeoff />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/tasktype"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <TaskTypeList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/employees"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <EmployeeList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/project"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <ProjectList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/project/:projectId"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <CreateProject />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/project/create"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <CreateProject />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/service"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <ServiceList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/client"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <ClientList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/payroll"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <Payroll />
                </ProtectedRoute>
              }
            />

            <Route
              path="/dashboard/client-projects/:id"
              element={
                <ProtectedRoute allowedRoles={[ADMIN]}>
                  <ClientProjectList />
                </ProtectedRoute>
              }
            />

            <Route
              path="/user/addtask"
              element={
                <ProtectedRoute allowedRoles={[USER, PM]}>
                  <AddTimeLog />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/edittimelog"
              element={
                <ProtectedRoute allowedRoles={[USER, PM]}>
                  <EditTimeLogPage />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/timesheet"
              element={
                <ProtectedRoute allowedRoles={[USER, PM]}>
                  <TimeSheet />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/timeoff"
              element={
                <ProtectedRoute allowedRoles={[USER, PM]}>
                  <Timeoff />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/approval"
              element={
                <ProtectedRoute allowedRoles={[ADMIN, PM]}>
                  <Approval />
                </ProtectedRoute>
              }
            />
            <Route
              path="/user/approval/detail"
              element={
                <ProtectedRoute allowedRoles={[PM]}>
                  <ApprovalDetail />
                </ProtectedRoute>
              }
            />
            <Route
              path="/pm/projects"
              element={
                <ProtectedRoute allowedRoles={[PM]}>
                  <Projects />
                </ProtectedRoute>
              }
            />

            <Route path="/404" element={<h1>Page not found</h1>} />
          </Routes>
        </QueryClientProvider>
      </AuthProvider>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Input from "../Global/Input";
import { useMutation, useQueryClient } from "react-query";
import { serverPOST } from "../../HttpFunctions";
import Select from "../Global/Select";
import { ACTIVE, ARCHIVED } from "../../utils/Constants";
import { successNotfiy } from "../Global/Toasts";
import Dialogue from "../Global/Dialogue";

type AddTaskTypeProps = {
  handleClose: () => void;
};

export const schema = yup
  .object({
    name: yup.string().required(),
    status: yup.string().required(),
    is_billable: yup.string().required(),
  })
  .required();

export default function AddTaskType({ handleClose }: AddTaskTypeProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      status: ACTIVE,
      is_billable: "No",
    },
  });

  const queryClient = useQueryClient();

  const mutation = useMutation({
    mutationFn: (taskType: any) => {
      return serverPOST(`/taskType/create`, taskType);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(["taskTypes"], (oldData: any) => {
        return { taskTypes: [updatedData.taskType, ...oldData.taskTypes] };
      });

      successNotfiy("TaskType has been updated successfully");
    },
  });

  function onSubmit(data) {
    let requestData = {
      name: data.name,
      is_billable: data.is_billable === "Yes" ? true : false,
      is_active: data.status === ACTIVE ? true : false,
    };

    mutation.mutate(requestData);
    handleClose();
  }
  return (
    <Dialogue
      title="Add task type"
      description="Input relevant data and click on Create to save your changes"
      handleClose={handleClose}
      central={true}
    >
      {/*body*/}
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="relative p-6 flex-auto">
          <div className="grid md:grid-cols-1">
            <div className="mb-2">
              <Input
                label={"Task Type Name"}
                placeholder="Enter task type name"
                type={"text"}
                name="name"
                regProp={{ ...register("name") }}
                error={errors.name?.message}
              />
            </div>
            <div className="mb-2">
              <Select
                label={"Billable"}
                id="is_billable"
                regProp={{ ...register("is_billable") }}
                options={["Yes", "No"]}
                error={errors.is_billable?.message}
                style={{
                  height: "37px",
                  marginTop: "8px",
                }}
              />
            </div>
            <div className="mb-2">
              <Select
                label={"Status"}
                id="status"
                regProp={{ ...register("status") }}
                options={[ACTIVE, ARCHIVED]}
                error={errors.status?.message}
                style={{
                  height: "37px",
                  marginTop: "8px",
                }}
              />
            </div>
          </div>
        </div>

        {/*footer*/}
        <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
          <div className="pr-4">
            <SecondaryButton onClick={handleClose} text="Cancel" />
          </div>
          <PrimaryButton text="Create" />
        </div>
      </form>
    </Dialogue>
  );
}

import React from "react";
import Dialogue from "../Global/Dialogue";
import { SecondaryButton } from "../Global/Button";
import moment from "moment";

export default function PayrollModal({ payrollError, handleClose }) {
  return (
    <Dialogue
      title="Payroll"
      description={`You have successfully added tasks to your timesheet. However, please note that these tasks were submitted after the payroll deadline (${moment(
        payrollError?.timesheet_deadline
      ).format(
        "ddd, MM/DD/YYYY"
      )}). As a result, the payment for these tasks will be processed in the next payroll period.`}
      handleClose={handleClose}
      central={true}
      width="max-w-3xl"
    >
      <div className="flex items-center justify-center pb-4 rounded-b">
        <SecondaryButton text="OK" onClick={handleClose} />
      </div>
    </Dialogue>
  );
}

import React from "react";
import ReactDatePicker from "react-datepicker";
import Dialogue from "../Global/Dialogue";

export default function ExportDate({
  handleModal,
  exportedDates,
  setExportedDates,
  handleDownload,
}: {
  handleModal: any;
  exportedDates: { start: Date; end: Date };
  setExportedDates?: React.Dispatch<
    React.SetStateAction<{
      start: Date;
      end: Date;
    }>
  >;
  handleDownload: any;
}) {
  return (
    <Dialogue
      title="Export"
      description="download entire week or choose date range"
      handleClose={handleModal}
      central={true}
    >
      <div className="transition ease-out duration-300 flex-shrink-0 flex-1 flex flex-wrap gap-5 justify-center">
        <div className="flex max-h-[36px] my-4">
          <ReactDatePicker
            selectsRange={true}
            className={` ${
              exportedDates.start && exportedDates.end ? "w-52" : "w-36"
            }  placeholder-gray-500 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block pl-2.5 p-2 customPicker`}
            placeholderText="Select date range"
            onChange={(dates) => {
              const [start, end] = dates;
              setExportedDates({
                start: start,
                end: end,
              });
            }}
            startDate={exportedDates.start}
            endDate={exportedDates.end}
            isClearable={true}
          />
        </div>
      </div>
      <div className="flex-1 text-center mb-4">
        <button
          className="px-4 py-2 border border-customPurple-400 text-customPurple-400 rounded-md enabled:secondary-hover-effect disabled:border-gray-400 disabled:text-gray-400"
          onClick={handleDownload}
        >
          Export
        </button>
      </div>
    </Dialogue>
  );
}

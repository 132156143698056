import React, { useState } from "react";

export default function Toggler({
  handleToggle,
  value,
  hideLabel,
  disabled,
}: {
  handleToggle: any;
  value: boolean;
  hideLabel?: boolean;
  disabled?: boolean;
}) {
  return (
    <div>
      {hideLabel ? null : (
        <p className="block text-sm font-medium text-slate-700 mb-2">
          {/* Billable by default */}
          Billable
        </p>
      )}
      <div className="overflow-hidden">
        <div
          className={`flex items-center h-[40px] ${
            hideLabel ? "bg-transparent" : "bg-[#8833ff1c]"
          } px-2 rounded-md`}
        >
          {/* {hideLabel ? null : ( */}
          <span className="mr-2 text-sm font-normal text-gray-900">
            {/* Billable: */}

            {value ? "Yes" : value === null ? "-" : "No"}
          </span>
          {/* )} */}

          {/* <label className="inline-flex relative items-center mr-5 cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={value}
              readOnly
            />
            <div
              onClick={disabled ? null : handleToggle}
              className="w-10 h-5 bg-gray-200 rounded-full peer  peer-focus:ring-customPurple-400  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-customPurple-900"
            ></div>
          </label> */}
        </div>
      </div>
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import TimePicker from "rc-time-picker";
import moment from "moment";

export const TimePickerComponent = ({
  isAdmin,
  row,
  getBillableDate,
  handleBillableHrChange,
}) => {
  return (
    <div className="z-10">
      {!isAdmin
        ? null
        : row.value?.map((task, index) => {
            return (
              <TaskBillableHours
                index={index}
                getBillableDate={getBillableDate}
                taskId={task._id}
                billableHrs={task.billableHrs}
                handleBillableHrChange={handleBillableHrChange}
              />
            );
          })}
    </div>
  );
};

export const TaskBillableHours = ({
  index,
  getBillableDate,
  billableHrs,
  taskId,
  handleBillableHrChange,
}) => {
  const [selectedTime, setSelectedTime] = useState(
    getBillableDate(billableHrs)
  );
  const [pendingSend, setPendingSend] = useState(false);

  // const debounced = useDebouncedCallback((taskId, e) => {
  //   handleBillableHrChange(taskId, e);
  // }, 1000);

  const handleTimeChange = (newTime) => {
    setSelectedTime(newTime);
    setPendingSend(true);
  };

  useEffect(() => {
    setSelectedTime(getBillableDate(billableHrs));
  }, [billableHrs]);

  useEffect(() => {
    let timeoutId;
    if (pendingSend) {
      timeoutId = setTimeout(() => {
        handleBillableHrChange(taskId, selectedTime);
        setPendingSend(false);
      }, 2000);
    }

    return () => {
      clearTimeout(timeoutId);
    };
  }, [pendingSend, selectedTime]);

  return (
    <div
      key={index}
      className="py-1.5"
      onClick={() => {
        console.log("b mins", getBillableDate(billableHrs));
        console.log("selelecred tune", selectedTime);
      }}
    >
      <TimePicker
        value={selectedTime}
        focusOnOpen
        showSecond={false}
        clearIcon={<></>}
        showMinute={true}
        minuteStep={15}
        inputReadOnly
        onChange={handleTimeChange}
        className="custom-time-picker"
      />
    </div>
  );
};

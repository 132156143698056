import React, { useState } from "react";
import { Navigate, useLocation } from "react-router";
import Navbar from "./components/Global/Navbar";
import Sidebar from "./components/Global/Sidebar";
import { useAuth } from "./context/Auth";
import "./App.css";

type ProtectedRouteProps = {
  hideSidebar?: boolean;
  children: any;
  allowedRoles: string[];
};
export const ProtectedRoute = ({
  hideSidebar,
  children,
  allowedRoles,
}: ProtectedRouteProps) => {
  const { isLogged, user } = useAuth();
  const location = useLocation();
  const [sidebarVisible, setSidebarVisible] = useState(false); //for mobile view

  if (allowedRoles.length && user) {
    const userRights = user?.type_of_user;
    const hasRequiredRights = allowedRoles.some((requiredRight) =>
      userRights?.includes(requiredRight)
    );

    if (!hasRequiredRights) {
      return <Navigate to="/404" />;

      // return reject(new ApiError(httpStatus.FORBIDDEN, 'Forbidden'));
    }
  }
  // resolve();

  if (!isLogged)
    return (
      <Navigate
        to={{
          pathname: `/login`,
        }}
        state={{ from: location.pathname }}
      />
    );

  // if (adminRoute && !user.type_of_user.includes("admin") && !bothRoute) {
  //   return <Navigate to="/504" />;
  // }
  // if (!adminRoute && user.type_of_user.includes("admin") && !bothRoute) {
  //   return <Navigate to="/604" />;
  // }

  // if (
  //   adminRoute &&
  //   user.type_of_user.includes("user") &&
  //   user.type_of_user.includes("pm") &&
  //   !bothRoute
  // ) {
  //   return <Navigate to="/704" />;
  // }

  // if (
  //   !adminRoute &&
  //   !user.type_of_user.includes("user") &&
  //   !user.type_of_user.includes("pm") &&
  //   !bothRoute
  // ) {
  //   return <Navigate to="/404" />;
  // }

  function handleClick() {
    setSidebarVisible(!sidebarVisible);
  }

  return (
    <>
      {hideSidebar ? (
        children
      ) : (
        <div className="h-screen lg:overflow-y-auto">
          <Navbar handleClick={handleClick} />

          <Sidebar
            className={sidebarVisible ? "fixed z-10" : "hidden"}
            handleClick={handleClick}
          >
            {children}
          </Sidebar>
        </div>
      )}
    </>
  );
};

import React, { useState } from "react";
import ContentContainer from "../../components/Global/ContentContainer";
import AddTimelogWeek from "../Task/AddTimelogWeek";
import ListView from "src/components/User/ListView";

export default function TimeSheet() {
  const [weekView, setWeekView] = useState(false);

  const handleViewChange = () => {
    setWeekView((prev) => !prev);
  };

  return (
    <ContentContainer>
      {weekView ? (
        <AddTimelogWeek handleViewChange={handleViewChange} />
      ) : (
        <ListView handleViewChange={handleViewChange} />
      )}
    </ContentContainer>
  );
}

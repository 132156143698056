import moment from "moment";
import React, { Dispatch, useEffect, useState } from "react";
import { APPROVED, DISAPPROVED, PENDING, REVISED } from "../../utils/Constants";
import TimePicker from "rc-time-picker";
import { RxCross2, RxCheck } from "react-icons/rx";
import { Task } from "./TimeLogDetails";
import EditClientProject from "./EditClientProject";
import CommentList, { Comment } from "../Global/CommentList";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import momentTimezone from "moment-timezone";
import AttachmentLink from "./AttachmentLink";

export default function TaskDetails({
  tl,
  index,
  handleStatus,
  statusList,
  setStatusList,
  comments,
  commentRefetch,
  weekView,
  isPm,
  billableHrsList,
  setBillableHrsList,
  handleBillableHours,
  clientData,
  projectData,
  page,
  optionsSelected,
  setSelectedTask,
  refetch,
  taskTypeData,
  serviceData,
}: {
  tl: Task;
  index?: number;
  handleStatus: (selectedTimeLog: Task, status: string) => void;
  handleBillableHours?: (selectedTask: Task) => void;
  clientData?: any;
  projectData: any;
  page?: number;
  optionsSelected?: any;
  setSelectedTask?: any;
  refetch?: any;
  statusList?: any;
  setStatusList?: any;
  comments: Comment[];
  commentRefetch: any;
  isPm?: boolean;
  weekView?: boolean;
  billableHrsList?: any[];
  setBillableHrsList?: any;
  taskTypeData?: any;
  serviceData?: any;
}) {
  console.log("🚀 ~ tl:", tl);
  const [editBillableHours, setEditBillableHours] = useState(false);
  const [billableMinutes, setBillableMinutes] = useState(tl?.billable_minutes);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  const [isOpen, setIsOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  function getBillableDate(minutes: number) {
    const date = moment();
    if (minutes === undefined) {
      date.hours(0);
      date.minutes(0);
    } else if (minutes === 1440) {
      date.hours(23);
      date.minutes(59);
    } else {
      const hours = minutes / 60;
      const mins = minutes % 60;
      date.hours(hours);
      date.minutes(mins);
    }
    return date;
  }

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const handleBillableHr = (date) => {
    const newMinutes = date.hours() * 60 + date.minutes();

    if (isPm && !weekView) {
      setEditBillableHours(true);
      setBillableMinutes(newMinutes);
      return;
    }

    setBillableMinutes(newMinutes);

    const objectExists = billableHrsList.some((item) => item._id === tl._id);

    if (objectExists) {
      const updatedArray = billableHrsList.map((item) =>
        item._id === tl._id
          ? { _id: tl._id, billable_minutes: newMinutes }
          : item
      );
      setBillableHrsList(updatedArray);
    } else {
      setBillableHrsList([
        ...billableHrsList,
        { _id: tl._id, billable_minutes: newMinutes },
      ]);
    }
  };

  const handleWeekViewBillableHr = (date) => {
    const newMinutes = date.hours() * 60 + date.minutes();
    setBillableMinutes(newMinutes);

    const objectExists = billableHrsList.some((item) => item._id === tl._id);

    if (objectExists) {
      const updatedArray = billableHrsList.map((item) =>
        item._id === tl._id
          ? { _id: tl._id, billable_minutes: newMinutes }
          : item
      );
      setBillableHrsList(updatedArray);
    } else {
      setBillableHrsList([
        ...billableHrsList,
        { _id: tl._id, billable_minutes: newMinutes },
      ]);
    }
  };

  return (
    <div className="min-h-100 bg-transparent w-full border border-gray-200 border-solid my-4 p-4 rounded-lg">
      <div className="mb-4 flex">
        <div className="flex items-center">
          {/* task number + status */}
          <p className="font-bold text-sm">
            {index !== undefined ? `Task ${index + 1}` : "Task"}
          </p>
          {tl.action_by && ![PENDING, REVISED].includes(tl.status) ? (
            <p className="pl-2 font-normal text-sm text-customGrey-500 italic">
              {tl.status === DISAPPROVED ? "Denied" : tl.status} by{" "}
              {tl.action_by.first_name} {tl.action_by.last_name}
            </p>
          ) : (
            <p className="ml-2 text-xs rounded-full px-2 py-0.5 items-center flex bg-customGrey-400 max-w-fit text-customGrey-500">
              {tl.status}
            </p>
          )}
        </div>
        {/* approve deny + toggler */}
        <div className="ml-auto flex items-center">
          {!isMobileScreen ? (
            <TaskStatus
              tl={tl}
              handleStatus={handleStatus}
              statusList={statusList}
              setStatusList={setStatusList}
              isPm={isPm}
              weekView={weekView}
            />
          ) : null}
          <div className="ml-auto flex items-center cursor-pointer">
            {isOpen ? (
              <IoIosArrowUp onClick={toggleAccordion} />
            ) : (
              <IoIosArrowDown onClick={toggleAccordion} />
            )}
          </div>
        </div>
      </div>
      {/* if task is open by arrow then show nothing else on desktop show nothing */}
      {isOpen ? null : (
        <div>
          {!isMobileScreen ? null : (
            <>
              {/* on mobile */}
              <div className={`flex ${isMobileScreen ? "w-full" : ""}`}>
                <div className="w-40">
                  <h2 className="text-sm">Task type</h2>
                  <p className="text-sm text-gray-500 mt-3">{tl.type.name}</p>
                </div>

                <div
                  className={`w-24 ml-auto ${
                    isMobileScreen ? " text-right" : ""
                  }`}
                >
                  <TaskDuration tl={tl} />
                </div>
              </div>
              <div className=" flex justify-center mt-5 mb-3">
                <TaskStatus
                  tl={tl}
                  handleStatus={handleStatus}
                  setStatusList={setStatusList}
                  statusList={statusList}
                  isPm={isPm}
                  weekView={weekView}
                />
              </div>
            </>
          )}
        </div>
      )}
      <div className={`${isOpen ? "block" : "hidden"} `}>
        <div className={`flex gap-3.5 ${isMobileScreen ? "flex-wrap" : ""}`}>
          {!weekView ? (
            <>
              <div className={`flex ${isMobileScreen ? "w-full" : ""}`}>
                {/* <div className="w-64 md:w-28 ">
                  <h2 className="text-sm">Task type</h2>
                  <p className="text-sm text-gray-500 mt-3">{tl.type.name}</p>
                </div> */}
                {/* {tl?.service?.name ? (
                  <div className={`w-full md:w-28`}>
                    <h2 className="text-sm">Service </h2>
                    <p className="text-sm text-gray-500 mt-3">
                      {tl?.service?.name}
                    </p>
                  </div>
                ) : null} */}

                {!isMobileScreen ? null : (
                  <div
                    className={`w-24 ml-auto ${
                      isMobileScreen ? " text-right" : ""
                    }`}
                  >
                    <TaskDuration tl={tl} />
                  </div>
                )}
              </div>

              {/* <div className="w-full md:w-40">
                <h2 className="text-sm">Client</h2>
                <p className="text-sm text-gray-500 mt-3">
                  {tl.project.client.name}
                </p>
              </div>

              <div className="w-full md:w-40">
                <h2 className="text-sm">Project</h2>
                <p className="text-sm text-gray-500 mt-3">
                  {tl.project.project_name}
                </p>
              </div> */}

              {/* temp because service added and need to adjust accordingly */}
              <EditClientProject
                clientData={clientData}
                taskTypeData={taskTypeData}
                projectData={projectData}
                serviceData={serviceData}
                selectedTask={tl}
                page={page}
                optionsSelected={optionsSelected}
                setSelectedTask={setSelectedTask}
                refetch={refetch}
              />
              <div className={`flex ${isMobileScreen ? "w-full" : ""} flex-1`}>
                <div className="w-24">
                  <h2 className="text-sm">Start Time</h2>
                  <p
                    className="text-sm text-gray-500 mt-3"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // wordWrap: "break-word",
                    }}
                  >
                    {moment(tl?.start_time)
                      .tz(tl?.timezone || "America/New_York")
                      .format("hh:mm A")}
                  </p>
                </div>
                <div
                  className={`w-24 ml-auto ${
                    isMobileScreen ? " text-right" : ""
                  } flex-1`}
                >
                  <h2 className="text-sm">End Time</h2>
                  <p
                    className="text-sm text-gray-500 mt-3"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // wordWrap: "break-word",
                    }}
                  >
                    {moment(tl?.end_time)
                      .tz(tl?.timezone || "America/New_York")
                      .format("hh:mm A")}
                  </p>
                </div>
              </div>

              {isMobileScreen ? null : <TaskDuration tl={tl} />}
              <div className={`w-44 ${!isMobileScreen ? "ml-4" : ""}`}>
                <h2 className="text-sm">Billable hrs</h2>
                <TimePicker
                  value={getBillableDate(billableMinutes)}
                  focusOnOpen
                  showSecond={false}
                  clearIcon={<></>}
                  showMinute={true}
                  minuteStep={15}
                  inputReadOnly
                  onChange={handleBillableHr}
                  className="custom-time-picker"
                />
              </div>
            </>
          ) : (
            <>
              <div
                className={`flex w-full justify-between flex-1 flex-wrap gap-y-4`}
              >
                <div>
                  <h2 className="text-sm">Start Time</h2>
                  <p
                    className="text-sm text-gray-500 mt-3"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // wordWrap: "break-word",
                    }}
                  >
                    {moment(tl?.start_time)
                      .tz(tl?.timezone || "America/New_York")
                      .format("hh:mm A")}
                  </p>
                </div>
                <div className={` text-right md:text-left`}>
                  <h2 className="text-sm">End Time</h2>
                  <p
                    className="text-sm text-gray-500 mt-3"
                    style={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      // wordWrap: "break-word",
                    }}
                  >
                    {moment(tl?.end_time)
                      .tz(tl?.timezone || "America/New_York")
                      .format("hh:mm A")}
                  </p>
                </div>

                {isMobileScreen ? null : <TaskDuration tl={tl} />}
                <div className={`md:ml-4`}>
                  <h2 className="text-sm">Billable hrs</h2>
                  <TimePicker
                    value={getBillableDate(billableMinutes)}
                    focusOnOpen
                    showSecond={false}
                    clearIcon={<></>}
                    showMinute={true}
                    minuteStep={15}
                    inputReadOnly
                    onChange={handleWeekViewBillableHr}
                    className="custom-time-picker"
                  />
                </div>
              </div>
            </>
          )}

          {isPm && !weekView ? (
            !editBillableHours ? null : (
              <div className="flex items-center pt-5 text-lg">
                <RxCheck
                  className="text-customGrey-300 hover:cursor-pointer border-r-1 border-indigo-600"
                  onClick={() => {
                    handleBillableHours({
                      ...tl,
                      billable_minutes: billableMinutes,
                    });
                    setEditBillableHours(false);
                  }}
                />
                <RxCross2
                  className="text-customGrey-300 hover:cursor-pointer"
                  onClick={() => {
                    setBillableMinutes(tl?.billable_minutes);
                    setEditBillableHours(false);
                  }}
                />
              </div>
            )
          ) : null}
        </div>
        {!tl.description ? (
          <></>
        ) : (
          <div className="py-2">
            <h2 className="text-sm">Task description</h2>
            <p className="text-sm text-gray-500">{tl?.description}</p>
          </div>
        )}
        {!isMobileScreen ? null : (
          <div>
            <div className=" flex justify-center mt-5 mb-3">
              <TaskStatus
                tl={tl}
                handleStatus={handleStatus}
                statusList={statusList}
                setStatusList={setStatusList}
                isPm={isPm}
                weekView={weekView}
              />
            </div>
          </div>
        )}
        {tl?.attachments?.length > 0 && (
          <AttachmentLink attachments={tl.attachments} />
        )}

        <CommentList
          taskId={tl._id}
          comments={comments}
          commentRefetch={commentRefetch}
        />
      </div>
    </div>
  );
}

type TaskStatusProps = {
  tl: Task;
  handleStatus: (tl: Task, status: string) => void;
  // approveList?: string[];
  // setApproveList?: Dispatch<React.SetStateAction<string[]>>;
  statusList?: any;
  setStatusList?: any;
  isPm?: boolean;
  weekView?: boolean;
};

function TaskStatus({
  tl,
  handleStatus,
  statusList,
  setStatusList,
  isPm,
  weekView,
}: TaskStatusProps) {
  const [status, setStatus] = useState(tl.status);
  return (
    <div className="flex">
      <p
        className={
          status === DISAPPROVED
            ? "rounded-full px-3 py-2 bg-customRed-100 max-w-fit text-sm text-red-500 font-semibold mr-1 hover:cursor-pointer"
            : "flex items-center px-3 py-2 text-sm text-red-500 font-semibold mr-1 hover:cursor-pointer"
        }
        onClick={() => {
          if (isPm && !weekView) {
            if (status === DISAPPROVED) {
              handleStatus(tl, PENDING);
            } else handleStatus(tl, DISAPPROVED);
          } else {
            const temp = [...statusList];
            const item = temp.find((x) => x.id === tl._id);

            if (item) {
              if (item.status === 1) {
                item.status = 0;
                setStatusList(temp);
              } else if (item.status === 0) {
                item.status = -1;
                setStatusList(temp);
              }
            } else {
              if (status === DISAPPROVED)
                setStatusList([...temp, { id: tl._id, status: -1 }]);
              else setStatusList([...temp, { id: tl._id, status: 0 }]);
            }
          }
          if (status === DISAPPROVED) setStatus(PENDING);
          else setStatus(DISAPPROVED);
        }}
      >
        {status === PENDING || status === APPROVED
          ? "Deny"
          : status === DISAPPROVED
          ? "Denied"
          : null}
      </p>
      {status !== REVISED && (
        <div className="w-px bg-customGrey-400 mr-1"></div>
      )}
      <p
        className={
          status === APPROVED
            ? "rounded-full px-3 py-2 bg-paleGreen-100 max-w-fit text-sm text-green-500 font-semibold mr-1 hover:cursor-pointer"
            : "flex items-center px-3 py-2 text-sm text-green-500 font-semibold mr-1 hover:cursor-pointer"
        }
        onClick={() => {
          if (isPm && !weekView) {
            if (status === APPROVED) handleStatus(tl, PENDING);
            else handleStatus(tl, APPROVED);
          } else {
            const temp = [...statusList];
            const item = temp.find((x) => x.id === tl._id);

            if (item) {
              if (item.status === 0) {
                item.status = 1;
                setStatusList(temp);
              } else if (item.status === 1) {
                item.status = -1;
                setStatusList(temp);
              }
            } else {
              if (status === APPROVED)
                setStatusList([...temp, { id: tl._id, status: -1 }]);
              else setStatusList([...temp, { id: tl._id, status: 1 }]);
            }
          }
          if (status === APPROVED) setStatus(PENDING);
          else setStatus(APPROVED);
        }}
      >
        {status === PENDING || status === DISAPPROVED
          ? "Approve"
          : status === APPROVED
          ? "Approved"
          : null}
      </p>
      {status === REVISED && (
        <>
          <p
            className={
              "flex items-center px-3 py-2 text-xs text-customYellow-900  mr-1 cursor-default"
            }
          >
            Revised
          </p>
          <p
            className={
              "flex items-center px-3 py-2 text-sm text-green-500 font-semibold mr-1 hover:cursor-pointer"
            }
            onClick={() => {
              if (isPm && !weekView) {
                handleStatus(tl, APPROVED);
              } else {
                const temp = [...statusList];
                const item = temp.find((x) => x.id === tl._id);

                if (item) {
                  if (!item.status) {
                    item.status = 1;
                    setStatusList(temp);
                  }
                } else {
                  setStatusList([...temp, { id: tl._id, status: 1 }]);
                }
              }
              setStatus(APPROVED);
            }}
          >
            Approve
          </p>
          <div className="w-px bg-customGrey-400 mr-1"></div>
          <p
            className={
              "flex items-center px-3 py-2 text-sm text-red-500 font-semibold mr-1 hover:cursor-pointer"
            }
            onClick={() => {
              if (isPm && !weekView) {
                handleStatus(tl, DISAPPROVED);
              } else {
                const temp = [...statusList];
                const item = temp.find((x) => x.id === tl._id);

                if (item) {
                  if (item.status) {
                    item.status = 0;
                    setStatusList(temp);
                  }
                } else {
                  setStatusList([...temp, { id: tl._id, status: 0 }]);
                }
              }
              setStatus(DISAPPROVED);
            }}
          >
            Deny
          </p>
        </>
      )}
    </div>
  );
}

function TaskDuration({ tl }: { tl: Task }) {
  return (
    <div className="">
      <h2 className="text-sm">Duration</h2>
      <p className="text-sm text-gray-500  mt-3">
        {Math.floor(tl?.duration_minutes / 60) +
          ":" +
          (tl?.duration_minutes % 60).toLocaleString([], {
            minimumIntegerDigits: 2,
          }) +
          " "}
        hrs
      </p>
    </div>
  );
}

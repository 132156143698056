export const getEmployeesWithTasks = "/user/users/taskdetails";

export const getEmployees = "/user";

export const getUserTasks =
  "/user/users/taskdetails?page=0&start_time=1677137609461";

export const getProjects = "/project";
export const getClients = "/project/clients";
export const getTaskTypes = "/tasktype";

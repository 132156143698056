import React, { useEffect, useRef, useState } from "react";
import ReactTable from "react-table-6";
import { FaEdit, FaTrashAlt } from "react-icons/fa";
import Chip from "../Global/Chip";
import { APPROVED, DISAPPROVED, PM } from "../../utils/Constants";
import { Employee } from "src/pages/User/EmployeeList";
import Dialogue from "../Global/Dialogue";

type EmployeeTableProps = {
  employeeList: Employee[];
  handleClick?: (employee) => void;
  isPm?: boolean;
  handlePassClick?: any;
  handleDeleteClick: any;
};

export default function EmployeeTable({
  employeeList,
  handleClick,
  isPm,
  handlePassClick,
  handleDeleteClick,
}: EmployeeTableProps) {
  const columns = [
    {
      Header: "#ID",
      accessor: "empid",
      maxWidth: 100,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Role",
      accessor: "role",
      Cell: (row) => {
        return (
          <div className="capitalize">
            {Array.isArray(row.original.role) ? (
              <div className="py-2">
                {row.original?.role?.map((r) => (
                  <>{r} </>
                ))}
              </div>
            ) : (
              <div>{row.original?.role}</div>
            )}
          </div>
        );
      },
    },

    {
      Header: "Status",
      accessor: "status",

      Cell: (row) => (
        <div>
          <div className="py-2">
            <Chip
              color={
                row.original?.status
                  ? "bg-paleGreen-100 text-paleGreen-200"
                  : "bg-customRed-100 text-customRed-200"
              }
              title={row.original?.status ? APPROVED : DISAPPROVED}
            />
          </div>
        </div>
      ),
    },
    {
      Header: "Actions",
      maxWidth: 140,
      show: !isPm,

      Cell: ({ row }) => (
        <Action
          index={row._index}
          handleClick={handleClick}
          handlePassClick={handlePassClick}
          handleDeleteClick={handleDeleteClick}
        />
      ),
    },
  ];

  const tableData = employeeList?.map((employee) => {
    return {
      name: `${employee.first_name} ${employee.last_name}`,
      email: employee.email,
      // role: employee?.type_of_user?.includes("pm") ? "PM" : "User",
      role: employee?.type_of_user,
      labour_rate: employee.labour_rate,
      status: employee.is_active,
      empid: employee?.emp_id,
    };
  });

  return (
    <div className="">
      <ReactTable
        data={tableData}
        columns={columns}
        showPagination={false}
        pageSize={tableData?.length}
        style={{ maxHeight: "calc(100vh - 160px)" }}
      />
    </div>
  );
}

function Action({ index, handleClick, handlePassClick, handleDeleteClick }) {
  const [showPopup, setShowPopup] = useState(false);
  const popupRef = useRef(null);

  const closePopup = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setShowPopup(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", closePopup);
    return () => {
      document.removeEventListener("click", closePopup);
    };
  }, []);

  return (
    <div className="relative">
      <div className="flex items-center gap-3">
        <FaEdit
          className={"text-black cursor-pointer"}
          onClick={(e) => {
            setShowPopup(true);
            e.stopPropagation();
          }}
        />
        <div className=" w-[2px] h-8 bg-gray-200"></div>
        <button
          className="cursor-pointer"
          onClick={() => handleDeleteClick(index)}
        >
          <FaTrashAlt className="text-red-600" />
        </button>
      </div>
      {showPopup && (
        <div
          className="relative bg-white border border-gray-300 p-2 mt-2  rounded shadow-md"
          ref={popupRef}
          onClick={(e) => e.stopPropagation()}
        >
          <ul className="text-xs">
            <li
              className="mt-1 cursor-pointer hover:font-semibold"
              onClick={() => {
                setShowPopup(false);
                handlePassClick(index);
              }}
            >
              Change Password
            </li>
            <li
              className="mt-1 cursor-pointer hover:font-semibold"
              onClick={() => {
                setShowPopup(false);
                handleClick(index);
              }}
            >
              Edit Details
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

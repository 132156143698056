import React, { useEffect } from "react";
import { useState } from "react";
import ReactDatePicker from "react-datepicker";
import "../../utils/styles/picker.css";
import { APPROVED, DISAPPROVED, PENDING, REVISED } from "../../utils/Constants";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { serverGET } from "../../HttpFunctions";
import { useQuery } from "react-query";
import { getTaskTypes } from "../../api";
import { useAuth } from "src/context/Auth";
import { MultiSelect } from "./MultiSelect";

export type FiltersModel = {
  status: string[];
  type: string[];
  employee: string[];
  project: string[];
  client: string[];
  date: null | Date;
  endDate: null | Date;
  create_startDate?: null | Date;
  create_endDate?: null | Date;
};

type FilteredChecksProps = {
  setOptionsSelected: React.Dispatch<React.SetStateAction<any[] | any>>;
  optionsSelected: FiltersModel;
  employeeOptions?: { value: string; label: string }[];
  projectOptions: { value: string; label: string }[];
  clientOptions: { value: string; label: string }[];
  isAdmin?: boolean;
  setPage?: React.Dispatch<React.SetStateAction<number>>;
  handleDownload?: () => Promise<void>;
  taskLength: number;
};

export default function FilteredChecks({
  setOptionsSelected,
  optionsSelected,
  employeeOptions,
  projectOptions,
  clientOptions,
  isAdmin,
  setPage,
  handleDownload,
  taskLength,
}: FilteredChecksProps) {
  const { data: taskTypeData } = useQuery("taskTypes", () =>
    serverGET(getTaskTypes)
  );

  const stausOptions = [
    { value: APPROVED, label: "Approved" },
    { value: DISAPPROVED, label: "Denied" },
    { value: PENDING, label: "Pending" },
    { value: REVISED, label: "Revised" },
  ];

  const [isOpen, setIsOpen] = useState(true);
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  var width = document.body.clientWidth;
  const { user } = useAuth();

  useEffect(() => {
    const handleResize = () => {
      setIsMobileScreen(window.innerWidth < 768); // Adjust the breakpoint as needed
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Check on initial render

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const typeOptions = taskTypeData?.taskTypes?.map((x) => ({
    value: x.name,
    label: x.name,
  }));
  return (
    <div className="block md:flex md:flex-start gap-4 mb-3 md:items-start md:flex-wrap">
      <div className={`flex z-40 mb-5 md:mb-0`}>
        <div className="flex items-center z-40">
          <h3 className="text-gray-600 mr-5">Search by: </h3>
          <MultiSelect
            label={"client"}
            options={clientOptions}
            setOptionsSelected={setOptionsSelected}
            optionsSelected={optionsSelected}
            setPage={setPage}
          />
        </div>
        <div className="flex items-center cursor-pointer text-customPurple-400 ml-auto md:hidden">
          {isOpen ? (
            <div className="flex items-center" onClick={toggleAccordion}>
              Filters
              <IoIosArrowUp />
            </div>
          ) : (
            <div className="flex items-center" onClick={toggleAccordion}>
              Filters
              <IoIosArrowDown />
            </div>
          )}
        </div>
      </div>

      <div
        className={`${
          isOpen ? "block" : "hidden"
        } transition ease-out duration-300 relative  z-30 flex-shrink-0 flex-1 flex gap-5 flex-wrap md:flex-nowrap
    `}
      >
        <MultiSelect
          label={"project"}
          options={projectOptions}
          setOptionsSelected={setOptionsSelected}
          optionsSelected={optionsSelected}
          setPage={setPage}
        />

        {!isAdmin && (
          <div
            className={`${
              optionsSelected.employee.length === 0 ? " max-h-[36px]" : ""
            }`}
          >
            <MultiSelect
              label={"employee"}
              options={employeeOptions}
              setOptionsSelected={setOptionsSelected}
              optionsSelected={optionsSelected}
              setPage={setPage}
            />
          </div>
        )}
        <div
          className={`${
            optionsSelected.type.length === 0 ? " max-h-[36px]" : ""
          }`}
        >
          <MultiSelect
            label={"type"}
            options={typeOptions}
            setOptionsSelected={setOptionsSelected}
            optionsSelected={optionsSelected}
            setPage={setPage}
          />
        </div>

        <div
          className={`${
            optionsSelected.status.length === 0 ? " max-h-[36px]" : ""
          }`}
        >
          <MultiSelect
            label={"status"}
            options={stausOptions}
            setOptionsSelected={setOptionsSelected}
            optionsSelected={optionsSelected}
            setPage={setPage}
          />
        </div>
        <div className="flex max-h-[36px]">
          <ReactDatePicker
            selectsRange={true}
            className={` ${
              optionsSelected.date && optionsSelected.endDate ? "w-52" : "w-36"
            }  placeholder-gray-500 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block pl-2.5 p-2 customPicker`}
            placeholderText="Select date range"
            onChange={(dates) => {
              const [start, end] = dates;
              setPage(0);
              setOptionsSelected({
                ...optionsSelected,
                date: start,
                endDate: end,
              });
            }}
            startDate={optionsSelected.date}
            endDate={optionsSelected.endDate}
            isClearable={true}
          /> 
        </div>
        {user?.type_of_user.includes("admin") && (
          <div className="flex max-h-[36px]">
            <ReactDatePicker
              selectsRange={true}
              className={` ${
                optionsSelected.create_startDate &&
                optionsSelected.create_endDate
                  ? "w-52"
                  : "w-36"
              }  placeholder-gray-500 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block pl-2.5 p-2 customPicker`}
              placeholderText="Select create range"
              onChange={(dates) => {
                const [start, end] = dates;
                setPage(0);
                setOptionsSelected({
                  ...optionsSelected,
                  create_startDate: start,
                  create_endDate: end,
                });
              }}
              startDate={optionsSelected.create_startDate}
              endDate={optionsSelected.create_endDate}
              isClearable={true}
            />
          </div>
        )}
        <div className="flex-1 text-end">
          <button
            className="px-4 py-2 border border-customPurple-400 text-customPurple-400 rounded-md enabled:secondary-hover-effect disabled:border-gray-400 disabled:text-gray-400"
            onClick={handleDownload}
            disabled={!!!taskLength}
          >
            Export
          </button>
        </div>
      </div>
    </div>
  );
}

import React from "react";

export default function TaskDescription({
  addDescription,
  setAddDescription,
  regProp,
  error,
}: {
  addDescription: boolean;
  setAddDescription;
  regProp;
  error;
}) {
  return (
    <div>
      <p
        className="text-sm my-4 text-customPurple-400 font-semibold cursor-pointer"
        onClick={() => {
          setAddDescription((prev) => !prev);
        }}
      >
        {!addDescription ? "Add Task description +" : " Add Task description -"}
      </p>
      {addDescription && (
        <div className="mt-4">
          <textarea
            id="description"
            rows={5}
            className="block p-2.5 w-full text-sm text-gray-900 bg-white rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500"
            placeholder="Task Description"
            // {...register(`tasks.${index}.description`)}
            {...regProp}
          ></textarea>

          <p className="text-sm text-red-500">
            {error}
            {/* {errors.description?.message} */}
          </p>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PrimaryButton, SecondaryButton } from "../Global/Button";
import Input from "../Global/Input";
import Autocomplete from "react-autocomplete";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { getClients } from "../../api";
import { serverGET, serverPUT } from "../../HttpFunctions";
import * as yup from "yup";
import Select from "../Global/Select";
import { ACTIVE, ARCHIVED } from "../../utils/Constants";
import { successNotfiy } from "../Global/Toasts";
import ManagerAutoComplete from "../Global/AutoComplete";

export const schema = yup
  .object({
    project_name: yup.string().required(),
    client: yup
      .object({
        name: yup.string().required(),
        _id: yup.string().required(),
      })
      .required(),
    // pm: yup
    //   .object({
    //     name: yup.string().required(),
    //     _id: yup.string().required(),
    //   })
    //   .required(),
    // status: yup.boolean().required(),
    status: yup.string().required(),
  })
  .required();

type EditProjectProps = {
  editProject: any;
  setEditProject: any;
  clientId?: string;
  // handleClose: () => void;
};

export default function EditProject({
  // handleClose,
  editProject,
  setEditProject,
  clientId,
}: EditProjectProps) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      project_name: "",
      client: {
        _id: "",
        name: "",
      },
      // pm: {
      //   _id: "",
      //   name: "",
      // },
      status: "",
    },
  });
  const queryClient = useQueryClient();
  const { data: clientData } = useQuery("clients", () => serverGET(getClients));
  const { data: pmData } = useQuery("pm", () => serverGET("/user/pms"));

  const [client, setClient] = useState(
    () =>
      watch("client") ?? {
        _id: "",
        name: "",
      } //change this
  );
  const [clientName, setClientName] = useState("");
  // const [pmName, setPmName] = useState("");
  const [pmNames, setPmNames] = useState(null);

  // const [pm, setPm] = useState(
  //   () =>
  //     watch("pm") ?? {
  //       _id: "",
  //       name: "",
  //     } //change this
  // );

  useEffect(() => {
    setValue("client", client);
  }, [client]);

  useEffect(() => {
    if (clientData?.clients?.length > 0) {
      const cli = clientData.clients;
      const found = cli.find((c) => c.name === clientName);
      if (found) {
        setClient(found);
      }
    }
  }, [clientName]);

  //All thingds related to PM are important. DO NOT DELETE
  // useEffect(() => {
  //   setValue("pm", pm);
  // }, [pm]);

  // useEffect(() => {
  //   if (pmData?.length > 0) {
  //     const pm = pmData;
  //     let found = pm.find((p) => p.first_name == pmName);

  //     if (found) {
  //       setPm({
  //         _id: found._id,
  //         name: found.first_name,
  //       });
  //     }
  //   }
  // }, [pmName]);

  const mutation = useMutation({
    mutationFn: (project: any) => {
      return serverPUT(`/project/${project._id}`, project);
    },
    onError: (error, variables, context: any) => {
      console.log(`rolling back optimistic update with id ${context.id}`);
    },
    onSuccess: (updatedData: any, variables, context) => {
      queryClient.setQueryData(["projects"], (oldData: any) => {
        const projects = oldData.projects.map((project) => {
          return project._id === updatedData.project._id
            ? updatedData.project
            : project;
        });

        return {
          projects,
        };
      });

      successNotfiy("Project has been updated successfully");
    },
  });

  useEffect(() => {
    if (editProject) {
      reset(editProject);

      // setPm({
      //   _id: editProject.project_manager?._id,
      //   name: editProject.project_manager?.first_name,
      // });

      setValue("status", editProject.is_active === true ? ACTIVE : ARCHIVED);
      setClientName(editProject.client.name);
      let formatted = editProject?.project_manager.map((pm) => {
        return {
          label: pm.first_name + " " + pm.last_name,
          value: pm._id,
        };
      });
      setPmNames(formatted.length > 0 ? formatted[0] : null);

      // setPmName(
      //   editProject?.project_manager?.first_name
      //     ? editProject?.project_manager?.first_name
      //     : ""
      // " " +
      // editProject.project_manager.last_name
      // );
    }
  }, [editProject]);

  const handleClose = () => {
    setEditProject("");
  };

  function onSubmit(data) {
    let project_manager = [];

    if (pmNames && Object.keys(pmNames).length > 0) {
      // project_manager = pmNames.map((pm) => pm.value);
      project_manager.push(pmNames.value);
    }

    let requestData = {
      _id: data._id,
      project_name: data.project_name,
      client: data.client._id,
      // project_manager: data.pm._id,
      project_manager: project_manager,
      is_active: data.status === ACTIVE ? true : false,
    };

    console.log("🚀 ~ onSubmit ~ requestData:", requestData);
    return;
    mutation.mutate(requestData);
    handleClose();
  }
  const handlePmChange = (value) => {
    setPmNames(value);
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-3xl">
          {/*content*/}
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center flex-col justify-center p-5 border-b border-solid border-slate-200 rounded-t">
              <button
                className="p-1 ml-auto bg-transparent border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={handleClose}
              >
                <span className="bg-transparent text-black h-6 w-6 text-2xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
              <h3 className="text-xl font-semibold">Edit Project</h3>
              <h5 className="text-sm">
                Input relevant data and click on Update to update project
              </h5>
            </div>

            {/*body*/}
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="relative p-6 flex-auto">
                <div className="grid md:grid-cols-2 gap-8 my-2">
                  <div>
                    <div>
                      <Input
                        label={"Project Name"}
                        placeholder="Enter project name"
                        type={"text"}
                        name="name"
                        regProp={{ ...register("project_name") }}
                        error={errors.project_name?.message}
                      />
                    </div>
                    {!!clientId ? null : (
                      <div>
                        <span className="block text-sm font-medium text-slate-700">
                          Client
                        </span>

                        {clientData && (
                          <Autocomplete
                            getItemValue={(item) => item?.name}
                            items={clientData?.clients ?? []}
                            shouldItemRender={(item, value) =>
                              item?.name
                                ?.toLowerCase()
                                ?.indexOf(value?.toLowerCase()) > -1
                            }
                            wrapperStyle={{
                              display: "block",
                              position: "relative",
                            }}
                            inputProps={{
                              className:
                                "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                            }}
                            renderItem={(item, highlighted) => (
                              <div
                                key={item?._id}
                                style={{
                                  backgroundColor: highlighted
                                    ? "#eee"
                                    : "transparent",
                                }}
                                className="p-2"
                              >
                                {item?.name}
                              </div>
                            )}
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            onSelect={(val) => setClientName(val)}
                            menuStyle={{
                              zIndex: 999,
                              boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                              position: "absolute",
                              left: 0,
                              top: 44,
                              backgroundColor: "white",
                              borderRadius: "8px",
                              overflow: "auto",
                              maxHeight: "200px",
                            }}
                          />
                        )}
                        <p className="text-sm text-red-500">
                          {errors?.client?.message}
                        </p>
                      </div>
                    )}
                  </div>
                  <div>
                    {/* <Input
                      label={"Project ID"}
                      placeholder="Enter project ID"
                      type={"string"}
                      name="projectId"
                      regProp={{ ...register("projectId") }}
                      error={errors.projectId?.message}
                    /> */}

                    {/* <div>
                      <span className="block text-sm font-medium text-slate-700">
                        Project Manager
                      </span>

                      {pmData && (
                        <Autocomplete
                          getItemValue={(item) => item?.first_name}
                          items={pmData ?? []}
                          shouldItemRender={
                            (item, value) => item?.first_name?.toLowerCase()
                            // ?.indexOf(value?.toLowerCase()) > -1
                          }
                          wrapperStyle={{
                            display: "block",
                            position: "relative",
                          }}
                          inputProps={{
                            className:
                              "first-letter:uppercase bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5",
                          }}
                          renderItem={(item, highlighted) => (
                            <div
                              key={item?._id}
                              style={{
                                backgroundColor: highlighted
                                  ? "#eee"
                                  : "transparent",
                              }}
                              className="p-2"
                            >
                              {item?.first_name}
                            </div>
                          )}
                          value={pmName}
                          onChange={(e) => setPmName(e.target.value)}
                          onSelect={(val) => setPmName(val)}
                          menuStyle={{
                            zIndex: 999,
                            boxShadow: "rgb(0 0 0 / 35%) 0px 2px 5px 0px",
                            position: "absolute",
                            left: 0,
                            top: 44,
                            backgroundColor: "white",
                            borderRadius: "8px",
                            overflow: "auto",
                            maxHeight: "200px",
                          }}
                        />
                      )}
                      <p className="text-sm text-red-500">
                        {errors?.pm?.message}
                      </p>
                    </div> */}
                    <div>
                      <Select
                        label={"Status"}
                        id="status"
                        regProp={{ ...register("status") }}
                        options={[ACTIVE, ARCHIVED]}
                        error={errors.status?.message}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <span className="block text-sm font-medium text-slate-700 ">
                    Project Manager
                  </span>
                  <ManagerAutoComplete
                    data={pmData}
                    handlePmChange={handlePmChange}
                    pmNames={pmNames}
                  />
                </div>
              </div>

              {/*footer*/}
              <div className="flex bg-customGrey-100 items-center justify-center p-6 rounded-b">
                <div className="pr-4">
                  <SecondaryButton onClick={handleClose} text="Cancel" />
                </div>
                <PrimaryButton text="Update" mutation={mutation} />
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
